var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "slide-fade"
    }
  }, [(!_vm.$store.getters.getIsMobile ? _vm.showPanel : _vm.selected == 'PinnedMsgs') ? _c('div', {
    ref: "abc",
    staticClass: "rightpanel effect5 ifMobile",
    attrs: {
      "id": "rightpanel"
    }
  }, [!_vm.$store.getters.getIsMobile ? _c('div', {
    staticClass: "dialer-pinArea",
    class: [_vm.is_pinned === true ? 'isPinned' : '']
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialer-pinIcon"
    },
    on: {
      "click": function ($event) {
        _vm.is_pinned = !_vm.is_pinned;
      }
    }
  }), _c('small', {
    staticClass: "sm-mar-left"
  }, [_vm._v(_vm._s(_vm.is_pinned === true ? "Unpin" : "Pin"))])], 1) : _vm._e(), _vm.selected != 'PowerDialer' ? _c('h2', {
    staticClass: "d-flex flex-wrap align-items-center justify-content-between"
  }, [_c('span', {
    staticClass: "d-block title"
  }, [_vm._v(_vm._s(_vm.titleName))]), _vm.$store.getters.getIsMobile ? _c('div', {
    on: {
      "click": function ($event) {
        return _vm.handleMenu();
      }
    }
  }, [_c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.5"
    }
  })], 1) : _vm._e()]) : _vm._e(), _c('div', {
    class: [_vm.selected === 'PinnedMsgs' ? 'pinnedMsg-content' : '']
  }, [_c(_vm.selected, {
    ref: _vm.selected.toLowerCase(),
    tag: "component",
    on: {
      "dial": function ($event) {
        return _vm.dial($event);
      },
      "videocall": function ($event) {
        return _vm.videoCall($event);
      },
      "close-side-bar": function ($event) {
        return _vm.closeSideBarChat();
      },
      "pinned": function ($event) {
        _vm.is_pinned = true;
      }
    }
  })], 1)]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }