<template>
  <component
    :is="component"
    :class="{
      'visibleTransition': isVisible,
    }"
    v-observe-visibility="{
      callback: visibilityChanged,
      intersection: {
        threshold: 1,
      },
    }"
    :call="call"
    :conditions="condition"
    @call="dial(call,$event)"
    @sms="sms(call,$event)"
    @info="$emit('info',$event)"
    @assign="$emit('assign')"
    @transcription="$emit('transcription')"
    @play-audio="$emit('play-audio',$event)"
  >
    <template v-for="(slot,key) in $scopedSlots" v-slot:[key]>
      <slot :name="key"></slot>
    </template>
  </component>
</template>

<script>
import SipCallLog from './SipCallLog.vue'
import JitsiCallLog from './JitsiCallLog.vue'
import VoicemailLog from './VoicemailLog.vue'
import SMSLog from './SMSLog.vue'
import AMILog from './AMILog.vue'
import { events } from '@/utils'
import { number_formater } from '@/filter'
import _ from "lodash";
export default {
  name: "CallActivityItem",
  components: {
    SipCallLog,
    JitsiCallLog,
    VoicemailLog,
    SMSLog,
    AMILog,
  },
  props: {
    call: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    conditions: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      isVisible: false
    }
  },
  computed: {
    component() {
      if(this.call.call_type=='audio') return 'SipCallLog'
      else if(this.call.call_type=='video') return 'JitsiCallLog'
      else if(this.call.call_type=='voicemail') return 'VoicemailLog'
      else if(this.call.call_type=='sms') return 'SMSLog'
      else if(this.call.ami) return 'AMILog'
      return '' 
    },
    condition(){
      return {
        design: {
          tag: _.isUndefined(_.get(this.conditions,'design.tag')) ? true : _.get(this.conditions,'design.tag'),
          note: _.isUndefined(_.get(this.conditions,'design.note')) ? true : _.get(this.conditions,'design.note'),
          recording: _.isUndefined(_.get(this.conditions,'design.recording')) ? true : _.get(this.conditions,'design.recording'),
          sip_recording: _.isUndefined(_.get(this.conditions,'design.sip_recording')) ? false : _.get(this.conditions,'design.sip_recording'),
          transfer_log: _.isUndefined(_.get(this.conditions,'design.transfer_log')) ? true : _.get(this.conditions,'design.transfer_log'),
          message: _.isUndefined(_.get(this.conditions,'design.message')) ? true : _.get(this.conditions,'design.message'),
          design_width: _.isUndefined(_.get(this.conditions,'design.design_width')) ? 50 : _.get(this.conditions,'design.design_width'),
          more: _.isUndefined(_.get(this.conditions,'design.more')) ? true : _.get(this.conditions,'design.more'),
        },
        action: {
          enable: _.isUndefined(_.get(this.conditions,'action.enable')) ? true : _.get(this.conditions,'action.enable'),
          assign: _.isUndefined(_.get(this.conditions,'action.assign')) ? false : _.get(this.conditions,'action.assign'),
          add_contact: _.isUndefined(_.get(this.conditions,'action.add_contact')) ? true : _.get(this.conditions,'action.add_contact'),
          call: _.isUndefined(_.get(this.conditions,'action.call')) ? true : _.get(this.conditions,'action.call'),
          info: _.isUndefined(_.get(this.conditions,'action.info')) ? true : _.get(this.conditions,'action.info'),
          assigned_detail: _.isUndefined(_.get(this.conditions,'action.assigned_detail')) ? false : _.get(this.conditions,'action.assigned_detail'),
          add_task: _.isUndefined(_.get(this.conditions,'action.add_task')) ? true : _.get(this.conditions,'action.add_task'),
          play_audio: _.isUndefined(_.get(this.conditions,'action.play_audio')) ? true : _.get(this.conditions,'action.play_audio'),
          transcription: _.isUndefined(_.get(this.conditions,'action.transcription')) ? true : _.get(this.conditions,'action.transcription'),
        },
      }
    },
  },
  methods: {
    visibilityChanged (isVisible) { // , entry
      this.isVisible = isVisible
    },
    dial(call,event){
      const { dialable, call_type, type } = event
      if(type=='jitsi') {
        this.$root.$emit(events.video_call, { 
          accounts: typeof dialable=="string" ? [dialable] : dialable, 
          call_type: call_type ?? 'audio' 
        });
      } else {
        this.$root.$emit(events.audio_call, { 
          number: number_formater(dialable) 
        });
      }
    },
    sms(call,event){
      const { number } = event
      this.$root.$emit(events.send_sms, { dialable: number });
    },
  },
};
</script>

<style>
</style>