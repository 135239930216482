<template>
  <div class="powerDialerContactInfoInPlaceOfCallLogs" style="flex: 1;overflow-y: auto;">
    <div class="latestGreyBox-9-9-2023">
      <div class="w-100 d-flex justify-content-start align-items-center">
        <div class="powerDialerContactInfoInPlaceOfCallLogs-heading">Auto call info</div>
        <b-button variant="primary" type="button" class="AutoCallInfo-button" @click="$emit('toggle-pause')">
          <b-icon :icon="`${paused?'play-fill':'pause-fill'}`" />
          {{paused?'Resume':'Pause'}}
        </b-button>
      </div>
      <template v-if="api.get_info.send && !response.info">
        <div class="whiteBoxWithBorderInsideGreyBox">
          <div class="whiteBoxWithBorderInsideGreyBox-row min-height-none py-3" v-for="n in 10" :key="n">
            <div class="latestShimmerDesign w-100" style="height:25px;"></div>
          </div>
        </div>
      </template>
      <template v-else>
        <!-- tags -->
        <div class="w-100 d-flex justify-content-between align-items-center mt-20px">
          <div class="powerDialerContactInfoInPlaceOfCallLogs-heading bigger">
            Tags 
          </div>
          <b-button variant="link" class="AutoCallInfo-EditButton" @click="$modal.show(`${_uid}-PowerDialerContactUpdateTags`,{ row_no: row_no, sheet_id: sheet_id, })">
            <b-icon-pencil-fill variant="primary" /> Edit
          </b-button>
        </div>
        <div class="whiteBoxWithBorderInsideGreyBox mt-10px">
          <div class="whiteBoxWithBorderInsideGreyBox-row min-height-none flex-wrap py-3 justify-content-start">
            <template v-if="tags.length==0">
              <div class="d-flex w-100 flex-column align-items-center">
                <vb-icon icon="noDataFound-v2" width="57" height="63" />
                <span>
                  <b>There is no Tags.</b>
                </span>
              </div>
            </template>
            <template v-else>
              <div class="m-1" v-for="tag in tags" :key="tag.id">
                <div 
                  v-vb-tag-color.text.bg="{
                    color: tag.colour,
                    text_color: tag.text_color
                  }" 
                  :style="`border-radius:4px;height:auto;width:fit-content;padding:0.2rem 0.4rem;font-size: 10.5px;font-weight: 500;line-height: 1.3;text-align: center;vertical-align: initial;white-space: nowrap;`" >
                  {{tag.tag}}
                </div>
              </div>
            </template>
          </div>
        </div>
        <!-- note -->
        <div class="w-100 d-flex justify-content-between align-items-center mt-20px">
          <div class="powerDialerContactInfoInPlaceOfCallLogs-heading bigger">
            Notes 
          </div>
          <b-button variant="link" class="AutoCallInfo-EditButton" @click="$modal.show(`${_uid}-PowerDialerContactUpdateNote`,{ row_no: row_no, sheet_id: sheet_id, })">
            <b-icon-pencil-fill variant="primary" /> Edit
          </b-button>
        </div>
        <!-- <div class="latestGreyBox-heading-main-16px mt-20px">Notes <b-button variant="link" @click="$modal.show(`${_uid}-PowerDialerContactUpdateNote`,{ row_no: row_no, sheet_id: sheet_id, })"><b-icon-pencil-fill variant="primary" /> Edit</b-button></div> -->
        <div class="whiteBoxWithBorderInsideGreyBox mt-10px">
          <div class="whiteBoxWithBorderInsideGreyBox-row min-height-none flex-wrap py-3 justify-content-start">
            <template v-if="note==''">
              <div class="d-flex w-100 flex-column align-items-center">
                <vb-icon icon="noDataFound-v2" width="57" height="63" />
                <span>
                  <b>There is no Note.</b>
                </span>
              </div>
            </template>
            <template v-else>
              <div>{{ note }}</div>
            </template>
          </div>
        </div>
        <!-- schedule -->
        <div class="w-100 d-flex justify-content-between align-items-center mt-20px">
          <div class="powerDialerContactInfoInPlaceOfCallLogs-heading bigger">
            Schedule 
          </div>
          <b-button variant="link" class="AutoCallInfo-EditButton" @click="$modal.show(`${_uid}-PowerDialerContactUpdateSchedule`,{ row_no: row_no, sheet_id: sheet_id, })">
            <b-icon-pencil-fill variant="primary" /> Edit
          </b-button>
        </div>
        <!-- <div class="latestGreyBox-heading-main-16px mt-20px">Schedule <b-button variant="link" @click="$modal.show(`${_uid}-PowerDialerContactUpdateSchedule`,{ row_no: row_no, sheet_id: sheet_id, })"><b-icon-pencil-fill variant="primary" /> Edit</b-button></div> -->
        <div class="whiteBoxWithBorderInsideGreyBox mt-10px">
          <div class="whiteBoxWithBorderInsideGreyBox-row min-height-none flex-wrap py-3 justify-content-start">
            <template v-if="schedule==''">
              <div class="d-flex w-100 flex-column align-items-center">
                <vb-icon icon="noDataFound-v2" width="57" height="63" />
                <span>
                  <b>Schedule is not set</b>
                </span>
              </div>
            </template>
            <template v-else>
              <div>{{ schedule | filter_date_current('YYYY-MM-DD hh:mm:ss',{ formate: 'YYYY-MM-DD hh:mm a' }) }}</div>
            </template>
          </div>
        </div>
        <!-- contact info -->
        <div class="powerDialerContactInfoInPlaceOfCallLogs-heading bigger mt-20px">
          Contact Info 
        </div>
        <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
          <div v-for="(value, key) in info" :key="key" class="whiteBoxWithBorderInsideGreyBox-row min-height-none py-3 justify-content-start">
            <b style="min-width:40%;width:40%;text-transform: capitalize;">{{key}}:</b>
            <span>{{value}}</span>
          </div>
        </div>
      </template>
      <PowerDialerContactUpdateNoteModal :modalName="`${_uid}-PowerDialerContactUpdateNote`" @updated="fetchInfoDebounce()" />
      <PowerDialerContactUpdateTagsModal :modalName="`${_uid}-PowerDialerContactUpdateTags`" @updated="fetchInfoDebounce()" />
      <PowerDialerContactUpdateScheduleModal :modalName="`${_uid}-PowerDialerContactUpdateSchedule`" @updated="fetchInfoDebounce()" />
    </div>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils'
import _ from 'lodash'
import PowerDialerContactUpdateNoteModal from '../Modals/PowerDialerContactUpdateNoteModal.vue';
import PowerDialerContactUpdateTagsModal from '../Modals/PowerDialerContactUpdateTagsModal.vue';
import PowerDialerContactUpdateScheduleModal from '../Modals/PowerDialerContactUpdateScheduleModal.vue';
export default {
  name: 'PowerDialerContactInfo',
  components: {
    PowerDialerContactUpdateNoteModal,
    PowerDialerContactUpdateTagsModal,
    PowerDialerContactUpdateScheduleModal,
  },
  inject: [
    'appNotify',
  ],
  props: {
    row_no: {
      type: [String,Number],
      default: ''
    },
    sheet_id: {
      type: [String,Number],
      default: ''
    },
    paused: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      api: {
        get_info: this.$helperFunction.apiInstance({  }), 
      },
      response: {
        info: null,
      },
    }
  },
  computed: {
    info(){ return this.response.info?.result ?? {} },
    tags(){ return this.response.info?.data?.tags || [] },
    note(){ return this.response.info?.data?.notes || '' },
    schedule(){ return this.response.info?.data?.scheduled_date || '' },
  },
  methods: {
    fetchInfoDebounce: _.debounce(function(){
      this.fetchInfo()
    }, 2 * 1000),
    async fetchInfo(){
      if(this.api.get_info.send) return;
      try {
        this.api.get_info.send=true
        const { data } = await VOIP_API.endpoints.power_dialer.getNextNumber(this.sheet_id,{
          row_no: this.row_no
        })
        this.response.info=data
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.get_info.send=false
      }
    },
  },
  mounted(){
    this.fetchInfo()
  },
}
</script>

<style>

</style>