var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "callActivity-Item-v2-container",
    class: [_vm.call.status == 'Dialed' ? 'Right' : 'Left', {
      'missedCall': _vm.call.status == 'Missed',
      'recievedCall': _vm.call.status == 'Received',
      'dialledCall': _vm.call.status == 'Dialed'
    }]
  }, [_c('div', {
    staticClass: "callActivity-Item-v2-inner-top"
  }, [_c('div', {
    staticClass: "icon-container"
  }, [_vm.call.status == 'Missed' ? _c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: 'Missed Audio Call',
      expression: "'Missed Audio Call'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    attrs: {
      "icon": "CallActivityV2-missedCall-icon",
      "height": "55.922",
      "width": "55.922"
    }
  }) : _vm.call.status == 'Received' ? _c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: 'Received Audio Call',
      expression: "'Received Audio Call'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    attrs: {
      "icon": "CallActivityV2-receivedCall-icon",
      "height": "55.922",
      "width": "55.922"
    }
  }) : _c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: 'Dialed Audio Call',
      expression: "'Dialed Audio Call'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    attrs: {
      "icon": "CallActivityV2-dialledCall-icon",
      "height": "55.922",
      "width": "55.922"
    }
  })], 1), _c('div', {
    staticClass: "user-info"
  }, [_vm.call.is_out_of_hours ? _c('small', [_vm._v("Business hour")]) : _vm._e(), _vm.call.status == 'Dialed' ? [_vm.call.dst_type == 'Number' ? [_c('div', {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.dst_number)))])] : [_c('div', {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm.call.dst_name))]), _c('div', {
    staticClass: "user-number"
  }, [_vm._v(_vm._s(_vm.call.dst_extn))])], !_vm.getIsMobile ? _c('div', {
    staticClass: "via-text"
  }, [_vm.call.dst_type == 'Number' ? [_vm._v("via "), _c('b', [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.src_number)))])] : _vm._e()], 2) : _vm._e()] : [_vm.call.src_type == 'Number' ? [_c('div', {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.src_number)))])] : [_c('div', {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm.call.src_name))]), _c('div', {
    staticClass: "user-number"
  }, [_vm._v(_vm._s(_vm.call.src_extn))])], !_vm.getIsMobile ? _c('div', {
    staticClass: "via-text"
  }, [_vm.call.dst_type == 'Number' ? [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.dst_number)))] : _vm.call.dst_number ? [_vm._v("called "), _c('b', [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.dst_number)) + " " + _vm._s(_vm.call.dst_name ? `(${_vm.call.dst_name})` : ''))])] : [_vm._v(_vm._s(_vm.call.dst_name))]], 2) : _vm._e()]], 2), _c('div', {
    staticClass: "middle-section"
  }, [_c('span', {
    staticClass: "middle-section-text"
  }), _vm.conditions.design.sip_recording && _vm.call.soundFile ? _c('vb-audio-player', {
    attrs: {
      "design": 'minimum',
      "src": _vm.call.soundFile
    }
  }) : _vm._e()], 1), _vm.conditions.action.enable ? _c('div', {
    staticClass: "callLogIcons-container"
  }, [_vm.conditions.action.call ? _c('span', {
    staticClass: "mr-8px"
  }, [_c('vb-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": "Audio call",
      "icon": "call-callLog-icon",
      "width": "35px",
      "height": "35px"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('call', {
          type: 'sip',
          dialable: _vm.dialable
        });
      }
    }
  })], 1) : _vm._e(), _vm.conditions.action.info ? _c('span', {
    staticClass: "mr-8px"
  }, [_c('vb-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "icon": "callInfo-callLog-icon",
      "width": "35px",
      "height": "35px",
      "title": `Call info`
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('info');
      }
    }
  })], 1) : _vm._e(), _vm.conditions.action.add_contact && (_vm.call.status == 'Dialed' ? _vm.call.dst_type == 'Number' && !_vm.call.dst_name : _vm.call.src_type == 'Number' && !_vm.call.src_name) ? _c('span', {
    staticClass: "mr-8px"
  }, [_c('vb-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": "Add to contacts",
      "icon": "addContact-callLog-icon",
      "width": "35px",
      "height": "35px"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.global_conditions.add_addressbooks = _vm.call.status == 'Dialed' ? _vm.call.dst_number : _vm.call.src_number;
      }
    }
  })], 1) : _vm._e(), _vm.conditions.action.add_task && _vm.call.linked_tasks && _vm.call.linked_tasks.length == 0 ? _c('span', {
    staticClass: "mr-8px"
  }, [_c('vb-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "icon": "assignTask-callLog-icon",
      "width": "35px",
      "height": "35px",
      "title": "Add to tasks"
    },
    on: {
      "click": function ($event) {
        return _vm.addTodoTask();
      }
    }
  })], 1) : _vm._e(), _vm.conditions.action.play_audio && _vm.call.soundFile ? _c('span', [_c('vb-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "icon": "playSound-callLog-icon",
      "width": "35px",
      "height": "35px",
      "title": "Call recording"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('play-audio', {
          soundFile: _vm.call.soundFile
        });
      }
    }
  })], 1) : _vm._e(), _vm.conditions.action.transcription && _vm.call.soundFile ? _c('span', [_c('vb-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "aiTranscript",
    attrs: {
      "icon": "ai-audio-icon",
      "width": "21.507px",
      "height": "23.377px",
      "title": "AI transcript"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('transcription');
      }
    }
  })], 1) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "last-section"
  }, [_c('div', {
    staticClass: "time"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("filter_date_current")(parseInt(_vm.call.date), null, {
    unix: true,
    formate: 'h:mm a'
  })))]), _vm.conditions.action.enable && _vm.conditions.action.assign ? _c('b-button', {
    on: {
      "click": function ($event) {
        return _vm.$emit('assign');
      }
    }
  }, [_vm._v("Assign")]) : _vm._e(), _vm.conditions.design.more ? _vm._t("more", function () {
    return [_vm.conditions.action.enable ? _c('b-dropdown', {
      staticClass: "ico-filter",
      attrs: {
        "varient": "link",
        "no-caret": "",
        "menu-class": "w-fit-content-imp"
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('b-icon', {
            attrs: {
              "icon": "three-dots",
              "variant": "#393939",
              "font-scale": "1.4"
            }
          })];
        },
        proxy: true
      }], null, false, 764454405)
    }, [_vm.conditions.action.add_contact && (_vm.call.status == 'Dialed' ? _vm.call.dst_type == 'Number' && !_vm.call.dst_name : _vm.call.src_type == 'Number' && !_vm.call.src_name) ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          _vm.$store.state.global_conditions.add_addressbooks = _vm.call.status == 'Dialed' ? _vm.call.dst_number : _vm.call.src_number;
        }
      }
    }, [_c('vb-icon', {
      staticClass: "dialer-addToContact-icon",
      attrs: {
        "icon": "dialer-addToContact-icon",
        "width": "16.245",
        "height": "15.004"
      }
    }), _c('span', {
      staticClass: "ml-3"
    }, [_vm._v("Add contact")])], 1) : _vm._e(), _vm.conditions.action.call ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.$emit('call', {
            type: 'sip',
            dialable: _vm.dialable
          });
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "telephone",
        "font-scale": "0.96"
      }
    }), _c('span', {
      staticClass: "ml-3"
    }, [_vm._v("Call")])], 1) : _vm._e(), _vm.conditions.action.info ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.$emit('info');
        }
      }
    }, [_c('vb-icon', {
      staticClass: "callInfoIcon",
      attrs: {
        "icon": "dialer-callinfo-icon",
        "width": "15.7",
        "height": "15.701"
      }
    }), _c('span', {
      staticClass: "ml-3"
    }, [_vm._v("Call info")])], 1) : _vm._e(), _vm.conditions.action.assigned_detail && _vm.call.status == 'Missed' ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.$emit('assigned');
        }
      }
    }, [_c('vb-icon', {
      staticClass: "callInfoIcon",
      attrs: {
        "icon": "dialer-callinfo-icon",
        "width": "15.7",
        "height": "15.701"
      }
    }), _c('span', {
      staticClass: "ml-3"
    }, [_vm._v("Assigned detail")])], 1) : _vm._e(), _vm.conditions.action.add_task && _vm.call.linked_tasks && _vm.call.linked_tasks.length == 0 ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.addTodoTask();
        }
      }
    }, [_c('vb-icon', {
      staticClass: "callInfoIcon",
      attrs: {
        "icon": "dialer-callinfo-icon",
        "width": "15.7",
        "height": "15.701"
      }
    }), _c('span', {
      staticClass: "ml-3"
    }, [_vm._v("Add to tasks")])], 1) : _vm._e()], 1) : _vm._e()];
  }) : _vm._e()], 2), _c('div', {
    staticClass: "duration"
  }, [_vm._v(_vm._s(_vm.call.bill_sec))])])]), _c('div', {
    staticClass: "callActivity-Item-v2-inner-2nd"
  }, [_vm.conditions.design.tag ? _c('div', {
    staticClass: "tags-container"
  }, _vm._l(_vm.tags, function (tag) {
    return _c('div', {
      key: tag.id,
      staticClass: "dialer-call-prefix tag"
    }, [_c('small', {
      directives: [{
        name: "vb-tag-color",
        rawName: "v-vb-tag-color",
        value: tag.tag.colour,
        expression: "tag.tag.colour"
      }]
    }, [_c('span', {
      directives: [{
        name: "vb-tag-color",
        rawName: "v-vb-tag-color.text",
        value: tag.tag.text_color,
        expression: "tag.tag.text_color",
        modifiers: {
          "text": true
        }
      }]
    }, [_vm._v(_vm._s(tag.tag.tag))])])]);
  }), 0) : _vm._e(), _vm.conditions.design.note && _vm.note ? _c('span', {
    staticClass: "dialer-call-prefix note"
  }, [_vm._v(_vm._s(_vm.note))]) : _vm._e()]), _vm.conditions.design.transfer_log ? _c('p', {
    staticClass: "extraInfoInCallLogs mb-0 w-100"
  }, [_vm.call.forward_from_type || _vm.call.forward_to_type ? [_c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Forward',
      expression: "'Forward'",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "icon": "callLogs-forward-icon",
      "width": "14.61px",
      "height": "15.595px"
    }
  }), _vm.call.forward_from_type ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', [_vm._v("from")]), _vm.call.forward_from_type == 'Number' ? _c('div', [_c('b', [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.forward_from_number)))])]) : _c('div', [_c('b', [_vm._v(_vm._s(_vm.call.forward_from_name))])])]) : _vm._e(), _vm.call.forward_to_type ? [_c('span', [_vm._v("to")]), _vm.call.forward_to_type == 'Number' ? _c('div', [_c('b', [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.forward_to_number)))])]) : _c('div', [_c('b', [_vm._v(_vm._s(_vm.call.forward_to_name))])])] : _vm._e()] : _vm._e(), _vm.call.answered_extn_type ? [_vm._v(" answered by: "), _c('b', [_vm._v(_vm._s(_vm.call.answered_extn_user_name))]), _c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: _vm.call.answered_extn_name,
      expression: "call.answered_extn_name",
      modifiers: {
        "hover": true
      }
    }],
    class: _vm._f("extensionIcon")(_vm.call.answered_extn_type),
    attrs: {
      "icon": _vm._f("extensionIcon")(_vm.call.answered_extn_type),
      "width": "25px",
      "height": "25px"
    }
  })] : _vm._e(), _vm.call.transfer_to_type ? [_c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Transfer',
      expression: "'Transfer'",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "icon": "callLogs-transfer-icon",
      "width": "16.448px",
      "height": "15.595px"
    }
  }), _c('span', [_vm._v("to")]), _vm.call.transfer_to_type == 'Number' ? _c('span', [_c('b', [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.transfer_to_number)))])]) : _c('span', [_c('b', [_vm._v(_vm._s(_vm.call.transfer_to_name))])])] : _vm._e()], 2) : _vm._e(), _vm.call.linked_tasks && _vm.call.linked_tasks.length > 1 ? _c('p', {
    staticClass: "extraInfoInCallLogs mb-0 w-100"
  }, [_c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Task',
      expression: "'Task'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mr-2",
    attrs: {
      "icon": "callLogs-task-icon",
      "width": "18.1px",
      "height": "18.11px"
    }
  }), _vm._v(" " + _vm._s(_vm.call.linked_tasks.length) + " tasks assigned ")], 1) : _vm.call.linked_tasks && _vm.call.linked_tasks.length == 1 && _vm.call.linked_tasks[0].task_assigned ? _c('p', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "extraInfoInCallLogs forTasks mt-1 mb-0 w-100",
    attrs: {
      "title": `${_vm.call.linked_tasks[0].task_assigned.spaces.prefix}#${_vm.call.linked_tasks[0].task_assigned.id}`
    },
    on: {
      "click": function ($event) {
        _vm.conditions.actions ? _vm.$emit('info', {
          task_modal: true
        }) : '';
      }
    }
  }, [_c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Task',
      expression: "'Task'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mr-2",
    attrs: {
      "icon": "callLogs-task-icon",
      "width": "18.1px",
      "height": "18.11px"
    }
  }), [_c('span', {
    staticClass: "latestBlueColor"
  }, [_vm._v(_vm._s(_vm.call.linked_tasks[0].task_assigned.title))])], _vm.call.linked_tasks[0].task_assigned.due_date ? _c('span', [_vm._v("Due: " + _vm._s(_vm.call.linked_tasks[0].task_assigned.due_date))]) : _vm._e(), [_vm.call.linked_tasks[0].task_assigned.status ? _c('div', {
    staticClass: "categoryText mr-16px ml-4",
    style: `background-color: ${_vm.call.linked_tasks[0].task_assigned.status.color};`
  }, [_vm._v(" " + _vm._s(_vm.call.linked_tasks[0].task_assigned.status.title) + " ")]) : _vm._e(), _vm.call.linked_tasks[0].task_assigned.is_important == 1 ? _c('div', {
    staticClass: "categoryText mx-2 px-1",
    style: `background-color: #F2F2F2;`
  }, [_c('b-icon', {
    attrs: {
      "icon": "exclamation-lg",
      "variant": "danger"
    }
  })], 1) : _vm._e()], _vm.call.linked_tasks[0].task_assigned.assigned.length != 0 ? [_c('vb-icon', {
    attrs: {
      "icon": "callLogs-task-assignedTo-icon",
      "width": "11.851px",
      "height": "14.227px"
    }
  }), _vm._v(" Assigned to "), _c('Info', {
    attrs: {
      "id": _vm.call.linked_tasks[0].task_assigned.assigned[0].assigned_to,
      "only_name": true
    }
  })] : _vm._e()], 2) : _vm._e(), _vm.call.call_recording && _vm.call.call_recording.recording_text ? _c('p', {
    staticClass: "extraInfoInCallLogs callTranscriptText mb-0 w-100"
  }, [_c('span', {
    staticStyle: {
      "font-weight": "800"
    }
  }, [_vm._v("Call transcript :")]), _vm._v(" " + _vm._s(_vm.call.call_recording.recording_text) + " ")]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }