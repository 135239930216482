var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "AfterCallFeedbackModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "AfterCallFeedbackModal-heading"
  }, [_vm._v("CALL ENDED")]), _c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)]), _vm.data.call_info ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-32px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-center justify-content-center"
  }, [_vm.api.fetch_cdr.send ? [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "89px",
      "width": "100%",
      "border-radius": "14px",
      "margin-bottom": "16px"
    }
  })] : [_vm.response.cdr ? _c('CallActivityItem1', {
    staticClass: "w-100 mx-0",
    attrs: {
      "call": _vm.response.cdr,
      "conditions": {
        actions: false
      }
    }
  }) : _vm.callLog ? _c('CallActivityItem1', {
    staticClass: "w-100 mx-0",
    attrs: {
      "call": _vm.callLog,
      "conditions": {
        actions: false
      }
    }
  }) : _vm._e()], _c('div', {
    staticClass: "d-flex flex-column align-items-center justify-content-center pb-5 mt-3"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("How was the quality of your call?")]), _c('b-form-rating', {
    staticClass: "latestCallFeedback",
    attrs: {
      "id": "rating-lg-no-border",
      "no-border": "",
      "size": "lg"
    },
    on: {
      "change": function ($event) {
        return _vm.updateFeedback();
      }
    },
    model: {
      value: _vm.forms.feedback.rating,
      callback: function ($$v) {
        _vm.$set(_vm.forms.feedback, "rating", $$v);
      },
      expression: "forms.feedback.rating"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center justify-content-around w-100"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "callFeedbackModal-Notes-icon",
      "height": "100px",
      "width": "100px"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallFeedbackNoteModal', {
          id: _vm.data.call_info.call_id,
          note: _vm.data.call_info.note
        });
      }
    }
  }), _c('vb-icon', {
    attrs: {
      "icon": "callFeedbackModal-Tags-icon",
      "height": "100px",
      "width": "100px"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallFeedbackTagsModal', {
          id: _vm.data.call_info.call_id,
          tags: _vm.data.call_info.tags
        });
      }
    }
  }), _c('vb-icon', {
    attrs: {
      "icon": "callFeedbackModal-assign-icon",
      "height": "100px",
      "width": "100px"
    },
    on: {
      "click": function ($event) {
        return _vm.addTodoTask();
      }
    }
  })], 1)], 2)])]) : _vm._e()]), _c('NoteModal', {
    attrs: {
      "modalName": `CallFeedbackNoteModal`
    },
    on: {
      "updated": function ($event) {
        return _vm.fetchCDR();
      },
      "updated-note": function ($event) {
        _vm.data.call_info.note = $event;
      }
    }
  }), _c('TagsModal', {
    attrs: {
      "modalName": `CallFeedbackTagsModal`
    },
    on: {
      "updated": function ($event) {
        return _vm.fetchCDR();
      },
      "updated-tags": function ($event) {
        _vm.data.call_info.tags = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }