<template>
  <div class="dialer-analytics-main dialer-analytics-likeSomeThing voicemailAnalytics">
    <div class="dialer-box p-16-24-px">
      <template v-if="$store.getters.getIsMobile">
        <header class="dialer-analytics-titleBar pb-1">
          <div class="dialer-analytics-title calc-title-width-analytics">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
            <h1 class="">Voicemail</h1>
          </div>
        </header>
        <div class="w-100 d-flex align-items-center justify-content-end">
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion userTeamsFilter optionIsdotted filterWithText mr-16px">
            <b-dropdown right>
              <template #button-content> 
                <b class="text-right">{{ `${filters.voicemails.extensions.length ? filters.voicemails.extensions.length : 'No'} selected` }}</b> 
                <div class="d-flex align-items-center">
                  <b-icon v-if="filters.voicemails.extensions.length>0" icon="x-circle-fill" varinat="#000000" class="ml-3" @click.stop="filters.voicemails.extensions=[];fetchReportingVoicemails();" />
                  <b-icon icon="three-dots-vertical" variant="dark" class="fillBlack sm-mar-left" scale="1.5" />
                </div>
              </template>
              <b-dropdown-form>
                <b-check-group v-model="filters.voicemails.extensions" @change="fetchReportingVoicemails();">
                  <b-check v-for="(extension, key) in extensions" :key="key" :value="key">
                    <Info :id="key" :is_blf="false" :is_suspended_show="false" :type="'user,team'" />
                  </b-check>
                </b-check-group>
              </b-dropdown-form>
            </b-dropdown>
          </div>
        </div>
      </template>
      <div v-else class="analytics-newHeader">
        <div class="analytics-newHeader-leftSide">
          <div>
            <h1>Voicemail</h1>
          </div>
        </div>
        <div class="analytics-newHeader-rightSide justify-content-end">
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion userTeamsFilter filterWithText w-33-imp">
            <b-dropdown right>
              <template #button-content> 
                <b class="text-right">{{ `${filters.voicemails.extensions.length ? filters.voicemails.extensions.length : 'No'} selected` }}</b> 
                <div class="d-flex align-items-center">
                  <b-icon v-if="filters.voicemails.extensions.length>0" icon="x-circle-fill" varinat="#000000" class="ml-3" @click.stop="filters.voicemails.extensions=[];fetchReportingVoicemails();" />
                  <b-icon icon="three-dots-vertical" variant="dark" class="fillBlack sm-mar-left" scale="1.5" />
                </div>
              </template>
              <b-dropdown-form>
                <b-check-group v-model="filters.voicemails.extensions" @change="fetchReportingVoicemails();">
                  <b-check v-for="(extension, key) in extensions" :key="key" :value="key">
                    <Info :id="key" :is_blf="false" :is_suspended_show="false" :type="'user,team'" />
                  </b-check>
                </b-check-group>
              </b-dropdown-form>
            </b-dropdown>
          </div>
        </div>
      </div>
      <p class="dialer-settings-intro newerTextVersion mb-0">
        <span>
          Access detailed analytics of voicemails ,to review and respond to messages efficiently.
        </span>
      </p>
      <div class="d-flex align-items-center w-100">
      
      </div>
      <!-- <vb-table 
        class="latestTableDesign-withBlackBorders-again"
        :isListEmpty="voicemails.length==0" 
        :listLength="voicemails.length" 
        :loading="api.fetch_voicemails.send" 
        :noRecordWholePage="true"
      >
        <tr slot="header">
          <th class="dialer-has-sort">
            <span>Number</span>
          </th>
          <th class="dialer-has-sort dialer-col-right dialer-missed">
            <span>Recording</span>
          </th>
        </tr>
        <template #body="{ start, end }">
          <tr v-for="(voicemail, index) in voicemails" :key="index" v-show="index >= start && index <= end">
            <td class="dialer-row-title dialer-col-name-width wd-75">
              <Info :only_name="true" v-if="voicemail.callerid" :id="voicemail.callerid | calleridTextFilter" /> 
              <b>{{voicemail.callerid | calleridTextFilter | number_formater}}</b>
              called 
              <Info :only_name="true" v-if="voicemail.mailboxuser" :id="voicemail.mailboxuser" /> 
            </td>
            <td class="dialer-col-center dialer-missed dialer-col-right wd-25">
              <div class="d-flex justify-content-end w-100">
                <vb-audio-player v-if="voicemail.filename" :src="voicemail.filename" :design="'minimum'" />
              </div>
            </td>
          </tr>
        </template>
        <template #loading>
          <tr v-for="n in 5" :key="n">
            <td>
              <div class="d-flex">
                <div class="latestShimmerDesign mr-2" style="height:35px;width:35px;"></div>
                <div class="d-flex flex-column justify-content-between">
                  <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
                  <div class="latestShimmerDesign mt-1" style="height:16px;width:30px"></div>
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:50px"></div>
              </div>
            </td>
          </tr>
        </template>
        <template #no-record>
          <div class="latestGreyBox-9-9-2023 mb-32px">
          <div class="emptyScreenContainer">
            <img class="mt-50px w-50 h-auto" :src="require('@/assets/images/emptyScreenImages/teamAnalytics.png')"/>
            <div class="emptyScreenContainer-heading">Team analytics: dive into team performance</div>
            <div class="emptyScreenContainer-text w-75">Explore comprehensive analytics for your teams' call activities, including call durations, volumes, and more.</div>
          </div>
        </div>
        </template>
      </vb-table> -->
      <vb-table 
        class="latestTableDesign-withBlackBorders-again"
        :isListEmpty="voicemails.length==0" 
        :listLength="voicemails.length" 
        :loading="api.fetch_voicemails.send" 
        :noRecordWholePage="true"
      >
        <tr slot="header">
          <th class="dialer-has-sort">
            <span>From</span>
          </th>
          <th class="dialer-has-sort">
            <span>To</span>
          </th>
          <th class="dialer-has-sort">
            <span>Shared</span>
          </th>
          <th class="dialer-has-sort">
            <span>Date</span>
          </th>
          <th class="dialer-has-sort dialer-col-right dialer-missed">
            <span>Recording</span>
          </th>
        </tr>
        <template #body="{ start, end }">
          <tr v-for="(voicemail, index) in voicemails" :key="index" v-show="index >= start && index <= end">
            <td class="dialer-row-title">
              <Info :only_name="true" v-if="voicemail.callerid" :id="voicemail.callerid | calleridTextFilter" /> 
              <b>{{voicemail.callerid | calleridTextFilter | number_formater}}</b>
            </td>
            <td class="dialer-row-title">
              <Info :only_name="true" v-if="voicemail.mailboxuser" :id="voicemail.mailboxuser" />
            </td>
            <td class="dialer-row-title">

            </td>
            <td class="dialer-row-title">
              {{ voicemail.origtime | filter_dateTime}}
            </td>
            <td class="dialer-col-center dialer-missed dialer-col-right wd-25">
              <div class="d-flex justify-content-end w-100">
                <vb-audio-player v-if="voicemail.filename" :src="voicemail.filename" :design="'minimum'" />
              </div>
            </td>
          </tr>
        </template>
        <template #loading>
          <tr v-for="n in 5" :key="n">
            <td>
              <div class="d-flex">
                <div class="latestShimmerDesign mr-2" style="height:35px;width:35px;"></div>
                <div class="d-flex flex-column justify-content-between">
                  <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
                  <div class="latestShimmerDesign mt-1" style="height:16px;width:30px"></div>
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:50px"></div>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:50px"></div>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:50px"></div>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:50px"></div>
              </div>
            </td>
          </tr>
        </template>
        <template #no-record>
          <div class="latestGreyBox-9-9-2023 mb-32px">
          <div class="emptyScreenContainer">
            <img class="mt-50px w-50 h-auto" :src="require('@/assets/images/emptyScreenImages/teamAnalytics.png')"/>
            <div class="emptyScreenContainer-heading">Team analytics: dive into team performance</div>
            <div class="emptyScreenContainer-text w-75">Explore comprehensive analytics for your teams' call activities, including call durations, volumes, and more.</div>
          </div>
        </div>
        </template>
      </vb-table>
    </div>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapState } from 'vuex';
import Info from '../Lists/Info.vue';
import moment from 'moment-timezone';
import _ from 'lodash';
export default {
  name: "Voicemail",
  components: { 
    Info, 
  },
  data() {
    return {
      api: {
        fetch_voicemails: this.$helperFunction.apiInstance({ 
          source: true,  
        }),
      },
      response: {
        voicemails: [],
      },
      filters: {
        voicemails: {
          extensions: [],
        },
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapState({
      users: (state)=>Object.values(state.common.voipusers ?? {}),
      teams: (state)=>state.common.teams?.data ?? [],
    }),
    extensions(){ 
      return {
        ..._.keyBy(this.users, 'voipaccount'),
        ..._.keyBy(this.teams, 'ringroup.accountcode'),
      }
    },
    voicemails(){ return this.response.voicemails },
  },
  filters: {
    calleridTextFilter(str){
      return str.substr(str.indexOf('<'),str.length).replace('<','').replace('>','')
    },
    filter_dateTime(value){
      return moment.unix(value).format('MMMM Do YYYY, h:mm a');
    },
  },
  methods: {
    async fetchReportingVoicemails(){
      try {
        this.api.fetch_voicemails.source?.cancel?.("Requesting Again");
        this.api.fetch_voicemails.source=require("axios").default.CancelToken.source()
        this.api.fetch_voicemails.send=true
        const { data } = await VOIP_API.endpoints.call_activity.reportingVoicemail({
          extensions: this.filters.voicemails.extensions,
        },this.api.fetch_voicemails.source.token)
        this.response.voicemails=data?.data ?? []
      } catch (ex) {
        if(ex.cancel) return;
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_voicemails.send=false
      }
    },
  },
  mounted() {
    this.fetchReportingVoicemails();
  },
}
</script>

<style>

</style>