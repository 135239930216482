<template>
  <div>
    <div class="bill-over-lay maintainaceScreen-container" v-if="conditions.maintenance">
      <div class="maintainaceScreen-inner">
        <b-alert :show="!!api.check_maintenance.error_message" variant="danger">{{ api.check_maintenance.error_message }}</b-alert>
        <!-- <img class="maintainaceScreen-img" :src="require('@/assets/images/maintenanceScreen-img.png')" alt="maintainance " />maintenanceScreen-gif.gif -->
        <img class="maintainaceScreen-img" :src="require('@/assets/images/maintenanceScreen-gif.gif')" alt="maintainance " />
        <div class="maintainaceScreen-heading">Maintenance in progress</div>
        <div class="maintainaceScreen-text">
          Sorry for the inconvenience! Our system is taking a short break to come back stronger than ever. 
          <br/>
          <br/>
          Stay tuned for updates!
        </div>
        <button class="maintainaceScreen-button" :disabled="api.check_maintenance.send" @click="checkMaintenance()">
          <!-- Retry -->
          <b-spinner v-if="api.check_maintenance.send" variant="primary" />
          <template v-else>
            Try Again
          </template>
        </button>
      </div>
    </div> 
    <div class="bill-over-lay maintainaceScreen-container" v-if="!conditions.available">
      <div class="maintainaceScreen-inner">
        <img class="maintainaceScreen-img" :src="require('@/assets/images/maintenanceScreen-gif.gif')" alt="maintainance " />
        <b-spinner v-if="updateState.state==1" />
        <template v-else>
          <b-alert :show="updateState.state==6 && updateState.error" variant="danger">{{ updateState.error }}</b-alert>
          <div v-if="updateState.version_available" class="maintainaceScreen-heading">{{ updateState.version_available }}</div>
          <template v-if="updateState.state==4">
            <!-- <div class="maintainaceScreen-text">{{ updateState.downloading.percentage | filterDownloadPercent }}</div>
            <div class="maintainaceScreen-text">{{ updateState.downloading.per_second | format_bytes }}</div>
            <div class="maintainaceScreen-text">{{ updateState.downloading.download | format_bytes }}</div>
            <div class="maintainaceScreen-text">{{ updateState.downloading.total | format_bytes }}</div> -->
            <div class="d-flex align-items-center w-100">
              <b-progress :value="updateState.downloading.percentage | filterDownloadPercent" max="100" class="w-100"></b-progress>
              <div class="maintainaceScreen-text-percentage ml-2"> {{updateState.downloading.percentage | filterDownloadPercent}}% </div>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-between">
              <div class="d-flex">
                <div class="maintainaceScreen-text-speed mr-1"> {{ updateState.downloading.per_second | format_bytes }}/s </div>
                <div class="maintainaceScreen-text-downloaded">- {{ updateState.downloading.download | format_bytes }} </div>
              </div>
              <div class="maintainaceScreen-text-totalToDownload"> {{ updateState.downloading.total | format_bytes }} </div>
            </div>
          </template>
          <button v-else-if="updateState.state!=5" class="maintainaceScreen-button mt-5" @click="download()">Download</button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { TokenService } from '@/services'
import { electron_events } from '../electron/events'
import { LOGGER, VOIP_API } from '../utils'
import { AMI_SOCKET } from '@/ami'
let check_maintenance_interval_instance;
let app_version_interval_instance;
export default {
  name: 'CommonElectron',
  data(){
    return {
      conditions: {
        maintenance: false,
        available: true,
      },
      api: {
        check_maintenance: this.$helperFunction.apiInstance({ error_message: true }),
      }
    }
  },
  computed: {
    updateState(){ return this.$store.state.electron.update },
    isDevelopment(){ return process.env.NODE_ENV!='production' },
  },
  filters: {
    // filterDownloadPercent(value){return `${~~value}%`},
    filterDownloadPercent(value){return `${~~value}`},
  },
  methods: {
    download(){
      console.log('this.$store.state.electron.update',this.$store.state.electron.update)
      if(![1,4,5].includes(this.$store.state.electron.update.state)){
        window.electron.send(electron_events.CHECK_UPDATE)
      }
    },
    async checkMaintenance(){
      let vm = this
      if(this.api.check_maintenance.send || this.isDevelopment) return;
      clearTimeout(check_maintenance_interval_instance)
      try {
        this.api.check_maintenance.send=true
        this.api.check_maintenance.error_message=''
        const { data } = await VOIP_API.endpoints.electron.checkMaintenance({
          domain: TokenService.CP_DOMAIN.get(),
          accountcode: TokenService.USER.get()?.account,
        })
        this.conditions.maintenance=!!data?.maintenance
      } catch(ex) {
        this.api.check_maintenance.error_message=ex.own_message || ex.message
      } finally {
        this.api.check_maintenance.send=false
        check_maintenance_interval_instance = setTimeout(()=>vm.checkMaintenance(),10*60*1000)
      }
    },
    CHECK_FOR_UPDATE(){
      LOGGER.log('CHECK_FOR_UPDATE')
      this.$store.state.electron.update.state=1
    },
    UPDATE_AVAILABLE(event){
      LOGGER.log('UPDATE_AVAILABLE')
      this.$store.state.electron.update.state=2
      this.$store.state.electron.update.version_available=event?.app_version ?? ''
    },
    UPDATE_NOT_AVAILABLE(){
      LOGGER.log('UPDATE_NOT_AVAILABLE')
      this.$store.state.electron.update.state=3
    },
    UPDATE_DOWNLOAD_PROGRESS(event){
      LOGGER.log('UPDATE_DOWNLOAD_PROGRESS',event)
      this.$store.state.electron.update.state=4
      this.$store.state.electron.update.downloading.percentage=(event?.percent ?? 0)
      this.$store.state.electron.update.downloading.per_second=(event?.bytesPerSecond ?? 0)
      this.$store.state.electron.update.downloading.download=(event?.transferred ?? 0)
      this.$store.state.electron.update.downloading.total=(event?.total ?? 0)
    },
    UPDATE_DOWNLOADED(){
      LOGGER.log('UPDATE_DOWNLOADED')
      this.$store.state.electron.update.state=5
    },
    UPDATE_ERROR(event){
      LOGGER.log('UPDATE_ERROR',event?.message)
      this.$store.state.electron.update.state=6
      this.$store.state.electron.update.error=event?.message
    },
    async APP_VERSION(event){
      let vm = this
      LOGGER.log('APP_VERSION',event?.app_version,event?.platform)
      console.log('APP_VERSION',event?.app_version,event?.platform)
      clearTimeout(app_version_interval_instance)
      if(!event?.platform || !event?.app_version) return;
      try {
        this.$store.state.electron.app_version=event?.app_version ?? ''
        const { data } = await VOIP_API.endpoints.electron.checkAvailable(event?.platform,event?.app_version,{
          domain: TokenService.CP_DOMAIN.get(),
          accountcode: TokenService.USER.get()?.account,
        })
        this.conditions.available=data.available
      } finally {
        check_maintenance_interval_instance = setTimeout(()=>vm.APP_VERSION(event),10*60*1000)
      }
    },
    DEVICE_NAME(event){
      LOGGER.log('DEVICE_NAME',event?.name)
      this.$store.state.electron.name=event?.name ?? ''
    },
    AUTO_UPDATE(event){
      LOGGER.log('AUTO_UPDATE',event?.auto_update)
      this.$store.state.electron.update.auto=event?.auto_update ?? false
    },
    UPDATE_DISABLED(event){
      LOGGER.log('UPDATE_DISABLED',event?.update_disabled)
      this.$store.state.electron.update.disabled=event?.update_disabled=='1'
    },
    APP_VIEW(event){
      LOGGER.log('APP_VIEW',event?.app_view)
      this.$store.state.electron.app_view=event?.app_view || 'web'
    },
    IDLE_STATE(event){
      LOGGER.log('IDLE_STATE',event?.state,event?.seconds)
      const { state, seconds } = event
      const { state: prev_state, seconds: prev_seconds } = this.$store.state.electron.idle_state
      this.$store.state.electron.idle_state.state=state || 'active'
      this.$store.state.electron.idle_state.seconds=seconds || 0
      if(['active','idle'].includes(state)) {
        const seconds_difference = seconds-prev_seconds
        if(prev_state!=state || seconds_difference>600) {
          AMI_SOCKET.idleState(event)
        }
      }
    },
  },
  created(){
    this.checkMaintenance()
    VOIP_API.oncpurlupdate=this.checkMaintenance.bind(this)
    window.electron.receive(electron_events.recieve.CHECK_FOR_UPDATE,this.CHECK_FOR_UPDATE)
    window.electron.receive(electron_events.recieve.UPDATE_AVAILABLE,this.UPDATE_AVAILABLE)
    window.electron.receive(electron_events.recieve.UPDATE_NOT_AVAILABLE,this.UPDATE_NOT_AVAILABLE)
    window.electron.receive(electron_events.recieve.UPDATE_DOWNLOAD_PROGRESS,this.UPDATE_DOWNLOAD_PROGRESS)
    window.electron.receive(electron_events.recieve.UPDATE_DOWNLOADED,this.UPDATE_DOWNLOADED)
    window.electron.receive(electron_events.recieve.UPDATE_ERROR,this.UPDATE_ERROR)
    window.electron.receive(electron_events.recieve.APP_VERSION,this.APP_VERSION)
    window.electron.receive(electron_events.recieve.DEVICE_NAME,this.DEVICE_NAME)
    window.electron.receive(electron_events.recieve.AUTO_UPDATE,this.AUTO_UPDATE)
    window.electron.receive(electron_events.recieve.UPDATE_DISABLED,this.UPDATE_DISABLED)
    window.electron.receive(electron_events.recieve.APP_VIEW,this.APP_VIEW)
    window.electron.receive(electron_events.recieve.IDLE_STATE,this.IDLE_STATE)
  },
}
</script>

<style lang="scss" scoped>
.bill-over-lay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 99;
  background-color: black;
  color: white;
}
</style>