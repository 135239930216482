<template>
  <modal class="dialer_animation right_side_popup showDetails-modal"
    width="50%"
    height="auto"
    :scrollable="true"
    :name="modalName" 
    @before-open="onBeforeOpen($event)"
    @before-close="onBeforeClose()"
  >
      <div class="dialer-edit-header">
        <h2 class="dialer-edit-title">Sub info modal</h2>
        <div class="dialer-edit-actions">
          <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
          <a v-else
            class="newCloseButton"
            type="button"
            @click="$modal.hide(modalName)"
          >
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
      </div>
      <vb-table
        v-if="data.is_addon"
        class="latestTableDesign-withBlackBorders-again"
        :isListEmpty="addonDetail.length==0" 
        :listLength="addonDetail.length" 
        :perPage="5" 
      >
        <tr slot="header" class="border-bottom-0" v-if="!getIsMobile">
          <th class="">
            <span class="dialer-sorted-by ascending">Label</span>
          </th>
          <th class="">
            <span class="">Type</span>
          </th>
          <th class="dialer-has-sort">
            <span class="">Addon</span>
          </th>
          <th class="dialer-has-sort">
            <span class="">Status</span>
          </th>
          <th class="dialer-has-sort" style="min-width:125px"> 
            <span class="">Created at</span>
          </th>
          <th class="dialer-col-right">
            <span class="">Action</span>
          </th>
        </tr>
        <template #body="{ start, end }">
          <template v-if="!getIsMobile">
            <tr v-for="(data, index1) in addonDetail" :key="data.subscription_id || data.id" v-show="index1 >= start && index1 <= end">
              <td class="dialer-row-title one">
                <Info 
                  :id="data.service_internal_accountcode || data.did" 
                  :type="data | infoType(serviceAddon.label)" 
                  :name="serviceAddon.label" 
                  :is_blf="false"
                />
              </td>
              <td class="dialer-row-title two">{{ 'service' }}</td>
              <td class="dialer-row-title three">{{ "Yes" }}</td>
              <td class="dialer-row-title four">{{ data.subscription_status || 'active' }}</td>
              <td class="dialer-row-title five" style="min-width:125px">{{ (data.subscription_updated_at || data.updated_at) | filter_date_current }}</td>
              <td class="dialer-row-title dialer-col-right six">
                <vb-spinner v-if="api.unsubscribe.send==(data.subscription_id || data.id)" />
                <button 
                  v-else-if="data.is_can_delete" 
                  :disabled="!!api.unsubscribe.send" 
                  @click="unsubscribe(data,serviceAddon.label)"
                  class="dialer-button dialer-button-delete-alt buttonNothing" 
                >
                  <vb-icon height="22px" width="22px" icon="trash-IOSIcon" />
                </button>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr v-for="(data, index1) in addonDetail" :key="data.subscription_id || data.id" v-show="index1 >= start && index1 <= end">
              <td class="left">
                <Info 
                  :id="data.service_internal_accountcode || data.did" 
                  :type="data | infoType(serviceAddon.label)" 
                  :name="serviceAddon.label" 
                  :is_blf="false"
                />
                <div class="textInsideCard mt-4px">Type: {{ 'service' }}</div>
                <div class="textInsideCard">Addon: {{ "Yes" }}</div>
                <div class="textInsideCard">Status: {{ data.subscription_status || 'active' }}</div>
                <div class="textInsideCard">{{ (data.subscription_updated_at || data.updated_at) | filter_date_current }}</div>
              </td>
              <td class="right dialer-col-right">
                <vb-spinner v-if="api.unsubscribe.send==(data.subscription_id || data.id)" />
                <button variant="link" 
                  v-else-if="data.is_can_delete" 
                  :disabled="!!api.unsubscribe.send" 
                  @click="unsubscribe(data,serviceAddon.label)"
                  class="p-0 b-0 bg-transparent" 
                >
                  <vb-icon height="22px" width="22px" icon="trash-IOSIcon" />
                </button>
              </td>
            </tr>
          </template>
        </template>
      </vb-table>
      <vb-table 
        v-else
        class="latestTableDesign-withBlackBorders-again"
        :isListEmpty="freeProductSubcriptions.length==0" 
        :listLength="freeProductSubcriptions.length" 
        :perPage="5" 
      >
        <tr slot="header" class="border-bottom-0" v-if="!getIsMobile">
          <th class=" dialer-has-sort   one">
            <span class="dialer-sorted-by ascending ">Label</span>
          </th>
          <th class=" dialer-has-sort  two"><span class="">Type</span></th>
          <th class=" dialer-has-sort  three"><span class="">Addon</span></th>
          <th class=" dialer-has-sort  four"><span class="">Status</span></th>
          <th class="  five" style="min-width:125px"> 
            <span class="">Created at</span>
          </th>
          <th class=" dialer-col-right   six">
            <span class="">Action</span>
          </th>
        </tr>
        <template #body="{ start, end }">
          <template v-if="!getIsMobile">
            <tr v-for="(data, index1) in freeProductSubcriptions" :key="data.free_resource_subscription_id" v-show="index1 >= start && index1 <= end">
              <td class="dialer-row-title dialer-col-fixed-width one">
                <Info 
                  :id="data.service_internal_accountcode" 
                  :type="data | infoType" :is_blf="false"
                  :name="data.free_product_detail.product.product_label" 
                />
              </td>
              <td class="dialer-row-title dialer-col-fixed-width two">{{ data.free_product_detail.product.product_type }}</td>
              <td class="dialer-row-title dialer-col-fixed-width three">{{ data.free_product_detail.product.is_addon == 0 ? "No" : "No" }}</td>
              <td class="dialer-row-title dialer-col-fixed-width four">{{ data.free_resource_subscription_status }}</td>
              <td class="dialer-row-title five" style="min-width:125px">{{ data.free_resource_subscription_created_at | filter_date_current }}</td>
              <td class="dialer-row-title dialer-col-right six">
                <vb-spinner v-if="api.unsubscribe.send==data.free_resource_subscription_id" />
                <button v-else-if="data.is_can_delete" :disabled="!!api.unsubscribe.send" @click="unsubscribe(data)" class="dialer-button dialer-button-delete-alt buttonNothing" >
                  <vb-icon height="22px" width="22px" icon="trash-IOSIcon" />
                </button>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr v-for="(data, index1) in freeProductSubcriptions" :key="data.free_resource_subscription_id" v-show="index1 >= start && index1 <= end">
              <td class="left">
                <Info 
                  :id="data.service_internal_accountcode" 
                  :type="data | infoType" :is_blf="false"
                  :name="data.free_product_detail.product.product_label" 
                />
                <div class="textInsideCard mt-4px">Type: {{ data.free_product_detail.product.product_type }}</div>
                <div class="textInsideCard">Addon: {{ data.free_product_detail.product.is_addon == 0 ? "No" : "No" }}</div>
                <div class="textInsideCard">Status: {{ data.free_resource_subscription_status }}</div>
                <div class="textInsideCard">{{ data.free_resource_subscription_created_at | filter_date_current }}</div>
              </td>
              <td class="right dialer-col-right">
                <vb-spinner v-if="api.unsubscribe.send==data.free_resource_subscription_id" />
                <button v-else-if="data.is_can_delete" :disabled="!!api.unsubscribe.send" @click="unsubscribe(data)" class="dialer-button dialer-button-delete-alt buttonNothing" >
                  <vb-icon height="22px" width="22px" icon="trash-IOSIcon" />
                </button>
              </td>
            </tr>
          </template>
        </template>
      </vb-table>
  </modal>
</template>

<script>
import { $fn, VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import Info from '../../Lists/Info.vue';

const getProductType = function(data,label){
  const value = data?.free_product_detail?.product?.product_billing_prefix || (data?.flaged_number=='yes' ? 'usa' : '')
  if(value=='us') return 'user'
  else if(value=='tm') return 'team'
  else if(value=='cq') return 'call_queue'
  else if(['ukc','ukm','ukn','usa'].includes(value)) return 'number'
  else if(value=='ivrs') return 'ivr'
  else if(label=='Telephone IVR') return 'ivr'
  else if(label=='Call Queue') return 'call_queue'
  else if(label=='USER') return 'user'
  else if(label=='User') return 'user'
  else if(label=='Team') return 'team'
  else if(label=='UK Geographic Number') return 'number'
  else if(label=='UK Mobile Number') return 'number'
  else if(label=='USA Number') return 'number'
  else if(label=='UK National Number') return 'number'
  else return ''
}


export default {
  name: 'ShowDetailsBillingModal',
  components:{
    Info
  },
  inject:[
    'getVoipUsers',
    'getNumbers',
    'getCallQueue',
    'getVoipTeams',
    'appNotify'
  ],
  props: {
    modalName: {
      type: String,
      default: 'ShowDetailsBillingModal'
    },
    subscription_detail: {
      type: Object,
      default: ()=>({})
    },
    allAddons: {
      type: Array,
      default: ()=>[]
    },
  },
  data() {
    return {
      api: {
        unsubscribe: this.$helperFunction.apiInstance({ send: '', }),
      },
      data: {
        id: '',
        is_addon: false,
      }
    }
  },
  computed: {
    ...mapGetters([
      'getIsMobile',
      'getVoipUsersAlises',
      'getCurrentUser',
      'getVoipNumbers',
    ]),
    freeProductSubcriptions() {
      const data = this.subscription_detail?.freeProductBreakDown?.[this.data.id]
      const plan_free_product_id = data?.plan_free_product_id
      return this.subscription_detail?.freeProductSubcription?.filter?.((item)=>item.plan_free_product_id==plan_free_product_id)?.map?.(i=>{
        const is_active = i.free_resource_subscription_status.toLowerCase()==='active'
        let is_can_delete = is_active
        if(is_can_delete){
          if(getProductType(i)=='user'){
            const is_main = this.getVoipUsersAlises[i.service_internal_accountcode]?.user?.user_type=='main'
            const is_current_user = i.service_internal_accountcode==this.getCurrentUser.account
            is_can_delete=!(is_main || is_current_user)
          } else if(getProductType(i)=='number'){
            const is_assign = !!this.getVoipNumbers?.find?.(num=>num.real_id==i.service_internal_accountcode)?.assignedToUsersArr?.extn
            is_can_delete=!is_assign
          }
        }
        return {
          ...i,
          is_can_delete,
        }
      }) ?? [];
    },
    serviceAddon(){ return this.allAddons.find(i=>i.product_id==this.data.id) },
    addonDetail(){ 
      return this.serviceAddon?.usedDetail?.map?.(i=>{
        if(i.flaged_number=='yes') {
          return {
            ...i,
            is_can_delete: true,
          }
        } else {
          const is_active = i.subscription_status.toLowerCase()==='active'
          let is_can_delete = is_active
          if(is_can_delete){
            if(getProductType(i,this.serviceAddon.label)=='user'){
              const is_main = this.getVoipUsersAlises[i.service_internal_accountcode]?.user?.user_type=='main'
              const is_current_user = i.service_internal_accountcode==this.getCurrentUser.account
              is_can_delete=!(is_main || is_current_user)
            } else if(getProductType(i,this.serviceAddon.label)=='number'){
              const is_assign = !!this.getVoipNumbers?.find?.(num=>num.real_id==i.service_internal_accountcode)?.assignedToUsersArr?.extn
              is_can_delete=!is_assign
            }
          }
          return {
            ...i,
            is_can_delete,
          }
        }
      }) ?? []
    },
  },
  filters: {
    infoType(value,label){ 
      return getProductType(value,label) 
    },
  },
  methods: {
    onBeforeOpen(event){
      this.data.id = event?.params?.id ?? ''
      this.data.is_addon = !!event?.params?.is_addon
    },
    onBeforeClose(){
      this.data.id = ''
      this.data.is_addon = false
    },
    unsubscribe(data,label=''){
      const { free_resource_subscription_id, subscription_id: addon_subscription_id, id } = data
      const subscription_id = free_resource_subscription_id || addon_subscription_id || id
      const product_type = getProductType(data,label)
      let vm = this
      if(vm.api.unsubscribe.send) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.unsubscribe.send=subscription_id
            $fn.sleep(0.5*1000)
            .then(()=>{
              if(product_type=='number'){
                return VOIP_API.endpoints.telephone_number.deleteNumber({
                  id: data.service_internal_accountcode,
                  uid: vm.getCurrentUser?.uid,
                  accountcode: vm.getCurrentUser?.account,
                })
              } else {
                return VOIP_API.endpoints.billing.unsubscribe({
                  id: data.service_internal_accountcode,
                  uid: vm.getCurrentUser?.uid,
                  accountcode: data.service_internal_accountcode,
                  product_type: product_type=='number' ? 'number' : '',
                })
              }
            }).then(()=>{
              return $fn.sleep(5*1000)
            }).then(()=>{
              vm.appNotify({
                message: 'Successfully UnSubscribed!',
                type: 'success',
              })
              if(product_type=='user'){
                vm.getVoipUsers()
              } else if(product_type=='team'){
                vm.getVoipTeams()
              } else if(product_type=='call_queue'){
                vm.getCallQueue()
              } else if(product_type=='number'){
                vm.getNumbers()
              }
              vm.$emit('delete-service')
            }).catch((ex)=>{
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            }).finally(()=>{
              vm.api.unsubscribe.send=''
            })
          }
        },
      });
    },
  }
}
</script>
