<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup new-call-info-modal new-call-info-modal-newer v2" 
      width="50%" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="panel-inner panel-inner-callInfo new-call-info-inner">
        <div class="d-flex flex-wrap align-items-center justify-content-between py-0 mb-0 dialer-edit-header">
          <h2 class="dialer-edit-title mb-0">Call info</h2>
          <div>
            <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
            <button v-else type="button" class="newCloseButton" @click="$modal.hide(modalName)"> 
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </button>
          </div>
        </div>
        <div class="vm--modal-text">Below you will see details about this call.</div>
        <div class="dialer-tabs">
          <div class="dialer-tab-content">
            <div class="list-area tab-info">
              <div class="w-100" v-if="api.fetch_call_log.send && !data.call">
                <!-- Loading... -->
                <div v-for="n in 13" :key="n.id" class="latestShimmerDesign mb-3" style="height:50px;width:100%"></div>
              </div>
              <component
                v-else
                :is="component"
                class="holder"
                :log="data.call"
                :params="data.params"
                @fetch-latest-data="fetchCallLog(data.call)"
              >
                <template #history-filter>
                  <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD">
                    <b-dropdown no-caret class="dd-text-dark dd-with-checkbox" right>
                      <template #button-content>
                        <vb-icon icon="callInfoModal-funnel-icon" width="17.892px" height="17.896px" />
                      </template>
                      <div class="w-100 position-relative pl-4">
                        <b-form-checkbox v-model="setting.recording" class="makeCheckBoxesSquare">Recording</b-form-checkbox>
                        <b-form-checkbox v-model="setting.message" class="makeCheckBoxesSquare">SMS and messages</b-form-checkbox>
                        <b-form-checkbox v-model="setting.tags" class="makeCheckBoxesSquare">Tags</b-form-checkbox>
                        <b-form-checkbox v-model="setting.note" class="makeCheckBoxesSquare">Notes</b-form-checkbox>
                      </div>
                    </b-dropdown>
                  </div>
                </template>
                <template #history>
                  <div 
                    class="phone-list full-width-call-info-number dialor-callHistoryList history_tab list-unstyled p-0 h-100" 
                    style="overflow:auto;" 
                    @scroll="onScroll($event)"
                  >
                    <template v-for="(call_history, index) in filteredCallHistory">
                      <h3 :key="`${index}-date-call-activity`" class="pb-1 ddd" v-if="call_history.isDateShow">
                        <span style="color:black;font-size:12px;"> {{ (call_history.call_date || call_history.time) | filter_date_current }} </span>
                      </h3>
                      <li :key="index">
                        <CallActivityTestItem 
                          :call="call_history" 
                          :conditions="{
                            design: {
                              tag: setting.tags,
                              note: setting.note,
                              recording: setting.recording,
                              sip_recording: setting.recording,
                              message: setting.message,
                              more: false,
                            },
                            action: {
                              enable: false,
                              assign: false,
                              add_contact: false,
                              call: false,
                              info: false,
                              assigned_detail: false,
                              add_task: false,
                              play_audio: false,
                              transcription: false,
                            },
                          }" 
                          :key="index"  
                        >
                          <template #more>
                            <b-dropdown varient="link" no-caret class="ico-filter" menu-class="w-fit-content-imp" right>
                              <template #button-content>
                                <b-icon icon="three-dots" variant="#393939" font-scale="1.4"/>
                              </template>
                              <b-dropdown-item @click="''">
                                <vb-icon icon="callInfoModal-addContacts-icon" class="mr-16px" width="17.004px" height="17.72px" />
                                Call info
                              </b-dropdown-item>
                            </b-dropdown>
                          </template>
                        </CallActivityTestItem>
                      </li>
                    </template>
                    <vb-no-record v-if="api.history.send || isEmpty(response.history)" :text="api.history.send ? '' : 'There is no history'" :design="3">
                      <template v-if="api.history.send" slot="loading">
                        <div v-for="n in 4" :key="n.id" :class="`latestShimmerDesign w-75 mb-4 ${n%2==1 ? 'ml-auto' : ''}`" style="height:80px;"></div>
                      </template>
                    </vb-no-record>
                  </div>
                </template>
              </component>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import SipCallLog from './SipCallLog.vue'
import JitsiCallLog from './JitsiCallLog.vue'
import SMSLog from './SMSLog.vue'
import VoicemailLog from './VoicemailLog.vue'
import CallActivityTestItem from '../../Home/CallActivity/TestItem/index.vue';
import { VOIP_API } from '@/utils';
export default {
  name: "CallActivityModal",
  components: {
    SipCallLog,
    JitsiCallLog,
    SMSLog,
    VoicemailLog,
    CallActivityTestItem,
  },
  inject: [
    'isEmpty',
    'appNotify',
  ],
  props: {
    modalName: {
      type: String,
      default: 'CallActivityModal'
    },
  },
  data() {
    return {
      api: {
        history: this.$helperFunction.apiInstance(),
        fetch_call_log: this.$helperFunction.apiInstance(),
      },
      response: this.$helperFunction.stateInstance({
        data: {
          history: [],
        },
      }),
      setting: this.$helperFunction.stateInstance({
        data: {
          tags: true,
          recording: true,
          note: true,
          message: true,
        },
      }),
      pagination: {
        history: this.$helperFunction.stateInstance({
          data: {
            page: 1,
            completed: false,
          },
        }),
      },
      data: {
        call: null,
        params: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getIsMobile',
      'getCurrentUser',
    ]),
    call(){ return this.data.call ?? {} },
    component(){
      if(this.call.call_type=='audio') return 'SipCallLog'
      else if(this.call.call_type=='video') return 'JitsiCallLog'
      else if(this.call.call_type=='voicemail') return 'VoicemailLog'
      else if(this.call.call_type=='sms') return 'SMSLog'
      return '' 
    },
    filteredCallHistory() {
      const call_history = this.response.history;
      var currentDate = "";
      const array = _.orderBy(call_history,"date","desc")
      return array.map((activity) => {
        const date = activity.date;
        const call_date = moment.unix(date).format('MM-DD-YYYY')
        var isDateShow = call_date !== currentDate;
        currentDate = call_date !== currentDate ? call_date : currentDate
        return {
          ...activity,
          isDateShow: isDateShow,
          dateOnly: call_date,
        };
      })
    },
  },
  methods: {
    onBeforeOpen(event){
      let vm = this
      const { callInfo, ...params } = event.params ?? {}
      this.data.params=params
      this.fetchCallLog(callInfo,()=>{
        vm.fetchHistory()
      })
    },
    onBeforeClose(){
      this.data.call=null
      this.data.params=null
      this.api.history.reset()
      this.api.fetch_call_log.reset()
      this.pagination.history.reset()
      this.response.reset()
      this.setting.reset()
    },
    async fetchCallLog(log,cb){
      this.api.fetch_call_log.source?.cancel?.("Requesting Again");
      this.api.fetch_call_log.source = require("axios").default.CancelToken.source()
      try {
        this.api.fetch_call_log.send=true
        let call = null
        if(log.call_type=='audio') {
          const { data } = await VOIP_API.endpoints.call_activity.getsipLogs({
            log_id: log.id,
            accountcode: this.getCurrentUser.account,
            type: log.call_type,
          })
          call=data
        } else {
          call=log
        }
        if(this.data.call && !_.isEqual(log, call)) {
          const index = this.$store.state.common.call_activity.data?.findIndex(i=>{
            if(log.call_type=='audio') return i.call_type=='audio' && i.id==log.id
            return false
          })
          if(index>-1) {
            this.$set(this.$store.state.common.call_activity.data,index,call)
          }
        }
        this.data.call=call
        cb?.()
      } catch (ex) {
        if(!ex.cancel) {
          this.appNotify({
            message: ex.own_message || ex.message,
            type: 'danger',
          })
        }
      } finally {
        this.api.fetch_call_log.send=false
      }
    },
    async fetchHistory() {
      if(this.api.history.send || this.pagination.history.completed) return;
      try {
        const call = this.data.call
        let number = '', extn=''
        if(call.call_type=='audio') {
          if(call.status=='Dialed') {
            if(call.dst_type=='Number') {
              number = call.dst_number
            } else {
              extn = call.dst_extn
            }
          } else {
            if(call.src_type=='Number') {
              number = call.src_number
            } else {
              extn = call.src_extn
            }
          }
        } else if(call.call_type=='video') {
          extn = call.destinationExt
        } else if(call.call_type=='voicemail') {
          if(call.caller_name=='-') {
            number = call.caller.replace(/"/g, "")
          } else  {
            let caller = call.caller.replace(/"/g, "")
            if(/^\d{4}$/.test(caller)) {
              extn = caller
            } else {
              number = caller
            }
          }
        } else if(call.call_type=='sms') {
          number = call.dialable
        }
        this.api.history.send = true;
        const { data: { data: history, /*block_number,*/ }, no_record } = await VOIP_API.endpoints.call_activity.newList({
          accountcode: this.getCurrentUser?.account,
          number: number,
          extn: extn,
          page: this.pagination.history.page,
          history: 'yes',
          start_date: parseInt(this.response.history[this.response.history.length-1]?.date ?? '') || ''
        })
        if(no_record) {
          this.pagination.history.completed=true
        } else {
          this.pagination.history.page = this.pagination.history.page + 1; 
          this.response.history = _.concat(this.response.history,history);
        }
        // this.conditions.block_number=block_number=='1'
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.history.send = false;
      }
    },
    onScroll(Event) {
      const height = Event.target.scrollHeight;
      const top = Event.target.scrollTop;
      const offset_height = Event.target.offsetHeight;
      const scroll_bar_height = height - (height - offset_height);
      const scroll_bottom = Math.floor(height - (scroll_bar_height + top));
      if (scroll_bottom <= 2) {
        this.fetchHistory();
      }
    },
  },
};
</script>

<style>
</style>