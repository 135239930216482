import { ADDRESS_API_ENDPOINTS } from "./address"
import { ADDRESS_BOOKS_API_ENDPOINTS } from "./address-books"
import { ANALYTICS_API_ENDPOINTS } from "./analytics"
import { AUTH_API_ENDPOINTS } from "./auth"
import { BILLING_API_ENDPOINTS } from "./billing"
import { BILLING_API_API_ENDPOINTS } from "./billing-api"
import { CALL_API_ENDPOINTS } from "./call"
import { CALL_ACTIVITY_API_ENDPOINTS } from "./call-activity"
import { CALL_QUEUES_API_ENDPOINTS } from "./call-queues"
import { CALL_RECORDING_API_ENDPOINTS } from "./call-recording"
import { CALLER_ID_API_ENDPOINTS } from "./caller-id"
import { CENTRIFUGE_API_ENDPOINTS } from "./centrifuge"
import { CONFERENCE_API_ENDPOINTS } from "./conference"
import { CONFERENCES_API_ENDPOINTS } from "./conferences"
import { CRM_API_ENDPOINTS } from "./crm/index"
import { DEFAULT_AVATARS_API_ENDPOINTS } from "./default-avatars"
import { DEPARTMENTS_API_ENDPOINTS } from "./departments"
import { DEVELOPER_NOTES_API_ENDPOINTS } from "./developer-notes"
import { DEVICES_API_ENDPOINTS } from "./devices"
import { DNS_API_ENDPOINTS } from "./dns"
import { DOMAIN_API_ENDPOINTS } from "./domain"
import { ELECTRON_API_ENDPOINTS } from "./electron"
import { ERROR_CAPTURE_API_ENDPOINTS } from "./error-capture"
import { EXTENSIONS_API_ENDPOINTS } from "./extensions"
import { FEATURES_API_ENDPOINTS } from "./features"
import { FEEDBACK_API_ENDPOINTS } from "./feedback"
import { INBOUND_RULE_API_ENDPOINTS } from "./inbound-rule"
import { INTEGRATIONS_API_ENDPOINTS } from "./integrations"
import { JITSI_CONFERENCE_API_ENDPOINTS } from "./jitsi-conference"
import { LOCATION_API_ENDPOINTS } from "./location"
import { MENU_API_ENDPOINTS } from "./menu"
import { MUSIC_ON_HOLD_API_ENDPOINTS } from "./music-on-hold"
import { PAMARLY_API_ENDPOINTS } from "./pamarly"
import { PICKUPGROUP_API_ENDPOINTS } from "./pickupgroup"
import { RECORDING_API_ENDPOINTS } from "./recording"
import { SCHEDULE_API_ENDPOINTS } from "./schedule"
import { SERVICES_API_ENDPOINTS } from "./services"
import { SNOOZ_API_ENDPOINTS } from "./snooz"
import { SPEED_DIALS_API_ENDPOINTS } from "./speed-dials"
import { STATUSES_API_ENDPOINTS } from "./statuses"
import { STORAGE_SETTING_API_ENDPOINTS } from "./storage-setting"
import { SUPPORT_TICKET_API_ENDPOINTS } from "./support-ticket"
import { TAGS_API_ENDPOINTS } from "./tags"
import { TASK_GROUPS_API_ENDPOINTS } from "./task-groups"
import { TASK_LIST_API_ENDPOINTS } from "./task-list"
import { TASK_SPACES_API_ENDPOINTS } from "./task-spaces"
import { TASK_STATUS_API_ENDPOINTS } from "./task-status"
import { TEAMS_API_ENDPOINTS } from "./teams"
import { TECH_PREFIX_API_ENDPOINTS } from "./tech-prefix"
import { TELEPHONE_NUMBER_API_ENDPOINTS } from "./telephone-number"
import { TODOS_API_ENDPOINTS } from "./todos"
import { TOPUP_API_ENDPOINTS } from "./topup"
import { TRUSTED_DEVICES_API_ENDPOINTS } from "./trusted-devices"
import { USER_API_ENDPOINTS } from "./user"
import { USER_STATUSES_API_ENDPOINTS } from "./user-statuses"
import { USERS_API_ENDPOINTS } from "./users"
import { VOICEMAIL_API_ENDPOINTS } from "./voicemail"

import { OWNERSHIP_CHANGE } from "./ownrshipchange"
import { LOCAL_SETTING_API_ENDPOINTS } from "./local-setting"
import { TEST_MODE_API_ENDPOINTS } from "./test-mode"
import { SMS_API_ENDPOINTS } from "./sms"
import { TICKETS_API_ENDPOINTS } from "./tickets"
import { AMI_API_ENDPOINTS } from "./ami"
import { POWER_DIALER_API_ENDPOINTS } from "./power-dialer"

export const API_ENDPOINTS = {
  auth: AUTH_API_ENDPOINTS,
  address_books: ADDRESS_BOOKS_API_ENDPOINTS,
  address: ADDRESS_API_ENDPOINTS,
  power_dialer: POWER_DIALER_API_ENDPOINTS,
  analytics: ANALYTICS_API_ENDPOINTS,
  ami: AMI_API_ENDPOINTS,
  billing_api: BILLING_API_API_ENDPOINTS,
  billing: BILLING_API_ENDPOINTS,
  crm: CRM_API_ENDPOINTS,
  call_activity: CALL_ACTIVITY_API_ENDPOINTS,
  call_recording: CALL_RECORDING_API_ENDPOINTS,
  call_queues: CALL_QUEUES_API_ENDPOINTS,
  callerid: CALLER_ID_API_ENDPOINTS,
  centrifuge: CENTRIFUGE_API_ENDPOINTS,
  conferences: CONFERENCES_API_ENDPOINTS,
  call: CALL_API_ENDPOINTS,
  conference: CONFERENCE_API_ENDPOINTS,
  dns: DNS_API_ENDPOINTS,
  developer_notes: DEVELOPER_NOTES_API_ENDPOINTS,
  departments: DEPARTMENTS_API_ENDPOINTS,
  devices: DEVICES_API_ENDPOINTS,
  domain: DOMAIN_API_ENDPOINTS,
  default_avatars: DEFAULT_AVATARS_API_ENDPOINTS,
  error_capture: ERROR_CAPTURE_API_ENDPOINTS,
  electron: ELECTRON_API_ENDPOINTS,
  extensions: EXTENSIONS_API_ENDPOINTS,
  features: FEATURES_API_ENDPOINTS,
  feed_back: FEEDBACK_API_ENDPOINTS,
  inbound_rule: INBOUND_RULE_API_ENDPOINTS,
  integrations: INTEGRATIONS_API_ENDPOINTS,
  jitsi_conference: JITSI_CONFERENCE_API_ENDPOINTS,
  location: LOCATION_API_ENDPOINTS,
  local_setting: LOCAL_SETTING_API_ENDPOINTS,
  menu: MENU_API_ENDPOINTS,
  music_on_hold: MUSIC_ON_HOLD_API_ENDPOINTS,
  ownrshipchange: OWNERSHIP_CHANGE,
  pamarly: PAMARLY_API_ENDPOINTS,
  pickupgroup: PICKUPGROUP_API_ENDPOINTS,
  recording: RECORDING_API_ENDPOINTS,
  snooz: SNOOZ_API_ENDPOINTS,
  schedule: SCHEDULE_API_ENDPOINTS,
  speed_dials: SPEED_DIALS_API_ENDPOINTS,
  supportticket: SUPPORT_TICKET_API_ENDPOINTS,
  statuses: STATUSES_API_ENDPOINTS,
  storage_setting: STORAGE_SETTING_API_ENDPOINTS,
  task_spaces: TASK_SPACES_API_ENDPOINTS,
  task_status: TASK_STATUS_API_ENDPOINTS,
  taskgroups: TASK_GROUPS_API_ENDPOINTS,
  tasklist: TASK_LIST_API_ENDPOINTS,
  todos: TODOS_API_ENDPOINTS,
  trusted_devices: TRUSTED_DEVICES_API_ENDPOINTS,
  teams: TEAMS_API_ENDPOINTS,
  telephone_number: TELEPHONE_NUMBER_API_ENDPOINTS,
  tags: TAGS_API_ENDPOINTS,
  topup: TOPUP_API_ENDPOINTS,
  techprifex: TECH_PREFIX_API_ENDPOINTS,
  test_mode: TEST_MODE_API_ENDPOINTS,
  tickets: TICKETS_API_ENDPOINTS,
  users: USERS_API_ENDPOINTS,
  user: USER_API_ENDPOINTS,
  user_statuses: USER_STATUSES_API_ENDPOINTS,
  voicemail: VOICEMAIL_API_ENDPOINTS,
  services: SERVICES_API_ENDPOINTS,
  sms: SMS_API_ENDPOINTS

}