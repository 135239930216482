<template>
  <div>
    <modal
      class="dialer_animation right_side_popup EditMenuModal"
      width="40%"
      height="auto"
      :scrollable="true"
      :name="modalName" 
      @before-open="onBeforeOpen($event)"
      @before-close="onBeforeClose()"
    >
      <EditMenu 
        v-if="data.accountcode" 
        :accountcode="data.accountcode" 
        @click-graph="$emit('click-graph')" 
        @welcome-audio-updated="$emit('welcome-audio-updated')" 
        @options-updated="$emit('options-updated')"
        @ofh-days-updated="$emit('ofh-days-updated')"
        @ofh-toggle-updated="$emit('ofh-toggle-updated')"
        @ofh-default-updated="$emit('ofh-default-updated')"
        @assign-number-updated="$emit('assign-number-updated')"
        @profile-image-updated="$emit('profile-image-updated')"
        @label-updated="$emit('label-updated')"
        @delete-ivr="$emit('delete-ivr')"
        @close-modal="$modal.hide(modalName)" 
      />
    </modal>
  </div>
</template>

<script>
import EditMenu from '../Setting/EditMenu.vue';
export default {
  name: "EditMenuModal",
  props: {
    modalName: {
      type: String,
      default: 'EditMenuModal'
    },
  },
  components: {
    EditMenu,
  },
  data() {
    return {
      data: {
        accountcode: '',
      },
    };
  },
  methods: {
    onBeforeOpen(event) {
      this.data.accountcode = event?.params?.accountcode;
    },
    onBeforeClose(){
      this.data.accountcode=''
    },
  },
};
</script>


<style lang="scss" scoped>
.dialer-audio-playback {
  @include border-radius($border-radius);
  overflow: hidden;
  margin-top: 5px;
  display: flex;
  align-items: center;
  font-size: 12px;
  flex-grow: 1;
  .dialer-audio-playback-inner {
    width: 100%;
    background-color: $white;
    padding: $sidebarTop;
    audio {
      min-width: 220px;
      max-width: 250px;
      max-height: 30px;

      &::-webkit-media-controls-panel {
        background-color: $gray-300;
      }
    }
    .dialer-audio-message {
      width: 100%;
      svg {
        @include border-radius(0px);
        background-color: transparent;
        color: $body-color;
        margin-right: 8px;
      }
      .dialer-audio-progress {
        position: relative;
        margin: 0px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 24px);
        .progress {
          background-color: $progressBar_bg-color;
          width: calc(100% - 28px);
          .progress-bar {
            transition: width 0.1s ease;
          }
        }
        small {
          &:first-child {
            width: 39px;
            margin-right: 8px;
          }
          &:last-child {
            margin-left: 8px;
          }
        }
        input {
          &[type="range"] {
            width: calc(100% - 84px);
            background-color: transparent;
            -webkit-appearance: none;
            position: absolute;
            left: 42px;
            &::-webkit-slider-runnable-track {
              height: 4px;
              @include border-radius(50px);
              background: transparent;
              cursor: pointer;
            }
            &::-moz-range-track {
              height: 4px;
              @include border-radius(50px);
              background: transparent;
              cursor: pointer;
            }
            &::-ms-track {
              height: 4px;
              @include border-radius(50px);
              background: transparent;
              cursor: pointer;
            }
            &::-webkit-slider-thumb {
              width: 12px;
              height: 12px;
              margin-top: -5px;
              background: $dialer-progressBarThumb-color;
              border: 1px solid $dialer-progressBarThumb-color;
              @include border-radius(50%);
              -webkit-appearance: none;
              cursor: pointer;
            }
            &::-moz-range-thumb {
              width: 12px;
              height: 12px;
              margin-top: -5px;
              background: $dialer-progressBarThumb-color;
              border: 1px solid $dialer-progressBarThumb-color;
              @include border-radius(50%);
              cursor: pointer;
            }
            &::-ms-thumb {
              width: 12px;
              height: 12px;
              background: $dialer-progressBarThumb-color;
              border: 1px solid $dialer-progressBarThumb-color;
              @include border-radius(50%);
              cursor: pointer;
              margin-top: -5px;
            }
            &::-ms-fill-lower {
              background: $black;
              border: 0.2px solid $black;
              @include border-radius(2.6px);
            }
            &::-ms-fill-lower {
              background: $black;
              border: 0.2px solid $black;
              @include border-radius(2.6px);
            }
            &:focus {
              &::-ms-fill-lower {
                background: $black;
              }
              &::-ms-fill-upper {
                background: $black;
              }
            }
          }
        }
      }
    }
    .dialer-play-button {
      outline: none;
      border: none;
      background: none;
      padding: 2px 6px 3px;
    }

    .dialer-playback-time {
      margin: 0 5px;
      min-width: 25px;
      color: $secondary;
    }
  }
}
</style>
