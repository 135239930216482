var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "CallTransferModal",
    attrs: {
      "name": _vm.modalName
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header d-flex w-100 justify-content-between align-items-center"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Transfer call to")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('b-input', {
    attrs: {
      "placeholder": "Enter number, user name or extension"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.dial();
      },
      "input": function ($event) {
        _vm.selected_id = '';
      }
    },
    model: {
      value: _vm.number,
      callback: function ($$v) {
        _vm.number = $$v;
      },
      expression: "number"
    }
  })], 1), _c('div', {
    ref: "drop-down"
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px p-3",
    staticStyle: {
      "--total": "90vh",
      "--minusValue": "275px"
    }
  }, [_c('ul', {
    staticClass: "whiteBoxWithBorderInsideGreyBox px-3 list-unstyled mb-0"
  }, [_vm.searchNumber.length == 0 ? [_c('li', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none flex-column align-items-center py-3"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "noDataFound-v2",
      "width": "57",
      "height": "63"
    }
  }), _c('span', [_c('b', [_vm._v("Enter number, user name or extension")])])], 1)] : _vm._l(_vm.searchNumber, function (data, index) {
    return _c('li', {
      key: index,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none py-3",
      on: {
        "click": function ($event) {
          return _vm.selectContact(data);
        }
      }
    }, [_c('Info', {
      attrs: {
        "is_blf": false,
        "name": data.name,
        "sub_info": data.number,
        "image": data.image
      }
    })], 1);
  })], 2)])]), _c('div', {
    staticClass: "d-flex justify-content-between mt-auto"
  }, [_c('button', {
    class: `d-flex dialer-button md-mar-top dialer-button-primary ${!_vm.isValidNumber ? 'disabled' : ''}`,
    attrs: {
      "disabled": !_vm.isValidNumber,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.transfer('soft');
      }
    }
  }, [_vm._v("Soft Transfer")]), _c('button', {
    class: `d-flex dialer-button md-mar-top dialer-button-primary ${!_vm.isValidNumber ? 'disabled' : ''}`,
    attrs: {
      "disabled": !_vm.isValidNumber,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.transfer('hard');
      }
    }
  }, [_vm._v("Hard Transfer")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }