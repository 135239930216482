var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup scrollable dialer-analytics-user-modal",
    attrs: {
      "width": "60%",
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose($event);
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header pb-0"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("User analytics")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)]), _c('div', {
    staticClass: "dialer-analytics-user dialer-analytics-tray"
  }, [_c('div', {
    staticClass: "analytics-newHeader mb-20px"
  }, [_c('div', {
    staticClass: "analytics-newHeader-leftSide"
  }, [_vm.user ? _c('h1', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v(_vm._s(_vm.user.user.display_name) + " (" + _vm._s(_vm.user.extn) + ")")]) : _vm._e()]), _c('div', {
    staticClass: "analytics-newHeader-rightSide"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterWithText mr-16px"
  }, [_c('b-dropdown', {
    staticClass: "ico-filter",
    attrs: {
      "varient": "link",
      "no-caret": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_vm.dateLabel ? _c('span', {
          staticClass: "mr-12px"
        }, [_vm._v(_vm._s(_vm.dateLabel))]) : _vm._e(), _c('b-icon', {
          staticClass: "fillBlack",
          attrs: {
            "icon": "three-dots-vertical",
            "variant": "dark",
            "scale": "1.5"
          }
        })];
      },
      proxy: true
    }])
  }, _vm._l(_vm.periods, function (period) {
    return _c('b-dropdown-item', {
      key: period.value,
      on: {
        "click": function ($event) {
          _vm.filters.stat.period = period.value;
          _vm.filters.stat.period == 'custom' ? _vm.$modal.show(`${_vm._uid}-dateFilter`) : _vm.fetchStat();
        }
      }
    }, [_c('vb-icon', {
      staticClass: "mr-12px",
      style: `opacity:${_vm.filters.stat.period == period.value ? '1' : '0'}`,
      attrs: {
        "icon": "markAsComplete-icon",
        "width": "14.49px",
        "height": "10.431px"
      }
    }), _c('span', [_vm._v(_vm._s(period.text))])], 1);
  }), 1)], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between flex-wrap w-100"
  }, [_c('div', {
    staticClass: "d-flex flex-column mb-3"
  }, [_c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Success RATE: " + _vm._s(_vm._f("get_property")(_vm.response.stat, 'response.data.successRate', 0)) + " %")])])]), _c('div', {
    staticClass: "dialer-analytics-totals v2 updatedAgain mt-20px"
  }, [_c('div', {
    staticClass: "dialer-call-total dialer-total",
    on: {
      "click": function ($event) {
        _vm.filters.call_logs.log_type = '';
        _vm.$modal.show(`${_vm._uid}-CallLogs`);
      }
    }
  }, [_c('div', {
    staticClass: "dialer-call-total-inner"
  }, [_c('div', {
    staticClass: "dialer-call-total-top"
  }, [_c('div', {
    staticClass: "dialer-call-type"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "CallSummary-TotalCall-icon"
    }
  }), _c('div', [_vm._v("total Calls")])], 1)]), _c('div', {
    staticClass: "dialer-call-figures"
  }, [_c('h4', {
    staticClass: "dialer-call-number"
  }, [_vm._v(_vm._s(_vm.callpercentage.total))])])])]), _c('div', {
    staticClass: "dialer-call-total dialer-missed",
    on: {
      "click": function ($event) {
        _vm.filters.call_logs.log_type = 'Missed';
        _vm.$modal.show(`${_vm._uid}-CallLogs`);
      }
    }
  }, [_c('div', {
    staticClass: "dialer-call-total-inner"
  }, [_c('div', {
    staticClass: "dialer-call-total-top"
  }, [_c('div', {
    staticClass: "dialer-call-type"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "CallSummary-MissedCall-icon"
    }
  }), _c('div', [_vm._v("missed Calls")])], 1), _c('span', {
    staticClass: "dialer-call-total-time"
  })]), _c('div', {
    staticClass: "dialer-call-figures"
  }, [_c('h4', {
    staticClass: "dialer-call-number"
  }, [_vm._v(_vm._s(_vm.callpercentage.missed))]), _c('div', {
    staticClass: "newProgressBar"
  }, [_c('div', {
    style: `--value:${_vm.callpercentage.missed_percentage};--fg:#dc3545`,
    attrs: {
      "role": "progressbar",
      "aria-valuenow": "65",
      "aria-valuemin": "0",
      "aria-valuemax": "100"
    }
  }, [_c('div', {
    staticClass: "newProgressBar-inner"
  }, [_vm._v(" " + _vm._s(_vm.callpercentage.missed_percentage) + "% ")])])])])])]), _c('div', {
    staticClass: "dialer-call-total dialer-inbound",
    on: {
      "click": function ($event) {
        _vm.filters.call_logs.log_type = 'Received';
        _vm.$modal.show(`${_vm._uid}-CallLogs`);
      }
    }
  }, [_c('div', {
    staticClass: "dialer-call-total-inner"
  }, [_c('div', {
    staticClass: "dialer-call-total-top"
  }, [_c('div', {
    staticClass: "dialer-call-type"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "CallSummary-InboundCall-icon"
    }
  }), _c('div', [_vm._v("Receive Calls")])], 1), _c('span', {
    staticClass: "dialer-call-total-time"
  })]), _c('div', {
    staticClass: "dialer-call-figures"
  }, [_c('h4', {
    staticClass: "dialer-call-number"
  }, [_vm._v(_vm._s(_vm.callpercentage.received))]), _c('div', {
    staticClass: "newProgressBar"
  }, [_c('div', {
    style: `--value:${_vm.callpercentage.received_percentage};--fg:#28a745`,
    attrs: {
      "role": "progressbar",
      "aria-valuenow": "65",
      "aria-valuemin": "0",
      "aria-valuemax": "100"
    }
  }, [_c('div', {
    staticClass: "newProgressBar-inner"
  }, [_vm._v(" " + _vm._s(_vm.callpercentage.received_percentage) + "% ")])])])])])]), _c('div', {
    staticClass: "dialer-call-total dialer-outbound",
    on: {
      "click": function ($event) {
        _vm.filters.call_logs.log_type = 'Dialed';
        _vm.$modal.show(`${_vm._uid}-CallLogs`);
      }
    }
  }, [_c('div', {
    staticClass: "dialer-call-total-inner"
  }, [_c('div', {
    staticClass: "dialer-call-total-top"
  }, [_c('div', {
    staticClass: "dialer-call-type"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "CallSummary-OutboundCall-icon"
    }
  }), _c('div', [_vm._v("outbound Calls")])], 1)]), _c('div', {
    staticClass: "dialer-call-figures"
  }, [_c('h4', {
    staticClass: "dialer-call-number"
  }, [_vm._v(_vm._s(_vm.callpercentage.outgoing))]), _c('div', {
    staticClass: "newProgressBar"
  }, [_c('div', {
    style: `--value:${_vm.callpercentage.outgoing_percentage};--fg:#5576d1`,
    attrs: {
      "role": "progressbar",
      "aria-valuenow": "65",
      "aria-valuemin": "0",
      "aria-valuemax": "100"
    }
  }, [_c('div', {
    staticClass: "newProgressBar-inner"
  }, [_vm._v(" " + _vm._s(_vm.callpercentage.outgoing_percentage) + "% ")])])])])])])]), _c('div', {
    staticClass: "visavail mt-20px",
    staticStyle: {
      "width": "100%",
      "overflow": "hidden"
    },
    attrs: {
      "id": `conatiner-measure-${_vm.data.accountcode}`
    }
  }, [_c('p', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "id": `p-measure-${_vm.data.accountcode}`
    }
  })])])])]), _c('modal', {
    staticClass: "callReportingFilterModal",
    attrs: {
      "name": `${_vm._uid}-dateFilter`
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header pt-0 position-relative"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Filters")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-dateFilter`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "analytics-newHeader-text"
  }, [_c('div', {
    staticClass: "d-flex customDate"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionLeftAligned w-50 pr-2"
  }, [_c('span', [_vm._v("From:")]), _c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "date-time-input",
    attrs: {
      "id": "starttime"
    },
    model: {
      value: _vm.filters.stat.start_date,
      callback: function ($$v) {
        _vm.$set(_vm.filters.stat, "start_date", $$v);
      },
      expression: "filters.stat.start_date"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionRightAligned w-50 pl-2"
  }, [_c('span', [_vm._v("To:")]), _c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "date-time-input",
    attrs: {
      "id": "endtime"
    },
    model: {
      value: _vm.filters.stat.end_date,
      callback: function ($$v) {
        _vm.$set(_vm.filters.stat, "end_date", $$v);
      },
      expression: "filters.stat.end_date"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1)]), _c('div', {
    staticClass: "w-100 d-flex justify-content-end mt-20px"
  }, [_c('a', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        _vm.fetchStat();
        _vm.$modal.hide(`${_vm._uid}-dateFilter`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-browse-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Fetch")])], 1)])])])]), _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 bigSquareCheckbox analytics-logs-modal",
    attrs: {
      "name": `${_vm._uid}-CallLogs`
    },
    on: {
      "before-open": function ($event) {
        return _vm.fecthCallLogs(true);
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header pt-0 position-relative"
  }, [_c('div', [_vm.filters.call_logs.log_type == '' ? _c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("All calls")]) : _vm._e(), _vm.filters.call_logs.log_type == 'Missed' ? _c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("All missed calls")]) : _vm._e(), _vm.filters.call_logs.log_type == 'Received' ? _c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("All received calls")]) : _vm._e(), _vm.filters.call_logs.log_type == 'Dialed' ? _c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("All dialed calls")]) : _vm._e()]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-CallLogs`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "analytics-logs-modal-inner latestGreyBox-9-9-2023",
    on: {
      "scroll": function ($event) {
        return _vm.onScroll($event);
      }
    }
  }, [_vm.paginations.call_logs.page == 1 && _vm.api.fetch_call_logs.send == true ? _c('div', _vm._l(10, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "latestShimmerDesign w-100",
      staticStyle: {
        "height": "88px",
        "margin-bottom": "16px"
      }
    });
  }), 0) : [_vm._l(_vm.response.call_logs, function (call) {
    return _c('CallActivityTestItem', {
      key: call.id,
      attrs: {
        "call": call,
        "conditions": {
          action: {
            enable: true,
            info: true,
            call: false,
            add_contact: false,
            add_task: false,
            play_audio: false,
            transcription: false,
            assigned_detail: false,
            assign: false
          },
          design: {
            more: false
          }
        }
      },
      on: {
        "info": function ($event) {
          return _vm.$modal.show(`${_vm._uid}-CallActivityModal`, Object.assign({
            callInfo: call
          }, $event !== null && $event !== void 0 ? $event : {}));
        }
      }
    });
  }), _vm.api.fetch_call_logs.send == true ? _c('div', {
    staticClass: "w-100 d-flex justify-content-center align-items-center"
  }, [_c('vb-loading', {
    attrs: {
      "type": "ellipseV2"
    }
  })], 1) : _vm._e()]], 2)]), _c('CallActivityModal', {
    attrs: {
      "modalName": `${_vm._uid}-CallActivityModal`
    },
    on: {
      "latest-record": function ($event) {
        'fecthCallLogs()';
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }