var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.session.callState === 'incall' || _vm.session.callState === 'pending' ? _c('div', {
    staticClass: "dialer-flex alt",
    on: {
      "dblclick": function ($event) {
        !_vm.callObjInfo.incall && _vm.callObjInfo.incoming ? '' : _vm.$store.state.global_conditions.tab = 'video-call';
      }
    }
  }, [_vm.session.extra_data.power_dialer_contact && _vm.session.extra_data.power_dialer_contact.data.sheet_id == _vm.$store.state.power_dialer.sheet_id ? _c('div', {
    staticClass: "d-flex justify-content-center align-items-center powerdialer-buttonsInTopBar"
  }, [_c('b-button', {
    staticClass: "stopAutoDialing-button",
    on: {
      "click": function ($event) {
        _vm.$store.state.power_dialer.sheet_id = '';
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "powerDiler-stopAutoDialing-icon"
    }
  }), _vm._v(" Stop auto dialing ")], 1), _c('div', {
    staticClass: "d-flex align-items-center ml-3"
  }, [_c('b-checkbox', {
    staticClass: "newerSwitch mr-2",
    attrs: {
      "switch": "",
      "checked": _vm.$store.state.power_dialer.auto
    },
    on: {
      "change": function ($event) {
        _vm.$store.state.power_dialer.auto = !_vm.$store.state.power_dialer.auto;
      }
    }
  }), _c('div', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v("Auto")])], 1)], 1) : _vm._e(), _vm.session.callState === 'incall' ? [_c('div', {
    staticClass: "dialer-incommingVideoCall-info d-flex align-items-center",
    attrs: {
      "id": "active-sip-call",
      "call-state": _vm.session.callState
    }
  }, [_c('div', {
    staticClass: "dialer-user-dp mr-2"
  }, [_c('img', {
    staticClass: "rounded",
    attrs: {
      "width": "40",
      "height": "40",
      "src": _vm.callObjInfo.image || '',
      "alt": "user image"
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.userDp;
      }
    }
  })]), _c('div', {
    staticClass: "dialer-user-bio"
  }, [_vm.callObjInfo.test_call ? _c('h6', {
    staticClass: "m-0"
  }, [_vm._v("testing call")]) : _c('div', [_vm._v(" From: " + _vm._s(_vm._f("number_formater")(_vm.callObjInfo.cids.from.caller_id ? _vm.callObjInfo.cids.from.caller_id : _vm.callObjInfo.from_number)) + " "), _vm.callObjInfo.from_name ? _c('span', {
    staticClass: "call-info-tag-112233"
  }, [_vm._v(_vm._s(_vm.callObjInfo.from_name))]) : _vm._e(), _vm.callObjInfo.from_other ? _c('span', {
    staticClass: "call-info-tag-112233"
  }, [_vm._v(_vm._s(_vm.callObjInfo.from_other))]) : _vm._e(), _vm.callObjInfo.cids.from.caller_id ? _c('span', {
    staticClass: "call-info-tag-112233"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.callObjInfo.from_number)))]) : _vm._e(), _vm.callObjInfo.cids.from.label ? _c('span', {
    staticClass: "call-info-tag-112233"
  }, [_vm._v(_vm._s(_vm.callObjInfo.cids.from.label))]) : _vm._e(), _c('br'), _vm._v(" To: " + _vm._s(_vm._f("number_formater")(_vm.callObjInfo.cids.to.caller_id ? _vm.callObjInfo.cids.to.caller_id : _vm.callObjInfo.to_number)) + " "), _vm.callObjInfo.to_name ? _c('span', {
    staticClass: "call-info-tag-112233"
  }, [_vm._v(_vm._s(_vm.callObjInfo.to_name))]) : _vm._e(), _vm.callObjInfo.to_other ? _c('span', {
    staticClass: "call-info-tag-112233"
  }, [_vm._v(_vm._s(_vm.callObjInfo.to_other))]) : _vm._e(), _vm.callObjInfo.cids.to.caller_id ? _c('span', {
    staticClass: "call-info-tag-112233"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.callObjInfo.to_number)))]) : _vm._e(), _vm.callObjInfo.cids.to.label ? _c('span', {
    staticClass: "call-info-tag-112233"
  }, [_vm._v(_vm._s(_vm.callObjInfo.cids.to.label))]) : _vm._e()]), _vm.callObjInfo.call_type ? _c('div', [_vm._v(_vm._s(_vm.callObjInfo.call_type))]) : _vm._e(), _vm._l(_vm.callObjInfo.integration_lookup, function (item) {
    return _c('vb-icon', {
      key: item.tags,
      staticClass: "integrationType-icon ml-2",
      attrs: {
        "height": "20px",
        "width": "20px",
        "icon": _vm._f("integrationIcons")(item.tags)
      },
      on: {
        "click": function ($event) {
          return _vm.openIntegrationContactDetail(item);
        }
      }
    });
  }), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('p', {
    staticClass: "call-info-tag-112233 m-0 pr-2 p-2",
    staticStyle: {
      "font-size": "15px"
    }
  }, [_vm._v(_vm._s(_vm._f("incrementFilter")(_vm.session.time)))]), _c('b-icon', {
    attrs: {
      "icon": "phone-alt"
    }
  })], 1)], 2)]), _vm._t("switchCalls"), _vm.session.feedback_screen_active ? _c('vb-icon', {
    staticClass: "sm-mar-bottom cursor_pointer",
    attrs: {
      "icon": "dialer-callRejectInbox-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.session.hangup();
      }
    }
  }) : [(_vm.callObjInfo.is_transfer ? !_vm.callObjInfo.incall : true) ? _c('vb-icon', {
    staticClass: "sm-mar-bottom cursor_pointer",
    attrs: {
      "icon": "dialer-callRejectInbox-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.session.hangup();
      }
    }
  }) : _vm._e(), _vm.callObjInfo.is_transfer && _vm.callObjInfo.incall ? _c('vb-icon', {
    staticClass: "mr-1 sm-mar-bottom cursor_pointer makePathBlack",
    attrs: {
      "icon": "duringCall-afterTransfer-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.$store.state.sip.phone.customAfterTransfer();
      }
    }
  }) : _vm._e(), _vm.callObjInfo.is_transfer && _vm.callObjInfo.incall ? _c('vb-icon', {
    staticClass: "ml-1 sm-mar-bottom cursor_pointer makePathBlack",
    attrs: {
      "icon": "duringCall-cancelTransfer-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.$store.state.sip.phone.cutomCancelTransfer();
      }
    }
  }) : _vm._e()]] : _vm._e(), _vm.session.callState === 'pending' ? [_c('div', {
    staticClass: "d-flex flex-column w-100"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "dialer-incommingVideoCall-info d-flex align-items-center",
    attrs: {
      "call-state": _vm.session.callState
    }
  }, [_c('div', {
    staticClass: "dialer-user-dp mr-2"
  }, [_c('img', {
    staticClass: "rounded",
    attrs: {
      "width": "40",
      "height": "40",
      "src": _vm.callObjInfo.image || '',
      "alt": "user image"
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.userDp;
      }
    }
  })]), _c('div', {
    staticClass: "dialer-user-bio"
  }, [_vm.session.direction != 'out' ? _c('h6', {
    staticClass: "text-capitalize mb-0"
  }, [_vm._v("incoming Audio Call")]) : _c('h6', {
    staticClass: "text-capitalize mb-0"
  }, [_vm._v("Dialing Audio Call")]), _c('div', {}, [_vm.session.number == '*154*' ? _c('h6', {
    staticClass: "m-0"
  }, [_vm._v("testing call")]) : _c('div', [_vm._v(" From: " + _vm._s(_vm._f("number_formater")(_vm.callObjInfo.cids.from.caller_id ? _vm.callObjInfo.cids.from.caller_id : _vm.callObjInfo.from_number)) + " "), _vm.callObjInfo.from_name ? _c('span', {
    staticClass: "call-info-tag-112233"
  }, [_vm._v(_vm._s(_vm.callObjInfo.from_name))]) : _vm._e(), _vm.callObjInfo.from_other ? _c('span', {
    staticClass: "call-info-tag-112233"
  }, [_vm._v(_vm._s(_vm.callObjInfo.from_other))]) : _vm._e(), _vm.callObjInfo.cids.from.caller_id ? _c('span', {
    staticClass: "call-info-tag-112233"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.callObjInfo.from_number)))]) : _vm._e(), _vm.callObjInfo.cids.from.label ? _c('span', {
    staticClass: "call-info-tag-112233"
  }, [_vm._v(_vm._s(_vm.callObjInfo.cids.from.label))]) : _vm._e(), _c('br'), _vm._v(" To: " + _vm._s(_vm._f("number_formater")(_vm.callObjInfo.cids.to.caller_id ? _vm.callObjInfo.cids.to.caller_id : _vm.callObjInfo.to_number)) + " "), _vm.callObjInfo.to_name ? _c('span', {
    staticClass: "call-info-tag-112233"
  }, [_vm._v(_vm._s(_vm.callObjInfo.to_name))]) : _vm._e(), _vm.callObjInfo.to_other ? _c('span', {
    staticClass: "call-info-tag-112233"
  }, [_vm._v(_vm._s(_vm.callObjInfo.to_other))]) : _vm._e(), _vm.callObjInfo.cids.to.caller_id ? _c('span', {
    staticClass: "call-info-tag-112233"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.callObjInfo.to_number)))]) : _vm._e(), _vm.callObjInfo.cids.to.label ? _c('span', {
    staticClass: "call-info-tag-112233"
  }, [_vm._v(_vm._s(_vm.callObjInfo.cids.to.label))]) : _vm._e()]), _vm.callObjInfo.call_type ? _c('div', [_vm._v(_vm._s(_vm.callObjInfo.call_type))]) : _vm._e(), _vm._l(_vm.callObjInfo.integration_lookup, function (item) {
    return _c('vb-icon', {
      key: item.tags,
      staticClass: "integrationType-icon ml-2",
      attrs: {
        "height": "20px",
        "width": "20px",
        "icon": _vm._f("integrationIcons")(item.tags)
      },
      on: {
        "click": function ($event) {
          return _vm.openIntegrationContactDetail(item);
        }
      }
    });
  })], 2)])]), _c('div', {
    staticClass: "d-flex"
  }, [_vm.session.direction == 'out' ? [_vm._t("switchCalls")] : _vm._e(), _vm.session.direction != 'out' ? _c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "dialer-callAcceptInbox-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.onAccept();
      }
    }
  }) : _vm._e(), _c('vb-icon', {
    staticClass: "cursor_pointer md-mar-left",
    attrs: {
      "icon": "dialer-callRejectInbox-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.session.hangup();
      }
    }
  })], 2)]), _vm.session.direction != 'out' ? [_vm._t("switchCalls")] : _vm._e()], 2)] : _vm._e()], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }