var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "ViewContactInfoModal",
    attrs: {
      "name": _vm.modalName
    },
    on: {
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      },
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      }
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to md-pad-bottom dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-flex w-100 justify-content-between align-items-center"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Contact Info")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_vm.api.get_info.send && !_vm.response.info ? [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(10, function (n) {
    return _c('div', {
      key: n,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none py-3"
    }, [_c('div', {
      staticClass: "latestShimmerDesign w-100",
      staticStyle: {
        "height": "25px"
      }
    })]);
  }), 0)] : [_c('div', {
    staticClass: "latestGreyBox-heading-main-16px"
  }, [_vm._v("Tags "), _c('b-button', {
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-PowerDialerContactUpdateTags`, {
          row_no: _vm.data.row_no,
          sheet_id: _vm.data.sheet_id
        });
      }
    }
  }, [_c('b-icon-pencil-fill', {
    attrs: {
      "variant": "primary"
    }
  }), _vm._v(" Edit")], 1)], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-10px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none flex-wrap py-3 justify-content-start"
  }, [_vm.tags.length == 0 ? [_c('div', {
    staticClass: "d-flex w-100 flex-column align-items-center"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "noDataFound-v2",
      "width": "57",
      "height": "63"
    }
  }), _c('span', [_c('b', [_vm._v("There is no Tags")])])], 1)] : _vm._l(_vm.tags, function (tag) {
    return _c('div', {
      key: tag.id,
      staticClass: "m-1"
    }, [_c('div', {
      directives: [{
        name: "vb-tag-color",
        rawName: "v-vb-tag-color.text.bg",
        value: {
          color: tag.colour,
          text_color: tag.text_color
        },
        expression: "{\n                      color: tag.colour,\n                      text_color: tag.text_color\n                    }",
        modifiers: {
          "text": true,
          "bg": true
        }
      }],
      style: `border-radius:4px;height:auto;width:fit-content;padding:0.2rem 0.4rem;font-size: 10.5px;font-weight: 500;line-height: 1.3;text-align: center;vertical-align: initial;white-space: nowrap;`
    }, [_vm._v(" " + _vm._s(tag.tag) + " ")])]);
  })], 2)]), _c('div', {
    staticClass: "latestGreyBox-heading-main-16px mt-20px"
  }, [_vm._v("Notes "), _c('b-button', {
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-PowerDialerContactUpdateNote`, {
          row_no: _vm.data.row_no,
          sheet_id: _vm.data.sheet_id
        });
      }
    }
  }, [_c('b-icon-pencil-fill', {
    attrs: {
      "variant": "primary"
    }
  }), _vm._v(" Edit")], 1)], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-10px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none flex-wrap py-3 justify-content-start"
  }, [_vm.note == '' ? [_c('div', {
    staticClass: "d-flex w-100 flex-column align-items-center"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "noDataFound-v2",
      "width": "57",
      "height": "63"
    }
  }), _c('span', [_c('b', [_vm._v("There is no Note")])])], 1)] : [_c('div', [_vm._v(_vm._s(_vm.note))])]], 2)]), _c('div', {
    staticClass: "latestGreyBox-heading-main-16px mt-20px"
  }, [_vm._v("Schedule "), _c('b-button', {
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-PowerDialerContactUpdateSchedule`, {
          row_no: _vm.data.row_no,
          sheet_id: _vm.data.sheet_id
        });
      }
    }
  }, [_c('b-icon-pencil-fill', {
    attrs: {
      "variant": "primary"
    }
  }), _vm._v(" Edit")], 1)], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-10px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none flex-wrap py-3 justify-content-start"
  }, [_vm.schedule == '' ? [_c('div', {
    staticClass: "d-flex w-100 flex-column align-items-center"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "noDataFound-v2",
      "width": "57",
      "height": "63"
    }
  }), _c('span', [_c('b', [_vm._v("Schedule is not set")])])], 1)] : [_c('div', [_vm._v(_vm._s(_vm._f("filter_date_current")(_vm.schedule, 'YYYY-MM-DD hh:mm:ss', {
    formate: 'YYYY-MM-DD hh:mm a'
  })))])]], 2)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, _vm._l(_vm.info, function (value, key) {
    return _c('div', {
      key: key,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none py-3 justify-content-start"
    }, [_c('b', {
      staticStyle: {
        "min-width": "40%",
        "width": "40%",
        "text-transform": "capitalize"
      }
    }, [_vm._v(_vm._s(key) + ":")]), _c('span', [_vm._v(_vm._s(value))])]);
  }), 0)]], 2)])]), _c('PowerDialerContactUpdateNoteModal', {
    attrs: {
      "modalName": `${_vm._uid}-PowerDialerContactUpdateNote`
    },
    on: {
      "updated": function ($event) {
        return _vm.fetchInfoDebounce();
      }
    }
  }), _c('PowerDialerContactUpdateTagsModal', {
    attrs: {
      "modalName": `${_vm._uid}-PowerDialerContactUpdateTags`
    },
    on: {
      "updated": function ($event) {
        return _vm.fetchInfoDebounce();
      }
    }
  }), _c('PowerDialerContactUpdateScheduleModal', {
    attrs: {
      "modalName": `${_vm._uid}-PowerDialerContactUpdateSchedule`
    },
    on: {
      "updated": function ($event) {
        return _vm.fetchInfoDebounce();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }