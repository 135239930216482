var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "todo"
  }, [_c('div', {
    staticClass: "dialer_contact_list allow_scroll add"
  }, _vm._l(_vm.assignedCalls, function (call) {
    return _c('div', {
      key: call.id,
      staticStyle: {
        "background-color": "black"
      }
    }, [_c('CallActivityItem1', {
      staticStyle: {
        "background-color": "black"
      },
      attrs: {
        "call": call.todoData,
        "conditions": {
          actions: false,
          assign: true
        }
      },
      on: {
        "assign": function ($event) {
          return _vm.$modal.show('AssignedTodoDetail', {
            call: call
          });
        }
      }
    })], 1);
  }), 0), _c('AssignedTodoDetailModal', {
    on: {
      "assigned": function ($event) {
        return _vm.getTodos();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }