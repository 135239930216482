var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.call ? _c('div', [_c('div', {
    staticClass: "d-flex justify-content-end position-absolute",
    staticStyle: {
      "right": "147px",
      "top": "-104px",
      "z-index": "10"
    }
  }, [(_vm.log.status == 'Dialed' ? _vm.log.dst_type == 'Number' : _vm.log.src_type == 'Number') ? _c('div', {
    staticClass: "TabsDesignWithIcon-item",
    class: {
      selected: _vm.selected.tab == _vm.tabs.add_contact
    },
    on: {
      "click": function ($event) {
        return _vm.selectAddContact();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "callInfo-tab-addContact-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "TabsDesignWithIcon-item-textPart"
  }, [_vm._v("Add contact")])], 1) : _vm._e(), _vm.getCurrentUser.administrator_account && !_vm.conditions.block_number ? _c('div', {
    staticClass: "TabsDesignWithIcon-item",
    class: {
      selected: _vm.selected.tab == _vm.tabs.block
    },
    on: {
      "click": function ($event) {
        _vm.selected.tab = _vm.tabs.block;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "callInfo-tab-BlockNumber-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "TabsDesignWithIcon-item-textPart"
  }, [_vm._v("Block")])], 1) : _vm._e()]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('ul', {
    staticClass: "phone-list full-width-call-info-number list-unstyled pr-0 pb-0 overlowX-visible-imp"
  }, [_c('li', {
    staticClass: "p-0"
  }, [_c('CallActivityTestItem', {
    staticClass: "m-0 w-100",
    attrs: {
      "call": _vm.call,
      "conditions": {
        action: {
          enable: false
        },
        design: {
          more: false
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "more",
      fn: function () {
        return [_c('b-dropdown', {
          staticClass: "ico-filter",
          attrs: {
            "varient": "link",
            "no-caret": "",
            "menu-class": "w-fit-content-imp",
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('b-icon', {
                attrs: {
                  "icon": "three-dots",
                  "variant": "#393939",
                  "font-scale": "1.4"
                }
              })];
            },
            proxy: true
          }], null, false, 764454405)
        })];
      },
      proxy: true
    }], null, false, 401778800)
  })], 1)])]), _c('div', [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 mt-32px mb-32px"
  }, [_c('div', {
    staticClass: "TabsDesignWithIcon-container"
  }, [_c('div', {
    staticClass: "TabsDesignWithIcon-item",
    class: {
      selected: _vm.selected.tab == _vm.tabs.tags_and_note
    },
    on: {
      "click": function ($event) {
        _vm.selected.tab = _vm.tabs.tags_and_note;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "newbilling-tab-tags-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "TabsDesignWithIcon-item-textPart"
  }, [_vm._v("Call info")])], 1), _c('div', {
    staticClass: "TabsDesignWithIcon-item",
    class: {
      selected: _vm.selected.tab == _vm.tabs.task
    },
    on: {
      "click": function ($event) {
        _vm.selected.tab = _vm.tabs.task;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "newbilling-tab-tags-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "TabsDesignWithIcon-item-textPart"
  }, [_vm._v("Task")])], 1), _c('div', {
    staticClass: "TabsDesignWithIcon-item",
    class: {
      selected: _vm.selected.tab == _vm.tabs.history
    },
    on: {
      "click": function ($event) {
        _vm.selected.tab = _vm.tabs.history;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "newbilling-tab-history-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "TabsDesignWithIcon-item-textPart"
  }, [_vm._v("History")])], 1), _vm.call.soundFile ? _c('div', {
    staticClass: "TabsDesignWithIcon-item",
    class: {
      selected: _vm.selected.tab == _vm.tabs.recordings
    },
    on: {
      "click": function ($event) {
        _vm.selected.tab = _vm.tabs.recordings;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "squared-ai-audio-icon",
    attrs: {
      "icon": "squared-ai-audio-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "TabsDesignWithIcon-item-textPart"
  }, [_vm._v("AI transcript")])], 1) : _vm._e(), _vm.isMobileNumber ? _c('div', {
    staticClass: "TabsDesignWithIcon-item",
    class: {
      selected: _vm.selected.tab == _vm.tabs.sms
    },
    on: {
      "click": function ($event) {
        _vm.selected.tab = _vm.tabs.sms;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "newbilling-tab-sms-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "TabsDesignWithIcon-item-textPart"
  }, [_vm._v("SMS")])], 1) : _vm._e()]), _vm.selected.tab == _vm.tabs.history ? _vm._t("history-filter") : _vm._e()], 2), _c('ul', {
    staticClass: "allow_scroll phone-list full-width-call-info-number dialor-callHistoryList list-unstyled pl-0 pr-2",
    class: {
      'tags_and_note_tab': _vm.selected.tab == _vm.tabs.tags_and_note
    },
    staticStyle: {
      "overflow-y": "unset",
      "height": "auto !important"
    }
  }, [_vm.selected.tab == _vm.tabs.tags_and_note ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Recording")]), _vm.call.soundFile ? _c('vb-audio-player', {
    staticClass: "w-100 mt-20px",
    attrs: {
      "src": _vm.call.soundFile,
      "design": 'NoTranscript'
    },
    scopedSlots: _vm._u([{
      key: "player-end",
      fn: function () {
        return [_c('vb-icon', {
          staticClass: "cursor_pointer_no_hover",
          attrs: {
            "icon": "audioTranscript-download-icon",
            "width": "14.2px",
            "height": "14.2px"
          },
          on: {
            "click": function ($event) {
              return _vm.download();
            }
          }
        })];
      },
      proxy: true
    }], null, false, 992160004)
  }) : _c('div', {
    staticClass: "latestGreyBox-9-9-2023 w-100 flex-wrap d-flex mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-descriptionText mt-0"
  }, [_vm._v("There is no recording")])])], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Call tags")]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "callInfoEditNotesTagsBoxTypeButton",
    attrs: {
      "title": _vm.getUserPermissions.call_tagging_and_notes ? '' : 'Your package does not support this feature'
    },
    on: {
      "click": function ($event) {
        _vm.conditions.edit_tags = !_vm.conditions.edit_tags;
      }
    }
  }, [_vm.conditions.edit_tags ? [_c('b-icon', {
    attrs: {
      "icon": "x-lg",
      "variant": "dark",
      "font-scale": "2"
    }
  }), _vm._v(" Cancel")] : _vm.isEmpty(_vm.callTags) ? [_c('b-icon', {
    attrs: {
      "font-scale": "1.3",
      "icon": "plus-lg",
      "variant": "dark"
    }
  }), _vm._v(" Add")] : [_c('b-icon', {
    attrs: {
      "font-scale": "1.3",
      "icon": "pencil-fill",
      "variant": "primary"
    }
  }), _vm._v(" Edit")]], 2)]), !_vm.conditions.edit_tags ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023 w-100 flex-wrap d-flex mt-20px"
  }, [_vm._l(_vm.callTags, function (tag) {
    return _c('span', {
      key: tag.id,
      staticClass: "latestTagDesignLayout"
    }, [_c('small', {
      directives: [{
        name: "vb-tag-color",
        rawName: "v-vb-tag-color",
        value: tag.colour,
        expression: "tag.colour"
      }]
    }, [_c('span', {
      directives: [{
        name: "vb-tag-color",
        rawName: "v-vb-tag-color.text",
        value: tag.text_color,
        expression: "tag.text_color",
        modifiers: {
          "text": true
        }
      }]
    }, [_vm._v(_vm._s(tag.tag))])])]);
  }), _vm.isEmpty(_vm.callTags) ? _c('div', {
    staticClass: "latestGreyBox-descriptionText mt-0"
  }, [_vm._v("There is no tags")]) : _vm._e()], 2) : _c('div', {
    staticClass: "latestGreyBox-9-9-2023 w-100 mt-20px"
  }, [_c('b-form', {
    staticClass: "whiteBGinputWithGreyRoundedBorder",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search tags"
    },
    model: {
      value: _vm.filter.tag.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.tag, "search", $$v);
      },
      expression: "filter.tag.search"
    }
  })], 1), _vm._l(_vm.api.tag.validation_errors.log, function (em, i) {
    return _c('p', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }), _c('div', {
    staticClass: "d-flex flex-wrap w-100 mt-20px"
  }, [_vm._l(_vm.filterTags, function (tag) {
    return _c('div', {
      key: tag.real_id,
      class: `${_vm.forms.tag.tags.includes(tag.real_id) ? 'selected' : ''} latestTagDesignLayout`,
      on: {
        "click": function ($event) {
          _vm.api.tag.send ? '' : _vm.forms.tag.tags.includes(tag.real_id) ? _vm.forms.tag.tags.splice(_vm.forms.tag.tags.indexOf(tag.real_id), 1) : _vm.forms.tag.tags.push(tag.real_id);
        }
      }
    }, [_c('small', {
      directives: [{
        name: "vb-tag-color",
        rawName: "v-vb-tag-color",
        value: tag.colour,
        expression: "tag.colour"
      }],
      style: `border-color: ${_vm.forms.tag.tags.includes(tag.real_id) ? '#3699FF' : ''}`
    }, [_c('span', {
      directives: [{
        name: "vb-tag-color",
        rawName: "v-vb-tag-color.text",
        value: tag.text_color,
        expression: "tag.text_color",
        modifiers: {
          "text": true
        }
      }]
    }, [_vm._v(_vm._s(tag.tag))])])]);
  }), _vm.isEmpty(_vm.filterTags) ? _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("There is no tags")]) : _vm._e()], 2), _vm._l(_vm.api.tag.validation_errors.log, function (em, i) {
    return _c('p', {
      key: i,
      staticClass: "latestGreyBox-descriptionText"
    }, [_vm._v(_vm._s(em))]);
  }), _c('div', {
    staticClass: "d-flex justify-content-end mt-20px"
  }, [_c('b-button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.tag.send,
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.updatetag();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "submitIcon",
    attrs: {
      "icon": "squared-submit-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api.tag.send ? _c('vb-spinner') : [_vm._v(" Submit ")]], 2)], 1)], 1)], 2)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Call notes")]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "callInfoEditNotesTagsBoxTypeButton",
    attrs: {
      "title": _vm.getUserPermissions.call_tagging_and_notes ? '' : 'Your package does not support this feature'
    },
    on: {
      "click": function ($event) {
        _vm.conditions.edit_notes = !_vm.conditions.edit_notes;
      }
    }
  }, [_vm.data.note ? [_vm.conditions.edit_notes ? _c('b-icon', {
    attrs: {
      "icon": "x-lg",
      "variant": "dark",
      "font-scale": "2"
    }
  }) : _c('b-icon', {
    attrs: {
      "font-scale": "1.3",
      "icon": "pencil-fill",
      "variant": "primary"
    }
  }), _vm._v(" " + _vm._s(_vm.conditions.edit_notes ? 'Cancel' : 'Edit') + " ")] : [_vm.conditions.edit_notes ? _c('b-icon', {
    attrs: {
      "icon": "x-lg",
      "variant": "dark",
      "font-scale": "2"
    }
  }) : _c('b-icon', {
    attrs: {
      "font-scale": "1.3",
      "icon": "plus-lg",
      "variant": "dark"
    }
  }), _vm._v(" " + _vm._s(_vm.conditions.edit_notes ? 'Cancel' : 'Add') + " ")]], 2)]), !_vm.conditions.edit_notes ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023 w-100 mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-descriptionText mt-0"
  }, [_vm._v(_vm._s(_vm.data.note || 'There is no note'))])]) : [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 w-100 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100"
  }, [_c('label', [_vm._v("Write some note for this call")]), _c('b-textarea', {
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.note.send,
      "rows": "10",
      "no-resize": "",
      "placeholder": "Enter note"
    },
    model: {
      value: _vm.forms.note.note,
      callback: function ($$v) {
        _vm.$set(_vm.forms.note, "note", $$v);
      },
      expression: "forms.note.note"
    }
  })], 1), _c('div', {
    staticClass: "d-flex justify-content-end mt-20px"
  }, [_c('b-button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.note.send,
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.updatenote();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "submitIcon",
    attrs: {
      "icon": "squared-submit-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api.note.send ? _c('vb-spinner') : [_vm._v("Submit")]], 2)], 1)], 1)])]], 2), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center"
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 w-100 mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-descriptionText mt-0"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.call, 'linked_tasks.length', 0)) + " tasks")])])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-center justify-content-center"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("How was the quality of your call?")]), _c('b-form-rating', {
    staticClass: "latestCallFeedback",
    attrs: {
      "no-border": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updateFeedback();
      }
    },
    model: {
      value: _vm.forms.feedback.rating,
      callback: function ($$v) {
        _vm.$set(_vm.forms.feedback, "rating", $$v);
      },
      expression: "forms.feedback.rating"
    }
  })], 1)])]) : _vm._e(), _vm.selected.tab == _vm.tabs.recordings ? [_vm._m(0), _c('AudioTranscriptDummy', {
    attrs: {
      "soundFile": _vm.call.soundFile,
      "uniqueID": _vm.uniqueID
    },
    scopedSlots: _vm._u([{
      key: "transcript-end",
      fn: function () {
        return [_c('div', {
          staticClass: "latestGreyBox-9-9-2023 mt-20px mb-20px"
        }, [_c('div', {
          staticClass: "latestGreyBox-heading-main w-100 d-flex align-items-center justify-content-between"
        }, [_c('span', [_vm._v("Call context")]), _c('button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip"
          }],
          staticClass: "newButton",
          attrs: {
            "title": _vm.getUserPermissions.call_tagging_and_notes ? '' : 'Your package does not support this feature'
          },
          on: {
            "click": function ($event) {
              !_vm.getUserPermissions.call_tagging_and_notes ? '' : _vm.$modal.show(`${_vm._uid}-UpdateCallNotesAndTags`, {
                note: _vm.data.note,
                tags: _vm.callTags
              });
            }
          }
        }, [_c('vb-icon', {
          attrs: {
            "icon": "squared-update-icon",
            "height": "38px",
            "width": "38px"
          }
        }), _c('span', {
          staticClass: "newButton-textPart"
        }, [_vm._v("Update")])], 1)]), _c('div', {
          staticClass: "AudioTranscript-whiteBox mt-20px"
        }, [_c('div', {
          staticClass: "latestGreyBox-heading-main-16px"
        }, [_vm._v("Notes")]), _c('div', {
          staticClass: "latestGreyBox-descriptionText-13px mt-10px"
        }, [_vm._v(_vm._s(_vm.data.note))]), _c('div', {
          staticClass: "latestGreyBox-heading-main-16px mt-24px"
        }, [_vm._v("tags")]), _c('div', {
          staticClass: "AI-tags-container"
        }, _vm._l(_vm.callTags, function (tag) {
          return _c('div', {
            directives: [{
              name: "vb-tag-color",
              rawName: "v-vb-tag-color.bg",
              value: tag.colour,
              expression: "tag.colour",
              modifiers: {
                "bg": true
              }
            }],
            key: tag.id,
            staticClass: "AI-tag"
          }, [_vm._v(_vm._s(tag.tag))]);
        }), 0)])])];
      },
      proxy: true
    }], null, false, 2064208293)
  })] : _vm._e(), _vm.selected.tab == _vm.tabs.sms ? _c('SMS', {
    attrs: {
      "otherNumber": _vm.dialable
    }
  }) : _vm._e(), _vm.selected.tab == _vm.tabs.history ? _vm._t("history") : _vm.selected.tab == _vm.tabs.block ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        !_vm.getUserPermissions.block_and_privacy ? '' : _vm.blocknumber();
      }
    }
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mb-0 w-100"
  }, [_c('label', [_vm._v("Reason for blocking")]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: {
      'for-disable': !_vm.getUserPermissions.block_and_privacy
    },
    attrs: {
      "title": _vm.getUserPermissions.block_and_privacy ? '' : 'Your package does not support this feature'
    }
  }, [_c('b-input', {
    attrs: {
      "disabled": _vm.api.block_number.send || !_vm.getUserPermissions.block_and_privacy
    },
    model: {
      value: _vm.forms.block_number.displayname,
      callback: function ($$v) {
        _vm.$set(_vm.forms.block_number, "displayname", $$v);
      },
      expression: "forms.block_number.displayname"
    }
  })], 1), _vm.forms.block_number.submitted && _vm.$v.forms.block_number.displayname.$invalid || _vm.api.block_number.validation_errors.displayname ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.block_number.displayname.required ? _c('span', [_vm._v("* First name is required")]) : _vm._e(), _vm._l(_vm.api.block_number.validation_errors.displayname, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  })], 2) : _vm._e()])], 1)]), _c('div', {
    staticClass: "latestGreyBox-heading-main mt-20px"
  }, [_vm._v("Direction of number block")]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "align-items-center justify-content-between d-flex w-100 bigSquareCheckbox",
    class: {
      'for-disable w-fit-content': !_vm.getUserPermissions.block_and_privacy
    },
    attrs: {
      "title": _vm.getUserPermissions.block_and_privacy ? '' : 'Your package does not support this feature'
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Block inbound calls")]), _c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "switch": "",
      "disabled": _vm.api.block_number.send || !_vm.getUserPermissions.block_and_privacy
    },
    model: {
      value: _vm.forms.block_number.inbound,
      callback: function ($$v) {
        _vm.$set(_vm.forms.block_number, "inbound", $$v);
      },
      expression: "forms.block_number.inbound"
    }
  })], 1)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "align-items-center justify-content-between d-flex w-100 bigSquareCheckbox",
    class: {
      'for-disable w-fit-content': !_vm.getUserPermissions.block_and_privacy
    },
    attrs: {
      "title": _vm.getUserPermissions.block_and_privacy ? '' : 'Your package does not support this feature'
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Block otbound calls")]), _c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "switch": "",
      "variant": "black",
      "disabled": _vm.api.block_number.send || !_vm.getUserPermissions.block_and_privacy
    },
    model: {
      value: _vm.forms.block_number.outbound,
      callback: function ($$v) {
        _vm.$set(_vm.forms.block_number, "outbound", $$v);
      },
      expression: "forms.block_number.outbound"
    }
  })], 1)])]), _c('div', {
    staticClass: "d-flex justify-content-end mt-20px"
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: {
      'for-disable w-fit-content': !_vm.getUserPermissions.block_and_privacy
    },
    attrs: {
      "title": _vm.getUserPermissions.block_and_privacy ? '' : 'Your package does not support this feature'
    }
  }, [_c('b-button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "submit",
      "disabled": _vm.api.block_number.send || !_vm.getUserPermissions.block_and_privacy
    }
  }, [_c('vb-icon', {
    staticClass: "submitIcon",
    attrs: {
      "icon": "squared-submit-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api.block_number.send ? _c('vb-spinner') : [_vm._v("Submit")]], 2)], 1)], 1)])], 1)])], 1) : _vm.selected.tab == _vm.tabs.add_contact ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_vm.conditions.edit_contact_id ? _c('CreateOrUpdateAddressbook', {
    ref: "update-addressbook",
    attrs: {
      "creating": false,
      "addressbookID": _vm.conditions.edit_contact_id
    }
  }) : _c('CreateOrUpdateAddressbook', {
    ref: "create-addressbook",
    attrs: {
      "creating": true
    },
    on: {
      "created": function ($event) {
        _vm.conditions.edit_contact_id = $event.addressbook.real_id;
        _vm.selectAddContact();
      }
    }
  })], 1) : _vm.selected.tab == _vm.tabs.task ? _c('div', [_c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Task")]), _c('div', {
    staticClass: "callInfoEditNotesTagsBoxTypeButton",
    on: {
      "click": function ($event) {
        return _vm.addTodoTask();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "mr-16px",
    attrs: {
      "icon": "callInfoModal-assignTo-icon",
      "width": "23.875px",
      "height": "20.436px"
    }
  }), _vm._v(" Create Task ")], 1), _c('div', {
    staticClass: "callInfoEditNotesTagsBoxTypeButton",
    on: {
      "click": function ($event) {
        _vm.$modal.show(`${_vm._uid}-AssignTaskToCallLogModal`, {
          call_id: _vm.callID,
          assigned_tasks: _vm.call.linked_tasks.map(function (i) {
            return i.task_id;
          })
        });
      }
    }
  }, [_c('vb-icon', {
    staticClass: "mr-16px",
    attrs: {
      "icon": "callInfoModal-assignTo-icon",
      "width": "23.875px",
      "height": "20.436px"
    }
  }), _vm._v(" Link Tasks ")], 1)]), _vm._l(_vm.call.linked_tasks, function (linked_task, index) {
    return _c('b-card', {
      key: linked_task.id,
      staticClass: "mb-1",
      staticStyle: {
        "background-color": "transparent",
        "border-width": "0px"
      },
      attrs: {
        "no-body": ""
      }
    }, [_c('b-card-header', {
      staticClass: "p-1 d-flex",
      staticStyle: {
        "background-color": "transparent",
        "border-width": "0px"
      },
      attrs: {
        "header-tag": "header",
        "role": "tab"
      }
    }, [_c('TaskItem', {
      attrs: {
        "task": linked_task.task_assigned
      },
      scopedSlots: _vm._u([{
        key: "more",
        fn: function () {
          return [_c('b-button', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: [`${_vm._uid}-task-${index}`],
              expression: "[`${_uid}-task-${index}`]"
            }],
            attrs: {
              "block": "",
              "variant": "link"
            }
          }, [_c('b-icon', {
            attrs: {
              "icon": "chevron-down"
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    })], 1), _c('b-collapse', {
      attrs: {
        "id": `${_vm._uid}-task-${index}`,
        "accordion": "tasks"
      }
    }, [_c('b-card-body', _vm._l(_vm.call.linked_tasks, function (linked_task) {
      return _c('EditTasksModal', {
        key: linked_task.id,
        attrs: {
          "propTaskID": linked_task.task_id
        }
      });
    }), 1)], 1)], 1);
  })], 2) : _vm._e()], 2), _c('UpdateCallNotesAndTags', {
    attrs: {
      "modalName": `${_vm._uid}-UpdateCallNotesAndTags`,
      "callID": _vm.callID
    },
    on: {
      "note-updated": function ($event) {
        _vm.data.note = $event.note;
        _vm.$emit('latest-record');
        _vm.$emit('updated');
      },
      "tags-updated": function ($event) {
        _vm.data.tag_ids = $event.tag_ids;
        _vm.$emit('latest-record');
        _vm.$emit('updated');
      }
    }
  }), _c('AssignTaskToCallLogModal', {
    attrs: {
      "modalName": `${_vm._uid}-AssignTaskToCallLogModal`
    },
    on: {
      "updated": function ($event) {
        return _vm.$emit('fetch-latest-data');
      }
    }
  })], 1)]) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-between align-items-center my-3"
  }, [_c('h2', {
    staticClass: "mb-0"
  }, [_vm._v("Call recording")])]);

}]

export { render, staticRenderFns }