var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 min-width-50percent-modal",
    attrs: {
      "height": "auto",
      "name": _vm.modalName,
      "scrollable": true
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_vm.$store.getters.getIsMobile ? _c('div', {
    staticClass: "d-flex w-100 justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-settings-title"
  }, [_vm._v("Storage")]), _vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _vm._e()], 1) : _c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Storage")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v(" Integrate with Amazon S3: Store your call recordings securely on a remote service like Amazon S3 or a compatible platform. "), _vm._v(" It is often used by enterprise organisations for better compliance and security. ")]), _c('div', {
    staticClass: "md-mar-bottom md-mar-top"
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.update.error_message,
      "variant": "danger"
    }
  }, [_vm._v(" " + _vm._s(_vm.api.update.error_message) + " ")]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Update setting")]), _c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "disabled": _vm.api.update.send || _vm.api.update_status.send || _vm.api.get.send,
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updatestatus();
      }
    },
    model: {
      value: _vm.forms.update.status,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update, "status", $$v);
      },
      expression: "forms.update.status"
    }
  })], 1)]), _vm.forms.update.status ? _c('form', {
    staticClass: "dialer-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.update();
      }
    }
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100"
  }, [_c('label', [_vm._v("Type")]), _c('vb-select', {
    staticClass: "w-100",
    attrs: {
      "options": _vm.typeOptions
    },
    model: {
      value: _vm.forms.update.type,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update, "type", $$v);
      },
      expression: "forms.update.type"
    }
  }), _vm.forms.update.submitted ? [!_vm.$v.forms.update.type.required ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [_c('span', [_vm._v("* Type is required")])]) : _vm._e()] : _vm._e(), _vm.api.update.validation_errors.provider ? _vm._l(_vm.api.update.validation_errors.provider, function (error_message, index) {
    return _c('p', {
      key: index,
      staticClass: "mb-1 text-small text text-danger animated bounceIntop"
    }, [_c('span', [_vm._v("* " + _vm._s(error_message))])]);
  }) : _vm._e()], 2)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100"
  }, [_c('label', [_vm._v("Key")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update.key,
      expression: "forms.update.key"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "label": "Key",
      "disabled": _vm.api.update.send || _vm.api.get.send
    },
    domProps: {
      "value": _vm.forms.update.key
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update, "key", $event.target.value);
      }
    }
  }), _vm.forms.update.submitted ? [!_vm.$v.forms.update.key.required ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [_c('span', [_vm._v("* key is required")])]) : _vm._e()] : _vm._e(), _vm.api.update.validation_errors.key ? _vm._l(_vm.api.update.validation_errors.key, function (error_message, index) {
    return _c('p', {
      key: index,
      staticClass: "mb-1 text-small text text-danger animated bounceIntop"
    }, [_c('span', [_vm._v("* " + _vm._s(error_message))])]);
  }) : _vm._e()], 2)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100"
  }, [_c('label', [_vm._v("SECRET")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update.secret,
      expression: "forms.update.secret"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "label": "SECRET",
      "disabled": _vm.api.update.send || _vm.api.get.send
    },
    domProps: {
      "value": _vm.forms.update.secret
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update, "secret", $event.target.value);
      }
    }
  }), _vm.forms.update.submitted ? [!_vm.$v.forms.update.secret.required ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [_c('span', [_vm._v("* secret is required")])]) : _vm._e()] : _vm._e(), _vm.api.update.validation_errors.secret ? _vm._l(_vm.api.update.validation_errors.secret, function (error_message, index) {
    return _c('p', {
      key: index,
      staticClass: "mb-1 text-small text text-danger animated bounceIntop"
    }, [_c('span', [_vm._v("* " + _vm._s(error_message))])]);
  }) : _vm._e()], 2)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100"
  }, [_c('label', [_vm._v("Bucket")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update.bucket,
      expression: "forms.update.bucket"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "label": "bucket",
      "disabled": _vm.api.update.send || _vm.api.get.send
    },
    domProps: {
      "value": _vm.forms.update.bucket
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update, "bucket", $event.target.value);
      }
    }
  }), _vm.forms.update.submitted ? [!_vm.$v.forms.update.bucket.required ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [_c('span', [_vm._v("* bucket is required")])]) : _vm._e()] : _vm._e(), _vm.api.update.validation_errors.bucket ? _vm._l(_vm.api.update.validation_errors.bucket, function (error_message, index) {
    return _c('p', {
      key: index,
      staticClass: "mb-1 text-small text text-danger animated bounceIntop"
    }, [_c('span', [_vm._v("* " + _vm._s(error_message))])]);
  }) : _vm._e()], 2)]), _vm.forms.update.type == 's3' ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100"
  }, [_c('label', [_vm._v("region")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update.region,
      expression: "forms.update.region"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "label": "region",
      "disabled": _vm.api.update.send || _vm.api.get.send
    },
    domProps: {
      "value": _vm.forms.update.region
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update, "region", $event.target.value);
      }
    }
  }), _vm.forms.update.submitted ? [!_vm.$v.forms.update.region.required ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [_c('span', [_vm._v("* region is required")])]) : _vm._e()] : _vm._e(), _vm.api.update.validation_errors.region ? _vm._l(_vm.api.update.validation_errors.region, function (error_message, index) {
    return _c('p', {
      key: index,
      staticClass: "mb-1 text-small text text-danger animated bounceIntop"
    }, [_c('span', [_vm._v("* " + _vm._s(error_message))])]);
  }) : _vm._e()], 2)]) : _vm._e(), _vm.forms.update.type == 'other' ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100"
  }, [_c('label', [_vm._v("URL")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update.url,
      expression: "forms.update.url"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "label": "URL",
      "disabled": _vm.api.update.send || _vm.api.get.send
    },
    domProps: {
      "value": _vm.forms.update.url
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update, "url", $event.target.value);
      }
    }
  }), _vm.forms.update.submitted ? [!_vm.$v.forms.update.url.required ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [_c('span', [_vm._v("* Type is required")])]) : _vm._e(), !_vm.$v.forms.update.url.url ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [_c('span', [_vm._v("* Invalid URL")])]) : _vm._e()] : _vm._e(), _vm.api.update.validation_errors.url ? _vm._l(_vm.api.update.validation_errors.url, function (error_message, index) {
    return _c('p', {
      key: index,
      staticClass: "mb-1 text-small text text-danger animated bounceIntop"
    }, [_c('span', [_vm._v("* " + _vm._s(error_message))])]);
  }) : _vm._e()], 2)]) : _vm._e()]), _c('div', {
    staticClass: "d-flex justify-content-end mt-4"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.update.send || _vm.api.get.send
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api.update.send ? _c('vb-spinner') : [_vm._v("Update")]], 2)], 1)])]) : _vm._e()])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }