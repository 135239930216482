var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main dialer-schedule-main v2"
  }, [_c('section', {
    staticClass: "dialer-settings-section w-100 mw-100"
  }, [_c('div', {
    staticClass: "dialer-settings-section-inner"
  }, [_c('div', {
    staticClass: "dialer-flex"
  }, [_c('h2', {
    staticClass: "dialer-settings-title mb-0"
  }, [_vm._v("Members")]), _c('div', {
    class: `w-25 h-50px d-flex align-items-center justify-content-end ${_vm.selected.tab == 'favourites' ? 'justify-content-end' : ''}`
  }, [_c('div', {
    staticClass: "mr-12px cursor_pointer_no_hover compactViewContainer",
    on: {
      "click": function ($event) {
        _vm.gridView = !_vm.gridView;
      }
    }
  }, [_vm.gridView ? _c('vb-icon', {
    attrs: {
      "icon": "members-gridView-icon",
      "height": "50px",
      "width": "50px"
    }
  }) : _c('vb-icon', {
    attrs: {
      "icon": "todo-compact-icon",
      "height": "50px",
      "width": "50px"
    }
  })], 1), _vm.selected.tab == 'users' ? _c('b-form', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-input', {
    staticClass: "w-100 mx-1",
    attrs: {
      "placeholder": "Search here"
    },
    model: {
      value: _vm.filter.users.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.users, "search", $$v);
      },
      expression: "filter.users.search"
    }
  })], 1) : _vm.selected.tab == 'teams' ? _c('b-form', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-input', {
    staticClass: "w-100 mx-1",
    attrs: {
      "placeholder": "Search here"
    },
    model: {
      value: _vm.filter.teams.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.teams, "search", $$v);
      },
      expression: "filter.teams.search"
    }
  })], 1) : _vm.selected.tab == 'queue' ? _c('b-form', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-input', {
    staticClass: "w-100 mx-1",
    attrs: {
      "placeholder": "Search here"
    },
    model: {
      value: _vm.filter.call_queue.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.call_queue, "search", $$v);
      },
      expression: "filter.call_queue.search"
    }
  })], 1) : _vm.selected.tab == 'addressbook' ? [_vm.selected.subTab == 'my' ? [_c('b-form', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-input', {
    staticClass: "w-100 mx-1",
    attrs: {
      "placeholder": "Search my contacts"
    },
    model: {
      value: _vm.filter.local_addressbooks.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.local_addressbooks, "search", $$v);
      },
      expression: "filter.local_addressbooks.search"
    }
  })], 1)] : _vm._e(), _vm.selected.subTab == 'global' ? [_c('b-form', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-input', {
    staticClass: "w-100 mx-1",
    attrs: {
      "placeholder": "Search global contacts"
    },
    model: {
      value: _vm.filter.global_addressbooks.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.global_addressbooks, "search", $$v);
      },
      expression: "filter.global_addressbooks.search"
    }
  })], 1)] : _vm._e()] : _vm._e()], 2)]), _c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between mt-20px"
  }, [_c('div', {
    staticClass: "TabsDesignWithIcon-container"
  }, _vm._l(_vm.tabs, function (allTab) {
    return _c('div', {
      key: allTab.id,
      staticClass: "TabsDesignWithIcon-item",
      class: {
        'active': _vm.selected.tab == allTab.value,
        'mwd-168px': allTab.value == 'balance'
      },
      on: {
        "click": function ($event) {
          _vm.selected.tab = allTab.value;
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": allTab.icon,
        "height": "38px",
        "width": "38px"
      }
    }), _c('span', {
      staticClass: "TabsDesignWithIcon-item-textPart"
    }, [_vm._v(_vm._s(allTab.text))])], 1);
  }), 0), _c('div', {
    staticClass: "TabsDesignWithIcon-item mwd-168px",
    class: {
      'active': _vm.selected.tab == 'addressbook'
    },
    on: {
      "click": function ($event) {
        _vm.selected.tab = 'addressbook';
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "newbilling-tab-invoices-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "TabsDesignWithIcon-item-textPart"
  }, [_vm._v("Address book")])], 1)]), _vm.selected.tab == 'users' ? [_vm.gridView ? [_c('div', {
    staticClass: "settingsGridView-container mt-20px"
  }, _vm._l(_vm.searchedUsers, function (data) {
    return _c('div', {
      key: data.voipaccount,
      staticClass: "settingsGridView-item"
    }, [_c('Info', {
      staticClass: "settingsGridView-item-info-component",
      attrs: {
        "id": data.voipaccount,
        "devices": true,
        "is_unread_show": true,
        "is_blf": false
      }
    }), data.object == 'UserInvitation' ? _c('div', {
      staticClass: "settingsGridView-display_name"
    }, [_vm._v(" " + _vm._s(_vm._f("get_property")(data, 'data.firstname')) + " " + _vm._s(_vm._f("get_property")(data, 'data.lastname')) + " ")]) : _vm._e(), _c('div', {
      staticClass: "settingsGridView-email"
    }, [_vm._v(" " + _vm._s(_vm._f("get_property")(data, 'user.job_tittle')) + " ")]), data.voipaccount && _vm.$store.getters.getCurrentUser.account != data.voipaccount ? _c('div', {
      staticClass: "d-flex settingsGridView-bottomIcons-container mt-20px"
    }, [data.state == 'Ringing' ? _c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `Pick call`,
        expression: "`Pick call`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "mr-3 cursor_pointer_no_hover",
      on: {
        "click": function ($event) {
          !_vm.getUserPermissions.blf ? _vm.appNotify.open({
            message: 'Your package does not support this feature',
            type: 'danger'
          }) : _vm.dialinringing(data);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": "newScheduling-call-icon",
        "height": "55px",
        "width": "55px"
      }
    })], 1) : _c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `Call`,
        expression: "`Call`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "mr-3 cursor_pointer_no_hover",
      on: {
        "click": function ($event) {
          return _vm.dial(data);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": "newScheduling-call-icon",
        "height": "55px",
        "width": "55px"
      }
    })], 1), _c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip"
      }],
      staticClass: "mr-3 cursor_pointer_no_hover",
      class: ['', !_vm.getUserPermissions.chat ? 'for-disable' : ''],
      attrs: {
        "id": `RightSideUsersChatOption-${data.voipaccount}`,
        "title": _vm.getUserPermissions.chat ? '' : 'Your package does not support this feature'
      }
    }, [_c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `Chat`,
        expression: "`Chat`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      class: !_vm.getUserPermissions.chat ? 'for-disable-disable' : '',
      on: {
        "click": function ($event) {
          !_vm.getUserPermissions.chat ? '' : _vm.chatUser(data);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": "newScheduling-chat-icon",
        "height": "55px",
        "width": "55px"
      }
    })], 1)]), _c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `Video call`,
        expression: "`Video call`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "mr-3 cursor_pointer_no_hover",
      on: {
        "click": function ($event) {
          return _vm.videoCall(data);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": "newScheduling-video-icon",
        "height": "55px",
        "width": "55px"
      }
    })], 1), _c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `${data.is_favourites ? 'Remove from favourite' : 'Add to favourite'}`,
        expression: "`${data.is_favourites ? 'Remove from favourite' : 'Add to favourite'}`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "cursor_pointer_no_hover",
      on: {
        "click": function ($event) {
          return _vm.toggleUserFavourite(data);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": data.is_favourites ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon',
        "height": "55px",
        "width": "55px"
      }
    })], 1)]) : _vm._e()], 1);
  }), 0)] : [_c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover",
    attrs: {
      "textNoRecord": " ",
      "id": "users-table",
      "isListEmpty": _vm.isEmpty(_vm.searchedUsers),
      "listLength": _vm.searchedUsers.length,
      "perPage": 5,
      "conditions": {
        loading: {
          show: false
        },
        pagination: {
          show: false
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return _vm._l(_vm.searchedUsers, function (data) {
          return _c('tr', {
            key: data.id || data.voipaccount,
            staticClass: "dialer-row-select"
          }, [_c('td', {
            staticClass: "dialer-row-title one w-100"
          }, [_c('div', {
            staticClass: "w-100 d-flex align-items-center justify-content-between"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [data.voipaccount ? _c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "is_suspended_show": "",
              "id": data.voipaccount,
              "is_blf": false,
              "username": _vm._f("usernameDisplay")(data, _vm.$store.getters.getCurrentUser.email)
            }
          }) : _vm._e(), data.object == 'UserInvitation' ? [data.object == 'UserInvitation' ? _c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "name": `${data.data.firstname} ${data.data.lastname}`,
              "username": `${data.email}`
            },
            scopedSlots: _vm._u([{
              key: "sub-info",
              fn: function () {
                return [_c('span', {
                  staticClass: "verySmallText"
                }, [_vm._v(" " + _vm._s(_vm._f("invitationExpireText")(data)) + " "), _c('b-icon', {
                  staticClass: "cursor_pointer_no_hover ml-2",
                  attrs: {
                    "icon": "arrow-clockwise",
                    "variant": "dark",
                    "animation": _vm.api.retry_invitation.send.includes(data.id) ? 'spin' : ''
                  },
                  on: {
                    "click": function ($event) {
                      return _vm.retryInvitation(data);
                    }
                  }
                }), _c('b-icon', {
                  staticClass: "cursor_pointer_no_hover ml-2",
                  attrs: {
                    "icon": "trash-fill",
                    "variant": "danger",
                    "animation": _vm.api.cancel_invitation.send.includes(data.id) ? 'spin' : ''
                  },
                  on: {
                    "click": function ($event) {
                      return _vm.cancelInvitation(data);
                    }
                  }
                })], 1)];
              },
              proxy: true
            }], null, true)
          }) : _vm._e()] : _vm._e(), data.queue_service ? _c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "id": data.id
            }
          }) : _vm._e(), data.voipaccount ? [data.user.user_type == 'main' ? _c('span', {
            staticClass: "OwnertaggedVersion mb-0 ml-16px"
          }, [_vm._v("Owner")]) : data.user.userRole != 0 ? _c('span', {
            staticClass: "AdmintaggedVersion mb-0 ml-16px"
          }, [_vm._v("Admin")]) : _vm._e()] : _vm._e()], 2), _c('div', {
            staticClass: "d-flex align-items-center"
          }, _vm._l(data.devices, function (device, accountcode) {
            return _c('vb-icon', {
              key: accountcode,
              class: `mr-12px statusWithRespectToDevice`,
              attrs: {
                "icon": _vm._f("accountType")(device.type),
                "width": "20.219px",
                "height": "24.997px"
              }
            });
          }), 1)])]), _c('td', {
            staticClass: "dialer-col-right four"
          }, [data.voipaccount && _vm.$store.getters.getCurrentUser.account != data.voipaccount ? _c('div', {
            staticClass: "d-flex justify-content-end settingsGridView-bottomIcons-container showOnly-OnHover-insideTable"
          }, [data.state == 'Ringing' ? _c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: `Pick call`,
              expression: "`Pick call`",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            staticClass: "mr-3 cursor_pointer_no_hover",
            on: {
              "click": function ($event) {
                !_vm.getUserPermissions.blf ? _vm.appNotify.open({
                  message: 'Your package does not support this feature',
                  type: 'danger'
                }) : _vm.dialinringing(data);
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": "newScheduling-call-icon",
              "height": "35px",
              "width": "35px"
            }
          })], 1) : _c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: `Call`,
              expression: "`Call`",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            staticClass: "mr-3 cursor_pointer_no_hover",
            on: {
              "click": function ($event) {
                return _vm.dial(data);
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": "newScheduling-call-icon",
              "height": "35px",
              "width": "35px"
            }
          })], 1), _c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip"
            }],
            staticClass: "mr-3 cursor_pointer_no_hover",
            class: ['', !_vm.getUserPermissions.chat ? 'for-disable' : ''],
            attrs: {
              "id": `RightSideUsersChatOption-${data.voipaccount}`,
              "title": _vm.getUserPermissions.chat ? '' : 'Your package does not support this feature'
            }
          }, [_c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: `Chat`,
              expression: "`Chat`",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            class: !_vm.getUserPermissions.chat ? 'for-disable-disable' : '',
            on: {
              "click": function ($event) {
                !_vm.getUserPermissions.chat ? '' : _vm.chatUser(data);
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": "newScheduling-chat-icon",
              "height": "35px",
              "width": "35px"
            }
          })], 1)]), _c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: `Video call`,
              expression: "`Video call`",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            staticClass: "mr-3 cursor_pointer_no_hover",
            on: {
              "click": function ($event) {
                return _vm.videoCall(data);
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": "newScheduling-video-icon",
              "height": "35px",
              "width": "35px"
            }
          })], 1), _c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: `${data.is_favourites ? 'Remove from favourite' : 'Add to favourite'}`,
              expression: "`${data.is_favourites ? 'Remove from favourite' : 'Add to favourite'}`",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            staticClass: "cursor_pointer_no_hover",
            on: {
              "click": function ($event) {
                return _vm.toggleUserFavourite(data);
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": data.is_favourites ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon',
              "height": "35px",
              "width": "35px"
            }
          })], 1)]) : _vm._e()])]);
        });
      },
      proxy: true
    }], null, false, 220246551)
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Name")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-right"
  })])])], 2)]] : _vm.selected.tab == 'teams' ? [_vm.gridView ? [_c('div', {
    staticClass: "settingsGridView-container mt-32px"
  }, [_vm._l(_vm.filterTeams, function (team) {
    return _c('div', {
      key: team.id,
      staticClass: "settingsGridView-item"
    }, [_c('vb-avatar', {
      attrs: {
        "id": team.real_id,
        "is_blf": false
      }
    }), _c('div', {
      staticClass: "settingsGridView-display_name"
    }, [_vm._v(_vm._s(team.name))]), _c('div', {
      staticClass: "d-flex settingsGridView-bottomIcons-container mt-20px"
    }, [_c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `Call`,
        expression: "`Call`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "mr-3 cursor_pointer_no_hover",
      on: {
        "click": function ($event) {
          return _vm.dialTeam(team);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": "newScheduling-call-icon",
        "height": "55px",
        "width": "55px"
      }
    })], 1), team.members.findIndex(function (mem) {
      return mem.accountcode == _vm.getCurrentUser.account;
    }) > -1 ? _c('div', {
      staticClass: "mr-3 cursor_pointer_no_hover",
      on: {
        "click": function ($event) {
          return _vm.chatTeam(team);
        }
      }
    }, [_c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `Chat`,
        expression: "`Chat`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      on: {
        "click": function ($event) {
          return _vm.chatTeam(team);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": "newScheduling-chat-icon",
        "height": "55px",
        "width": "55px"
      }
    })], 1)]) : _vm._e(), _c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `${team.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`,
        expression: "`${team.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "cursor_pointer_no_hover",
      on: {
        "click": function ($event) {
          return _vm.toggleFavourite(team);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": team.is_favourite ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon',
        "height": "55px",
        "width": "55px"
      }
    })], 1)]), _c('div', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: 'accordion-' + team.id,
        expression: "'accordion-' + team.id"
      }],
      staticClass: "d-flex align-items-center justify-content-between w-100"
    }, [_c('div', {
      staticClass: "settingsGridView-email mt-2 mb-0"
    }, [_vm._v(" " + _vm._s(team.members.length) + " members ")]), _c('b-icon', {
      attrs: {
        "icon": "chevron-down"
      }
    })], 1), _c('b-collapse', {
      staticClass: "settingsGridView-collapseable-container",
      attrs: {
        "id": 'accordion-' + team.id,
        "accordion": "my-accordion"
      }
    }, [!_vm.isEmpty(team.members) ? _c('div', _vm._l(team.members, function (member, key) {
      return _c('div', {
        key: key,
        staticClass: "settingsGridView-collapseable-row"
      }, [_c('div', {
        staticClass: "settingsGridView-collapseable-row-leftSide"
      }, [_c('Info', {
        attrs: {
          "id": member.accountcode,
          "is_blf": false
        }
      })], 1), _c('div', {
        staticClass: "settingsGridView-collapseable-row-rightSide"
      }, [_c('span', {
        staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute"
      }, [_vm.getCurrentUser.account != member.accountcode ? _c('b-dropdown', {
        staticClass: "ico-filter",
        attrs: {
          "varient": "link",
          "no-caret": "",
          "right": ""
        },
        scopedSlots: _vm._u([{
          key: "button-content",
          fn: function () {
            return [_c('b-icon', {
              attrs: {
                "icon": "three-dots-vertical",
                "scale": "1.5"
              }
            })];
          },
          proxy: true
        }], null, true)
      }, [_c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.dial(member);
          }
        }
      }, [_c('vb-svg', {
        staticClass: "mr-2",
        attrs: {
          "name": "dialer-outlineCall-icon2",
          "width": "16.501",
          "height": "16.501",
          "viewBox": "0 0 16.501 16.501",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', [_vm._v("Call")])], 1), _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.chatUser(member);
          }
        }
      }, [_c('vb-svg', {
        staticClass: "mr-2",
        attrs: {
          "name": "dialer-outlineChat-icon",
          "width": "16.501",
          "height": "16.501",
          "viewBox": "0 0 16.501 16.501",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', [_vm._v("Chat")])], 1), _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.videoCall(member);
          }
        }
      }, [_c('vb-svg', {
        staticClass: "mr-2",
        attrs: {
          "name": "dialer-outlineVideo-icon",
          "width": "20.6",
          "height": "13.6",
          "viewBox": "0 0 20.6 13.6",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', [_vm._v("Video")])], 1)], 1) : _vm._e()], 1)])]);
    }), 0) : _vm._e(), _vm.isEmpty(team.members) ? _c('vb-no-record', {
      attrs: {
        "text": 'No members in this Group',
        "design": 3
      }
    }) : _vm._e()], 1)], 1);
  }), _vm.isEmpty(_vm.filterTeams) ? _c('vb-no-record', {
    attrs: {
      "text": 'No teams available',
      "design": 3
    }
  }) : _vm._e()], 2)] : [_c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover",
    attrs: {
      "textNoRecord": " ",
      "id": "teams-table",
      "isListEmpty": _vm.isEmpty(_vm.filterTeams),
      "listLength": _vm.filterTeams.length,
      "perPage": 5,
      "noRecordWholePage": true
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return [_vm._l(_vm.filterTeams, function (team, index) {
          return [_c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: team.id,
            class: `dialer-row-select ${_vm.conditions.more == team.id ? 'makeTDverticalAlignTop' : ''}`
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [team.queue_service ? _c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "id": team.id,
              "is_blf": false
            }
          }) : _c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "id": team.real_id,
              "is_blf": false,
              "is_suspended_show": ""
            }
          })], 1), _c('td', {
            staticClass: "dialer-row-title three"
          }, [!team.queue_service ? _c('div', {
            staticClass: "d-flex"
          }, [_c('a', {
            staticClass: "dialer-link-more text-decoration-underline",
            on: {
              "click": function ($event) {
                _vm.membersModalData.type = 'team';
                _vm.membersModalData.items = team.members;
                _vm.$modal.show('ShowMembersOnlyModal');
              }
            }
          }, [_vm._v(" " + _vm._s(team.members.length) + " users ")])]) : _vm._e()]), _c('td', {
            staticClass: "dialer-col-right four"
          }, [_c('div', {
            staticClass: "d-flex justify-content-end settingsGridView-bottomIcons-container showOnly-OnHover-insideTable"
          }, [_c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: `Call`,
              expression: "`Call`",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            staticClass: "mr-3 cursor_pointer_no_hover",
            on: {
              "click": function ($event) {
                return _vm.dialTeam(team);
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": "newScheduling-call-icon",
              "height": "35px",
              "width": "35px"
            }
          })], 1), team.members.findIndex(function (mem) {
            return mem.accountcode == _vm.getCurrentUser.account;
          }) > -1 ? _c('div', {
            staticClass: "mr-3 cursor_pointer_no_hover",
            on: {
              "click": function ($event) {
                return _vm.chatTeam(team);
              }
            }
          }, [_c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: `Chat`,
              expression: "`Chat`",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            on: {
              "click": function ($event) {
                return _vm.chatTeam(team);
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": "newScheduling-chat-icon",
              "height": "35px",
              "width": "35px"
            }
          })], 1)]) : _vm._e(), _c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: `${team.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`,
              expression: "`${team.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            staticClass: "cursor_pointer_no_hover",
            on: {
              "click": function ($event) {
                return _vm.toggleFavourite(team);
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": team.is_favourite ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon',
              "height": "35px",
              "width": "35px"
            }
          })], 1)])])])];
        })];
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "35px",
              "width": "35px"
            }
          }), _c('div', {
            staticClass: "d-flex flex-column justify-content-between"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "16px",
              "width": "60px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign mt-1",
            staticStyle: {
              "height": "16px",
              "width": "30px"
            }
          })])])]), _c('td', [_c('div', {
            staticClass: "d-flex"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "20px",
              "min-width": "40px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "48px",
              "width": "48px"
            }
          })])]);
        });
      },
      proxy: true
    }, {
      key: "no-record",
      fn: function () {
        return [_c('div', {
          staticClass: "latestGreyBox-9-9-2023 mb-32px"
        }, [_c('div', {
          staticClass: "emptyScreenContainer"
        }, [_vm.GET_LOCAL_SETTING_IS_DARK_MODE ? _c('img', {
          staticClass: "mt-50px",
          attrs: {
            "width": "365px",
            "height": "128px",
            "src": require('@/assets/images/emptyScreenImages/darkMode/teams.png')
          }
        }) : _c('img', {
          staticClass: "mt-50px",
          attrs: {
            "width": "365px",
            "height": "128px",
            "src": require('@/assets/images/emptyScreenImages/teams.png')
          }
        }), _c('div', {
          staticClass: "emptyScreenContainer-heading mt-50px"
        }, [_vm._v(" Create your first team ")]), _c('div', {
          staticClass: "emptyScreenContainer-text w-75"
        }, [_vm._v(" Teams help you organize and collaborate efficiently. Create your first team to get started. ")]), _c('button', {
          staticClass: "dialer-button dialer-button-primary mt-20px",
          on: {
            "click": function ($event) {
              return _vm.$modal.show('AddTeamModal', {
                suggested_name: `team ${_vm.voip_teams.length + 1}`
              });
            }
          }
        }, [_c('b-icon', {
          staticClass: "mr-3",
          attrs: {
            "icon": "plus-lg"
          }
        }), _vm._v(" Create team ")], 1)])])];
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Name")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Members")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-right"
  })])])], 2)]] : _vm.selected.tab == 'favourites' ? [_vm.gridView ? [_c('div', {
    staticClass: "settingsGridView-container mt-20px"
  }, [_vm._l(_vm.favouriteTeams, function (team) {
    return _c('div', {
      key: team.id,
      staticClass: "settingsGridView-item"
    }, [_c('vb-avatar', {
      attrs: {
        "image": team.avatar.image.image
      }
    }), _c('div', {
      staticClass: "settingsGridView-display_name"
    }, [_vm._v(_vm._s(team.name))]), _c('div', {
      staticClass: "d-flex settingsGridView-bottomIcons-container mt-20px"
    }, [_c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `Call`,
        expression: "`Call`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "mr-3 cursor_pointer_no_hover",
      on: {
        "click": function ($event) {
          return _vm.dialTeam(team);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": "newScheduling-call-icon",
        "height": "55px",
        "width": "55px"
      }
    })], 1), team.members.findIndex(function (mem) {
      return mem.accountcode == _vm.getCurrentUser.account;
    }) > -1 ? _c('div', {
      staticClass: "mr-3 cursor_pointer_no_hover",
      on: {
        "click": function ($event) {
          return _vm.chatTeam(team);
        }
      }
    }, [_c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `Chat`,
        expression: "`Chat`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      on: {
        "click": function ($event) {
          return _vm.chatTeam(team);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": "newScheduling-chat-icon",
        "height": "55px",
        "width": "55px"
      }
    })], 1)]) : _vm._e(), _c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `${team.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`,
        expression: "`${team.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "cursor_pointer_no_hover",
      on: {
        "click": function ($event) {
          return _vm.toggleFavourite(team);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": team.is_favourites ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon',
        "height": "55px",
        "width": "55px"
      }
    })], 1)]), _c('div', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: 'accordionF-' + team.id,
        expression: "'accordionF-' + team.id"
      }],
      staticClass: "d-flex align-items-center justify-content-between w-100"
    }, [_c('div', {
      staticClass: "settingsGridView-email mt-2 mb-0"
    }, [_vm._v(" " + _vm._s(team.members.length) + " members ")]), _c('b-icon', {
      attrs: {
        "icon": "chevron-down"
      }
    })], 1), _c('b-collapse', {
      staticClass: "settingsGridView-collapseable-container",
      attrs: {
        "id": 'accordionF-' + team.id,
        "accordion": "my-accordion"
      }
    }, [!_vm.isEmpty(team.members) ? _c('div', _vm._l(team.members, function (member, key) {
      return _c('div', {
        key: key,
        staticClass: "settingsGridView-collapseable-row"
      }, [_c('div', {
        staticClass: "settingsGridView-collapseable-row-leftSide"
      }, [_c('Info', {
        attrs: {
          "id": member.accountcode,
          "is_blf": false
        }
      })], 1), _c('div', {
        staticClass: "settingsGridView-collapseable-row-rightSide"
      }, [_c('span', {
        staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute"
      }, [_vm.getCurrentUser.account != member.accountcode ? _c('b-dropdown', {
        staticClass: "ico-filter",
        attrs: {
          "varient": "link",
          "no-caret": "",
          "right": ""
        },
        scopedSlots: _vm._u([{
          key: "button-content",
          fn: function () {
            return [_c('b-icon', {
              attrs: {
                "icon": "three-dots-vertical",
                "scale": "1.5"
              }
            })];
          },
          proxy: true
        }], null, true)
      }, [_c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.dial(member);
          }
        }
      }, [_c('vb-svg', {
        staticClass: "mr-2",
        attrs: {
          "name": "dialer-outlineCall-icon2",
          "width": "16.501",
          "height": "16.501",
          "viewBox": "0 0 16.501 16.501",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', [_vm._v("Call")])], 1), _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.chatUser(member);
          }
        }
      }, [_c('vb-svg', {
        staticClass: "mr-2",
        attrs: {
          "name": "dialer-outlineChat-icon",
          "width": "16.501",
          "height": "16.501",
          "viewBox": "0 0 16.501 16.501",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', [_vm._v("Chat")])], 1), _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.videoCall(member);
          }
        }
      }, [_c('vb-svg', {
        staticClass: "mr-2",
        attrs: {
          "name": "dialer-outlineVideo-icon",
          "width": "20.6",
          "height": "13.6",
          "viewBox": "0 0 20.6 13.6",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', [_vm._v("Video")])], 1)], 1) : _vm._e()], 1)])]);
    }), 0) : _vm._e(), _vm.isEmpty(team.members) ? _c('vb-no-record', {
      attrs: {
        "text": 'No members in this Group',
        "design": 3
      }
    }) : _vm._e()], 1)], 1);
  }), _vm._l(_vm.favouriteUsers, function (data) {
    return _c('div', {
      key: data.voipaccount,
      staticClass: "settingsGridView-item"
    }, [data.voipaccount ? _c('vb-avatar', {
      attrs: {
        "account": data.voipaccount,
        "image": data.profile_img,
        "name": _vm._f("get_property")(data, 'user.display_name'),
        "status_id": _vm._f("get_property")(data, 'status')
      }
    }) : _vm._e(), data.voipaccount ? _c('div', {
      staticClass: "settingsGridView-display_name"
    }, [_vm._v(_vm._s(_vm._f("get_property")(data, 'user.display_name')))]) : _vm._e(), data.object == 'UserInvitation' ? _c('div', {
      staticClass: "settingsGridView-display_name"
    }, [_vm._v(" " + _vm._s(_vm._f("get_property")(data, 'data.firstname')) + " " + _vm._s(_vm._f("get_property")(data, 'data.lastname')) + " ")]) : _vm._e(), _c('div', {
      staticClass: "settingsGridView-email"
    }, [_vm._v(" Job title ")]), data.voipaccount ? _c('div', {
      staticClass: "d-flex settingsGridView-bottomIcons-container mt-20px"
    }, [data.state == 'Ringing' ? _c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `Pick call`,
        expression: "`Pick call`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "mr-3 cursor_pointer_no_hover",
      on: {
        "click": function ($event) {
          !_vm.getUserPermissions.blf ? _vm.appNotify.open({
            message: 'Your package does not support this feature',
            type: 'danger'
          }) : _vm.dialinringing(data);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "telephone-fill"
      }
    })], 1) : _c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `Call`,
        expression: "`Call`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "mr-3 cursor_pointer_no_hover",
      on: {
        "click": function ($event) {
          return _vm.dial(data);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": "newScheduling-call-icon",
        "height": "55px",
        "width": "55px"
      }
    })], 1), _c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip"
      }],
      staticClass: "mr-3 cursor_pointer_no_hover",
      class: ['', !_vm.getUserPermissions.chat ? 'for-disable' : ''],
      attrs: {
        "id": `RightSideUsersChatOption-${data.voipaccount}`,
        "title": _vm.getUserPermissions.chat ? '' : 'Your package does not support this feature'
      }
    }, [_c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `Chat`,
        expression: "`Chat`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      class: !_vm.getUserPermissions.chat ? 'for-disable-disable' : '',
      on: {
        "click": function ($event) {
          !_vm.getUserPermissions.chat ? '' : _vm.chatUser(data);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": "newScheduling-chat-icon",
        "height": "55px",
        "width": "55px"
      }
    })], 1)]), _c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `Video call`,
        expression: "`Video call`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "mr-3 cursor_pointer_no_hover",
      on: {
        "click": function ($event) {
          return _vm.videoCall(data);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": "newScheduling-video-icon",
        "height": "55px",
        "width": "55px"
      }
    })], 1), _c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `${data.is_favourites ? 'Remove from favourite' : 'Add to favourite'}`,
        expression: "`${data.is_favourites ? 'Remove from favourite' : 'Add to favourite'}`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "cursor_pointer_no_hover",
      on: {
        "click": function ($event) {
          return _vm.toggleUserFavourite(data);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": data.is_favourites ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon',
        "height": "55px",
        "width": "55px"
      }
    })], 1)]) : _vm._e()], 1);
  }), _vm._l(_vm.favouriteCallQueue, function (call_queue) {
    return _c('div', {
      key: call_queue.voipaccount,
      staticClass: "settingsGridView-item"
    }, [_c('vb-avatar', {
      attrs: {
        "account": call_queue.voipaccount,
        "image": call_queue.avatar.image.image,
        "name": _vm._f("get_property")(call_queue, 'user.display_name'),
        "status_id": _vm._f("get_property")(call_queue, 'status')
      }
    }), _c('div', {
      staticClass: "settingsGridView-display_name"
    }, [_vm._v(_vm._s(call_queue.label))]), _c('div', {
      staticClass: "d-flex settingsGridView-bottomIcons-container mt-20px"
    }, [_c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `Call`,
        expression: "`Call`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "mr-3 cursor_pointer_no_hover",
      on: {
        "click": function ($event) {
          return _vm.dialCallQueue(call_queue);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": "newScheduling-call-icon",
        "height": "55px",
        "width": "55px"
      }
    })], 1), _vm.getProperty(call_queue, `call_queue_status.${_vm.getCurrentUser.account}.adminforce`, 'no') == 'no' ? [_c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `${call_queue.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`,
        expression: "`${call_queue.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "cursor_pointer_no_hover",
      on: {
        "click": function ($event) {
          return _vm.toggleCallQueueFavourite(call_queue);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": call_queue.is_favourite ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon',
        "height": "55px",
        "width": "55px"
      }
    })], 1)] : _vm._e()], 2), _c('div', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: 'accordion-' + call_queue.voipaccount,
        expression: "'accordion-' + call_queue.voipaccount"
      }],
      staticClass: "d-flex align-items-center justify-content-between w-100"
    }, [_c('div', {
      staticClass: "settingsGridView-email mt-2 mb-0"
    }, [_vm._v(" " + _vm._s(call_queue.members.length) + " members ")]), _c('b-icon', {
      attrs: {
        "icon": "chevron-down"
      }
    })], 1), _c('b-collapse', {
      staticClass: "settingsGridView-collapseable-container",
      attrs: {
        "id": 'accordion-' + call_queue.voipaccount,
        "accordion": "my-accordion"
      }
    }, [!_vm.isEmpty(call_queue.members) ? _c('div', _vm._l(call_queue.members, function (member) {
      return _c('div', {
        key: member.membername,
        staticClass: "settingsGridView-collapseable-row"
      }, [_c('div', {
        staticClass: "settingsGridView-collapseable-row-leftSide"
      }, [_c('Info', {
        attrs: {
          "id": member.membername,
          "is_blf": false
        }
      })], 1), _c('div', {
        staticClass: "settingsGridView-collapseable-row-rightSide"
      }, [_c('span', {
        staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute"
      }, [_vm.getCurrentUser.account != member.membername ? _c('b-dropdown', {
        staticClass: "ico-filter",
        attrs: {
          "varient": "link",
          "no-caret": "",
          "right": ""
        },
        scopedSlots: _vm._u([{
          key: "button-content",
          fn: function () {
            return [_c('b-icon', {
              attrs: {
                "icon": "three-dots-vertical",
                "scale": "1.5"
              }
            })];
          },
          proxy: true
        }], null, true)
      }, [_c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.dial(member);
          }
        }
      }, [_c('vb-svg', {
        staticClass: "mr-2",
        attrs: {
          "name": "dialer-outlineCall-icon2",
          "width": "16.501",
          "height": "16.501",
          "viewBox": "0 0 16.501 16.501",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', [_vm._v("Call")])], 1), _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.chatUser(member);
          }
        }
      }, [_c('vb-svg', {
        staticClass: "mr-2",
        attrs: {
          "name": "dialer-outlineChat-icon",
          "width": "16.501",
          "height": "16.501",
          "viewBox": "0 0 16.501 16.501",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', [_vm._v("Chat")])], 1), _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.videoCall(member);
          }
        }
      }, [_c('vb-svg', {
        staticClass: "mr-2",
        attrs: {
          "name": "dialer-outlineVideo-icon",
          "width": "20.6",
          "height": "13.6",
          "viewBox": "0 0 20.6 13.6",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', [_vm._v("Video")])], 1)], 1) : _vm._e()], 1)])]);
    }), 0) : _vm._e(), _vm.isEmpty(call_queue.members) ? _c('vb-no-record', {
      attrs: {
        "text": 'No members in this Group',
        "design": 3
      }
    }) : _vm._e()], 1)], 1);
  }), _vm._l(_vm.favouriteAddressBook, function (addressbook) {
    return _c('div', {
      key: addressbook.real_id,
      staticClass: "settingsGridView-item"
    }, [_c('vb-avatar', {
      attrs: {
        "image": _vm._f("get_property")(addressbook, 'profile_image')
      }
    }), _c('div', {
      staticClass: "settingsGridView-display_name"
    }, [_vm._v(_vm._s(`${addressbook.firstName} ${addressbook.lastName || ''}`))]), _c('div', {
      staticClass: "settingsGridView-email"
    }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm._f("get_property")(addressbook, 'main_phone.number'))))]), _c('div', {
      staticClass: "d-flex settingsGridView-bottomIcons-container mt-20px"
    }, [_c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `Call`,
        expression: "`Call`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "mr-3 cursor_pointer_no_hover",
      on: {
        "click": function ($event) {
          return _vm.audioCall(addressbook);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": "newScheduling-call-icon",
        "height": "55px",
        "width": "55px"
      }
    })], 1), _c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `${addressbook.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`,
        expression: "`${addressbook.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "cursor_pointer_no_hover",
      attrs: {
        "disabled": _vm.api.toggle_favourite.send.includes(addressbook.real_id)
      },
      on: {
        "click": function ($event) {
          return _vm.toggleFavouriteAddressbook(addressbook);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": addressbook.is_favourite ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon',
        "height": "55px",
        "width": "55px"
      }
    })], 1)])], 1);
  }), _vm.isEmpty(_vm.favouriteTeams) && _vm.isEmpty(_vm.favouriteUsers) && _vm.isEmpty(_vm.favouriteCallQueue) && _vm.isEmpty(_vm.favouriteAddressBook) ? _c('vb-no-record', {
    attrs: {
      "text": 'Nothing is set as favourite',
      "design": 3
    }
  }) : _vm._e()], 2)] : [_c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover",
    attrs: {
      "isListEmpty": _vm.favouriteTeams.length == 0 && _vm.favouriteUsers.length == 0 && _vm.favouriteCallQueue.length == 0 && _vm.favouriteAddressBook.length == 0,
      "listLength": _vm.favouriteTeams.length + _vm.favouriteUsers.length + _vm.favouriteCallQueue.length + _vm.favouriteAddressBook.length
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref2) {
        var start = _ref2.start,
          end = _ref2.end;
        return [_vm._l(_vm.favouriteTeams, function (team, index) {
          return [_c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: team.id,
            class: `dialer-row-select ${_vm.conditions.more == team.id ? 'makeTDverticalAlignTop' : ''}`
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [team.queue_service ? _c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "id": team.id,
              "is_blf": false
            }
          }) : _c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "id": team.real_id,
              "is_blf": false,
              "is_suspended_show": ""
            }
          })], 1), _c('td', {
            staticClass: "dialer-row-title three"
          }, [!team.queue_service ? _c('div', {
            staticClass: "d-flex"
          }, [_c('a', {
            staticClass: "dialer-link-more text-decoration-underline",
            on: {
              "click": function ($event) {
                _vm.membersModalData.type = 'team';
                _vm.membersModalData.items = team.members;
                _vm.$modal.show('ShowMembersOnlyModal');
              }
            }
          }, [_vm._v(" " + _vm._s(team.members.length) + " users ")])]) : _vm._e()]), _c('td', {
            staticClass: "dialer-col-right four"
          }, [_c('div', {
            staticClass: "d-flex justify-content-end settingsGridView-bottomIcons-container showOnly-OnHover-insideTable"
          }, [_c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: `Call`,
              expression: "`Call`",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            staticClass: "mr-3 cursor_pointer_no_hover",
            on: {
              "click": function ($event) {
                return _vm.dialTeam(team);
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": "newScheduling-call-icon",
              "height": "35px",
              "width": "35px"
            }
          })], 1), team.members.findIndex(function (mem) {
            return mem.accountcode == _vm.getCurrentUser.account;
          }) > -1 ? _c('div', {
            staticClass: "mr-3 cursor_pointer_no_hover",
            on: {
              "click": function ($event) {
                return _vm.chatTeam(team);
              }
            }
          }, [_c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: `Chat`,
              expression: "`Chat`",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            on: {
              "click": function ($event) {
                return _vm.chatTeam(team);
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": "newScheduling-chat-icon",
              "height": "35px",
              "width": "35px"
            }
          })], 1)]) : _vm._e(), _c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: `${team.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`,
              expression: "`${team.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            staticClass: "cursor_pointer_no_hover",
            on: {
              "click": function ($event) {
                return _vm.toggleFavourite(team);
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": team.is_favourite ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon',
              "height": "35px",
              "width": "35px"
            }
          })], 1)])])])];
        }), _vm._l(_vm.favouriteUsers, function (data) {
          return _c('tr', {
            key: data.id || data.voipaccount,
            staticClass: "dialer-row-select"
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [data.voipaccount ? _c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "is_suspended_show": "",
              "id": data.voipaccount,
              "is_blf": false,
              "username": _vm._f("usernameDisplay")(data, _vm.$store.getters.getCurrentUser.email)
            }
          }) : _vm._e(), data.object == 'UserInvitation' ? [data.object == 'UserInvitation' ? _c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "name": `${data.data.firstname} ${data.data.lastname}`,
              "username": `${data.email}`
            },
            scopedSlots: _vm._u([{
              key: "sub-info",
              fn: function () {
                return [_c('span', {
                  staticClass: "verySmallText"
                }, [_vm._v(" " + _vm._s(_vm._f("invitationExpireText")(data)) + " "), _c('b-icon', {
                  staticClass: "cursor_pointer_no_hover ml-2",
                  attrs: {
                    "icon": "arrow-clockwise",
                    "variant": "dark",
                    "animation": _vm.api.retry_invitation.send.includes(data.id) ? 'spin' : ''
                  },
                  on: {
                    "click": function ($event) {
                      return _vm.retryInvitation(data);
                    }
                  }
                }), _c('b-icon', {
                  staticClass: "cursor_pointer_no_hover ml-2",
                  attrs: {
                    "icon": "trash-fill",
                    "variant": "danger",
                    "animation": _vm.api.cancel_invitation.send.includes(data.id) ? 'spin' : ''
                  },
                  on: {
                    "click": function ($event) {
                      return _vm.cancelInvitation(data);
                    }
                  }
                })], 1)];
              },
              proxy: true
            }], null, true)
          }) : _vm._e()] : _vm._e(), data.queue_service ? _c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "id": data.id
            }
          }) : _vm._e(), data.voipaccount ? [data.user.user_type == 'main' ? _c('span', {
            staticClass: "OwnertaggedVersion mb-0 ml-16px"
          }, [_vm._v("Owner")]) : data.user.userRole != 0 ? _c('span', {
            staticClass: "AdmintaggedVersion mb-0 ml-16px"
          }, [_vm._v("Admin")]) : _vm._e()] : _vm._e()], 2)]), _c('td', {
            staticClass: "dialer-row-title three"
          }), _c('td', {
            staticClass: "dialer-col-right four"
          }, [data.voipaccount && _vm.$store.getters.getCurrentUser.account != data.voipaccount ? _c('div', {
            staticClass: "d-flex justify-content-end settingsGridView-bottomIcons-container showOnly-OnHover-insideTable"
          }, [data.state == 'Ringing' ? _c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: `Pick call`,
              expression: "`Pick call`",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            staticClass: "mr-3 cursor_pointer_no_hover",
            on: {
              "click": function ($event) {
                !_vm.getUserPermissions.blf ? _vm.appNotify.open({
                  message: 'Your package does not support this feature',
                  type: 'danger'
                }) : _vm.dialinringing(data);
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": "newScheduling-call-icon",
              "height": "35px",
              "width": "35px"
            }
          })], 1) : _c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: `Call`,
              expression: "`Call`",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            staticClass: "mr-3 cursor_pointer_no_hover",
            on: {
              "click": function ($event) {
                return _vm.dial(data);
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": "newScheduling-call-icon",
              "height": "35px",
              "width": "35px"
            }
          })], 1), _c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip"
            }],
            staticClass: "mr-3 cursor_pointer_no_hover",
            class: ['', !_vm.getUserPermissions.chat ? 'for-disable' : ''],
            attrs: {
              "id": `RightSideUsersChatOption-${data.voipaccount}`,
              "title": _vm.getUserPermissions.chat ? '' : 'Your package does not support this feature'
            }
          }, [_c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: `Chat`,
              expression: "`Chat`",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            class: !_vm.getUserPermissions.chat ? 'for-disable-disable' : '',
            on: {
              "click": function ($event) {
                !_vm.getUserPermissions.chat ? '' : _vm.chatUser(data);
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": "newScheduling-chat-icon",
              "height": "35px",
              "width": "35px"
            }
          })], 1)]), _c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: `Video call`,
              expression: "`Video call`",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            staticClass: "mr-3 cursor_pointer_no_hover",
            on: {
              "click": function ($event) {
                return _vm.videoCall(data);
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": "newScheduling-video-icon",
              "height": "35px",
              "width": "35px"
            }
          })], 1), _c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: `${data.is_favourites ? 'Remove from favourite' : 'Add to favourite'}`,
              expression: "`${data.is_favourites ? 'Remove from favourite' : 'Add to favourite'}`",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            staticClass: "cursor_pointer_no_hover",
            on: {
              "click": function ($event) {
                return _vm.toggleUserFavourite(data);
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": data.is_favourites ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon',
              "height": "35px",
              "width": "35px"
            }
          })], 1)]) : _vm._e()])]);
        }), _vm._l(_vm.favouriteCallQueue, function (callQueue, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: callQueue.voipaccount || callQueue.id
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "is_suspended_show": !!callQueue.voipaccount,
              "id": callQueue.voipaccount || callQueue.id
            }
          })], 1), _c('td', {
            staticClass: "dialer-row-title three"
          }, [!callQueue.queue_service ? _c('div', {
            staticClass: "d-flex"
          }, [_c('a', {
            staticClass: "dialer-link-more text-decoration-underline",
            on: {
              "click": function ($event) {
                _vm.membersModalData.type = 'queue';
                _vm.membersModalData.items = callQueue.members;
                _vm.$modal.show('ShowMembersOnlyModal');
              }
            }
          }, [_vm._v(" " + _vm._s(_vm._f("get_property")(callQueue, 'members.length', 0)) + " users ")])]) : _vm._e()]), _c('td', {
            staticClass: "dialer-row-title dialer-col-right three"
          }, [_c('div', {
            staticClass: "d-flex justify-content-end settingsGridView-bottomIcons-container showOnly-OnHover-insideTable"
          }, [_c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: `Call`,
              expression: "`Call`",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            staticClass: "mr-3 cursor_pointer_no_hover",
            on: {
              "click": function ($event) {
                return _vm.dialCallQueue(callQueue);
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": "newScheduling-call-icon",
              "height": "35px",
              "width": "35px"
            }
          })], 1), _vm.getProperty(callQueue, `call_queue_status.${_vm.getCurrentUser.account}.adminforce`, 'no') == 'no' ? [_c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: `${callQueue.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`,
              expression: "`${callQueue.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            staticClass: "cursor_pointer_no_hover",
            on: {
              "click": function ($event) {
                return _vm.toggleCallQueueFavourite(callQueue);
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": callQueue.is_favourite ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon',
              "height": "35px",
              "width": "35px"
            }
          })], 1)] : _vm._e()], 2)])]);
        }), _vm._l(_vm.favouriteAddressBook, function (addressbook, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: addressbook.real_id
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('vb-avatar', {
            attrs: {
              "image": _vm._f("get_property")(addressbook, 'profile_image')
            }
          }), _c('div', {
            staticClass: "ml-3"
          }, [_c('div', {
            staticClass: "settingsGridView-display_name"
          }, [_vm._v(_vm._s(`${addressbook.firstName} ${addressbook.lastName || ''}`))]), _c('div', {
            staticClass: "settingsGridView-email"
          }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm._f("get_property")(addressbook, 'main_phone.number'))))])])], 1)]), _c('td', {
            staticClass: "dialer-row-title three"
          }), _c('td', {
            staticClass: "dialer-row-title dialer-col-right four"
          }, [_c('div', {
            staticClass: "d-flex justify-content-end settingsGridView-bottomIcons-container showOnly-OnHover-insideTable"
          }, [_c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: `Call`,
              expression: "`Call`",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            staticClass: "mr-3 cursor_pointer_no_hover",
            on: {
              "click": function ($event) {
                return _vm.audioCall(addressbook);
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": "newScheduling-call-icon",
              "height": "35px",
              "width": "35px"
            }
          })], 1), _c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: `${addressbook.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`,
              expression: "`${addressbook.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            staticClass: "cursor_pointer_no_hover",
            attrs: {
              "disabled": _vm.api.toggle_favourite.send.includes(addressbook.real_id)
            },
            on: {
              "click": function ($event) {
                return _vm.toggleFavouriteAddressbook(addressbook);
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": addressbook.is_favourite ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon',
              "height": "35px",
              "width": "35px"
            }
          })], 1)])])]);
        })];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Label")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Members")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-right"
  })])])], 2)]] : _vm.selected.tab == 'queue' ? [_vm.gridView ? [_c('div', {
    staticClass: "settingsGridView-container mt-20px"
  }, [_vm._l(_vm.filterCallQueues, function (call_queue) {
    return _c('div', {
      key: call_queue.voipaccount,
      staticClass: "settingsGridView-item"
    }, [_c('vb-avatar', {
      attrs: {
        "account": call_queue.voipaccount,
        "image": call_queue.avatar.image.image,
        "name": _vm._f("get_property")(call_queue, 'user.display_name'),
        "status_id": _vm._f("get_property")(call_queue, 'status')
      }
    }), _c('div', {
      staticClass: "settingsGridView-display_name"
    }, [_vm._v(_vm._s(call_queue.label))]), _c('div', {
      staticClass: "d-flex settingsGridView-bottomIcons-container mt-20px"
    }, [_c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `Call`,
        expression: "`Call`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "mr-3 cursor_pointer_no_hover",
      on: {
        "click": function ($event) {
          return _vm.dialCallQueue(call_queue);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": "newScheduling-call-icon",
        "height": "55px",
        "width": "55px"
      }
    })], 1), _vm.getProperty(call_queue, `call_queue_status.${_vm.getCurrentUser.account}.adminforce`, 'no') == 'no' ? [_c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `${call_queue.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`,
        expression: "`${call_queue.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "cursor_pointer_no_hover",
      on: {
        "click": function ($event) {
          return _vm.toggleCallQueueFavourite(call_queue);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": call_queue.is_favourite ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon',
        "height": "55px",
        "width": "55px"
      }
    })], 1)] : _vm._e()], 2), _c('div', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: 'accordion-' + call_queue.voipaccount,
        expression: "'accordion-' + call_queue.voipaccount"
      }],
      staticClass: "d-flex align-items-center justify-content-between w-100"
    }, [_c('div', {
      staticClass: "settingsGridView-email mt-2 mb-0"
    }, [_vm._v(" " + _vm._s(call_queue.members.length) + " members ")]), _c('b-icon', {
      attrs: {
        "icon": "chevron-down"
      }
    })], 1), _c('b-collapse', {
      staticClass: "settingsGridView-collapseable-container",
      attrs: {
        "id": 'accordion-' + call_queue.voipaccount,
        "accordion": "my-accordion"
      }
    }, [!_vm.isEmpty(call_queue.members) ? _c('div', _vm._l(call_queue.members, function (member) {
      return _c('div', {
        key: member.membername,
        staticClass: "settingsGridView-collapseable-row"
      }, [_c('div', {
        staticClass: "settingsGridView-collapseable-row-leftSide"
      }, [_c('Info', {
        attrs: {
          "id": member.membername,
          "is_blf": false
        }
      })], 1), _c('div', {
        staticClass: "settingsGridView-collapseable-row-rightSide"
      }, [_c('span', {
        staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute"
      }, [_vm.getCurrentUser.account != member.membername ? _c('b-dropdown', {
        staticClass: "ico-filter",
        attrs: {
          "varient": "link",
          "no-caret": "",
          "right": ""
        },
        scopedSlots: _vm._u([{
          key: "button-content",
          fn: function () {
            return [_c('b-icon', {
              attrs: {
                "icon": "three-dots-vertical",
                "scale": "1.5"
              }
            })];
          },
          proxy: true
        }], null, true)
      }, [_c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.dial(member);
          }
        }
      }, [_c('vb-svg', {
        staticClass: "mr-2",
        attrs: {
          "name": "dialer-outlineCall-icon2",
          "width": "16.501",
          "height": "16.501",
          "viewBox": "0 0 16.501 16.501",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', [_vm._v("Call")])], 1), _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.chatUser(member);
          }
        }
      }, [_c('vb-svg', {
        staticClass: "mr-2",
        attrs: {
          "name": "dialer-outlineChat-icon",
          "width": "16.501",
          "height": "16.501",
          "viewBox": "0 0 16.501 16.501",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', [_vm._v("Chat")])], 1), _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.videoCall(member);
          }
        }
      }, [_c('vb-svg', {
        staticClass: "mr-2",
        attrs: {
          "name": "dialer-outlineVideo-icon",
          "width": "20.6",
          "height": "13.6",
          "viewBox": "0 0 20.6 13.6",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', [_vm._v("Video")])], 1)], 1) : _vm._e()], 1)])]);
    }), 0) : _vm._e(), _vm.isEmpty(call_queue.members) ? _c('vb-no-record', {
      attrs: {
        "text": 'No members in this Group',
        "design": 3
      }
    }) : _vm._e()], 1)], 1);
  }), _vm.isEmpty(_vm.filterCallQueues) ? _c('vb-no-record', {
    attrs: {
      "text": 'No call queue available',
      "design": 3
    }
  }) : _vm._e()], 2)] : [_c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover",
    attrs: {
      "isListEmpty": _vm.filterCallQueues.length == 0,
      "listLength": _vm.filterCallQueues.length
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref3) {
        var start = _ref3.start,
          end = _ref3.end;
        return _vm._l(_vm.filterCallQueues, function (callQueue, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: callQueue.voipaccount || callQueue.id
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "is_suspended_show": !!callQueue.voipaccount,
              "id": callQueue.voipaccount || callQueue.id
            }
          })], 1), _c('td', {
            staticClass: "dialer-row-title dialer-col-right four"
          }, [!callQueue.queue_service ? _c('div', {
            staticClass: "d-flex justify-content-end"
          }, [_c('a', {
            staticClass: "dialer-link-more text-decoration-underline",
            on: {
              "click": function ($event) {
                _vm.membersModalData.type = 'queue';
                _vm.membersModalData.items = callQueue.members;
                _vm.$modal.show('ShowMembersOnlyModal');
              }
            }
          }, [_vm._v(" " + _vm._s(_vm._f("get_property")(callQueue, 'members.length', 0)) + " users ")])]) : _vm._e()]), _c('td', {
            staticClass: "dialer-row-title dialer-col-right three"
          }, [_c('div', {
            staticClass: "d-flex justify-content-end settingsGridView-bottomIcons-container showOnly-OnHover-insideTable"
          }, [_c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: `Call`,
              expression: "`Call`",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            staticClass: "mr-3 cursor_pointer_no_hover",
            on: {
              "click": function ($event) {
                return _vm.dialCallQueue(callQueue);
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": "newScheduling-call-icon",
              "height": "35px",
              "width": "35px"
            }
          })], 1), _vm.getProperty(callQueue, `call_queue_status.${_vm.getCurrentUser.account}.adminforce`, 'no') == 'no' ? [_c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: `${callQueue.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`,
              expression: "`${callQueue.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            staticClass: "cursor_pointer_no_hover",
            on: {
              "click": function ($event) {
                return _vm.toggleCallQueueFavourite(callQueue);
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": callQueue.is_favourite ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon',
              "height": "35px",
              "width": "35px"
            }
          })], 1)] : _vm._e()], 2)])]);
        });
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Label")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-right"
  }, [_c('span', [_vm._v("Members")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-right"
  })])])], 2)]] : _vm.selected.tab == 'addressbook' ? [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between mt-16px"
  }, [_c('button', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        _vm.selected.show_addContactTab = !_vm.selected.show_addContactTab;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart position-relative d-flex justify-content-center"
  }, [_vm._v(" " + _vm._s(_vm.selected.show_addContactTab ? 'Close' : 'Add contact') + " ")])], 1)]), _vm.selected.show_addContactTab ? [_c('CreateOrUpdateAddressbook', {
    attrs: {
      "creating": true
    },
    on: {
      "created": function ($event) {
        _vm.selected.addContactTab == 'local' ? _vm.selected.subTab = 'my' : _vm.selected.subTab = _vm.selected.addContactTab;
        _vm.selected.show_addContactTab = false;
      }
    }
  })] : _vm.gridView ? [_c('div', {
    staticClass: "settingsGridView-container mt-20px"
  }, [_vm._l(_vm.filterAddressBooks, function (addressbook) {
    return _c('div', {
      key: addressbook.real_id,
      staticClass: "settingsGridView-item"
    }, [_c('vb-avatar', {
      attrs: {
        "image": _vm._f("get_property")(addressbook, 'profile_image')
      }
    }), _c('div', {
      staticClass: "settingsGridView-display_name"
    }, [_vm._v(_vm._s(`${addressbook.firstName} ${addressbook.lastName || ''}`))]), _c('div', {
      staticClass: "settingsGridView-email"
    }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm._f("get_property")(addressbook, 'main_phone.number'))))]), _c('div', {
      staticClass: "d-flex settingsGridView-bottomIcons-container mt-20px"
    }, [_c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `Call`,
        expression: "`Call`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "mr-3 cursor_pointer_no_hover",
      on: {
        "click": function ($event) {
          return _vm.audioCall(addressbook);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": "newScheduling-call-icon",
        "height": "55px",
        "width": "55px"
      }
    })], 1), _c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `${addressbook.is_favourite ? 'Remove from favourite' : 'Add To favourite'}`,
        expression: "`${addressbook.is_favourite ? 'Remove from favourite' : 'Add To favourite'}`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "cursor_pointer_no_hover",
      attrs: {
        "disabled": _vm.api.toggle_favourite.send.includes(addressbook.real_id)
      },
      on: {
        "click": function ($event) {
          return _vm.toggleFavouriteAddressbook(addressbook);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": addressbook.is_favourite ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon',
        "height": "55px",
        "width": "55px"
      }
    })], 1)])], 1);
  }), _vm.isEmpty(_vm.filterAddressBooks) ? _c('vb-no-record', {
    attrs: {
      "text": 'No contacts found',
      "design": 3
    }
  }) : _vm._e()], 2)] : [_c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover",
    attrs: {
      "textNoRecord": " ",
      "id": "users-table",
      "isListEmpty": _vm.isEmpty(_vm.filterAddressBooks),
      "listLength": _vm.filterAddressBooks.length,
      "perPage": _vm.filterAddressBooks.length,
      "conditions": {
        loading: {
          show: false
        },
        pagination: {
          show: false
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref4) {
        var start = _ref4.start,
          end = _ref4.end;
        return _vm._l(_vm.filterAddressBooks, function (addressbook, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: addressbook.real_id
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('vb-avatar', {
            attrs: {
              "image": _vm._f("get_property")(addressbook, 'profile_image')
            }
          }), _c('div', {
            staticClass: "ml-3"
          }, [_c('div', {
            staticClass: "settingsGridView-display_name"
          }, [_vm._v(_vm._s(`${addressbook.firstName} ${addressbook.lastName || ''}`))]), _c('div', {
            staticClass: "settingsGridView-email"
          }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm._f("get_property")(addressbook, 'main_phone.number'))))])])], 1)]), _c('td', {
            staticClass: "dialer-row-title three"
          }), _c('td', {
            staticClass: "dialer-row-title dialer-col-right four"
          }, [_c('div', {
            staticClass: "d-flex justify-content-end settingsGridView-bottomIcons-container showOnly-OnHover-insideTable"
          }, [_c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: `Call`,
              expression: "`Call`",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            staticClass: "mr-3 cursor_pointer_no_hover",
            on: {
              "click": function ($event) {
                return _vm.audioCall(addressbook);
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": "newScheduling-call-icon",
              "height": "35px",
              "width": "35px"
            }
          })], 1), _c('div', {
            directives: [{
              name: "b-popover",
              rawName: "v-b-popover.hover.top",
              value: `${addressbook.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`,
              expression: "`${addressbook.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`",
              modifiers: {
                "hover": true,
                "top": true
              }
            }],
            staticClass: "cursor_pointer_no_hover",
            attrs: {
              "disabled": _vm.api.toggle_favourite.send.includes(addressbook.real_id)
            },
            on: {
              "click": function ($event) {
                return _vm.toggleFavouriteAddressbook(addressbook);
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": addressbook.is_favourite ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon',
              "height": "35px",
              "width": "35px"
            }
          })], 1)])])]);
        });
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Name")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }), _c('th', {
    staticClass: "dialer-has-sort dialer-col-right"
  })])])], 2)]] : _vm._e()], 2), _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500 addExtension-voicemailModal addRemove_userModal bigSquareCheckbox CallQueueAddRemoveUsersModal min-width-50percent-modal fixScrollIssue",
    attrs: {
      "height": "auto",
      "name": "ShowMembersOnlyModal"
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Members")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        _vm.$modal.hide('ShowMembersOnlyModal');
        _vm.membersModalData.items = [];
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_vm.membersModalData.type == 'queue' ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_vm._l(_vm.membersModalData.items, function (item) {
    return _c('div', {
      key: item.membername,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset"
    }, [_c('div', {
      staticClass: "w-100 d-flex justify-content-between align-item-center"
    }, [_c('Info', {
      attrs: {
        "id": item.membername,
        "is_blf": false
      }
    }), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `Call`,
        expression: "`Call`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "mr-3 cursor_pointer_no_hover",
      on: {
        "click": function ($event) {
          return _vm.dial(item);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": "newScheduling-call-icon",
        "height": "35px",
        "width": "35px"
      }
    })], 1), _c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip"
      }],
      staticClass: "mr-3 cursor_pointer_no_hover",
      class: ['', !_vm.getUserPermissions.chat ? 'for-disable' : ''],
      attrs: {
        "id": `RightSideUsersChatOption-${item.voipaccount}`,
        "title": _vm.getUserPermissions.chat ? '' : 'Your package does not support this feature'
      }
    }, [_c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `Chat`,
        expression: "`Chat`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      class: !_vm.getUserPermissions.chat ? 'for-disable-disable' : '',
      on: {
        "click": function ($event) {
          !_vm.getUserPermissions.chat ? '' : _vm.chatUser(item);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": "newScheduling-chat-icon",
        "height": "35px",
        "width": "35px"
      }
    })], 1)]), _c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `Video call`,
        expression: "`Video call`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "mr-3 cursor_pointer_no_hover",
      on: {
        "click": function ($event) {
          return _vm.videoCall(item);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": "newScheduling-video-icon",
        "height": "35px",
        "width": "35px"
      }
    })], 1)])], 1)]);
  }), _vm.isEmpty(_vm.membersModalData.items) ? _c('vb-no-record', {
    staticClass: "my-4",
    attrs: {
      "design": 3
    }
  }) : _vm._e()], 2) : _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_vm._l(_vm.membersModalData.items, function (item) {
    return _c('div', {
      key: item.membername,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset"
    }, [_c('div', {
      staticClass: "w-100 d-flex justify-content-between align-item-center"
    }, [_c('Info', {
      attrs: {
        "id": item.accountcode,
        "is_blf": false
      }
    }), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `Call`,
        expression: "`Call`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "mr-3 cursor_pointer_no_hover",
      on: {
        "click": function ($event) {
          return _vm.dial(item);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": "newScheduling-call-icon",
        "height": "35px",
        "width": "35px"
      }
    })], 1), _c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip"
      }],
      staticClass: "mr-3 cursor_pointer_no_hover",
      class: ['', !_vm.getUserPermissions.chat ? 'for-disable' : ''],
      attrs: {
        "id": `RightSideUsersChatOption-${item.voipaccount}`,
        "title": _vm.getUserPermissions.chat ? '' : 'Your package does not support this feature'
      }
    }, [_c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `Chat`,
        expression: "`Chat`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      class: !_vm.getUserPermissions.chat ? 'for-disable-disable' : '',
      on: {
        "click": function ($event) {
          !_vm.getUserPermissions.chat ? '' : _vm.chatUser(item);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": "newScheduling-chat-icon",
        "height": "35px",
        "width": "35px"
      }
    })], 1)]), _c('div', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: `Video call`,
        expression: "`Video call`",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "mr-3 cursor_pointer_no_hover",
      on: {
        "click": function ($event) {
          return _vm.videoCall(item);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": "newScheduling-video-icon",
        "height": "35px",
        "width": "35px"
      }
    })], 1)])], 1)]);
  }), _vm.isEmpty(_vm.membersModalData.items) ? _c('vb-no-record', {
    staticClass: "my-4",
    attrs: {
      "design": 3
    }
  }) : _vm._e()], 2)])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }