
import { VOIP_API } from "../index"

export const BILLING_API_ENDPOINTS = {
  whmcsDetail(data={}){
    return VOIP_API.axios.voip.request({ 
      url: "portal-proxy",
      method: "POST",
      data: {
        ...data,
        action: 'whmcs_client_data',
        type: 'billing',
      },
    })
  },
  getbalance(data={}){
    return VOIP_API.axios.voip.request({ 
      url: "portal-proxy",
      method: "POST",
      data: {
        ...data,
        action: 'user_current_balance',
        type: 'billing',
      },
    })
  },
  getsmsbalance(data={}){
    return VOIP_API.axios.voip.request({ 
      url: "portal-proxy",
      method: "POST",
      data: {
        ...data,
        action: 'user_sms_current_balance',
        type: 'billing',
      },
    })
  },
  checkservice(data={}){
    return VOIP_API.axios.voip.request({ 
      url: `billingcons`,
      method: 'POST',
      data: {
        ...data,
        check_service: true
      },
    })
  },
  packages(data={}){
    return VOIP_API.axios.voip.request({ 
      url: "portal-proxy",
      method: "POST",
      data: {
        ...data,
        action: 'get_vb_packages',
        type: 'billing',
      },
    })
  },
  getpromotions(data={}){
    return VOIP_API.axios.voip.request({
      url: "portal-proxy",
      method: "POST",
      data: {
        ...data,
        action: 'get_promotions',
        type: 'billing',
      },
    })
  },
  createpromotion(data={}){
    return VOIP_API.axios.voip.request({
      url: "portal-proxy",
      method: "POST",
      data: {
        ...data,
        action: 'create_promotion',
        type: 'billing',
      },
    })
  },
  list(data) {
    return VOIP_API.axios.voip_billing.request({
      url: "user-callerid",
      method: "POST",
      data,
    })
  },
  update(data) {
    return VOIP_API.axios.voip_billing.request({
      url: "user-callerid",
      method: "patch",
      data,
    })
  },
  numbers(){
    return VOIP_API.axios.voip_billing.request({
      url: "telephonenumbers",
      method: "get",
    })
  },
  extensions() {
    return VOIP_API.axios.voip_billing.request({
      url: "telephonenumbers/shortextensions",
      method: "get",
    })
  },
  updateExtn(data) {
    return VOIP_API.axios.voip_billing.request({
      url: "telephonenumbers",
      method: "patch",
      data,
    })
  },
  getsubscriptiondetail(data={}){
    return VOIP_API.axios.voip.request({
      url: "portal-proxy",
      method: "POST",
      data: {
        ...data,
        action: 'org_subscription_detail',
        type: 'billing',
      },
    })
  },
  getsubscriptions(data={}){
    return VOIP_API.axios.voip.request({
      url: "portal-proxy",
      method: "POST",
      data: {
        ...data,
        action: 'user_subscription_detail_new',
        type: 'billing',
      },
    })
  },
  getPlanAddonDetail(data={}){
    return VOIP_API.axios.voip.request({
      url: "portal-proxy",
      method: "POST",
      data: {
        ...data,
        action: 'user_addon_detail',
        type: 'billing',
      },
    })
  },
  updateplan(data={},options={}){
    return VOIP_API.axios.voip.request({
      ...options,
      url: "portal-proxy",
      method: "POST",
      data: {
        ...data,
        action: 'changeplan',
        type: 'billing',
      },
    })
  },
  checkTrail(data={}){
    return VOIP_API.axios.voip.request({
      url: "portal-proxy",
      method: "POST",
      data: {
        ...data,
        action: 'check_plan_trial_info',
        type: 'billing',
      },
    })
  },
  unsubscribe(data={}){
    // return VOIP_API.axios.voip.request({
    //   url: "suspendsubscription",
    //   method: "POST",
    //   data,
    // })
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'billing',
        action: 'billing_service_action',
      },
    })
  },
  callBalance(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'billing',
        action: 'user_call_current_balance',
      },
    })
  },
  cancelTrail(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'billing',
        action: 'end_trial_plan',
      },
    })
  },
  reactivateServices(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'billing',
        action: 'reactive_trial_suspended',
      },
    })
  },
}