<template>
  <div v-if="call">
    <div class="d-flex justify-content-end position-absolute" style="right: 147px; top: -104px; z-index: 10;">
      <div 
        v-if="log.status=='Dialed' ? log.dst_type=='Number' : log.src_type=='Number'" 
        @click="selectAddContact()"
        class="TabsDesignWithIcon-item" 
        :class="{
          selected: selected.tab == tabs.add_contact
        }" 
      >
        <vb-icon icon="callInfo-tab-addContact-icon" height="38px" width="38px" />
        <span class="TabsDesignWithIcon-item-textPart">Add contact</span>
      </div>
      <div 
        v-if="getCurrentUser.administrator_account && !conditions.block_number" @click="selected.tab = tabs.block"
        class="TabsDesignWithIcon-item" 
        :class="{
          selected: selected.tab == tabs.block
        }" 
      >
        <vb-icon icon="callInfo-tab-BlockNumber-icon" height="38px" width="38px" />
        <span class="TabsDesignWithIcon-item-textPart">Block</span>
      </div>
    </div>
    <div class="latestGreyBox-9-9-2023">
      <ul class="phone-list full-width-call-info-number list-unstyled pr-0 pb-0 overlowX-visible-imp">
        <li class="p-0">
          <!-- call -->
          <CallActivityTestItem class="m-0 w-100" :call="call" :conditions="{ action: { enable: false, }, design: { more: false, } }">
            <template #more>
              <b-dropdown varient="link" no-caret class="ico-filter" menu-class="w-fit-content-imp" right>
                <template v-slot:button-content>
                  <b-icon icon="three-dots" variant="#393939" font-scale="1.4"/>
                </template>
              </b-dropdown>
            </template>
          </CallActivityTestItem>
        </li>
      </ul>
    </div>
    <div>
      <div class="d-flex justify-content-between align-items-center w-100 mt-32px mb-32px">
        <div class="TabsDesignWithIcon-container">
          <div 
            class="TabsDesignWithIcon-item" 
            :class="{
              selected: selected.tab == tabs.tags_and_note
            }"
            @click="selected.tab = tabs.tags_and_note" 
          >
            <vb-icon icon="newbilling-tab-tags-icon" height="38px" width="38px" />
            <span class="TabsDesignWithIcon-item-textPart">Call info</span>
          </div>
          <div 
            class="TabsDesignWithIcon-item" 
            :class="{
              selected: selected.tab == tabs.task
            }" 
            @click="selected.tab = tabs.task" 
          >
            <vb-icon icon="newbilling-tab-tags-icon" height="38px" width="38px" />
            <span class="TabsDesignWithIcon-item-textPart">Task</span>
          </div>
          <div 
            class="TabsDesignWithIcon-item" 
            :class="{
              selected: selected.tab == tabs.history
            }" 
            @click="selected.tab = tabs.history"
          >
            <vb-icon icon="newbilling-tab-history-icon" height="38px" width="38px" />
            <span class="TabsDesignWithIcon-item-textPart">History</span>
          </div>
          <div 
            v-if="call.soundFile"
            class="TabsDesignWithIcon-item" 
            :class="{
              selected: selected.tab == tabs.recordings
            }" 
            @click="selected.tab = tabs.recordings" 
          >
            <vb-icon icon="squared-ai-audio-icon" class="squared-ai-audio-icon" height="38px" width="38px" />
            <span class="TabsDesignWithIcon-item-textPart">AI transcript</span>
          </div>
          <div 
            v-if="isMobileNumber" 
            class="TabsDesignWithIcon-item" 
            :class="{
              selected: selected.tab == tabs.sms
            }" 
            @click="selected.tab = tabs.sms"  
          >
            <vb-icon icon="newbilling-tab-sms-icon" height="38px" width="38px" />
            <span class="TabsDesignWithIcon-item-textPart">SMS</span>
          </div>
        </div>
        <!-- history filter -->
        <slot v-if="selected.tab == tabs.history" name="history-filter"></slot>
      </div>
      <ul 
        class="allow_scroll phone-list full-width-call-info-number dialor-callHistoryList list-unstyled pl-0 pr-2"
        :class="{
          'tags_and_note_tab': selected.tab == tabs.tags_and_note,
        }" 
        style="overflow-y: unset;height: auto !important;"
      > 
        <!-- tags and notes -->
        <div v-if="selected.tab == tabs.tags_and_note" class="latestGreyBox-9-9-2023">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <!-- recording -->
            <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center">
              <div class="latestGreyBox-heading-main">Recording</div>
              <vb-audio-player v-if="call.soundFile" class="w-100 mt-20px" :src="call.soundFile" :design="'NoTranscript'" >
                <template #player-end>
                  <vb-icon class="cursor_pointer_no_hover" icon="audioTranscript-download-icon" width="14.2px" height="14.2px" @click="download()" />
                </template>
              </vb-audio-player>
              <div v-else class="latestGreyBox-9-9-2023 w-100 flex-wrap d-flex mt-20px">
                <div class="latestGreyBox-descriptionText mt-0">There is no recording</div>
              </div>
            </div>
            <!-- tags -->
            <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center">
              <div class="d-flex justify-content-between align-items-center w-100">
                <div class="latestGreyBox-heading-main">Call tags</div>
                <div 
                  class="callInfoEditNotesTagsBoxTypeButton" 
                  v-b-tooltip :title="getUserPermissions.call_tagging_and_notes?'':'Your package does not support this feature'"
                  @click="conditions.edit_tags=!conditions.edit_tags"
                >
                  <template v-if="conditions.edit_tags"><b-icon icon="x-lg" variant="dark" font-scale="2" /> Cancel</template>
                  <template v-else-if="isEmpty(callTags)"><b-icon font-scale="1.3" icon="plus-lg" variant="dark"  /> Add</template>
                  <template v-else><b-icon font-scale="1.3" icon="pencil-fill" variant="primary"  /> Edit</template>
                </div>
              </div>
              <div v-if="!conditions.edit_tags" class="latestGreyBox-9-9-2023 w-100 flex-wrap d-flex mt-20px">
                <span v-for="tag in callTags" :key="tag.id" class="latestTagDesignLayout">
                  <small v-vb-tag-color="tag.colour">
                    <span v-vb-tag-color.text="tag.text_color">{{ tag.tag }}</span>
                  </small>
                </span>
                <div class="latestGreyBox-descriptionText mt-0" v-if="isEmpty(callTags)">There is no tags</div>
              </div>
              <div v-else class="latestGreyBox-9-9-2023 w-100 mt-20px">
                <b-form class="whiteBGinputWithGreyRoundedBorder" @submit.prevent="''">
                  <b-form-input placeholder="Search tags" v-model="filter.tag.search" />
                </b-form>
                <p v-for="(em, i) in api.tag.validation_errors.log" :key="i">{{ em }}</p>
                <div class="d-flex flex-wrap w-100 mt-20px">
                  <div
                    v-for="tag in filterTags" 
                    :key="tag.real_id" 
                    @click="
                      api.tag.send ? '' :
                      forms.tag.tags.includes(tag.real_id) ?
                        forms.tag.tags.splice(forms.tag.tags.indexOf(tag.real_id),1) :
                        forms.tag.tags.push(tag.real_id)" 
                    :class="`${forms.tag.tags.includes(tag.real_id)?'selected':''} latestTagDesignLayout`" 
                  >
                    <small v-vb-tag-color="tag.colour" :style="`border-color: ${forms.tag.tags.includes(tag.real_id)?'#3699FF':''}`">
                      <span v-vb-tag-color.text="tag.text_color">{{ tag.tag }}</span>
                    </small>
                  </div>
                  <div class="latestGreyBox-descriptionText" v-if="isEmpty(filterTags)">There is no tags</div>
                </div>
                <p class="latestGreyBox-descriptionText" v-for="(em, i) in api.tag.validation_errors.log" :key="i">{{ em }}</p>
                <div class="d-flex justify-content-end mt-20px">
                  <b-button :disabled="api.tag.send" class="newDoneButton" variant="primary" @click="updatetag()">
                    <vb-icon icon="squared-submit-icon" class="submitIcon" height="38px" width="38px" />
                    <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                      <vb-spinner v-if="api.tag.send" />
                      <template v-else>
                        Submit
                      </template>
                    </span>
                  </b-button>
                </div>
              </div>
            </div>
            <!-- note -->
            <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center">
              <div class="d-flex justify-content-between align-items-center w-100">
                <div class="latestGreyBox-heading-main">Call notes</div>
                <div 
                  class="callInfoEditNotesTagsBoxTypeButton" 
                  v-b-tooltip :title="getUserPermissions.call_tagging_and_notes?'':'Your package does not support this feature'"
                  @click="conditions.edit_notes=!conditions.edit_notes"
                >
                  <template v-if="data.note">
                    <b-icon v-if="conditions.edit_notes" icon="x-lg" variant="dark" font-scale="2" />
                    <b-icon v-else font-scale="1.3" icon="pencil-fill" variant="primary"  />
                    {{conditions.edit_notes ? 'Cancel' : 'Edit'}}
                  </template>
                  <template v-else>
                    <b-icon v-if="conditions.edit_notes" icon="x-lg" variant="dark" font-scale="2" />
                    <b-icon v-else font-scale="1.3" icon="plus-lg" variant="dark"  />
                    {{conditions.edit_notes ? 'Cancel' : 'Add'}}
                  </template>
                </div>
              </div>
              <div v-if="!conditions.edit_notes" class="latestGreyBox-9-9-2023 w-100 mt-20px">
                <div class="latestGreyBox-descriptionText mt-0">{{ data.note || 'There is no note' }}</div>
              </div>
              <template v-else>
                <div class="latestGreyBox-9-9-2023 w-100 mt-20px">
                  <div class="whiteBGinputWithGreyRoundedBorder w-100">
                    <label>Write some note for this call</label>
                    <b-textarea :disabled="api.note.send" rows="10" no-resize placeholder="Enter note" v-model="forms.note.note" class="w-100" />
                  </div>
                  <div class="d-flex justify-content-end mt-20px">
                    <b-button :disabled="api.note.send" variant="primary" class="newDoneButton" @click="updatenote()">
                      <vb-icon icon="squared-submit-icon" class="submitIcon" height="38px" width="38px" />
                      <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                        <vb-spinner v-if="api.note.send" />
                        <template v-else>Submit</template>
                      </span>
                    </b-button>
                  </div>
                </div>
              </template>
            </div>
            <!-- task -->
            <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center">
              <div class="latestGreyBox-9-9-2023 w-100 mt-20px">
                <div class="latestGreyBox-descriptionText mt-0">{{ call | get_property('linked_tasks.length',0) }} tasks</div>
              </div>
            </div>
            <!-- feedback -->
            <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-center justify-content-center">
              <div class="latestGreyBox-heading-main">How was the quality of your call?</div>
              <b-form-rating class="latestCallFeedback" no-border  v-model="forms.feedback.rating" @change="updateFeedback()" />
            </div>
          </div>
        </div>
        <!-- recording -->
        <template v-if="selected.tab == tabs.recordings">
          <div class="d-flex justify-content-between align-items-center my-3">
            <h2 class="mb-0">Call recording</h2>
          </div>
          <AudioTranscriptDummy 
            :soundFile="call.soundFile" 
            :uniqueID="uniqueID"
          >
            <template #transcript-end>
              <div class="latestGreyBox-9-9-2023 mt-20px mb-20px">
                <div class="latestGreyBox-heading-main w-100 d-flex align-items-center justify-content-between">
                  <span>Call context</span>
                  <button 
                    class="newButton"
                    v-b-tooltip :title="getUserPermissions.call_tagging_and_notes?'':'Your package does not support this feature'"
                    @click="!getUserPermissions.call_tagging_and_notes ? '' : $modal.show(`${_uid}-UpdateCallNotesAndTags`,{
                      note: data.note,
                      tags: callTags,
                    })"
                  >
                    <vb-icon icon="squared-update-icon" height="38px" width="38px" />
                    <span class="newButton-textPart">Update</span>
                  </button>
                </div>
                <div class="AudioTranscript-whiteBox mt-20px">
                  <div class="latestGreyBox-heading-main-16px">Notes</div>
                  <div class="latestGreyBox-descriptionText-13px mt-10px">{{data.note}}</div>
                  <div class="latestGreyBox-heading-main-16px mt-24px">tags</div>
                  <div class="AI-tags-container">
                    <div class="AI-tag" v-for="tag in callTags" :key="tag.id" v-vb-tag-color.bg="tag.colour">{{tag.tag}}</div>
                  </div>
                </div>
              </div>
            </template>
          </AudioTranscriptDummy>
        </template>
        <!-- sms -->
        <SMS v-if="selected.tab == tabs.sms" :otherNumber="dialable" />
        <!-- history -->
        <slot v-if="selected.tab == tabs.history" name="history" ></slot>
        <!-- block number -->
        <div v-else-if="selected.tab == tabs.block" class="latestGreyBox-9-9-2023">
          <b-form @submit.prevent="!getUserPermissions.block_and_privacy ? '' : blocknumber()">
            <div class="whiteBoxWithBorderInsideGreyBox">
              <div class="whiteBoxWithBorderInsideGreyBox-row">
                <b-form-group class="whiteBGinputWithGreyRoundedBorder mb-0 w-100">
                  <label>Reason for blocking</label>
                  <div 
                    v-b-tooltip :title="getUserPermissions.block_and_privacy?'':'Your package does not support this feature'"
                    :class="{
                      'for-disable' : !getUserPermissions.block_and_privacy
                    }"
                  >
                    <b-input :disabled="api.block_number.send || !getUserPermissions.block_and_privacy" v-model="forms.block_number.displayname" />
                  </div>
                  <p v-if="(forms.block_number.submitted && $v.forms.block_number.displayname.$invalid) || api.block_number.validation_errors.displayname" class="text text-danger animated bounceIntop">
                    <span v-if="!$v.forms.block_number.displayname.required">* First name is required</span>
                    <span v-for="(error_message, index) in api.block_number.validation_errors.displayname" :key="index">* {{ error_message }}</span>
                  </p>
                </b-form-group>
              </div>
            </div>
            <div class="latestGreyBox-heading-main mt-20px">Direction of number block</div>
            <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
              <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center">
                <div 
                  class="align-items-center justify-content-between d-flex w-100 bigSquareCheckbox" 
                  v-b-tooltip :title="getUserPermissions.block_and_privacy?'':'Your package does not support this feature'"
                  :class="{
                    'for-disable w-fit-content' : !getUserPermissions.block_and_privacy 
                  }"
                >
                  <div class="latestGreyBox-heading-main">Block inbound calls</div>
                  <b-form-checkbox switch
                    :disabled="api.block_number.send || !getUserPermissions.block_and_privacy" 
                    v-model="forms.block_number.inbound" 
                    class="newerSwitch"
                  />
                </div>
              </div>
              <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center">
                <div 
                  class="align-items-center justify-content-between d-flex w-100 bigSquareCheckbox" 
                  v-b-tooltip :title="getUserPermissions.block_and_privacy?'':'Your package does not support this feature'"
                  :class="{
                    'for-disable w-fit-content' : !getUserPermissions.block_and_privacy 
                  }"
                >
                  <div class="latestGreyBox-heading-main">Block otbound calls</div>
                  <b-form-checkbox switch
                    variant="black" 
                    :disabled="api.block_number.send || !getUserPermissions.block_and_privacy" 
                    v-model="forms.block_number.outbound" 
                    class="newerSwitch"
                  />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end mt-20px">
              <b-form-group class="mb-0">
                <div 
                  v-b-tooltip :title="getUserPermissions.block_and_privacy?'':'Your package does not support this feature'"
                  :class="{
                    'for-disable w-fit-content' : !getUserPermissions.block_and_privacy
                  }"
                >
                  <b-button type="submit" :disabled="api.block_number.send || !getUserPermissions.block_and_privacy" class="newDoneButton">
                    <vb-icon icon="squared-submit-icon" class="submitIcon" height="38px" width="38px" />
                    <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                      <vb-spinner v-if="api.block_number.send" />
                      <template v-else>Submit</template>
                    </span>
                  </b-button>
                </div>
              </b-form-group>
            </div>
          </b-form>
        </div>
        <!-- add contact -->
        <div v-else-if="selected.tab == tabs.add_contact" class="latestGreyBox-9-9-2023">
          <CreateOrUpdateAddressbook v-if="conditions.edit_contact_id" ref="update-addressbook" :creating="false" :addressbookID="conditions.edit_contact_id" />
          <CreateOrUpdateAddressbook v-else ref="create-addressbook" :creating="true" @created="conditions.edit_contact_id=$event.addressbook.real_id;selectAddContact()" />
        </div>
        <!-- task -->
        <div v-else-if="selected.tab == tabs.task">
          <div class="w-100 d-flex justify-content-between align-items-center">
            <div class="latestGreyBox-heading-main">Task</div>
            <div @click="addTodoTask()" class="callInfoEditNotesTagsBoxTypeButton">
              <vb-icon icon="callInfoModal-assignTo-icon" class="mr-16px" width="23.875px" height="20.436px" />
              Create Task
            </div>
            <div @click="$modal.show(`${_uid}-AssignTaskToCallLogModal`,{ call_id: callID, assigned_tasks: call.linked_tasks.map(i=>i.task_id) })" class="callInfoEditNotesTagsBoxTypeButton">
              <vb-icon icon="callInfoModal-assignTo-icon" class="mr-16px" width="23.875px" height="20.436px" />
              Link Tasks
            </div>
          </div>
          <b-card no-body class="mb-1" style="background-color: transparent; border-width: 0px;" v-for="(linked_task, index) in call.linked_tasks" :key="linked_task.id">
            <b-card-header header-tag="header"  style="background-color: transparent; border-width: 0px;" class="p-1 d-flex" role="tab">
              <TaskItem :task="linked_task.task_assigned">
                <template #more>
                  <b-button block v-b-toggle="[`${_uid}-task-${index}`]" variant="link">
                    <b-icon icon="chevron-down" />
                  </b-button>
                </template>
              </TaskItem>
            </b-card-header>
            <b-collapse :id="`${_uid}-task-${index}`" accordion="tasks">
              <b-card-body>
                <EditTasksModal v-for="linked_task in call.linked_tasks" :key="linked_task.id" :propTaskID="linked_task.task_id" />
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </ul>
      <UpdateCallNotesAndTags
        :modalName="`${_uid}-UpdateCallNotesAndTags`" 
        :callID="callID" 
        @note-updated="data.note=$event.note;$emit('latest-record');$emit('updated');" 
        @tags-updated="data.tag_ids=$event.tag_ids;$emit('latest-record');$emit('updated');" 
      />
      <AssignTaskToCallLogModal 
        :modalName="`${_uid}-AssignTaskToCallLogModal`"
        @updated="$emit('fetch-latest-data')" 
      />
    </div>
  </div>
</template>

<script>
import { number_formater, validNumberChecker } from '@/filter'
import { 
  VOIP_API, 
  $fn, 
} from "../../../utils";
import CallActivityTestItem from '../../Home/CallActivity/TestItem/index.vue';
import _ from 'lodash';
import { required, maxLength, minLength, email } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import AudioTranscriptDummy from '../../widgets/AudioTranscriptDummy.vue';
import TaskItem from '../../Todo/TaskItem.vue';
import SMS from '../../sms/index.vue';
import UpdateCallNotesAndTags from '../UpdateCallNotesAndTags.vue';
import AssignTaskToCallLogModal from '../Todo/AssignTaskToCallLogModal.vue';
import CreateOrUpdateAddressbook from '../../widgets/addressbook/CreateOrUpdate.vue';
import parseMax from 'libphonenumber-js/max'
const tabs = {
  history: 'history',
  block: 'block',
  tags_and_note: 'tags_and_note',
  add_contact: 'add_contact',
  recordings: 'recordings',
  sms: 'sms',
  task: 'task',
}
export default {
  name: "SipCallLog",
  props: {
    log: {
      type: Object,
      default: ()=>({}),
    },
    params: {
      type: Object,
      default: null,
    },
  },
  components: {
    EditTasksModal: () => import('../Todo/EditTasksModal.vue'),
    CallActivityTestItem,
    AudioTranscriptDummy,
    TaskItem,
    SMS,
    UpdateCallNotesAndTags,
    AssignTaskToCallLogModal,
    CreateOrUpdateAddressbook,
  },
  inject:[
    'isEmpty',
    'getAddressBooks',
    'appNotify'
  ],
  data() {
    return {
      api: {
        add_todo: this.$helperFunction.apiInstance(),
        text_copy: this.$helperFunction.apiInstance(),
        tag: this.$helperFunction.apiInstance({
          status: true,
          error_message: true,
          validation_errors: true,
        }),
        note: this.$helperFunction.apiInstance({
          status: true,
          error_message: true,
          validation_errors: true,
        }),
        block_number: this.$helperFunction.apiInstance({
          error_message: true,
          validation_errors: true,
        }),
        shortcode: this.$helperFunction.apiInstance(),
        update_feedback: this.$helperFunction.apiInstance({}),
      },
      forms: {
        note: this.$helperFunction.formInstance({
          data: {
            note: '',
          },
        }),
        tag: this.$helperFunction.formInstance({
          data: {
            tags: [],
          },
        }),
        block_number: this.$helperFunction.formInstance({
          data: {
            displayname: '',
            inbound: true,
            outbound: true,
          },
        }),
        feedback: this.$helperFunction.formInstance({
          data: {
            rating: '0.0',
          },
        }),
      },
      selected: this.$helperFunction.stateInstance({
        data: {
          tab: tabs.tags_and_note,
        },
      }),
      data: this.$helperFunction.stateInstance({
        data: {
          call_info: {},
          tag_ids: [],
          note: '',
        },
      }),
      filter: {
        tag: this.$helperFunction.stateInstance({
          data: {
            search: '',
          },
        }),
      },
      conditions: this.$helperFunction.stateInstance({
        data: {
          edit_tags_and_note: false,
          edit_tags: false,
          edit_notes: false,
          show_more_add_contact: false,
          show_more_edit_contact: false,
          edit_contact_id: '',
          block_number: false,
        },
      }),
    };
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getVoipCallTags',
      'getCurrentUser',
      'getIsMobile',
      'getVoipUsersAlisesDisplayName',
      'getVoipUsersAlises',
    ]),
    call(){ return this.data.call_info ?? {} },
    callID() { return this.call?.call_id; },
    uniqueID() { return this.call?.uniqueid },
    tabs(){ return tabs },
    isMobileNumber(){
      let is_mobile = false
      try {
        const phoneNumber = validNumberChecker(this.dialable)
        if(phoneNumber) {
          const maxPhoneNumber = parseMax(phoneNumber.number)
          if(maxPhoneNumber){
            is_mobile=maxPhoneNumber.getType()?.toLocaleLowerCase?.()?.includes?.('mobile')
          }
        }
        return is_mobile
      } catch (ex) {
        return is_mobile
      }
    },
    callTags() {
      return _.uniqBy([
        ...this.getVoipCallTags,
        ...(this.call?.tags_notes?.filter?.(i=>i.type=="tag" && !!i.tag)?.map(i=>({...i.tag,real_id:i.tag.id})) ?? [])
      ],'real_id')
      .filter(item=>this.data.tag_ids.includes(item.real_id))
      .filter(item=>item.deleted_tags<1);
    },
    filterTags() {
      return _.uniqBy([
        ...this.getVoipCallTags,
        ...(this.call?.tags_notes?.filter?.(i=>i.type=="tag" && !!i.tag)?.map(i=>({...i.tag,real_id:i.tag.id})) ?? [])
      ],'real_id')
      .filter(item => `${item.tag}`.toLowerCase().trim().includes(this.filter.tag.search.toLowerCase().trim()))
      .filter(item=>item.deleted_tags<1);
    },
    dialable(){
      let type=this.call.src_type, number=this.call.src_number, extn=this.call.src_extn 
      if(this.call.call_direction=='outbound') type=this.call.dst_type, number=this.call.dst_number, extn=this.call.dst_extn
      if(type=='Number') return number
      else return extn
    },
  },
  validations: {
    forms: {
      feedback: {
        rating: {
          required: required,
        }
      },
      add_contact: {
        firstname: {
          required,
          maxLength: maxLength(16),
        },
        suffix: {
          
        },
        lastname: {
          maxLength: maxLength(16),
        },
        company: {
          minLength: minLength(3),
        },
        jobtitle: {
          minLength: minLength(3),
        },
        department: {
          minLength: minLength(3),
        },
        emails: {
          $each: {
            emails: {
              email,
            },
          }
        },
        phones: {
          required,
          minLength: minLength(1),
          $each: {
            phone: {
              required,
            },
            number: {
              required,
              validNumber(value){ return $fn.validPhoneNumber(value) },
            },
            shortcode: {
              required,
            },
          }
        },
      },
      edit_contact: {
        id: {
          required,
        },
        firstname: {
          required,
          maxLength: maxLength(16),
        },
        suffix: {
          
        },
        lastname: {
          maxLength: maxLength(16),
        },
        company: {
          minLength: minLength(3),
        },
        jobtitle: {
          minLength: minLength(3),
        },
        department: {
          minLength: minLength(3),
        },
        emails: {
          $each: {
            emails: {
              email,
            },
          }
        },
        phones: {
          required,
          minLength: minLength(1),
          $each: {
            phone: {
              required,
            },
            number: {
              required,
              validNumber(value){ return $fn.validPhoneNumber(value) },
            },
            shortcode: {
              required,
            },
          }
        },
      },
      block_number: {
        displayname: {
          required,
        },
      },
    },
  },
  watch: {
    "log": {
      immediate: true,
      deep: true,
      handler(log){
        this.data.call_info = log
        this.data.tag_ids = this.call.tags_notes?.filter?.(i=>i.type=="tag" && i.tag)?.map?.(tag=>tag.tag.id) ?? [];
        this.forms.tag.tags = _.cloneDeep(this.data.tag_ids)
        this.forms.note.note = this.data.note = this.call.tags_notes?.find?.(i=>i.log && i.type=="note")?.log || '';
        this.forms.feedback.rating=this.data.call_info?.call_feed_back?.stars || '0'
      },
    },
  },
  methods: {
    selectAddContact(){
      let vm = this
      this.selected.tab = tabs.add_contact
      this.$nextTick(()=>{
        if(!vm.conditions.edit_contact_id) {
          const international_number = number_formater((vm.data.call_info.status=='Dialed' ? vm.data.call_info.dst_number : vm.data.call_info.src_number) || '','',true)
          const phoneNumber = $fn.getParsePhoneNumber('')(international_number)
          if(phoneNumber && phoneNumber.isValid()){
            vm.$refs['create-addressbook'].forms.create_addressbook.phones[0].number=phoneNumber.formatNational()
            vm.$refs['create-addressbook'].forms.create_addressbook.phones[0].country=phoneNumber.country
          }
        } else {
          vm.$refs['update-addressbook'].setUpdate()
        }
      })
    },
    async updateFeedback(){
      this.forms.feedback.submitted=true
      this.$v.forms.feedback.$touch()
      if(this.api.update_feedback.send || this.$v.forms.feedback.$invalid) return;
      try {
        this.api.update_feedback.send=true
        await VOIP_API.endpoints.call_activity.callFeedback({
          accountcode: this.getCurrentUser.account,
          description: '',
          caller_id: this.callID,
          stars: this.forms.feedback.rating,
        })
        this.appNotify({
          message: 'Successfully Updated',
          type: 'success',
        })
        this.$emit('fetch-latest-data')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.forms.feedback.submitted=false
        this.api.update_feedback.send=false
      }
    },
    async blocknumber(){
      this.forms.block_number.submitted=true
      this.$v.forms.block_number.$touch();
      if(this.api.block_number.send || this.$v.forms.block_number.$invalid) return;
      try {
        this.api.block_number.send=true
        this.api.block_number.validation_errors={}
        this.api.block_number.error_message=''
        console.log($fn.getNumberFormated(this.dialable,'','','max',true))
        await VOIP_API.endpoints.inbound_rule.create({
          callerid: $fn.getNumberFormated(this.dialable,'','','max',true),
          displayname: this.forms.block_number.displayname,
          type: this.forms.block_number.inbound && this.forms.block_number.outbound ? 'both' : this.forms.block_number.inbound ? 'in' : this.forms.block_number.outbound ? 'out' : '',
          action: "save",
          enable: "Y",
          command: "busy",
          fid: 12,
          args: "fsdfsd",
          source: "app",
        })
        this.appNotify({
          message: 'Successfully updated',
          type: 'success',
        })
        this.conditions.block_number=true
        this.selected.tab=tabs.history
      } catch (ex) {
        this.api.block_number.validation_errors=ex.own_errors
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.block_number.send=false
        this.forms.block_number.submitted=false
      }
    },
    async updatetag() {
      this.forms.tag.submitted=true
      if (this.api.tag.send) return;
      try {
        this.api.tag.send = true;
        this.api.tag.error_message = '';
        this.api.tag.validation_errors = {};
        this.api.tag.status = 0;
        await VOIP_API.endpoints.call_activity.calltagsnotes({
          uid: this.getCurrentUser?.uid,
          accountcode: this.getCurrentUser?.account,
          id: this.callID,
          log: this.forms.tag.tags.toString(),
          type: "tag",
        })
        this.api.tag.status = 1;
        this.conditions.edit_tags=false
        this.data.tag_ids=_.cloneDeep(this.forms.tag.tags)
        this.$emit('latest-record')
        this.appNotify({
          message: 'Successfully updated',
          type: 'success',
        })
        this.$emit('updated')
        this.$emit('fetch-latest-data')
      } catch (ex) {
        this.api.tag.status = 2;
        this.api.tag.error_message = ex.own_message
        this.api.tag.validation_errors = ex.own_errors
        this.appNotify({
          message: this.api.tag.error_message,
          type: 'danger',
        })
      } finally {
        this.api.tag.send = false;
        this.forms.tag.submitted=false
      }
    },
    async updatenote() {
      this.forms.note.submitted=true
      if (this.api.note.send) return;
      try {
        this.api.note.send = true;
        this.api.note.error_message = '';
        this.api.note.validation_errors = {};
        this.api.note.status = 0;
        await VOIP_API.endpoints.call_activity.calltagsnotes({
          uid: this.getCurrentUser?.uid,
          accountcode: this.getCurrentUser?.account,
          id: this.callID,
          log: this.forms.note.note,
          type: "note",
        })
        this.api.note.status = 1;
        this.conditions.edit_notes=false
        this.data.note=this.forms.note.note
        this.$emit('latest-record')
        this.appNotify({
          message: 'Successfully updated',
          type: 'success',
        })
        this.$emit('updated')
        this.$emit('fetch-latest-data')
      } catch (ex) {
        this.api.note.status = 2;
        this.api.note.error_message = ex.own_message
        this.api.note.validation_errors = ex.own_errors
        this.appNotify({
          message: this.api.note.error_message,
          type: 'danger',
        })
      } finally {
        this.api.note.send = false;
        this.forms.note.submitted=false
      }
    },
    async block(){
      try {
        const { confirm } = await this.$appConfirmation({
          title: "warning",
          message: `Are you sure you want to block this number`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        });
        if (confirm) {
          this.appNotify({
            message: 'This feature is in process',
            type: 'info',
          })
        }
      } finally {
        // TODO - 
      }
    },
    async download() {
      try {
        const response = await fetch(this.call.soundFile)
        const blob = await response.blob()
        let blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.target = '_blank';
        link.download = 'download';
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (ex) {
        // TODO - 
      }
    },
    async addTodoTask(){
      if(this.api.add_todo.send || this.isTodoAdded) return;
      try {
        this.api.add_todo.send=true
        let type = 'outgoing'
        if(this.call.status=='Missed') type='missed'
        else if(this.call.status=='Received') type='incoming'
        let title = ''
        if(this.call.status=='Dialed') {
          if(this.call.dst_type=='Number') title=number_formater(this.call.dst_number)
          else title=this.call.dst_name
        } else {
          if(this.call.src_type=='Number') title=number_formater(this.call.src_number)
          else title=this.call.src_name
        }
        const { data } = await VOIP_API.endpoints.tasklist.callAssign({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          title: title,
          cdr_id: this.callID,
          type: type
        })
        this.appNotify({
          message: 'Successfully Added to Tasks',
          type: 'success',
        })
        this.$modal.show('GlobalEditTask',{
          id: data.id,
        })
        this.$emit('fetch-latest-data')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.add_todo.send=false
      }
    },
  },
  mounted(){
    if(this.params.task_modal) {
      this.selected.tab=tabs.task
    }
  },
};
</script>

<style>
</style>