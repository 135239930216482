<template>
  <modal class="ViewContactInfoModal" :name="modalName" @before-close="onBeforeClose()" @before-open="onBeforeOpen($event)">
    <div class="dialer-assign-to md-pad-bottom dialer-edit">
      <div class="dialer-edit-header d-flex w-100 justify-content-between align-items-center">
        <div>
          <h2 class="dialer-settings-title newer mb-0">Add note</h2>
        </div>
        <div class="dialer-edit-actions">
          <button class="newCloseButton" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <template v-if="api.get_info.send">
          <!-- Loading... -->
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-none py-3" v-for="n in 10" :key="n">
              <div class="latestShimmerDesign w-100" style="height:25px;"></div>
            </div>
          </div>
        </template>
        <div v-else>
          <b-form @submit.prevent="''">
            <div class="whiteBGinputWithGreyRoundedBorder">
              <b-form-textarea v-model="forms.update_note.note" class="minHeight-150px" placeholder="Enter Note" @input="updateNoteDebounce()" />
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import _ from 'lodash';
export default {
  name: 'PowerDialerContactUpdateNoteModal',
  inject: [
    'appNotify'
  ],
  props: {
    modalName: {
      type: String,
      default: 'PowerDialerContactUpdateNote'
    },
  },
  data() {
    return {
      api: {
        get_info: this.$helperFunction.apiInstance({  }), 
        update_note: this.$helperFunction.apiInstance({  }), 
      },
      forms: {
        update_note: this.$helperFunction.formInstance({
          data: {
            note: '',
          },
        })
      },
      data: {
        sheet_id: '',
        row_no: '',
      },
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
  },
  methods: {
    onBeforeOpen(event){
      this.data.sheet_id=event?.params?.sheet_id || ''
      this.data.row_no=event?.params?.row_no || ''
      this.fetchInfo()
    },
    onBeforeClose(){
      this.api.get_info.reset()
      this.api.update_note.reset()
      this.forms.update_note.reset()
      this.data.sheet_id=''
      this.data.row_no=''
    },
    updateNoteDebounce: _.debounce(function(){
      this.updateNote()
    }, 2*1000),
    async updateNote(){
      if(this.api.update_note.send) return;
      try {
        this.api.update_note.send=true
        await VOIP_API.endpoints.power_dialer.updateNote({
          sheet_id: this.data.sheet_id,
          row_no: this.data.row_no,
          notes: this.forms.update_note.note,
          created_by: this.getCurrentUser.account,
        })
        this.$emit('updated')
        this.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
        // this.$modal.hide(this.modalName)
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.update_note.send=false
      }
    },
    async fetchInfo(){
      if(this.api.get_info.send) return;
      try {
        this.api.get_info.send=true
        const { data } = await VOIP_API.endpoints.power_dialer.getNextNumber(this.data.sheet_id,{
          row_no: this.data.row_no
        })
        this.forms.update_note.note=data?.data?.notes || ''
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.get_info.send=false
      }
    },
  },
}
</script>

<style>

</style>