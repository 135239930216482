var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      }
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to my-4 dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-actions d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Menu option")]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_vm.ivr ? _c('MenuOptionsWidget', {
    attrs: {
      "menu": _vm.ivr
    },
    on: {
      "updated": function ($event) {
        return _vm.$emit('options-updated');
      }
    }
  }) : _vm._e()], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }