<template>
  <div>
    <div class="latestGreyBox-9-9-2023">
      <ul class="phone-list full-width-call-info-number list-unstyled pr-0 pb-0 overlowX-visible-imp">
        <li class="p-0">
          <!-- call -->
          <CallActivityTestItem class="m-0 w-100" :call="call" :conditions="{ action: {enable: false,}, design: { more: false, } }">
            <template #more>
              <b-dropdown varient="link" no-caret class="ico-filter" menu-class="w-fit-content-imp" right>
                <template v-slot:button-content>
                  <b-icon icon="three-dots" variant="#393939" font-scale="1.4"/>
                </template>
              </b-dropdown>
            </template>
          </CallActivityTestItem>
        </li>
      </ul>
    </div>
    <div>
      <div class="d-flex justify-content-between align-items-center w-100 mt-32px mb-32px">
        <div class="TabsDesignWithIcon-container">
          <div 
            class="TabsDesignWithIcon-item" 
            :class="{
              selected: selected.tab == tabs.history
            }" 
            @click="selected.tab = tabs.history"
          >
            <vb-icon icon="newbilling-tab-history-icon" height="38px" width="38px" />
            <span class="TabsDesignWithIcon-item-textPart">History</span>
          </div>
          <div 
            v-if="call.call_type=='sms' && isMobileNumber" 
            class="TabsDesignWithIcon-item" 
            :class="{
              selected: selected.tab == tabs.sms
            }" 
            @click="selected.tab = tabs.sms"  
          >
            <vb-icon icon="newbilling-tab-sms-icon" height="38px" width="38px" />
            <span class="TabsDesignWithIcon-item-textPart">SMS</span>
          </div>
        </div>
        <!-- history filter -->
        <slot v-if="selected.tab == tabs.history" name="history-filter"></slot>
      </div>
      <ul 
        class="allow_scroll phone-list full-width-call-info-number dialor-callHistoryList list-unstyled pl-0 pr-2"
        style="overflow-y: unset;height: auto !important;"
      > 
        <!-- sms -->
        <SMS v-if="selected.tab == tabs.sms" :otherNumber="call.receiver" />
        <!-- history -->
        <slot v-if="selected.tab == tabs.history" name="history" ></slot>
      </ul>
    </div>
  </div>
</template>

<script>
import CallActivityTestItem from '../../Home/CallActivity/TestItem/index.vue';
import { mapGetters } from 'vuex';
import SMS from '../../sms/index.vue';
import { parsePhoneNumber } from 'libphonenumber-js';
import parseMax from 'libphonenumber-js/max'
const tabs = {
  history: 'history',
  sms: 'sms', 
}
export default {
  name: "SMSLog",
  props: {
    log: {
      type: Object,
      default: ()=>({}),
    },
    params: {
      type: Object,
      default: null,
    },
  },
  components: {
    CallActivityTestItem,
    SMS,
  },
  inject:[
    
  ],
  data() {
    return {
      selected: this.$helperFunction.stateInstance({
        data: {
          tab: tabs.sms,
        },
      }),
      data: this.$helperFunction.stateInstance({
        data: {
          call_info: {},
        },
      }),
    };
  },
  computed: {
    ...mapGetters([
      
    ]),
    call(){ return this.data.call_info },
    tabs(){ return tabs },
    isMobileNumber(){
      let is_mobile = false
      try {
        const number = this.call?.receiver
        const phoneNumber = parsePhoneNumber(`+${number}`)
        // console.log(phoneNumber)
        if(phoneNumber) {
          const maxPhoneNumber = parseMax(phoneNumber.number)
          if(maxPhoneNumber){
            is_mobile=maxPhoneNumber.getType()?.toLocaleLowerCase?.()?.includes?.('mobile')
          }
        }
        return is_mobile
      } catch (ex) {
        return is_mobile
      }
    },
  },
  mounted(){
    this.data.call_info = this.log
  },
};
</script>

<style>
</style>