
import { VOIP_API } from "../index"

export const POWER_DIALER_API_ENDPOINTS = {
  createSheet(data={},option={}){
    return VOIP_API.axios.voip.request({
      ...option,
      method: 'POST',
      url: 'autodialer/files',
      data: data,
    })
  },
  updateSheet(data={},option={}){
    return VOIP_API.axios.voip.request({
      ...option,
      method: 'PATCH',
      url: 'autodialer/file',
      data: data,
    })
  },
  getSheets(params={},option={}){
    return VOIP_API.axios.voip.request({
      ...option,
      method: 'GET',
      url: 'autodialer/files',
      params: params,
    })
  },
  deleteSheet(id='',data={},option={}){
    return VOIP_API.axios.voip.request({
      ...option,
      method: 'DELETE',
      url: `autodialer/file/${id}`,
      data: data,
    })
  },
  exportSheet(id='',params={},option={}){
    return VOIP_API.axios.voip.request({
      ...option,
      method: 'GET',
      url: `export/sheet/${id}`,
      params: params,
      responseType: 'blob', 
    })
  },
  getSheetNumbers(id='',params={},option={}){
    return VOIP_API.axios.voip.request({
      ...option,
      method: 'GET',
      url: `autodialer/file/${id}`,
      params: params,
    })
  },
  getNextNumber(id='',params={},option={}){
    return VOIP_API.axios.voip.request({
      ...option,
      method: 'GET',
      url: `autodialer/number/${id}`,
      params: params,
    })
  },
  updateStatus(data={},option={}){
    return VOIP_API.axios.voip.request({
      ...option,
      method: 'PATCH',
      url: `autodialer/file/status`,
      data: data,
    })
  },
  deleteStatus(data={},option={}){
    return VOIP_API.axios.voip.request({
      ...option,
      method: 'DELETE',
      url: `autodialer/call/status`,
      data: data,
    })
  },
  updateTags(data={},option={}){
    return VOIP_API.axios.voip.request({
      ...option,
      method: 'PATCH',
      url: `autodialer/file/tags`,
      data: data,
    })
  },
  updateSchedule(data={},option={}){
    return VOIP_API.axios.voip.request({
      ...option,
      method: 'PATCH',
      url: `autodialer/file/schedule`,
      data: data,
    })
  },
  updateNote(data={},option={}){
    return VOIP_API.axios.voip.request({
      ...option,
      method: 'PATCH',
      url: `autodialer/file/notes`,
      data: data,
    })
  },
  createTag(data={},option={}){
    return VOIP_API.axios.voip.request({
      ...option,
      method: 'POST',
      url: `tags`,
      data: data,
    })
  },
  deleteTag(id,data={},option={}){
    return VOIP_API.axios.voip.request({
      ...option,
      method: 'DELETE',
      url: `tags/${id}`,
      data: data,
    })
  },
  getTags(params={},option={}){
    return VOIP_API.axios.voip.request({
      ...option,
      method: 'GET',
      url: `dialer/tags`,
      params: params,
    })
  },
}