<template>
  <div class="dialer-settings-main">
    <section class="dialer-settings-section dialer-musicOnHold-settings">
      <div class="dialer-flex pt-3">
        <template v-if="$store.getters.getIsMobile">
          <div class="d-flex align-items-center mb-3 calc-title-width-button">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" @click="$emit('back')" />
            <h2 class="dialer-settings-title w-100 mb-0 text-center">Updates</h2>
          </div>
        </template>
        <h2 v-else class="dialer-settings-title newer">VoIP Business updates</h2>
      </div>
      <p class="dialer-settings-intro with-button newerTextVersion">
        <span>Here you can check for updates, check what version you are at and control whether you want to receive auto updates or not.</span>
      </p>
      <div class="latestGreyBox-heading-main-18px text-left">Current VoIP Business version is {{ electron.app_version }} {{ electron.name }}</div>
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <b-alert class="my-3"
          :show="!!electron.update.error && electron.update.state==6" 
          dismissible 
          variant="danger" 
          fade 
          @dismissed="$store.state.electron.update.error=''"
        >
          {{ electron.update.error }}
        </b-alert>
        <b-alert class="my-3"
          :show="electron.update.state==3"
          dismissible 
          variant="success" 
          fade 
          @dismissed="$store.state.electron.update.state=0"
        >
          You are Already on upto date
        </b-alert>
        <div class="whiteBoxWithBorderInsideGreyBox">
          <div class="whiteBoxWithBorderInsideGreyBox-row" v-if="electron.update.state==5">
            <div>
              <div class="latestGreyBox-heading-main">Updates downloaded</div>
              <div class="latestGreyBox-descriptionText mb-20px">The latest update for VoIP business is being downloaded</div>
              <div class="latestGreyBox-descriptionText-14px">
                VoIP business version {{electron | get_property('update.version_available')}} is available
              </div>
            </div>
            <button class="newButton mr-0" @click="restartForUpdate()">
              <vb-icon icon="squared-configure-icon" height="38px" width="38px" />
              <span class="newButton-textPart d-flex align-items-center justify-content-center position-relative">Update now</span>
            </button>
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox-row" v-if="electron.update.state==4">
            <div>
              <div class="latestGreyBox-heading-main">Updates downloading</div>
              <div class="latestGreyBox-descriptionText">
                The latest updates for VoIP business are downloading {{electron | get_property('update.version_available')}}
              </div>
            </div>
            <div class="latestGreyBox-heading-main">
              {{electron | get_property('update.downloading.percentage') | filterDownloadPercent}}
            </div>
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div>
              <div class="latestGreyBox-heading-main">Auto update</div>
              <div class="latestGreyBox-descriptionText">Want to receive auto updates, enable the button to receive auto updates.</div>
            </div>
            <b-form-checkbox class="newerSwitch" :checked="electron.update.auto" @change="toggleAutoUpdate()" name="check-button" switch />
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox-row" v-if="electron.update.state!=4">
            <div>
              <div class="latestGreyBox-heading-main">Check for update</div>
              <div class="latestGreyBox-descriptionText">Click on a button to check if there is a update available.</div>
            </div>
            <button class="newButton mr-0 mwd-200px" :disabled="electron.update.state==1" @click="checkForUpdate()">
              <vb-icon icon="squared-update-icon" height="38px" width="38px" />
              <span class="newButton-textPart d-flex align-items-center justify-content-center position-relative">
                <vb-spinner v-if="electron.update.state==1" />
                <template v-else>
                  <span>Check for update</span>
                </template>
              </span>
            </button>
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox-row" v-if="latestReleaseVersion">
            <div>
              <div class="latestGreyBox-heading-main">{{ latestReleaseVersion.label }}</div>
              <div class="latestGreyBox-descriptionText">{{electron.app_version==latestReleaseVersion.label?'You are already up to date':'There is an update available.'}}</div>
            </div>
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div>
              <div class="latestGreyBox-heading-main">Changelogs</div>
              <div class="latestGreyBox-descriptionText">View the latest updatess, features, and fixes in our app.</div>
            </div>
            <button class="newButton mr-0" @click="$modal.show('ChangeLogsTableModal')">
              <vb-icon icon="squared-show-icon" height="38px" width="38px" />
              <span class="newButton-textPart d-flex align-items-center justify-content-center position-relative">
                <span>View</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </section>
    <modal 
      name="ChangeLogsTableModal"
      class="dialer_animation right_side_popup m-w-900 ChangeLogsTableModal" 
      width="50%" 
      height="auto" 
    >
      <div class="dialer-edit-header flex-column pb-1 align-items-start">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div>
            <h2 class="dialer-settings-title newer">Changelogs</h2>
          </div>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" type="button" @click="$modal.hide('ChangeLogsTableModal')">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <p class="vm--modal-text">
          View the latest updates and stay informed about new features, bug fixes, and performance enhancements.
        </p>
      </div>
      <vb-table 
        class="latestTableDesign-withBlackBorders-again"
        :isListEmpty="isEmpty(filterReleases)"
        :loading="api.allowed_versions.send" 
        :listLength="filterReleases.length" 
      >
        <template slot="header">
          <tr>
            <th class="dialer-has-sort">
              <span>name</span>
            </th>
            <th class="dialer-has-sort">
              <span>desc</span>
            </th>
          </tr>
        </template>
        <template #body>
          <tr class="dialer-row-select" v-for="release in filterReleases" :key="release.label">
            <td class="dialer-row-title">
              <span>{{release.label}}</span>
            </td>
            <td class="dialer-row-title">
              <span>{{release.notes}}</span>
            </td>
          </tr>
        </template>
        <template #loading>
          <tr v-for="n in 5" :key="n">
            <td>
              <div class="latestShimmerDesign flex-1" style="height:16px;width:100%"></div>
            </td>
            <td class="dialer-col-right">
              <div class="latestShimmerDesign flex-1" style="height:16px;width:100%"></div>
            </td>
          </tr>
        </template>
      </vb-table>
    </modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { electron_events } from '../../electron/events';
import { DEVICE_INFO, VOIP_API } from '../../utils';
export default {
  name: "ElectronSetting",
  inject:['isEmpty', 'getProperty','appNotify'],
  data(){
    return {
      api: {
        releases: this.$helperFunction.apiInstance(),
        latest_release: this.$helperFunction.apiInstance(),
        allowed_versions: this.$helperFunction.apiInstance(),
      },
      response: {
        releases: [],
        latest_release: {},
        allowed_versions: {},
      },
      filter: {
        releases: {
          search: '',
        },
      },
      pagination: {
        releases: {
          per_page: 10,
          page: 1,
          total: 0,
        },
      },
    }
  },
  filters: {
    filterDownloadPercent(value){return `${~~value}%`},
  },
  computed: {
    ...mapState({
      electron: (state)=>state.electron
    }),
    ...mapGetters([
      'getCurrentUser',
    ]),
    filterReleases(){ return this.response.allowed_versions ?? [] },
    latestReleaseVersion(){ return this.response.latest_release },
  },
  methods: {
    async fetchLatestVersion(){
      if(this.api.latest_release.send) return;
      try {
        this.api.latest_release.send=true
        const platform = DEVICE_INFO.os.window ? 'window' : DEVICE_INFO.os.mac ? 'mac' :' linux'
        const { data } = await VOIP_API.endpoints.electron.latestVersion({
          platform: platform,
          accountcode: this.getCurrentUser.account,
        })
        this.response.latest_release = data;
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.latest_release.send=false
      }
    },
    async fetchVersions(){
      if(this.api.allowed_versions.send) return;
      try {
        this.api.allowed_versions.send=true
        const platform = DEVICE_INFO.os.window ? 'window' : DEVICE_INFO.os.mac ? 'mac' :' linux'
        const { data } = await VOIP_API.endpoints.electron.allowVersions({
          platform: platform,
          accountcode: this.getCurrentUser.account,
          records: 'all'
        })
        this.response.allowed_versions=data
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.allowed_versions.send=false
      }
    },
    toggleAutoUpdate(){
      window.electron.send(electron_events.TOGGLE_AUTO_UPDATE)
    },
    checkForUpdate(){
      window.electron.send(electron_events.CHECK_UPDATE)
    },
    restartForUpdate(){
      window.electron.send(electron_events.INSTALL_UPDATE)
    },
  },
  activated(){
    this.fetchLatestVersion()
    this.fetchVersions()
  },
};
</script>