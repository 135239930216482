<template>
  <!--<modal class="ViewContactInfoModal" :name="modalName" @before-close="onBeforeClose()" @before-open="onBeforeOpen($event)">
    <div class="dialer-assign-to md-pad-bottom dialer-edit">
      <div class="dialer-edit-header d-flex w-100 justify-content-between align-items-center">
        <div>
          <h2 class="dialer-settings-title newer mb-0">Add tags</h2>
        </div>
        <div class="dialer-edit-actions">
          <button class="newCloseButton" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <template v-if="api.get_info.send || api.get_tags.send">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-none py-3" v-for="n in 10" :key="n">
              <div class="latestShimmerDesign w-100" style="height:25px;"></div>
            </div>
          </div>
        </template>
        <div v-else>
          <!~~ <b-form @submit.prevent="updateTags()">
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion">
              <b-form-select :disabled="api.update_tags.send" multiple v-model="forms.update_tags.tags" :options="response.tags" text-field="tag" value-field="real_id" :select-size="4" />
            </div>
            <b-button class="mt-20px fullWidthDoneButton" type="submit">Submit</b-button>
          </b-form> ~~>
          <div class="dialer-call-notification-actions">
            <div class="dialer-call-notification-actions-inner">
              <ul class="dialer-tagList mt-20px">
                <li v-for="tag in response.tags" :key="tag.real_id" @click="api.update_tags.send?'':forms.update_tags.tags.includes(tag.real_id)?forms.update_tags.tags.splice(forms.update_tags.tags.indexOf(tag.real_id),1):forms.update_tags.tags.push(tag.real_id)" v-vb-tag-color.bg.text.border="forms.update_tags.tags.includes(tag.real_id) && {color:tag.colour,text_color:tag.text_color}" :class="{selected: forms.update_tags.tags.includes(tag.real_id)}">
                  {{ tag.tag }}
                </li>
              </ul>
              <b-button @click="updateTags()">Submit</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>-->
  <modal class="center-zoomIn-transition sipActiveCall-Modal TagsModal" :transition="!getIsMobile ? 'center-zoomIn-transition' : ''" :name="modalName" @before-open="onBeforeOpen($event)" @before-close="onBeforeClose()">
    <div class="dialer-call-notification-tray d-block">
      <div class="dialer-call-notification">
        <div class="dialer-call-notification-inner justify-content-between w-100">
          <div class="dialer-call-notification-text">
            <div class="dialer-call-notification-main-text">Power dialer tag</div>
            <div class="dialer-call-notification-sub-text">Here you can select the tags for this call</div>
          </div>
          <a class="newCloseButton" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
        <div class="dialer-call-notification-actions">
          <div class="dialer-call-notification-actions-inner">
            <b-form class="dialer-searchBox" @submit.prevent="''">
              <div class="whiteBGinputWithGreyRoundedBorder">
                <b-form-input placeholder="Search here" v-model="filter.tag.search" />
              </div>
            </b-form>
            <ul class="dialer-tagList mt-20px">
              <li v-for="tag in tags" :key="tag.real_id" @click="api.update_tags.send?'':(forms.update_tags.tags.includes(tag.real_id)?forms.update_tags.tags.splice(forms.update_tags.tags.indexOf(tag.real_id),1):forms.update_tags.tags.push(tag.real_id),updateTagsDebounce())" v-vb-tag-color.bg.text.border="forms.update_tags.tags.includes(tag.real_id) && {color:tag.colour,text_color:tag.text_color}" :class="{selected: forms.update_tags.tags.includes(tag.real_id)}">
                {{ tag.tag }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </modal>
  <!-- <modal class="center-zoomIn-transition sipActiveCall-Modal TagsModal" :transition="!$store.getters.getIsMobile ? 'center-zoomIn-transition' : ''" :name="modalName" @before-open="beforeOpen" @before-close="beforeClose">
    <div class="dialer-call-notification-tray d-block">
      <div class="dialer-call-notification">
        <div class="dialer-call-notification-inner justify-content-between w-100">
          <div class="dialer-call-notification-text">
            <div class="dialer-call-notification-main-text">Tag</div>
            <div class="dialer-call-notification-sub-text">Here you can Select the tags for this call</div>
          </div>
          <a class="newCloseButton" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
        <div class="dialer-call-notification-actions">
          <div class="dialer-call-notification-actions-inner">
            <b-form class="dialer-searchBox" @submit.prevent="''">
              <div class="whiteBGinputWithGreyRoundedBorder">
                <b-form-input placeholder="Search here" v-model="filter.tag.search" />
              </div>
            </b-form>
            <template v-if="api.tag.error.errors">
              <p v-for="error_log in api.tag.error.errors.log" :key="error_log">{{ error_log }}</p>
            </template>
            <ul class="dialer-tagList mt-20px">
              <li v-for="tag in filterTags" :key="tag.real_id" @click="handleTags(tag)" v-vb-tag-color.bg.text.border="form.tag.tags.includes(tag.real_id) && {color:tag.colour,text_color:tag.text_color}" :class="{selected: form.tag.tags.indexOf(tag.real_id)>-1}">
                {{ tag.tag }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </modal> -->
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import _ from 'lodash';
export default {
  name: 'PowerDialerContactUpdateTagsModal',
  inject: [
    'appNotify'
  ],
  props: {
    modalName: {
      type: String,
      default: 'PowerDialerContactUpdateTags'
    },
  },
  data() {
    return {
      api: {
        get_info: this.$helperFunction.apiInstance({  }), 
        get_tags: this.$helperFunction.apiInstance({  }), 
        update_tags: this.$helperFunction.apiInstance({  }), 
      },
      forms: {
        update_tags: this.$helperFunction.formInstance({
          data: {
            tags: [],
          },
        })
      },
      filter: {
        tag: {
          search: '',
        },
      },
      response: {
        tags: [],
      },
      data: {
        sheet_id: '',
        row_no: '',
      },
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile',
    ]),
    tags(){ return this.response.tags?.filter?.(i=>i.tag.toLowerCase().trim().includes(this.filter.tag.search.toLowerCase().trim())) },
  },
  methods: {
    onBeforeOpen(event){
      this.data.sheet_id=event?.params?.sheet_id || ''
      this.data.row_no=event?.params?.row_no || ''
      this.fetchInfo()
      this.fetchTags()
    },
    onBeforeClose(){
      this.api.get_info.reset()
      this.api.get_tags.reset()
      this.api.update_tags.reset()
      this.forms.update_tags.reset()
      this.response.tags=[]
      this.data.sheet_id=''
      this.data.row_no=''
    },
    updateTagsDebounce: _.debounce(function(){
      this.updateTags()
    }, 2* 1000),
    async updateTags(){
      if(this.api.update_tags.send) return;
      try {
        this.api.update_tags.send=true
        await VOIP_API.endpoints.power_dialer.updateTags({
          sheet_id: this.data.sheet_id,
          row_no: this.data.row_no,
          tags: this.forms.update_tags.tags.join(','),
          created_by: this.getCurrentUser.account,
        })
        this.$emit('updated')
        this.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
        // this.$modal.hide(this.modalName)
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.update_tags.send=false
      }
    },
    async fetchTags(){
      if(this.api.get_tags.send) return;
      try {
        this.api.get_tags.send=true
        const { data } = await VOIP_API.endpoints.power_dialer.getTags({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
        })
        this.response.tags=data?.data
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.get_tags.send=false
      }
    },
    async fetchInfo(){
      if(this.api.get_info.send) return;
      try {
        this.api.get_info.send=true
        const { data } = await VOIP_API.endpoints.power_dialer.getNextNumber(this.data.sheet_id,{
          row_no: this.data.row_no
        })
        this.forms.update_tags.tags=data?.data?.tags?.map?.(i=>i.id) ?? []
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.get_info.send=false
      }
    },
  },
}
</script>

<style>

</style>