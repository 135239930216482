<template>
  <div class="dialer-analytics-main dialer-analytics-users">
    <div class="dialer-box p-16-24-px">
      <template v-if="getIsMobile">
        <header class="dialer-analytics-titleBar pb-1">
          <div class="dialer-analytics-title calc-title-width-analytics">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" @click="$emit('back')" />
            <h1>Users</h1>
          </div>
        </header>
        <div class="w-100 d-flex align-items-center justify-content-end">
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterWithText mr-16px">
            <b-dropdown varient="link" no-caret class="ico-filter" right> 
              <template #button-content>
                <span class="mr-12px" v-if="response.users.data && response.users.data.date_label">{{ response.users.data.date_label }}</span>
                <b-icon icon="three-dots-vertical" variant="dark" class="fillBlack" scale="1.5" />
              </template>
              <b-dropdown-item v-for="tab in tabs" :key="tab.value" @click="selected.tab=tab.value;tab.value==tabs.custom.value ? $modal.show(`${_uid}-dateFilterModal`) : fetchUsers()" >
                <vb-icon icon="markAsComplete-icon" :style="`opacity:${selected.tab === tab.value ? '1' : '0'}`" class="mr-12px" width="14.49px" height="10.431px"/>
                <span >{{tab.text}}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="whiteBGinputWithGreyRoundedBorder seachBox w-100 mb-16px mt-8px">
          <b-input type="text" placeholder="Search user" class="w-100" v-model="filter.users.search" />
        </div>
      </template>
      <div v-else class="analytics-newHeader mb-32px">
        <div class="analytics-newHeader-leftSide">
          <h1 >Users</h1>
          <!-- <b-form-checkbox v-model="conditions.new_activity" @change="fetchUsers()"></b-form-checkbox> -->
        </div>
        <div class="analytics-newHeader-rightSide">
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterWithText mr-16px">
            <b-dropdown varient="link" no-caret class="ico-filter" right> 
              <template #button-content>
                <span class="mr-12px" v-if="response.users.data && response.users.data.date_label">{{ response.users.data.date_label }}</span>
                <b-icon icon="three-dots-vertical" variant="dark" class="fillBlack" scale="1.5" />
              </template>
              <b-dropdown-item v-for="tab in tabs" :key="tab.value" @click="selected.tab=tab.value;tab.value==tabs.custom.value ? $modal.show(`${_uid}-dateFilterModal`) : fetchUsers()" >
                <vb-icon icon="markAsComplete-icon" :style="`opacity:${selected.tab === tab.value ? '1' : '0'}`" class="mr-12px" width="14.49px" height="10.431px"/>
                <span >{{tab.text}}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder seachBox">
            <b-input type="text" placeholder="Search user" v-model="filter.users.search" />
          </div>
        </div>
      </div>
      <vb-table
        class="latestTableDesign-withBlackBorders-again"
        :isListEmpty="users.length==0"
        :listLength="users.length"
        :loading="api.fetch_users.send"
        :noRecordWholePage="true"
      >
        <template #header>
          <tr v-if="!getIsMobile">
            <th class="dialer-has-sort">
              <span>User</span>
            </th>
            <th class="dialer-has-sort dialer-col-center">
              <span>Total</span>
            </th>
            <th class="dialer-has-sort dialer-col-center dialer-inbound">
              <span>Received</span>
            </th>
            <th class="dialer-has-sort dialer-col-center dialer-outbound">
              <span>Outbound</span>
            </th>
            <th class="dialer-has-sort dialer-col-center dialer-missed">
              <span>Missed</span>
            </th>
            <th class="dialer-has-sort dialer-col-right" v-if="!getIsMobile">
              <span id="tcdTooltip" v-b-popover.hover.focus.top.html="'<small>Total call duration</small>'">TCD</span>
            </th>
            <th class="dialer-has-sort dialer-col-right" v-if="!getIsMobile">
              <span id="tcdTooltip" v-b-popover.hover.focus.top.html="'<small>Average call duration</small>'">ACD</span>
            </th>
            <th class="dialer-has-sort dialer-col-right" v-if="!getIsMobile">
              <span id="tcdTooltip" v-b-popover.hover.focus.top.html="'<small>Time to answer</small>'">TTA</span>
            </th>
          </tr>
        </template>
        <template #body="{ start, end }">
          <template v-if="!getIsMobile">
            <tr v-for="(user, index) in users"
              :key="user.accountcode" 
              class="dialer-row-select" 
              @click="conditions.new_activity?
                $modal.show(`${_uid}-UserAnalytics`, { accountcode: user.accountcode }):
                $modal.show('NumberAnalyticsModal', { accountcode: user.accountcode, user: user.user, time_line: user.time_line, })
              "
              v-show="index >= start && index <= end"
            >
              <td class="dialer-col-name-width wd-75">
                <div class="dialer-wrap-initials d-flex">
                  <Info :id="user.accountcode" :is_blf="false" />
                </div>
              </td>
              <td class="dialer-col-center wd-25">
                <span class="display-responsive mr-2">TOTAL :</span>
                {{ user.user.totalCalls }}
              </td>
              <td class="dialer-col-center dialer-inbound wd-25">
                <span class="display-responsive mr-2">INBOUND :</span>
                {{ user.user.totalReceiveCalls }}
              </td>
              <td class="dialer-col-center dialer-outbound wd-25">
                <span class="display-responsive mr-2">OUTBOUND :</span>
                {{ user.user.totalOutgoingCalls }}
              </td>
              <td class="dialer-col-center dialer-missed wd-25">
                <span class="display-responsive mr-2">MISSED :</span>
                {{ user.user.totalMissCalls }}
              </td>
              <td class="dialer-col-right wd-25" v-if="!$store.getters.getIsMobile">
                <span class="display-responsive mr-2">TCD :</span>
                {{user.kpis.tcd | time_audio(true)}}
              </td>
              <td class="dialer-col-right wd-25" v-if="!$store.getters.getIsMobile">
                <span class="display-responsive mr-2">ACD :</span>
                {{user.kpis.acd | time_audio(true)}}
              </td>
              <td class="dialer-col-right wd-25" v-if="!$store.getters.getIsMobile">
                <span class="display-responsive mr-2">TTA :</span>
                {{user.kpis.tta | time_audio(true)}}
              </td>
            </tr>
          </template>
          <template v-else>
            <tr v-for="(user, index) in users"
              :key="user.accountcode" 
              class="dialer-row-select" 
              @click="conditions.new_activity?
                $modal.show(`${_uid}-UserAnalytics`, { accountcode: user.accountcode }):
                $modal.show('NumberAnalyticsModal', { accountcode: user.accountcode, user: user.user, time_line: user.time_line, })
              "
              v-show="index >= start && index <= end"
            >
              <td class="left">
                <div class="dialer-wrap-initials d-flex">
                  <Info :id="user.accountcode" :is_blf="false" />
                </div>
              </td>
              <td class="dialer-col-right right">
                <div class="d-flex align-items-center justify-content-end">
                  <div class="d-flex mr-6px">
                    <div class="textInsideCard onRightSide textAlignRight" style="color: #87868C;">{{ user | get_property('user.totalCalls',0) }}</div>
                    <div class="textInsideCard onRightSide textAlignRight mx-1">/</div>
                    <div class="textInsideCard onRightSide textAlignRight" style="color: #FF1900;">{{ user | get_property('user.totalMissCalls',0) }}</div>
                  </div>
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
                </div>
              </td>
            </tr>
          </template>
        </template>
        <template #loading>
          <tr v-for="n in 5" :key="n">
            <td>
              <div class="d-flex">
                <div class="latestShimmerDesign mr-2" style="height:35px;width:35px;"></div>
                <div class="d-flex flex-column justify-content-between">
                  <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
                  <div class="latestShimmerDesign mt-1" style="height:16px;width:30px"></div>
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:50px"></div>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:50px"></div>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:50px"></div>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:50px"></div>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:100%"></div>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-items-center w-100">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:100%"></div>
              </div>
            </td>
            <td class="dialer-col-right">
              <div class="latestShimmerDesign ml-auto" style="height:16px;width:35px;"></div>
            </td>
          </tr>
        </template>
        <template #no-record>
          <div class="latestGreyBox-9-9-2023 mb-32px">
            <div class="emptyScreenContainer">
              <img class="mt-50px w-50 h-auto" v-if="GET_LOCAL_SETTING_IS_DARK_MODE" :src="require('@/assets/images/emptyScreenImages/darkMode/usersanalytics.png')"/>
              <img class="mt-50px w-50 h-auto" v-else :src="require('@/assets/images/emptyScreenImages/usersanalytics.png')"/>
              <div class="emptyScreenContainer-heading">
                User analytics: discover user activity insights
              </div>
              <div class="emptyScreenContainer-text w-75">
                Uncover valuable insights into user call patterns, durations, and more with our detailed analytics.
              </div>
              <button class="dialer-button dialer-button-primary mt-20px">
                Analyze users
              </button>
            </div>
          </div>
        </template>
      </vb-table>
    </div>
    <modal :name="`${_uid}-dateFilterModal`" class="callReportingFilterModal">
      <div class="dialer-edit-header pt-0 position-relative">
        <div>
          <h2 class="dialer-settings-title newer mb-0">Filters</h2>
        </div>
        <div class="dialer-edit-actions">
          <button class="newDoneButton" type="button" @click="$modal.hide(`${_uid}-dateFilterModal`)">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023">
        <div class="analytics-newHeader-text">
          <div class="d-flex customDate">
            <div class="whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionLeftAligned w-50 pr-2">
              <span>From:</span>
              <vue-ctk-date-time-picker v-bind="ctkDateTimePickerOptions" class="date-time-input" v-model="filter.users.start_time" />
            </div>
            <div class="whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionRightAligned w-50 pl-2">
              <span>To:</span>
              <vue-ctk-date-time-picker :min-date="filter.users.start_time" v-bind="ctkDateTimePickerOptions" class="date-time-input" v-model="filter.users.end_time" />
            </div>
          </div>
          <div class="w-100 d-flex justify-content-end mt-20px">
            <a class="newButton" @click="fetchUsers();$modal.hide(`${_uid}-dateFilterModal`)">
              <vb-icon icon="squared-browse-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Fetch</span>
            </a>
          </div>
        </div>
      </div>
    </modal>
    <NumberAnalyticsModal />
    <UserAnalyticsModal :modalName="`${_uid}-UserAnalytics`" />
  </div>
</template>

<script>
import NumberAnalyticsModal from "../Modals/NumberAnalyticsModal.vue";
import UserAnalyticsModal from "../Modals/UserAnalyticsModal.vue";
import Info from "../Lists/Info.vue";
import { VOIP_API } from '../../utils';
import { ctkDateTimePicker } from '../../mixin';
import { mapGetters } from 'vuex';
import { GET_LOCAL_SETTING_IS_DARK_MODE } from '@/store/helper/getters';
const tabs = {
  today: {
    text: "Today",
    value: "today",
    icon: 'squared-today-icon'
  },
  week: {
    text: "This week",
    value: "week",
    icon: 'squared-thisWeek-icon'
  },
  month: {
    text: "This month",
    value: "month",
    icon: 'squared-thisMonth-icon'
  },
  custom: {
    text: "Custom",
    value: "custom",
    icon: 'squared-customDate-icon'
  },
}
export default {
  name: 'Users',
  mixins: [ctkDateTimePicker],
  components: {
    NumberAnalyticsModal,
    UserAnalyticsModal,
    Info,
  },
  inject:['isEmpty'],
  data() {
    return {
      api: {
        fetch_users: this.$helperFunction.apiInstance({ source: true, }),
      },
      response: {
        users: {},
      },
      filter:  {
        users: {
          start_time: '',
          end_time: '',
          search: '',
        },
      },
      selected: {
        tab: tabs.today.value,
      },
      conditions: {
        new_activity: true,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getIsMobile',
      GET_LOCAL_SETTING_IS_DARK_MODE
    ]),
    users(){
      const time_line = this.response.users.timeLineData ?? []
      const users = this.response.users?.data?.users ?? {}
      const kpis = this.response.users.kpis ?? {}
      const search = this.filter.users.search.trim().toLowerCase()
      return Object.keys(users).map(accountcode=>{
        return {
          accountcode: accountcode,
          time_line: time_line?.find?.(i=>i.measure==accountcode),
          user: users[accountcode],
          kpis: kpis[accountcode],
        }
      }).filter(item=>`${item.user.name} ${item.user.extn}`.trim().toLowerCase().includes(search))
    },
    tabs(){ return tabs },
  },
  methods: {
    async fetchUsers(){
      try {
        this.api.fetch_users.send=true
        let data = null
        if(this.conditions.new_activity) {
          const { data: res } = await VOIP_API.endpoints.analytics.graph({
            type: this.selected.tab,
            section: 'users',
            start: this.filter.users.start_time,
            end: this.filter.users.end_time,
          })
          data=res
        } else {
          const { data: res } = await VOIP_API.endpoints.analytics.list({
            type: this.selected.tab,
            section: 'users',
            start: this.filter.users.start_time,
            end: this.filter.users.end_time,
          })
          data=res
        }
        // const { data } = await VOIP_API.endpoints.analytics.list({
        //   type: this.selected.tab,
        //   section: 'users',
        //   start: this.filter.users.start_time,
        //   end: this.filter.users.end_time,
        // })
        this.response.users = data
      } finally {
        this.api.fetch_users.send=false
      }
    },
  },
  mounted() {
    this.fetchUsers();
  },
};
</script>
