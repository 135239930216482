var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.conditions.can_show_toaster ? [_vm.permissions.microphone != 'granted' ? _c('div', {
    staticClass: "dialer-offline-notification v2 forTrialPackage microphoneNotAllowed fixed-top position-absolute"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "mr-4",
    staticStyle: {
      "margin": "-5px"
    },
    attrs: {
      "icon": "trialPackageWarning-icon",
      "width": "25px",
      "height": "20px"
    }
  }), _c('span', {
    staticStyle: {
      "line-height": "1.1",
      "font-size": "15px"
    }
  }, [_vm._v("You won't be able to make or receive calls without a microphone")])], 1)]) : _vm._e(), _vm.conditions.trail_toaster ? _c('div', {
    staticClass: "dialer-offline-notification v2 forTrialPackage forTrialPackageReallu fixed-top position-absolute"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "mr-4",
    staticStyle: {
      "margin": "-5px"
    },
    attrs: {
      "icon": "trialPackageWarning-icon",
      "width": "25px",
      "height": "20px"
    }
  }), _c('span', {
    staticStyle: {
      "line-height": "1.1",
      "font-size": "15px"
    }
  }, [_vm._v("Your trial plan is ending on " + _vm._s(_vm._f("filter_date_current")(_vm.getTrialPlanDetails.trial_expiry_date, '', {
    formate: 'DD MMM YYYY'
  })) + " in " + _vm._s(_vm.trailEndDays) + " days ")])], 1), _c('b-icon', {
    attrs: {
      "icon": "x-circle-fill",
      "variant": "black",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.trail_toaster = false;
      }
    }
  })], 1) : _vm._e(), _vm.conditions.grace_period ? _c('div', {
    staticClass: "dialer-offline-notification v2 forTrialPackage fixed-top position-absolute gracePeriodRemainingDays"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "mr-4",
    staticStyle: {
      "margin": "-5px"
    },
    attrs: {
      "icon": "trialPackageWarning-icon",
      "width": "25px",
      "height": "20px"
    }
  }), _c('span', {
    staticStyle: {
      "line-height": "1.1",
      "font-size": "15px"
    }
  }, [_vm._v("Paid your invoice otherwise your account will be suspended after " + _vm._s(_vm._f("get_property")(_vm.$store.state.common.current_subscription, 'grace_period_remaining_days')) + " days")])], 1), _c('b-icon', {
    attrs: {
      "icon": "x-circle-fill",
      "variant": "black",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.grace_period = false;
      }
    }
  })], 1) : _vm._e(), _vm.conditions.offline ? _c('div', {
    class: `dialer-offline-notification v2 fixed-top position-absolute socketNotConnected-small`
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "mr-4",
    staticStyle: {
      "margin-top": "-3px"
    },
    attrs: {
      "icon": "offline-icon",
      "width": "20px",
      "height": "15px"
    }
  }), _c('span', {
    staticStyle: {
      "line-height": "1.1",
      "font-size": "15px"
    }
  }, [_vm._v("Oops! You are offline!")])], 1), _c('b-icon', {
    attrs: {
      "icon": "x-circle-fill",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.offline = false;
      }
    }
  })], 1) : _vm._e(), _vm.conditions.sip_disconnected ? _c('div', {
    class: `dialer-offline-notification v2 fixed-top position-absolute socketNotConnected-large`
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "mr-4",
    staticStyle: {
      "margin-top": "-3px"
    },
    attrs: {
      "icon": "offline-icon",
      "width": "20px",
      "height": "15px"
    }
  }), _c('span', {
    staticStyle: {
      "line-height": "1.1",
      "font-size": "15px"
    }
  }, [_vm._v("Oh no! we have lost server connection. It will reconnect automatically, but you can refresh by clicking here.")]), _c('div', {
    staticClass: "indicator-light v2 ml-4",
    attrs: {
      "id": "indicator-light-tooltip"
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "latest-reload-icon",
      "width": "16px",
      "height": "16px"
    },
    on: {
      "click": function ($event) {
        return _vm.reload();
      }
    }
  })], 1)], 1), _c('b-icon', {
    attrs: {
      "icon": "x-circle-fill",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.sip_disconnected = false;
      }
    }
  })], 1)]) : _vm._e()] : _vm._e(), _c('DetectedSpeed', {
    on: {
      "network-type": function ($event) {
        _vm.network.type = $event;
      },
      "network-speed": function ($event) {
        _vm.network.downlink = $event;
      }
    }
  }), !_vm.getCurrentUser.is_number_purchased ? _c('div', {
    staticClass: "toast custome-toast-class opacity-1"
  }, [_c('div', {
    staticClass: "toast-body custome-toast-body-class"
  }, [_c('div', {
    staticClass: "new-error-template-2-1 v2"
  }, [_c('div', {
    staticClass: "error-message-new"
  }, [_c('div', {
    staticClass: "beta-container z-index-0-imp"
  }, [_c('div', {
    staticClass: "beta-text"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "i-container"
  }, [_c('svg', {
    staticClass: "bi-bell-fill b-icon bi text-primary",
    staticStyle: {
      "font-size": "200%"
    },
    attrs: {
      "viewBox": "0 0 16 16",
      "width": "1em",
      "height": "1em",
      "focusable": "false",
      "role": "img",
      "aria-label": "bell fill",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  }, [_c('g', [_c('path', {
    attrs: {
      "d": "M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"
    }
  })])])]), _c('div', {
    staticClass: "pr-3 word-break"
  }, [_vm._v("Assigning number to your account is in progress")])])])])])])])]) : _vm._e(), !!_vm.$store.state.us_number_progress ? _c('div', {
    staticClass: "toast custome-toast-class opacity-1"
  }, [_c('div', {
    staticClass: "toast-body custome-toast-body-class"
  }, [_c('div', {
    staticClass: "new-error-template-2-1 v2"
  }, [_c('div', {
    staticClass: "error-message-new"
  }, [_c('div', {
    staticClass: "beta-container z-index-0-imp"
  }, [_c('div', {
    staticClass: "beta-text"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "i-container"
  }, [_c('svg', {
    staticClass: "bi-bell-fill b-icon bi text-primary",
    staticStyle: {
      "font-size": "200%"
    },
    attrs: {
      "viewBox": "0 0 16 16",
      "width": "1em",
      "height": "1em",
      "focusable": "false",
      "role": "img",
      "aria-label": "bell fill",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  }, [_c('g', [_c('path', {
    attrs: {
      "d": "M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"
    }
  })])])]), _c('div', {
    staticClass: "pr-3 word-break"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.$store.state.us_number_progress)) + " is in progress")])])])])])])])]) : _vm._e(), _c('audio', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "id": "alert-ringtone",
      "src": _vm._f("get_property")(_vm.media_devices_setting.selectedAlertRingtone, 'file')
    }
  }), _c('ExtraResourcesAlertModal'), _c('FeedbackModal', {
    attrs: {
      "modalName": "FeedBackCommonComponentModal"
    },
    on: {
      "submitted": function ($event) {
        _vm.conditions.feed_back = false;
      },
      "closed": function ($event) {
        '';
      }
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }