<template>
  <modal class="CallTransferModal" :name="modalName">
    <div class="dialer-edit-header d-flex w-100 justify-content-between align-items-center">
      <div>
        <h2 class="dialer-settings-title newer mb-0">Transfer call to</h2>
      </div>
      <div class="dialer-edit-actions">
        <button class="newCloseButton" @click="$modal.hide(modalName)">
          <vb-icon icon="squared-close-icon" height="38px" width="38px" />
          <span class="newCloseButton-textPart">Close</span>
        </button>
      </div>
    </div>
    <div class="mt-20px">
      <div class="whiteBGinputWithGreyRoundedBorder">
        <b-input placeholder="Enter number, user name or extension" @keyup.enter="dial()" v-model="number" @input="selected_id=''" />
      </div>
      <div ref="drop-down">
        <div class="latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px p-3" style="--total: 90vh; --minusValue: 275px;">
          <ul class="whiteBoxWithBorderInsideGreyBox px-3 list-unstyled mb-0">
            <template v-if="searchNumber.length == 0">
              <li class="whiteBoxWithBorderInsideGreyBox-row min-height-none flex-column align-items-center py-3">
                <vb-icon icon="noDataFound-v2" width="57" height="63" />
                <span>
                  <b>Enter number, user name or extension</b>
                </span>
              </li>
            </template>
            <template v-else>
              <li v-for="(data, index) in searchNumber" :key="index" @click="selectContact(data)" class="whiteBoxWithBorderInsideGreyBox-row min-height-none py-3">
                <Info :is_blf="false" :name="data.name" :sub_info="data.number" :image="data.image" /> 
              </li>
            </template>
          </ul>
        </div>
      </div>
      <div class="d-flex justify-content-between mt-auto">
        <button :disabled="!isValidNumber" type="button" :class="`d-flex dialer-button md-mar-top dialer-button-primary ${ !isValidNumber ? 'disabled' : '' }`" @click="transfer('soft')">Soft Transfer</button>
        <button :disabled="!isValidNumber" type="button" :class="`d-flex dialer-button md-mar-top dialer-button-primary ${ !isValidNumber ? 'disabled' : '' }`" @click="transfer('hard')">Hard Transfer</button>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Info from '@/components/Lists/Info.vue';
import { number_formater } from '@/filter';
import { AsYouType } from 'libphonenumber-js';
import { parsePhoneNumber } from 'libphonenumber-js/max';
export default {
  name: 'CallTransferModal',
  components: {
    Info,
  },
  props: {
    modalName: {
      type: String,
      default: 'CallTransfer'
    },
    phones: {
      type: Object,
      default: () => ({}),
      require: true,
    },
    session: {
      type: Object,
      default: () => ({}),
      require: true,
    },
  },
  data() {
    return {
      number: '',
      selected_id: '',
    }
  },
  watch: {
    "number"(number) { 
      this.$nextTick()
      .then(()=>{
        if(this.searchNumber.length==0 && [/[0-9]/, /^[ 0-9+]*$/].every(item=>item.test(number.replace(/ /g,'') || ''))){
          this.number = new AsYouType(this.getCurrentUser.country).input(number)
        }
      })
    },
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getVoipTeams',
      'getVoipCallQueues',
      'getVoipIVRS',
      'getAddressbooksNumbers',
    ]),
    searchedList(){
      return [
        ...Object.values(this.$store.state.common.voipusers),
        ...this.getVoipTeams,
        ...this.getVoipCallQueues,
        ...Object.values(this.getVoipIVRS),
        ...this.getAddressbooksNumbers,
      ].map((data) => {
        let result = {
          id: '',
          name: '',
          image: '',
          number: '',
          type: '',
          searched_text: '',
        }
        if (data?.object=="PhoneBook") { // addressbook
          result.id=data?.main_phone?.uid || ''
          result.name=data?.name || ''
          result.image=data?.profile_image || ''
          result.number=data?.main_phone?.formated_number || ''
          result.type='addressbook'
          result.searched_text=`${data?.name || ""} ${data?.main_phone?.number || ""} ${data?.main_phone?.formated_number || ""} ${data?.main_phone?.shortCode || ""} ${data?.main_phone?.dialShortCode || ""} ${data?.nickname || ""}`
        } else if(data?.object == 'Teams'){ // team
          result.id=data?.ringroup?.accountcode || ''
          result.name=data?.name || ''
          result.image=data?.avatar?.image?.image || ''
          result.number=data?.ringroup?.extn || ''
          result.type='team'
          result.searched_text=`${data?.name || ""} ${data?.ringroup?.extn || ""}`
        } else if(data?.object == 'CallQueue'){ // call_queue
          result.id=data?.voipaccount || ''
          result.name=data?.label || ''
          result.image=data?.avatar?.image?.image || ''
          result.number=data?.extn || ''
          result.type='call_queue'
          result.searched_text=`${data?.label || ""} ${data?.extn || ""}`
        } else if(data?.schedules){ // ivr
          result.id=data?.voipaccount || ''
          result.name=data?.label || ''
          result.image=data?.avatar?.image?.image || ''
          result.number=data?.extn || ''
          result.type='ivr'
          result.searched_text=`${data?.label || ""} ${data?.extn || ""}`
        } else if(data?.user?.display_name){ // user
          result.id=data?.voipaccount || ''
          result.name=data?.user?.display_name || ''
          result.image=data?.profile_img || ''
          result.number=data?.extn || ''
          result.type='user'
          result.searched_text=`${data?.user?.display_name || ""} ${data?.extn || ""}`
        }
        return result
      });
    },
    searchNumber() {
      const search = this.number.toLowerCase().trim();
      const user = this.getCurrentUser
      if (!search) return [];
      return this.searchedList.filter(function(data) {
        if(this.count >= 5 || data.id==user.account) return false
        else if(data.searched_text.toLowerCase().trim().includes(search)) {
          this.count++
          return true
        }
        else return false
      },{
        count: 0
      });
    },
    isValidNumber() {
      try {
        if(this.selected_id){
          return true
        } else {
          const phoneNumber = parsePhoneNumber(this.number,this.getCurrentUser.country)
          return !!phoneNumber?.isValid?.()
        }
      } catch (ex) {
        return false
      }
    },
  },
  methods: {
    selectContact(data){
      this.number=number_formater(data.number,'',true);
      this.selected_id=data.id
    },
    async transfer(type) {
      if (!this.isValidNumber) {
        this.appNotify({
          message: 'invalid number',
          type: 'danger',
        })
        return;
      }
      let number = this.number
      if(number==this.getCurrentUser.extn){
        this.appNotify({
          message: 'can not transfer call to own self',
          type: 'danger',
        })
        return;
      }
      if(number==this.session.number){
        this.appNotify({
          message: 'can not transfer call to the user which you are talking right now',
          type: 'danger',
        })
        return;
      }
      if(type=='hard') {
        await this.session.transferByAPI({
          number: number,
          type: 'blind'
        })
        // number = `##${number}`
        // for (let index = 0; index < number.length; index++) {
        //   const num = number[index];
        //   this.phones.sendDTMF(num);
        // }
      } else {
        await this.session.transferByAPI({
          number: number,
          type: 'attended'
        })
        // this.phones.customBeforeTransfer(number,{
        //   is_transfer: true,
        //   uuid,
        // });
        // this.$store.state.calls.active_call=uuid
        // this.$emit('tranfered',this.form.number)
        // // this.phones.transfer(this.form.number);
        // this.$modal.hide(this.modalName);
      }
      this.$modal.hide(this.modalName);
    },
  },
}
</script>

<style>

</style>