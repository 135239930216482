<template>
  <modal class="ViewContactInfoModal schedulesModal" :name="modalName" @before-close="onBeforeClose()" @before-open="onBeforeOpen($event)">
    <div class="dialer-assign-to md-pad-bottom dialer-edit">
      <div class="dialer-edit-header d-flex w-100 justify-content-between align-items-center">
        <div>
          <h2 class="dialer-settings-title newer mb-0">Update schedule</h2>
        </div>
        <div class="dialer-edit-actions">
          <button class="newCloseButton" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <!-- <div v-if="api.get_info.send">Loading...</div> -->
        <template v-if="api.get_info.send">
          <!-- Loading... -->
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-none py-3" v-for="n in 10" :key="n">
              <div class="latestShimmerDesign w-100" style="height:25px;"></div>
            </div>
          </div>
        </template>
        <div v-else>
          <b-form @submit.prevent="updateSchedule()">
            <div class="whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionLeftAligned">
              <label>Select date and time:</label>
              <vue-ctk-date-time-picker v-bind="ctkDateTimePickerOptions" format="MM/DD/YYYY hh:mm a"  class="date-time-input" v-model="forms.update_schedule.schedule" />
            </div>
            <b-button class="mt-20px fullWidthDoneButton" type="submit">Submit</b-button>
          </b-form>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import { ctkDateTimePicker } from '@/mixin';
import moment from 'moment-timezone';
export default {
  name: 'PowerDialerContactUpdateScheduleModal',
  mixins: [
    ctkDateTimePicker
  ],
  inject: [
    'appNotify'
  ],
  props: {
    modalName: {
      type: String,
      default: 'PowerDialerContactUpdateSchedule'
    },
  },
  data() {
    return {
      api: {
        get_info: this.$helperFunction.apiInstance({  }), 
        update_schedule: this.$helperFunction.apiInstance({  }), 
      },
      forms: {
        update_schedule: this.$helperFunction.formInstance({
          data: {
            schedule: '',
          },
        })
      },
      data: {
        sheet_id: '',
        row_no: '',
      },
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
  },
  methods: {
    onBeforeOpen(event){
      this.data.sheet_id=event?.params?.sheet_id || ''
      this.data.row_no=event?.params?.row_no || ''
      this.fetchInfo()
    },
    onBeforeClose(){
      this.api.get_info.reset()
      this.api.update_schedule.reset()
      this.forms.update_schedule.reset()
      this.data.sheet_id=''
      this.data.row_no=''
    },
    async updateSchedule(){
      if(this.api.update_schedule.send) return;
      try {
        this.api.update_schedule.send=true
        await VOIP_API.endpoints.power_dialer.updateSchedule({
          sheet_id: this.data.sheet_id,
          row_no: this.data.row_no,
          schedule: this.forms.update_schedule.schedule,
          account: this.getCurrentUser.account,
        })
        this.$emit('updated')
        this.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
        this.$modal.hide(this.modalName)
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.update_schedule.send=false
      }
    },
    async fetchInfo(){
      if(this.api.get_info.send) return;
      try {
        this.api.get_info.send=true
        const { data } = await VOIP_API.endpoints.power_dialer.getNextNumber(this.data.sheet_id,{
          row_no: this.data.row_no
        })
        this.forms.update_schedule.schedule=data?.data?.scheduled_date ? moment(data?.data?.scheduled_date,'YYYY-MM-DD hh:mm:ss').format('MM/DD/YYYY hh:mm a') : ''
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.get_info.send=false
      }
    },
  },
}
</script>

<style>

</style>