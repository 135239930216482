<template>
  <div>
    <modal class="dialer_animation right_side_popup " width = "600px" height="auto" :scrollable="true" @before-open="onBeforeOpen($event)" :name="modalName">
      <div class="dialer-assign-to my-4 dialer-edit">
        <div class="dialer-edit-actions d-flex justify-content-end">
          <button class="newCloseButton" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <div class="latestGreyBox-heading-main">Menu option</div>
        <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
          <MenuOptionsWidget v-if="ivr" :menu="ivr" @updated="$emit('options-updated')" />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import MenuOptionsWidget from '../widgets/ivr/MenuOptionsWidget.vue';
import { mapGetters } from 'vuex';
export default {
  name: "IvrGraphMenuOptionsModal",
  components:{
    MenuOptionsWidget,
  },
  inject: [
    'appNotify',
  ],
  props: {
    modalName: {
      type: String,
      default: "IvrGraphMenuOptionsModal",
    },
  },
  data(){
    return{
      data: {
        accountcode: '',
      },
    }
  },
  computed: {
    ...mapGetters([
      'getVoipIVRS',
    ]),
    ivr(){ return this.getVoipIVRS?.[this?.data?.accountcode] ?? null },
  },
  methods: {
    async onBeforeOpen(event){
      this.data.accountcode=event?.params?.accountcode
    },
  }
};
</script>
