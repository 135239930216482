var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: `dialer-analytics-main dialer-analytics-likeSomeThing ${_vm.conditions.full_data ? 'itsExpandedNow' : ''}`
  }, [_c('div', {
    staticClass: "dialer-box"
  }, [_vm.getIsMobile ? [_c('header', {
    staticClass: "dialer-analytics-titleBar pb-1"
  }, [_c('div', {
    staticClass: "dialer-analytics-title calc-title-width-analytics"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h1', {}, [_vm._v("Call activity")])], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion userTeamsFilter optionIsdotted filterWithText ml-auto"
  }, [_c('b-dropdown', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b', {
          staticClass: "text-right"
        }, [_vm._v("Options")]), _c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-icon', {
          staticClass: "fillBlack sm-mar-left",
          attrs: {
            "icon": "three-dots-vertical",
            "variant": "dark",
            "scale": "1.5"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 3396696157)
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-ReportingFilterModal`);
      }
    }
  }, [_vm._v("Filter")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.filters.reports.reset();
        _vm.fetchReports();
      }
    }
  }, [_vm._v("Reset filter")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.conditions.full_data = !_vm.conditions.full_data;
      }
    }
  }, [_vm._v(_vm._s(_vm.conditions.full_data ? 'Basic' : 'Expand'))]), _c('b-dropdown-item', {
    attrs: {
      "disabled": _vm.api.export_data.send
    },
    on: {
      "click": function ($event) {
        return _vm.exportData();
      }
    }
  }, [_vm._v("Export")])], 1)], 1), _vm._m(0)])] : [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v(" Call activity ")]), _c('p', {
    staticClass: "dialer-settings-intro newerTextVersion mb-0"
  }, [_c('span', [_vm._v("Here’s where you can monitor calls and users.")]), _c('a', {
    staticClass: "w-auto billedCallsLink",
    staticStyle: {
      "text-decoration": "underline",
      "font-size": "16px",
      "color": "black",
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('change-tab', 'Billing');
      }
    }
  }, [_vm._v("Billed calls")])]), _c('div', {
    staticClass: "d-flex w-100 justify-content-end mt-20px"
  }, [_c('b-button', {
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-ReportingFilterModal`);
      }
    }
  }, [_vm._v("Filter")]), _c('b-button', {
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        _vm.filters.reports.reset();
        _vm.fetchReports();
      }
    }
  }, [_vm._v("Reset filter")]), _c('b-button', {
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('someFullScreenModal');
      }
    }
  }, [_vm._v("Expand")]), _c('b-button', {
    staticClass: "newButton",
    attrs: {
      "disabled": _vm.api.export_data.send
    },
    on: {
      "click": function ($event) {
        return _vm.exportData();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-exportFile-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Export")])], 1)], 1)], _c('div', {
    class: `latestGreyBox-9-9-2023 pt-0 mt-20px border-top-0 ${_vm.conditions.full_data ? 'overflow-X-Table' : ''}`
  }, [_c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again mt-0",
    attrs: {
      "horizontalScrollVersion": true,
      "noRecordClass": `noDataInTable`,
      "textNoRecord": "No record found",
      "isListEmpty": _vm.response.reports.length == 0,
      "loading": _vm.api.fetch_reports.send,
      "listLength": _vm.paginations.reports.total,
      "perPage": _vm.paginations.reports.per_page,
      "conditions": {
        pagination: {
          per_page: false,
          page_input: true
        }
      },
      "page": _vm.paginations.reports.page
    },
    on: {
      "page-changed": function ($event) {
        _vm.paginations.reports.page = $event;
        _vm.fetchReportsDebounce();
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_vm.api.fetch_reports.send ? _vm._l(6, function (n) {
          return _c('tr', {
            key: n.id
          }, [_vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })])]);
        }) : _vm._l(_vm.response.reports, function (report) {
          return _c('tr', {
            key: report.id
          }, [_c('td', {
            staticClass: "td-border-right"
          }, [report.status == 'Missed' ? _c('vb-icon', {
            staticClass: "mr-2",
            attrs: {
              "icon": "analyticsReporting-missedCall-icon",
              "height": "13.922",
              "width": "13.922"
            }
          }) : report.status == 'Received' ? _c('vb-icon', {
            staticClass: "mr-2",
            attrs: {
              "icon": "analyticsReporting-receiveCall-icon",
              "height": "13.922",
              "width": "13.922"
            }
          }) : _c('vb-icon', {
            staticClass: "mr-2",
            attrs: {
              "icon": "analyticsReporting-dialledCall-icon",
              "height": "13.922",
              "width": "13.922"
            }
          }), _vm._v(" " + _vm._s(_vm._f("filter_call_info")(report, 'call_type')) + " "), _c('br'), !_vm.conditions.full_data ? _c('span', {
            staticClass: "callReportingTimeSubText"
          }, [_vm._v(_vm._s(_vm._f("showTime")(report.date, '-', true)))]) : _vm._e()], 1), _vm.conditions.full_data ? _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("showDashIfNoValue")(report.call_direction)))]) : _vm._e(), _vm.conditions.full_data ? _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("showDashIfNoValue")(_vm._f("filter_call_info")(report, 'user'))))]) : _vm._e(), _vm.conditions.full_data ? _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("showDashIfNoValue")(_vm._f("filter_call_info")(report, 'team'))))]) : _vm._e(), _vm.conditions.full_data ? _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("showDashIfNoValue")(_vm._f("filter_call_info")(report, 'ivr'))))]) : _vm._e(), _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm._f("filter_call_info")(report, 'from'))))]), _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm._f("filter_call_info")(report, 'to'))))]), _vm.conditions.full_data ? _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("showDashIfNoValue")(_vm._f("number_formater")(_vm._f("filter_call_info")(report, 'customer_number')))))]) : _vm._e(), _vm.conditions.full_data ? _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("showDashIfNoValue")(_vm._f("number_formater")(_vm._f("filter_call_info")(report, 'voip_number')))))]) : _vm._e(), _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("showDashIfNoValue")(report.bill_sec)))]), _vm.conditions.full_data ? _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("showDashIfNoValue")(report.call_duration)))]) : _vm._e(), _vm.conditions.full_data ? _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("showDashIfNoValue")(report.waiting_time)))]) : _vm._e(), _c('td', {
            staticClass: "call_history analyticsReportAudio td-border-right"
          }, [report.soundFile ? _c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('vb-audio-player', {
            attrs: {
              "design": 'minimum',
              "src": report.soundFile
            }
          }), _c('vb-icon', {
            staticClass: "download_icon ml-3",
            staticStyle: {
              "cursor": "pointer"
            },
            attrs: {
              "icon": "callAnalytics-reporting-download-icon",
              "height": "17px",
              "width": "17px",
              "variant": "primary"
            },
            on: {
              "click": function ($event) {
                _vm.api.download_audio.send ? '' : _vm.download(report.soundFile);
              }
            }
          }), _c('vb-icon', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip"
            }],
            staticClass: "ml-3",
            staticStyle: {
              "cursor": "pointer"
            },
            attrs: {
              "title": _vm.getUserPermissions.call_recording ? '' : 'Your package does not support this feature',
              "icon": "ai-audio-icon",
              "width": "21.507px",
              "height": "23.377px"
            },
            on: {
              "click": function ($event) {
                !_vm.getUserPermissions.call_recording ? '' : _vm.$modal.show(`${_vm._uid}-RecordingTranscript`, {
                  uniq_id: report.uniqueid,
                  sound_file: report.soundFile
                });
              }
            }
          })], 1) : [_c('span', {
            staticClass: "text-center"
          }, [_vm._v(" - ")])]], 2), _vm.conditions.full_data ? _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("filter_call_info")(report, 'country_code')))]) : _vm._e(), _vm.conditions.full_data ? _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("filter_call_info")(report, 'answered')))]) : _vm._e(), _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("showTime")(report.date)))]), _vm.conditions.full_data ? _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("filter_call_info")(report, 'start_time')))]) : _vm._e(), _vm.conditions.full_data ? _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("filter_call_info")(report, 'end_time')))]) : _vm._e(), _vm.conditions.full_data ? _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("showDashIfNoValue")(_vm._f("filter_call_info")(report, 'extension'))))]) : _vm._e(), _vm.conditions.full_data ? _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("filter_call_info")(report, 'tags')))]) : _vm._e(), _vm.conditions.full_data ? _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("filter_call_info")(report, 'notes')))]) : _vm._e(), _vm.conditions.full_data ? _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(" " + _vm._s(_vm._f("get_property")(report, 'call_feed_back.stars', 0)) + " "), _c('b-icon', {
            attrs: {
              "icon": "star-fill",
              "variant": "warning"
            }
          })], 1) : _vm._e(), _vm.conditions.full_data ? _c('td', [_vm._v(_vm._s(_vm._f("showDashIfNoValue")(report.call_id)))]) : _vm._e()]);
        })];
      },
      proxy: true
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(6, function (n) {
          return _c('tr', {
            key: n.id
          }, [_vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _vm.conditions.full_data ? _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]) : _vm._e(), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })])]);
        });
      },
      proxy: true
    }])
  }, [_c('tr', {
    staticClass: "w-100",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("Call Type")])]), _vm.conditions.full_data ? _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("Call Direction")])]) : _vm._e(), _vm.conditions.full_data ? _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("User")])]) : _vm._e(), _vm.conditions.full_data ? _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("team")])]) : _vm._e(), _vm.conditions.full_data ? _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("ivr")])]) : _vm._e(), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("From ")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("To")])]), _vm.conditions.full_data ? _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("customer number")])]) : _vm._e(), _vm.conditions.full_data ? _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("Voip Business Number")])]) : _vm._e(), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("In-call duration")])]), _vm.conditions.full_data ? _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("duration (total)")])]) : _vm._e(), _vm.conditions.full_data ? _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("waiting time")])]) : _vm._e(), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("recordings")])]), _vm.conditions.full_data ? _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("country code")])]) : _vm._e(), _vm.conditions.full_data ? _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("answered")])]) : _vm._e(), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("Date")])]), _vm.conditions.full_data ? _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("call start time")])]) : _vm._e(), _vm.conditions.full_data ? _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("call end time")])]) : _vm._e(), _vm.conditions.full_data ? _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("extension")])]) : _vm._e(), _vm.conditions.full_data ? _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("tags")])]) : _vm._e(), _vm.conditions.full_data ? _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("notes")])]) : _vm._e(), _vm.conditions.full_data ? _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("call quality")])]) : _vm._e(), _vm.conditions.full_data ? _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("call id")])]) : _vm._e()])])], 1), _c('modal', {
    staticClass: "someFullScreenModal",
    attrs: {
      "name": "someFullScreenModal"
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header pt-0 pb-0 position-relative"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Call reporting detail")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('someFullScreenModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "d-flex w-100 justify-content-end mt-20px"
  }, [_c('b-button', {
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-ReportingFilterModal`);
      }
    }
  }, [_vm._v("Filter")]), _c('b-button', {
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        _vm.filters.reports.reset();
        _vm.fetchReports();
      }
    }
  }, [_vm._v("Reset filter")]), _c('b-button', {
    staticClass: "newButton",
    attrs: {
      "disabled": _vm.api.export_data.send
    },
    on: {
      "click": function ($event) {
        return _vm.exportData();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-exportFile-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Export")])], 1)], 1), _c('div', {
    class: `latestGreyBox-9-9-2023 pt-0 mt-20px border-top-0 overflow-X-Table`
  }, [_c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again mt-0",
    attrs: {
      "horizontalScrollVersion": true,
      "noRecordClass": `noDataInTable`,
      "textNoRecord": "No record found",
      "isListEmpty": _vm.response.reports.length == 0,
      "loading": _vm.api.fetch_reports.send,
      "listLength": _vm.paginations.reports.total,
      "perPage": _vm.paginations.reports.per_page,
      "conditions": {
        pagination: {
          per_page: false,
          page_input: true
        }
      },
      "page": _vm.paginations.reports.page
    },
    on: {
      "page-changed": function ($event) {
        _vm.paginations.reports.page = $event;
        _vm.fetchReports();
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_vm.api.fetch_reports.send ? _vm._l(6, function (n) {
          return _c('tr', {
            key: n.id
          }, [_c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })])]);
        }) : _vm._l(_vm.response.reports, function (report) {
          return _c('tr', {
            key: report.id
          }, [_c('td', {
            staticClass: "td-border-right"
          }, [report.status == 'Missed' ? _c('vb-icon', {
            staticClass: "mr-2",
            attrs: {
              "icon": "analyticsReporting-missedCall-icon",
              "height": "13.922",
              "width": "13.922"
            }
          }) : report.status == 'Received' ? _c('vb-icon', {
            staticClass: "mr-2",
            attrs: {
              "icon": "analyticsReporting-receiveCall-icon",
              "height": "13.922",
              "width": "13.922"
            }
          }) : _c('vb-icon', {
            staticClass: "mr-2",
            attrs: {
              "icon": "analyticsReporting-dialledCall-icon",
              "height": "13.922",
              "width": "13.922"
            }
          }), _vm._v(" " + _vm._s(_vm._f("filter_call_info")(report, 'call_type')) + " "), _c('br'), !_vm.conditions.full_data ? _c('span', {
            staticClass: "callReportingTimeSubText"
          }, [_vm._v(_vm._s(_vm._f("showTime")(report.date, '-', true)))]) : _vm._e()], 1), _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("showDashIfNoValue")(report.call_direction)))]), _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("showDashIfNoValue")(_vm._f("filter_call_info")(report, 'user'))))]), _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("showDashIfNoValue")(_vm._f("filter_call_info")(report, 'team'))))]), _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("showDashIfNoValue")(_vm._f("filter_call_info")(report, 'ivr'))))]), _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm._f("filter_call_info")(report, 'from'))))]), _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm._f("filter_call_info")(report, 'to'))))]), _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("showDashIfNoValue")(_vm._f("number_formater")(_vm._f("filter_call_info")(report, 'customer_number')))))]), _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("showDashIfNoValue")(_vm._f("number_formater")(_vm._f("filter_call_info")(report, 'voip_number')))))]), _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("showDashIfNoValue")(report.bill_sec)))]), _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("showDashIfNoValue")(report.call_duration)))]), _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("showDashIfNoValue")(report.waiting_time)))]), _c('td', {
            staticClass: "call_history analyticsReportAudio td-border-right"
          }, [report.soundFile ? _c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('vb-audio-player', {
            attrs: {
              "design": 'minimum',
              "src": report.soundFile
            }
          }), _c('vb-icon', {
            staticClass: "download_icon ml-3",
            staticStyle: {
              "cursor": "pointer"
            },
            attrs: {
              "icon": "callAnalytics-reporting-download-icon",
              "height": "17px",
              "width": "17px",
              "variant": "primary"
            },
            on: {
              "click": function ($event) {
                _vm.api.download_audio.send ? '' : _vm.download(report.soundFile);
              }
            }
          }), _c('vb-icon', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip"
            }],
            staticClass: "ml-3",
            staticStyle: {
              "cursor": "pointer"
            },
            attrs: {
              "title": _vm.getUserPermissions.call_recording ? '' : 'Your package does not support this feature',
              "icon": "ai-audio-icon",
              "width": "21.507px",
              "height": "23.377px"
            },
            on: {
              "click": function ($event) {
                !_vm.getUserPermissions.call_recording ? '' : _vm.$modal.show(`${_vm._uid}-RecordingTranscript`, {
                  uniq_id: report.uniqueid,
                  sound_file: report.soundFile
                });
              }
            }
          })], 1) : [_c('span', {
            staticClass: "text-center"
          }, [_vm._v(" - ")])]], 2), _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("filter_call_info")(report, 'country_code')))]), _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("filter_call_info")(report, 'answered')))]), _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(" " + _vm._s(_vm._f("showTime")(report.date)) + " ")]), _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("filter_call_info")(report, 'start_time')))]), _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("filter_call_info")(report, 'end_time')))]), _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("showDashIfNoValue")(_vm._f("filter_call_info")(report, 'extension'))))]), _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("filter_call_info")(report, 'tags')))]), _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(_vm._s(_vm._f("filter_call_info")(report, 'notes')))]), _c('td', {
            staticClass: "td-border-right"
          }, [_vm._v(" " + _vm._s(_vm._f("get_property")(report, 'call_feed_back.stars', 0)) + " "), _c('b-icon', {
            attrs: {
              "icon": "star-fill",
              "variant": "warning"
            }
          })], 1), _c('td', [_vm._v(_vm._s(_vm._f("showDashIfNoValue")(report.call_id)))])]);
        })];
      },
      proxy: true
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(6, function (n) {
          return _c('tr', {
            key: n.id
          }, [_c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })])]);
        });
      },
      proxy: true
    }])
  }, [_c('tr', {
    staticClass: "w-100",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("Call Type")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("Call Direction")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("User")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("team")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("ivr")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("From ")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("To")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("customer number")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("Voip Business Number")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("In-call duration")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("duration (total)")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("waiting time")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("recordings")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("country code")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("answered")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("Date")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("call start time")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("call end time")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("extension")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("tags")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("notes")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("call quality")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("call id")])])])])], 1)]), _c('modal', {
    staticClass: "callReportingFilterModal",
    attrs: {
      "name": `${_vm._uid}-ReportingFilterModal`
    },
    on: {
      "before-close": function ($event) {
        _vm.$v.filters.reports.$invalid ? (_vm.filters.reports.reset(), _vm.fetchReports()) : '';
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header pt-0 position-relative"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Filters")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.fetchReports();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "d-flex w-100"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pr-2"
  }, [_c('label', [_vm._v("Date")]), _c('vb-select', {
    staticClass: "w-100",
    attrs: {
      "options": Object.values(_vm.dates)
    },
    on: {
      "change": function ($event) {
        _vm.filters.reports.start_date = '';
        _vm.filters.reports.end_date = '';
      }
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function (_ref) {
        var option = _ref.option;
        return [_c('div', {
          staticClass: "d-flex align-content-center w-100 h-100"
        }, [_c('span', {
          staticClass: "p-0"
        }, [_vm._v(_vm._s(_vm._f("get_property")(option, 'text', 'Select date')))]), option ? _c('b-button', {
          staticClass: "align-content-center justify-content-center p-0 m-0 mr-2",
          staticStyle: {
            "line-height": "0"
          },
          attrs: {
            "variant": "link"
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "x",
            "scale": "1.5"
          },
          on: {
            "click": function ($event) {
              _vm.filters.reports.date = '';
            }
          }
        })], 1) : _vm._e()], 1)];
      }
    }, {
      key: "option",
      fn: function (_ref2) {
        var option = _ref2.option;
        return [_vm._v(" " + _vm._s(_vm._f("get_property")(option, 'text')) + " ")];
      }
    }, {
      key: "no-record",
      fn: function () {
        return [_vm._v("There is no date")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filters.reports.date,
      callback: function ($$v) {
        _vm.$set(_vm.filters.reports, "date", $$v);
      },
      expression: "filters.reports.date"
    }
  })], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pl-2"
  }, [_c('label', [_vm._v("Call type")]), _c('vb-select', {
    staticClass: "w-100",
    attrs: {
      "options": Object.values(_vm.callTypes)
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function (_ref3) {
        var option = _ref3.option;
        return [_c('div', {
          staticClass: "d-flex align-content-center w-100 h-100"
        }, [_c('span', {
          staticClass: "p-0"
        }, [_vm._v(_vm._s(_vm._f("get_property")(option, 'text', 'Select call type')))]), option ? _c('b-button', {
          staticClass: "align-content-center justify-content-center p-0 m-0 mr-2",
          staticStyle: {
            "line-height": "0"
          },
          attrs: {
            "variant": "link"
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "x",
            "scale": "1.5"
          },
          on: {
            "click": function ($event) {
              _vm.filters.reports.call_type = '';
            }
          }
        })], 1) : _vm._e()], 1)];
      }
    }, {
      key: "option",
      fn: function (_ref4) {
        var option = _ref4.option;
        return [_vm._v(" " + _vm._s(_vm._f("get_property")(option, 'text')) + " ")];
      }
    }, {
      key: "no-record",
      fn: function () {
        return [_vm._v("There is no date")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filters.reports.call_type,
      callback: function ($$v) {
        _vm.$set(_vm.filters.reports, "call_type", $$v);
      },
      expression: "filters.reports.call_type"
    }
  })], 1)]), _vm.filters.reports.date == _vm.dates.custom.value ? _c('div', {
    staticClass: "d-flex w-100 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder subSectionLeftAligned VueCtkDateTimePicker w-50 pr-2"
  }, [_c('label', [_vm._v("Start date")]), _c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "date-time-input",
    attrs: {
      "format": 'YYYY-MM-DD hh:mm:ss a',
      "formatted": 'YYYY-MM-DD hh:mm:ss a',
      "noLabel": false,
      "id": "start_time",
      "name": "start_time"
    },
    on: {
      "input": function ($event) {
        _vm.filters.reports.end_date = '';
      }
    },
    model: {
      value: _vm.filters.reports.start_date,
      callback: function ($$v) {
        _vm.$set(_vm.filters.reports, "start_date", $$v);
      },
      expression: "filters.reports.start_date"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false)), _vm.$v.filters.reports.start_date.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.filters.reports.start_date.required ? _c('span', [_vm._v("Start date is required")]) : _vm._e()]) : _vm._e()], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker w-50 pl-2"
  }, [_c('label', [_vm._v("End date")]), _c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "date-time-input",
    attrs: {
      "format": 'YYYY-MM-DD hh:mm:ss a',
      "formatted": 'YYYY-MM-DD hh:mm:ss a',
      "noLabel": false,
      "min-date": _vm.filters.reports.start_date,
      "id": "end_time",
      "name": "end_time"
    },
    model: {
      value: _vm.filters.reports.end_date,
      callback: function ($$v) {
        _vm.$set(_vm.filters.reports, "end_date", $$v);
      },
      expression: "filters.reports.end_date"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false)), _vm.$v.filters.reports.end_date.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.filters.reports.end_date.required ? _c('span', [_vm._v("End date is required")]) : _vm._e()]) : _vm._e()], 1)]) : _vm._e(), _c('div', {
    staticClass: "d-flex w-100 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pr-2"
  }, [_c('label', [_vm._v("VoIP Business numbers")]), _c('vb-select', {
    staticClass: "w-100",
    attrs: {
      "valueField": 'number',
      "textField": 'number',
      "options": _vm.voipnumbers
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function (_ref5) {
        var option = _ref5.option;
        return [_c('div', {
          staticClass: "d-flex align-content-center w-100 h-100"
        }, [_c('span', {
          staticClass: "p-0"
        }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm._f("get_property")(option, 'number', 'Select number'))))]), option ? _c('b-button', {
          staticClass: "align-content-center justify-content-center p-0 m-0 mr-2",
          staticStyle: {
            "line-height": "0"
          },
          attrs: {
            "variant": "link"
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "x",
            "scale": "1.5"
          },
          on: {
            "click": function ($event) {
              _vm.filters.reports.voip_number = '';
            }
          }
        })], 1) : _vm._e()], 1)];
      }
    }, {
      key: "option",
      fn: function (_ref6) {
        var option = _ref6.option;
        return [_vm._v(" " + _vm._s(_vm._f("number_formater")(_vm._f("get_property")(option, 'number'))) + " ")];
      }
    }, {
      key: "no-record",
      fn: function () {
        return [_vm._v("There is no number")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filters.reports.voip_number,
      callback: function ($$v) {
        _vm.$set(_vm.filters.reports, "voip_number", $$v);
      },
      expression: "filters.reports.voip_number"
    }
  })], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pl-2"
  }, [_c('label', [_vm._v("Number")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filters.reports.customer_number,
      expression: "filters.reports.customer_number"
    }, {
      name: "vb-input-number-formate",
      rawName: "v-vb-input-number-formate.not_start_plus",
      modifiers: {
        "not_start_plus": true
      }
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.filters.reports.customer_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filters.reports, "customer_number", $event.target.value);
      }
    }
  }), _vm.$v.filters.reports.customer_number.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.filters.reports.customer_number.validNumber ? _c('span', [_vm._v("Number is not valid")]) : _vm._e()]) : _vm._e()])]), _c('div', {
    staticClass: "d-flex w-100 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pr-2"
  }, [_c('label', [_vm._v("Users")]), _c('vb-select', {
    staticClass: "w-100",
    attrs: {
      "options": _vm.users,
      "textField": "user.display_name",
      "valueField": "voipaccount"
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function (_ref7) {
        var option = _ref7.option;
        return [_c('div', {
          staticClass: "d-flex align-content-center justify-content-between w-100 h-100"
        }, [option ? _c('Info', {
          attrs: {
            "id": option.voipaccount,
            "is_blf": false,
            "is_suspended_show": false,
            "type": 'user'
          }
        }) : _c('span', {
          staticClass: "p-0"
        }, [_vm._v("Select user")]), option ? _c('b-button', {
          staticClass: "align-content-center justify-content-center p-0 m-0 mr-2",
          staticStyle: {
            "line-height": "0"
          },
          attrs: {
            "variant": "link"
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "x",
            "scale": "1.5"
          },
          on: {
            "click": function ($event) {
              _vm.filters.reports.user = '';
            }
          }
        })], 1) : _vm._e()], 1)];
      }
    }, {
      key: "option",
      fn: function (_ref8) {
        var option = _ref8.option;
        return [_c('Info', {
          attrs: {
            "id": option.voipaccount,
            "is_blf": false,
            "is_suspended_show": false,
            "type": 'user'
          }
        })];
      }
    }, {
      key: "no-record",
      fn: function () {
        return [_vm._v("There is no user")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filters.reports.user,
      callback: function ($$v) {
        _vm.$set(_vm.filters.reports, "user", $$v);
      },
      expression: "filters.reports.user"
    }
  })], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pl-2"
  }, [_c('label', [_vm._v("Teams")]), _c('vb-select', {
    staticClass: "w-100",
    attrs: {
      "options": _vm.teams,
      "textField": "name",
      "valueField": "real_id"
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function (_ref9) {
        var option = _ref9.option;
        return [_c('div', {
          staticClass: "d-flex align-content-center justify-content-between w-100 h-100"
        }, [option ? _c('Info', {
          attrs: {
            "id": option.real_id,
            "is_blf": false,
            "is_suspended_show": false,
            "type": 'team'
          }
        }) : _c('span', {
          staticClass: "p-0"
        }, [_vm._v("Select team")]), option ? _c('b-button', {
          staticClass: "align-content-center justify-content-center p-0 m-0 mr-2",
          staticStyle: {
            "line-height": "0"
          },
          attrs: {
            "variant": "link"
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "x",
            "scale": "1.5"
          },
          on: {
            "click": function ($event) {
              _vm.filters.reports.team = '';
            }
          }
        })], 1) : _vm._e()], 1)];
      }
    }, {
      key: "option",
      fn: function (_ref10) {
        var option = _ref10.option;
        return [_c('Info', {
          attrs: {
            "id": option.real_id,
            "is_blf": false,
            "is_suspended_show": false,
            "type": 'team'
          }
        })];
      }
    }, {
      key: "no-record",
      fn: function () {
        return [_vm._v("There is no team")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filters.reports.team,
      callback: function ($$v) {
        _vm.$set(_vm.filters.reports, "team", $$v);
      },
      expression: "filters.reports.team"
    }
  })], 1)])])])], 2), _c('RecordingTranscriptModal', {
    attrs: {
      "modalName": `${_vm._uid}-RecordingTranscript`
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "dialer-settings-intro newerTextVersion mb-0"
  }, [_c('span', [_vm._v("Here’s where you can monitor calls and users.")])]);

}]

export { render, staticRenderFns }