var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: [_vm.themeClass, {
      'insideIntigrations': _vm.$store.getters.isIntegration
    }, {
      'powerDialerIsActive': _vm.isInCall && (_vm.$store.state.power_dialer.sheet_id || _vm.activeSession && _vm.activeSession.is_sip && _vm.activeSession.extra_data.power_dialer_contact)
    }],
    staticStyle: {
      "display": "flex",
      "flex-direction": "column"
    },
    attrs: {
      "id": "app"
    }
  }, [_c('DeveloperSetting'), _vm.isElectron ? _c('CommonElectron') : _vm._e(), _vm.is_loading ? _c('LoaderMain', {
    staticStyle: {
      "z-index": "999999999999999999999999999999999999999999999999999999999999999999999999"
    }
  }) : _vm._e(), _c('div', {
    class: `latestMobileLoading ${_vm.is_mobile_loading ? 'visible' : ''}`,
    staticStyle: {
      "z-index": "999999999999999999999999999999999999999999999999999999999999999999999999"
    }
  }, [_c('div', {
    staticClass: "latestMobileLoading-inner h-100"
  })]), _c('div', {
    class: `${_vm.$store.getters.isAppTourStarted ? 'fixWhenAppTourStarted custom-100vh' : ''}`
  }, [_c('router-view', {
    attrs: {
      "name": "login"
    }
  }), _c('div', {
    staticClass: "h-100"
  }, [_c('router-view')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }