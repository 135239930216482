<template>
  <div ref="addressBookContainer">
    <div v-if="conditions.screen==screens.add" class="dialer_contact_list allow_scroll add">
      <CreateOrUpdateAddressBook ref="create-addressbook" :creating="true" @created="conditions.screen=screens.list;fetchAddressBooks();">
        <template #top="slotProp">
          <div class="dialer_activity_box_header">
            <div class="d-flex align-items-center">
              <a class="goToPreviousScreen" @click="slotProp.api.create_addressbook.send ? '' : (conditions.screen=screens.list)">
                <vb-svg 
                  name="leftLongArrow-icon" 
                  width="20" 
                  height="12" 
                  viewBox="0 0 30 16" 
                  stroke-width="1" 
                  stroke="" 
                  fill="none" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                />
              </a>
              <h4 class="w-100 text-center">Add contact</h4>
            </div>
          </div>
        </template>
      </CreateOrUpdateAddressBook>
    </div>
    <div v-else-if="conditions.screen==screens.list">
      <div class="tab-pane active">
        <div class="dialer-tabs">
          <div class="dialer-tab-content">
            <div v-if="getIsMobile">
              <div class="inContacts-contacts-searchBar-container">
                <b-form class="updatingContactsAgain-contacts-searchBar-container" @submit.prevent="''">
                  <div class="updatingContactsAgain-contacts-searchBar">
                    <vb-icon icon="mobile-searchBar-icon-likeAirCall-icon" width="12.68px" height="12.67px"/>
                    <b-input v-model="filter.addressbooks.search" placeholder="Search"/>
                  </div>
                  <b-dropdown no-caret class="filterDD noPaddingInButton">
                    <template #button-content>
                      <vb-icon icon="mobile-funnel-icon-likeAirCall-icon" width="18.61px" height="18.61px" />
                    </template>
                    <b-dropdown-item @click="filter.addressbooks.type='all'">
                      <div class="d-flex align-items-center">
                        <b-icon v-if="filter.addressbooks.type=='all'" icon="check" class="mr-2" :style="`opacity:1;`"/>
                        <div>All</div>
                      </div>
                    </b-dropdown-item>
                    <b-dropdown-item @click="filter.addressbooks.type='local'">
                      <div class="d-flex align-items-center">
                        <b-icon v-if="filter.addressbooks.type=='local'" icon="check" class="mr-2" :style="`opacity:1;`"/>
                        <div>Local</div>
                      </div>
                    </b-dropdown-item>
                    <b-dropdown-item @click="filter.addressbooks.type='org'">
                      <div class="d-flex align-items-center">
                        <b-icon v-if="filter.addressbooks.type=='org'" icon="check" class="mr-2" :style="`opacity:1;`"/>
                        <div>Organization</div>
                      </div>
                    </b-dropdown-item>
                    <b-dropdown-item v-for="platform in response.integrateds" :key="platform.platform" @click="filter.addressbooks.type=platform.platform">
                      <div class="d-flex align-items-center">
                        <b-icon v-if="filter.addressbooks.type==platform.platform" icon="check" class="mr-2" :style="`opacity:1;`"/>
                        <div>{{ platform.platform }}</div>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                  <vb-icon 
                    @click="$store.state.global_conditions.add_addressbooks = true;" 
                    icon="mobile-plus-icon-likeAirCall-icon" 
                    style="cursor:pointer;" 
                    width="20.38px" 
                    height="20.38px"
                  />
                </b-form>
              </div>
              <div class="dialer_contact_list addressbook_list allow_scroll">
                <div class="dialer_contact_row" v-for="addressbook in filterAddressBooks" :key="addressbook.real_id">
                  <div class="updatingContactsAgain-contact-item">
                    <div class="d-flex">
                      <vb-avatar class="sm-mar-right" :image="addressbook | get_property('profile_image')" />
                      <div class="user-info">
                        <div class="user-name-box">
                          <span class="user-name">
                            <span>{{`${addressbook.firstName} ${addressbook.lastName || ''}`}}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <vb-spinner variant="primary" v-if="api.delete_addressbook.send==addressbook.real_id" />
                    <b-dropdown v-else varient="link" no-caret class="ico-filter">
                      <template v-slot:button-content>
                        <!-- <span class="speedial_code updateSpeedDialCodeAgain">{{ addressbook | get_property('main_phone.dialShortCode') }}</span> -->
                        <vb-icon icon="mobile-topBar-threeDots-likeAirCall-icon" class="mobile-topBar-threeDots-likeAirCall-icon" height="27.06px" width="6px" />
                      </template>
                      <b-dropdown-item @click="audioCall(addressbook)">
                        <b-icon icon="telephone-fill" />
                        <span class="ml-2">Call</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="setUpdate(addressbook)">
                        <b-icon icon="pencil-square" />
                        <span class="ml-2">Edit</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="deleteAddressBook(addressbook)">
                        <b-icon icon="trash-fill" />
                        <span class="ml-2">Delete</span>
                      </b-dropdown-item>
                      <b-dropdown-item :disabled="api.toggle_favourite.send.includes(addressbook.real_id)" @click="toggleFavourite(addressbook)">
                        <b-icon icon="star-fill"/>
                        <span class="ml-2">{{ addressbook.is_favourite ? 'Remove from favourites' : 'Add to favourites' }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <vb-loading v-if="api.fetch_addressbooks.send"  />
                <vb-no-record v-else-if="isEmpty(filterAddressBooks)" :text="api.fetch_addressbooks.send ? '' : 'There is no addressbook'" :design="3" />
              </div>
            </div>
            <div v-else>
              <div class="integrationFilterWithSearchBar-container mb-3">
                <b-form class="whiteBGinputWithGreyRoundedBorder flex-fill" @submit.prevent="''">
                  <b-input v-model="filter.addressbooks.search" placeholder="Search here"/>
                </b-form>
                <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD withCustomSvgCheck">
                  <b-dropdown no-caret right>
                    <template #button-content>
                      <b-icon icon="three-dots-vertical" font-scale="1.7"/>
                    </template>
                    <b-dropdown-item @click="filter.addressbooks.type='all'">
                      <div class="d-flex align-items-center">
                        <!-- <b-icon v-if="filter.addressbooks.type=='all'" icon="check" class="mr-2"/> -->
                        <vb-svg v-if="filter.addressbooks.type=='all'" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                        <div>All</div>
                      </div>
                    </b-dropdown-item>
                    <b-dropdown-item  @click="filter.addressbooks.type='local'">
                      <div class="d-flex align-items-center">
                        <!-- <b-icon v-if="filter.addressbooks.type=='local'" icon="check" class="mr-2"/> -->
                        <vb-svg v-if="filter.addressbooks.type=='local'" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                        <div>Local</div>
                      </div>
                    </b-dropdown-item>
                    <b-dropdown-item  @click="filter.addressbooks.type='org'">
                      <div class="d-flex align-items-center">
                        <!-- <b-icon v-if="filter.addressbooks.type=='org'" icon="check" class="mr-2"/> -->
                        <vb-svg v-if="filter.addressbooks.type=='org'" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                        <div>Organization</div>
                      </div>
                    </b-dropdown-item>
                    <b-dropdown-item v-for="platform in response.integrateds" :key="platform.platform"  @click="filter.addressbooks.type=platform.platform">
                      <div class="d-flex align-items-center">
                        <!-- <b-icon v-if="filter.addressbooks.type==platform.platform" icon="check" class="mr-2"/> -->
                        <vb-svg v-if="filter.addressbooks.type==platform.platform" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                        <div>{{ platform.platform }}</div>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div class="dialer-addContact cursor_pointer" @click="$store.state.global_conditions.add_addressbooks = true;">
                <vb-svg 
                  name="dialer-addUser-icon" 
                  width="23.401" 
                  height="18.295" 
                  viewBox="0 0 23.401 18.295" 
                  stroke-width="0" 
                  stroke="" 
                  fill="none" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                />
                <span>New contact</span>
              </div>
              <div class="dialer_contact_list addressbook_list allow_scroll">
                <div class="dialer_contact_row" v-for="addressbook in filterAddressBooks" :key="addressbook.real_id">
                  <div class="dialer_contact">
                    <div class="dialer_contact-imageContainer">
                      <vb-avatar :image="addressbook | get_property('profile_image')" />
                      <vb-icon v-if="addressbook.tags && addressbook.integration_ref_id" @click="openIntegrationContactDetail(addressbook)" :icon="addressbook.tags | integrationIcons" class="integrationType-icon" />
                    </div>
                    <div class="user-info">
                      <div class="user-name-box">
                        <span class="user-name">
                          <span>{{`${addressbook.firstName} ${addressbook.lastName || ''}`}}</span>
                          <span v-if="addressbook.is_favourite==1">
                            <b-icon icon="star-fill" variant="primary"/>
                          </span>
                        </span>
                        <div class="dialer_contact_hidden_area">
                          <div class="dialer_contact_connection_box">
                            <span class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute thereIsAnotherDropdownInside">
                              <vb-spinner variant="primary" v-if="api.delete_addressbook.send==addressbook.real_id" />
                              <b-dropdown v-else varient="link" no-caret class="ico-filter">
                                <template #button-content>
                                  <!-- <span class="speedial_code">{{ addressbook | get_property('main_phone.dialShortCode') }}</span> -->
                                  <b-icon icon="three-dots" scale="1.5" />
                                </template>
                                <b-dropdown-item @click="audioCall(addressbook)">
                                  <vb-svg 
                                    class="mr-2" 
                                    name="dialer-outlineCall-icon2" 
                                    width="16.501" 
                                    height="16.501" 
                                    viewBox="0 0 16.501 16.501" 
                                    stroke-width="0" 
                                    stroke="#005DFF" 
                                    fill="none" 
                                    stroke-linecap="round" 
                                    stroke-linejoin="round" 
                                  />
                                  <span>Call</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="setUpdate(addressbook)">
                                  <vb-svg 
                                    name="dialer-edit-icon" 
                                    width="16" 
                                    class="mr-2" 
                                    height="16" 
                                    viewBox="0 0 24 24" 
                                    stroke-width="1.2" 
                                    stroke="currentColor" 
                                    fill="none" 
                                    stroke-linecap="round" 
                                    stroke-linejoin="round" 
                                  />
                                  <span>Edit</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="deleteAddressBook(addressbook)">
                                  <vb-svg 
                                    class="dialer-icon dialer-delete-icon mr-2" 
                                    name="dialer-delete-icon" 
                                    width="16" 
                                    height="16" 
                                    viewBox="0 0 24 24" 
                                    stroke-width="1.2" 
                                    stroke="currentColor" 
                                    fill="none" 
                                    stroke-linecap="round" 
                                    stroke-linejoin="round" 
                                  />
                                  <span>Delete</span>
                                </b-dropdown-item>
                                <b-dropdown-form v-if="addressbook.global==0 && !addressbook.tags" @submit.stop.prevent="''">
                                  <b-form-group label-for="dropdown-form-email">
                                    <!-- label="Sync Integration" -->
                                    <b-form-select
                                      @change="syncContact(addressbook,$event)"
                                      :disabled="api.sync_contact.send"
                                      size="sm"
                                      v-model="addressbook.tags"
                                    >
                                      <!-- text-field="platform"
                                      value-field="platform"
                                      :options="response.integrateds" -->
                                      <template #first>
                                        <b-form-select-option :value="null" disabled>Sync integration</b-form-select-option>
                                      </template>
                                      <b-form-select-option :value="data.platform" v-for="data in response.integrateds" :key="data.platform">
                                        <span style="width: 16px;display: inline-block;"></span>{{data.platform}}
                                      </b-form-select-option>
                                    </b-form-select>
                                  </b-form-group>
                                </b-dropdown-form>
                                <b-dropdown-item :disabled="api.toggle_favourite.send.includes(addressbook.real_id)" @click="toggleFavourite(addressbook)">
                                  <b-icon class="mr-2" :icon="addressbook.is_favourite ? 'star-fill' : 'star'"/>
                                  <span>{{ addressbook.is_favourite ? 'Remove from favourites' : 'Add to favourites' }}</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="user-number">{{ addressbook | get_property('main_phone.number') | number_formater }}</div>
                    </div>
                  </div>
                </div>
                <template v-if="api.fetch_addressbooks.send">
                  <div v-for="n in 3" :key="n.id" class="d-flex mt-20px align-items-center w-100">
                    <div class="latestShimmerDesign mr-3" style="height:40px;width:40px;"></div>
                    <div class="d-flex flex-column flex-fill">
                      <div class="latestShimmerDesign" style="height:18px;width:50%;"></div>
                      <div class="latestShimmerDesign mt-1" style="height:18px;width:80%;"></div>
                    </div>
                  </div>
                </template>
                <div v-else-if="isEmpty(filterAddressBooks)" class="emptyScreenContainer mt-32px b-0 p-0">
                  <img width="50%" height="auto" v-if="GET_LOCAL_SETTING_IS_DARK_MODE" :src="require('@/assets/images/emptyScreenImages/darkMode/addressBook.png')"/>
                  <img width="50%" height="auto" v-else :src="require('@/assets/images/emptyScreenImages/addressBook.png')"/>
                  <div class="emptyScreenContainer-heading mt-32px">Explore address book</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="conditions.screen==screens.update" class="dialer_contact_list allow_scroll add">
      <CreateOrUpdateAddressBook ref="update-addressbook" :creating="false" :addressbookID="selected.updated_id" @updated="conditions.screen=screens.list;">
        <template #top="slotProps">
          <div class="dialer_activity_box_header">
            <div class="d-flex align-items-center justify-content-between">
              <a class="goToPreviousScreen" @click="slotProps.api.update_addressbook.send ? '' : conditions.screen=screens.list">
                <vb-svg 
                  name="leftLongArrow-icon" 
                  width="20" 
                  height="12" 
                  viewBox="0 0 30 16" 
                  stroke-width="1" 
                  stroke="" 
                  fill="none" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                />
              </a>
              <h4 class="w-100 text-center">Edit contact</h4>
            </div>
          </div>
        </template>
      </CreateOrUpdateAddressBook>
    </div>
    <SelectAddressBookNumberModal />
  </div>
</template>

<script>
import { events, VOIP_API, $fn } from "@/utils";
import { number_formater } from '@/filter';
import { mapGetters } from 'vuex';
import { GET_LOCAL_SETTING_IS_DARK_MODE } from '@/store/helper/getters';
import SelectAddressBookNumberModal from "@/components/Modals/SelectAddressBookNumberModal.vue";
import CreateOrUpdateAddressBook from "@/components/widgets/addressbook/CreateOrUpdate.vue";
const screens = {
  list: 'list',
  add: 'add',
  update: 'update',
}
const integrations = {
  hubspot: {
    name: 'HubSpot',
    icon: 'hubSpot-icon',
    desc: `V2 - HubSpot is an inbound marketing and sales platform that helps companies attract visitors, convert leads, and close customers.`,
    type: 'CRM',
    value: 'hubspot',
  },
  zoho: {
    name: 'ZOHO',
    icon: 'zoho-icon',
    desc: `End-to-end, fully customizable CRM solution for growing businesses and enterprises.`,
    type: 'CRM',
    value: 'zoho',
  },
  salesforce: {
    name: 'Sales Force',
    icon: 'salesForce-icon',
    desc: `Integrate all your phone calls & phone numbers into salesforce cloud. Get an integrated dialer & have your phone calls/text messages logged at the right place.`,
    type: 'CRM',
    value: 'salesforce',
  },
  copper: {
    name: 'Copper',
    icon: 'copper-icon',
    desc: `Copper organizes all your emails, calls, files, and notes for every contact—so you have a full view of that relationship history.`,
    type: 'CRM',
    value: 'copper',
  },
  pipedrive: {
    name: 'Pipedrive',
    icon: 'pipedrive-icon',
    desc: `Sync your pipedrive contacts. Create activities for your calls and text messages against your contacts and associated deals. Get click to call & text icons.`,
    type: 'CRM',
    value: 'pipedrive',
  },
  freshdesk: {
    name: 'Freshdesk',
    icon: 'freahdesk-icon',
    desc: `Sync your freshdesk contacts. Create activities for your calls and text messages against your contacts and associated deals. Get click to call & text icons.`,
    type: 'CRM',
    value: 'freshdesk',
  },
  intercom: {
    name: 'Intercom',
    icon: 'intercom-icon',
    desc: `Sync your intercom contacts. Create activities for your calls and text messages against your contacts and associated deals. Get click to call & text icons.`,
    type: 'CRM',
    value: 'intercom',
  },
  monday: {
    name: 'Monday',
    icon: 'intercom-icon',
    desc: `Sync your monday contacts. Create activities for your calls and text messages against your contacts and associated deals. Get click to call & text icons.`,
    type: 'CRM',
    value: 'monday',
  },
}
export default {
  name: "Addressbooks",
  components: {
    SelectAddressBookNumberModal,
    CreateOrUpdateAddressBook,
  },
  props: {
    openedFromMeetings: {
      type: Boolean,
      default: false
    }
  },
  inject: [
    'local_filess', 
    'isEmpty', 
    'getProperty',
    'getAddressBooks',
    'appNotify'
  ],
  data() {
    return {
      api: {
        fetch_addressbooks: this.$helperFunction.apiInstance(),
        delete_addressbook: this.$helperFunction.apiInstance({
          send: '',
        }),
        toggle_favourite: this.$helperFunction.apiInstance({
          send: [],
        }),
        fetch_integrated: this.$helperFunction.apiInstance(),
        sync_contact: this.$helperFunction.apiInstance(),
      },
      filter: {
        addressbooks: {
          search: '',
          type: 'all'
        },
      },
      response: {
        integrateds: [],
      },
      conditions: {
        screen: screens.list,
      },
      selected: {
        updated_id: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      GET_LOCAL_SETTING_IS_DARK_MODE,
      'getCurrentUser',
      'getIsMobile'
    ]),
    screens(){ return screens },
    integrateds(){ return this.response.integrateds?.map?.(i=>i.platform) ?? [] },
    filterAddressBooks(){
      const search = this.filter.addressbooks.search.toLowerCase().trim();
      const type = this.filter.addressbooks.type;
      return this.$store.state.common.address_books.data.filter((data) =>{
        let result = false
        if(type=='all') result = true
        else if(type=='local' && data.global==0 && !data.tags) result = true
        else if(type=='org' && data.global==1 && !data.tags) result = true
        else if(type==data.tags) result = true
        if(result && !`${data.firstName} ${data.lastName} ${data.all_numbers} ${data.all_formated_numbers}`.toLowerCase().trim().includes(search)) result = false
        return result
      });
    },
  },
  filters: {
    integrationIcons(value){return integrations[value]?.icon ?? ''},
  },
  watch: {
    "$store.state.global_conditions.add_addressbooks": {
      immediate: true,
      async handler(number){
        if (number) {
          this.conditions.screen=screens.add
          await $fn.sleep(1.5 * 1000)
          if(typeof number == 'string'){
            const international_number = number_formater(number,'',true)
            const phoneNumber = $fn.getParsePhoneNumber('')(international_number)
            if(phoneNumber && phoneNumber.isValid()){
              this.$refs['create-addressbook'].forms.create_addressbook.phones[0].number=phoneNumber.formatNational()
              this.$refs['create-addressbook'].forms.create_addressbook.phones[0].country=phoneNumber.country
            }
          }
        }
      },
    },
    "conditions.screen"(new_screen,old_screen){
      if(old_screen==screens.add) {
        this.$store.state.global_conditions.add_addressbooks=false
      }
    },
  },
  methods: {
    setUpdate(addressbook){
      let vm = this
      this.selected.updated_id=addressbook.real_id
      this.conditions.screen=screens.update
      this.$nextTick(()=>{
        vm.$refs['update-addressbook'].setUpdate()
      })
    },
    audioCall(addressbook) {
      if (this.api.delete_addressbook.send==addressbook.real_id) return;
      if (addressbook?.phones?.length < 2) {
        const number_data = addressbook.main_phone;
        if (!number_data) return;
        this.$root.$emit(events.audio_call, {
          number: number_data.number,
          id: number_data.uid,
          type: 'addressbook',
        });
      } else {
        this.$modal.show("SelectAddressBookNumberModal", {
          addressbook: addressbook,
        });
      }
    },
    async syncContact(addressbook,platform){
      if (this.api.sync_contact.send) return;
      try {
        this.api.sync_contact.send = true;
        await VOIP_API.endpoints.address_books.syncContact({
          id: addressbook.real_id,
          platform: platform,
        })
        this.fetchAddressBooks()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.sync_contact.send = false;
      }
    },
    async deleteAddressBook(addressbook) {
      const id = addressbook?.real_id ?? ''
      const is_global = addressbook?.global==1
      const name = `${addressbook?.firstName || ''} ${addressbook?.lastName || ''}`
      if (this.api.delete_addressbook.send || !id) return;
      try {
        this.api.delete_addressbook.send = addressbook.id;
        const { confirm } = await this.$appConfirmation({
          title: "warning",
          message: `Are you sure you want to delete this ${name} Contact`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        await VOIP_API.endpoints.address_books.delete({
          id: id,
          accountcode: this.getCurrentUser?.account,
          uid: this.getCurrentUser?.uid,
          flag: is_global ? 'global' : 'local',
        })
        this.$store.state.common.address_books.total = this.$store.state.common.address_books.total - 1
        this.$store.state.common.address_books.data = await this.$store.state.common.address_books.data.filter((item)=>item.real_id!=id);
        this.appNotify({
          message: "Successfully Deleted!",
          type: "success",
        })
        this.$root.$emit(events.fetch_call_activity)
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.delete_addressbook.send = '';
      }
    },
    async toggleFavourite(addressbook){
      const id = addressbook?.real_id ?? ''
      const is_favourite = addressbook?.is_favourite
      if(!id || this.api.toggle_favourite.send.includes(id)) return;
      try {
        this.api.toggle_favourite.send.push(id)
        await VOIP_API.endpoints.address_books.toggleFavourite({
          id: id,
          action: is_favourite ? 'remove' : 'add'
        })
        const index = await this.$store.state.common.address_books.data.findIndex((item)=>item.real_id==id);
        this.$store.state.common.address_books.data[index].is_favourite=~~!is_favourite;
        this.appNotify({
          message: `Successfully ${is_favourite ? 'Un Favourite' : 'Favourite'}!`,
          type: "success",
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.toggle_favourite.send.splice(this.api.toggle_favourite.send.indexOf(id),1)
      }
    },
    async fetchAddressBooks(){
      if(this.api.fetch_addressbooks.send) return;
      try {
        this.api.fetch_addressbooks.send=true
        await this.getAddressBooks()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_addressbooks.send=false
      }
    },
    async fetchIntegrated(){
      if(this.api.fetch_integrated.send) return;
      try {
        this.api.fetch_integrated.send=true
        const { data } = await VOIP_API.endpoints.integrations.integratedlist({
          accountcode: this.getCurrentUser.account
        })
        this.response.integrateds=data
      } catch(ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_integrated.send=false
      }
    },
    async openIntegrationContactDetail(addressbook){
      try {
        const { data: url } = await VOIP_API.endpoints.integrations.integratedContactDetailLink({
          accountcode: this.getCurrentUser.account,
          integration: addressbook.tags,
          contactId: addressbook.real_id,
        })
        if(!url) return;
        window.open(url,'_blank');
      } catch(ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_integrated.send=false
      }
    },
  },
  mounted(){
    this.fetchAddressBooks()
    this.fetchIntegrated()
    if(this.openedFromMeetings){
      this.conditions.screen = this.screens.add
    }
  }
};
</script>
