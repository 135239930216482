var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup EditMenuModal",
    attrs: {
      "width": "40%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_vm.data.accountcode ? _c('EditMenu', {
    attrs: {
      "accountcode": _vm.data.accountcode
    },
    on: {
      "click-graph": function ($event) {
        return _vm.$emit('click-graph');
      },
      "welcome-audio-updated": function ($event) {
        return _vm.$emit('welcome-audio-updated');
      },
      "options-updated": function ($event) {
        return _vm.$emit('options-updated');
      },
      "ofh-days-updated": function ($event) {
        return _vm.$emit('ofh-days-updated');
      },
      "ofh-toggle-updated": function ($event) {
        return _vm.$emit('ofh-toggle-updated');
      },
      "ofh-default-updated": function ($event) {
        return _vm.$emit('ofh-default-updated');
      },
      "assign-number-updated": function ($event) {
        return _vm.$emit('assign-number-updated');
      },
      "profile-image-updated": function ($event) {
        return _vm.$emit('profile-image-updated');
      },
      "label-updated": function ($event) {
        return _vm.$emit('label-updated');
      },
      "delete-ivr": function ($event) {
        return _vm.$emit('delete-ivr');
      },
      "close-modal": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }