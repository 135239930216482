<template>
  <div ref="todo">
    <div class="dialer_contact_list allow_scroll add">
      <div 
        style="background-color: black;"
        v-for="call in assignedCalls" 
        :key="call.id"
      >
        <!-- <Info v-if="call.accountcode" :id="call.accountcode" />
        <Info v-if="call.assigned_accountcode" :id="call.assigned_accountcode" />
        {{ call.status }}
        <b-button 
          @click="$modal.show('AssignTodo',{
            call_id: call.todoData.callerid,
          })"
        >
          Assign
        </b-button>
        <b-button 
          @click="$modal.show('AssignTodo',{
            call_id: call.todoData.callerid,
          })"
        >
          Done
        </b-button>
        <b-button 
          @click="$modal.show('AssignTodo',{
            call_id: call.todoData.callerid,
          })"
        >
          Archive
        </b-button> -->
        <CallActivityItem1 
          style="background-color: black;"
          :call="call.todoData"
          :conditions="{ actions: false, assign: true, }"
          @assign="$modal.show('AssignedTodoDetail',{
            call: call
          })"
        />
      </div>
    </div>
    <AssignedTodoDetailModal @assigned="getTodos()" />
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import CallActivityItem1 from '../Home/CallActivity/Item1.vue'
import AssignedTodoDetailModal from '../Modals/AssignedTodoDetailModal.vue';
// import Info from '../Lists/Info.vue';
export default {
  name: "Todo",
  components: {
    CallActivityItem1,
    AssignedTodoDetailModal,
    // Info,
  },
  data() {
    return {
      api: {
        todos_list: this.$helperFunction.apiInstance({}),
      },
      response: {
        todos: {},
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
    callHistory(){ return this.response.todos.call_history ?? [] },
    assignedCalls(){ return this.response.todos.assigned_call ?? [] },
  },
  methods: {
    async getTodos(){
      if(this.api.todos_list.send) return;
      try {
        this.api.todos_list.send=true
        const { data } = await VOIP_API.endpoints.todos.list({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
        })
        this.response.todos=data ?? {}
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.todos_list.send=false
      }
    },
  },
  mounted(){
    this.getTodos()
  }
};
</script>
