<template>
  <div class="content-area d-flex h-100 dialer_main_body">
    <div class="dialer_main_body_inner w-100">
      <div :class="getIsMobile?'mobileCallContainer updatedNow VideoCallVersion4':`dialer-videoCall w-100 d-flex ${ GET_LOCAL_SETTING_DASHBOARD.design.flip ? 'flex-row-reverse' : ''}`">
        <div v-if="getIsMobile && isIncomingCallBanner && !showCallsList" class="mobile2ndIncommingCall-identification"  @click="showCallsList=true">
          Incoming Calls
          <b-icon icon="circle-fill" variant="success" />
        </div>
        <JitsiVideoCall
          v-show="activeCall && activeSession && activeSession.room==videoCall.room && (getIsMobile?!showCallsList:true)"
          @to-dashboard="$store.state.global_conditions.tab='homeCopy'"
          @calls-list="showCallsList=true"
          v-for="(videoCall, index) in $store.state.calls.videoCalls"
          :key="videoCall.room"
          :listIndex="index"
          :videoCall="videoCall"
          @toggleIsPlay="videoCallToggleIsPlay(videoCall)" 
          @onEnd="onCallEnd(videoCall)"
        />
        <IncomingCalls
          v-if="!activeCall && activeSession && activeSession.room && (getIsMobile?!showCallsList:true)"
          :data="activeSession"
          :isMobileMain="true"
          @join="joinRoomNow($event)"
          @calls-list="showCallsList=true" 
          @to-dashboard="$store.state.global_conditions.tab='homeCopy'" 
        />
        <DialPanel 
          v-if="activeSession && !getIsMobile && activeSession.is_sip==true && !activeSession.extra_data.power_dialer_contact" 
          :style="`width:${GET_LOCAL_SETTING_DASHBOARD.design.width}%`"
          :isCallScreen="true"
        />
        <PowerDialerContactInfo 
          v-if="activeSession && !getIsMobile && activeSession.is_sip==true && activeSession.extra_data.power_dialer_contact" 
          :style="`width:${50}%`"
          :row_no="activeSession.extra_data.power_dialer_contact.data.row_id"
          :sheet_id="activeSession.extra_data.power_dialer_contact.data.sheet_id"
          :paused="activeSession.paused"
          @toggle-pause="activeSession.togglePause()"
        />
        <SipSessions 
          v-if="activeSession && (getIsMobile?!showCallsList:true) && activeSession.is_sip==true" 
          :session="activeSession" 
          :phones="$store.state.sip.phone" 
          @to-dashboard="$store.state.global_conditions.tab='homeCopy'" 
          @calls-list="showCallsList=true" 
          :style="`width:calc(99% - ${GET_LOCAL_SETTING_DASHBOARD.design.width}%)`"
        />
        <div v-show="showCallsList && getIsMobile" class="current-calls-container">
          <div class="current-calls-top">
            <b-icon icon="chevron-left" @click="showCallsList=false" font-scale="1.3" class="settings-back-icon cursor_pointer" style="" />
            <h6 class="current-calls-heading">Current Calls</h6>
          </div>
          <div class="current-calls-container-inner">
            <SipSessionCallsList 
              v-for="session in sipsessions" 
              :key="session.uuid" 
              :session="session" 
              :phones="phones" 
              @call-play="playCall(session);showCallsList=false;"
              @call-list-hide="showCallsList=false"
              @on-play="showCallsList=false" 
            />
            <template v-if="getIsMobile">
              <IncomingCalls
                v-for="incoming_call in $store.state.calls.incoming_calls" 
                :key="incoming_call.room"
                :data="incoming_call"
                @call-play="$store.state.calls.active_incoming_call=incoming_call.room;showCallsList=false;"
                @join="joinRoomNow($event)"
                @on-play="showCallsList=false"
              />
            </template>
            <JitsiCalls 
              v-for="videoCall in $store.state.calls.videoCalls" 
              :key="videoCall.room" 
              :videoCall="videoCall" 
              @call-play="playCall(videoCall);showCallsList=false;"
              @on-play="showCallsList=false" 
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { centrifugo } from '../Centrifuge';
import JitsiVideoCall from "../components/Video/index.vue";
import SipSessionCallsList from '../components/Calls/mobileCalls/SipSessionCallsList.vue'
import IncomingCalls from '../components/Calls/IncomingCalls.vue'
import JitsiCalls from '../components/Calls/mobileCalls/JitsiCalls.vue'
import SipSessions from '../components/Calls/mobileCalls/SipSessions.vue'
import DialPanel from "../components/Home/CallActivity/List.vue";
import PowerDialerContactInfo from "../components/PowerDialer/ContactInfo.vue";
import { LOGGER, events, VOIP_API } from '../utils';
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex';

import { GET_LOCAL_SETTING_DASHBOARD } from '@/store/helper/getters';
import { AMI_EVENTS } from '@/ami';

export default {
  name: "VideoCall",
  components: {
    JitsiVideoCall,
    SipSessions,
		SipSessionCallsList,
    IncomingCalls,
    JitsiCalls,
    DialPanel,
    PowerDialerContactInfo,
  },
  inject:[
    'isEmpty'
  ],
  data(){
    return {
			showCallsList: false,
      flip: false,
      width: 25,
    }
  },
	computed: {
    ...mapGetters([
      'getIsMobile',
      'activeCall',
      'activeSession',
      'activeIncomingCall',
      'allIncomingCalls',
      'sipsessions',
      'isActiveSessionIncoming',
      GET_LOCAL_SETTING_DASHBOARD,
    ]),
    isIncomingCallBanner(){ return this.isActiveSessionIncoming ? this.allIncomingCalls.length>1 : this.allIncomingCalls.length; },
		phones(){ return this.$store.state.sip.phone || {} },
	},
  watch: {
    activeSession(value){
      if(!value) {
        this.showCallsList=false
      }
		},
    activeCall: {
      handler(activeCall){
        if(activeCall?.is_sip) {
          this.$root.$emit(events.fetch_call_recording_setting)
        }
      },
    },
  },
  methods: {
    ...mapActions([
      'playCall',
    ]),
    async onCallEnd(videoCall) {
      const { data } = videoCall
      try {
        this.$store.state.calls.videoCalls = this.$store.state.calls.videoCalls.filter((call) => call.room !== videoCall.room);
        const playTrack = this.$store.state.calls.videoCalls.filter((call) => call.isPlay);
        if (_.isEmpty(playTrack)) {
          if (this.$store.state.calls.videoCalls[0]) {
            this.$store.state.calls.videoCalls[0].isShown = true;
            this.$store.state.calls.videoCalls[0].isPlay = true;
          } else {
            this.$parent.isFullScreen = false;
          }
        }
        await VOIP_API.endpoints.users.publish({
          method: 'publish',
          params: {
            channel: `${data.caller}=in`,
            data: {
              ...data,
              type: AMI_EVENTS.BYE
            }
          },
        })
      } catch (error) {
        LOGGER.danger("error in onCallEnd function", error);
      }
    },
    videoCallToggleIsPlay(videoCall) {
      this.$store.state.calls.videoCalls = this.$store.state.calls.videoCalls.map((call) => {
        return {
          ...call,
          isPlay: call.jwt === videoCall.jwt,
        };
      });
    },
    joinRoomNow(data){
      this.$root.$emit(events.join_room_now,data)
    },
  },
};
</script>

<style lang="scss">
.dialer-videoCall {
  .md-mar-left {
    margin-left: $dialer-default-padding;
  }
  .sm-mar-right {
    margin-right: 8px;
  }
  .connection {
    height: 100%;
    display: flex;
    border-bottom: none;
    .conference-container {
      @include border-radius(24px);
      .activeCallBar-inVideo {
        .actions-info-container {
          .active-caller {
            position: relative;
            z-index: 2;
          }
          .actions {
            position: relative;
            z-index: 2;
          }
        }
      }
      .activeCall-videoArea {
        border-top: 1px solid $gray-600;
        display: flex;
        height: 100%;
        width: 100%;
        .video-container {
          width: 100%;
          height: 100%;
          position: relative;
          .participants-container {
            height: 100%;
            width: 100%;
            .remote-track-container {
              .video-container {
                width: 100%;
                height: 100%;
              }
            }
          }
          .resizable-component {
            position: absolute;
            z-index: 111;
            .local-track {
              .video-container {
                height: 100%;
                video {
                  @include border-radius(24px);
                  -webkit-transform: rotateY(180deg);
                  -moz-transform: rotateY(180deg);
                  transform: rotateY(180deg);
                }
              }
            }
            &.after-participant {
              cursor: pointer;
              .localtracks-container-3 {
                width: 100%;
                height: 100%;
              }
            }
          }
          .dialer-incommingVideoCall-active {
            background-color: transparent;
            padding: $dialer-default-padding 24px;
            @include border-radius($border-radius);
            position: absolute;
            z-index: 111;
            left: 50%;
            transform: translateX(-50%);
            bottom: 40px;
            .dialer-incommingVideoCall-info {
              margin-right: 8px;
              border-right: 1px solid $white;
              z-index: 0;
              max-width:350px;
              display:flex;
              justify-content: space-between;
              padding-right: 8px;
            }
            &:before {
              content: "";
              backdrop-filter: blur(10px);
              --webkit-backdrop-filter: blur(10px);
              background-color: rgba(0, 0, 0, 0.5);
              width: 100%;
              position: absolute;
              height:100%;
              left: 0px;
              top: 0px;
              @include border-radius($border-radius);
              opacity: 50%;
            }
            &.dialer-incommingVideoCall-active-new{
              padding:$dialer-default-padding - 4 ;
              &:before{
                height: -webkit-fill-available;
              }
              .dialer-incommingVideoCall-info{
                width:auto;
                border-right:0px;
                padding-right:0px;
                .dialer-caller-dp{
                  &.mr-3{
                    margin-right: 0px !important;
                  }
                }
              }
              .dialer-incommingVideoCall-close{
                .actions-info-container{
                  & > span {
                    margin-left:8px;
                  }
                }
              }
            }
          }
        }
        .dialer-chatBox-inVideo {
          width: 525px;
          min-width: 525px;
          .panel-wide {
            padding-left: 0 !important;
            height: 100%;
            .chat-info {
              display: none;
            }
            .chat-box-section {
              flex-grow: 0;
              justify-content: space-between;
              .chat-box {
                padding: 0 0 0 10px;
                height:calc(100% - 135px);
                flex-grow: 0;
                .msg-box {
                  .chat-msg-box {
                    .chat-msg-box-inner {
                      .dialer-user-msg-box {
                        max-width: calc(100% - 45px);
                        .msg {
                          background: rgba(255, 255, 255, 0.1) !important;
                          .msg-media{
                            flex-direction: column;
                            align-items: flex-end;
                            .msg-media-inner{
                              .dialer-audio-container{
                                .dialer-audio-playback{
                                  .dialer-audio-playback-inner{
                                    min-width: 210px;
                                  }
                                }
                              }
                              &.msg-image{
                                max-width: 60px;
                                min-width: 60px;
                                & > div{
                                  max-width: 60px;
                                  max-height: 60px;
                                  width: 60px;
                                  height: 60px;
                                  .dialer-image-container{
                                    max-width: 60px;
                                    max-height: 60px;
                                    width: 60px;
                                    height: 60px;
                                    overflow: hidden;
                                    img{
                                      // transform: scale(1.5);
                                      height: 60px;
                                    }
                                  }
                                }
                              }
                              // &.msg-video{
                              //   height: 110px;
                              //   width: 150px;
                              //   .dialer-video-container {
                              //     height: 110px;
                              //     width: 150px;
                              //     video{
                              //       height: 110px;
                              //       width: 150px;
                              //     }
                              //   }
                              // }
                            }
                          }
                        }
                        .main{
                          & > img{
                            height:40%;
                            margin-top:1rem;
                          }
                          &.link-background{
                            & > div {
                              p,h5 {
                                max-width:110px;
                                max-height:110px;
                                overflow:hidden;
                                font-size:12px;
                              }
                              a{
                                max-width:120px;
                                max-height:110px;
                                overflow:hidden;
                                font-size:12px;
                                text-align: left;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .chat-actions {
                .ico-chat {
                  width: 20px;
                  height: 20px;
                  background: transparent;
                  color: $white;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  &.submit-icon {
                    transform: rotate(45deg);
                  }
                }
                .row {
                  .col-12 {
                    flex-wrap: wrap;
                  }
                }
                .dialer-chatBox-typingMessage-area {
                  .dialer-chatBox-actionIcon-area {
                    padding: 8px;
                    & > ul {
                      & > li {
                        &:nth-child(2){
                          padding-left: 0px !important;
                        }
                        & > a > svg{
                          height: 33px;
                          width: 33px;
                        }
                      }
                      &:last-child{
                        .audio-record-area{
                          &.open{
                            position: absolute;
                            top: -25px;
                            right: 55px;
                            background-color: white;
                            // border-radius: 25px;
                            @include border-radius(8px);
                            box-shadow: 1px 0px 9px lightgrey;
                            align-items: center !important;
                            .recording-timer{
                              .recording-timer-inner{
                                margin: 0px 16px;
                              }
                              .dialer-icon{
                                svg{
                                  height: 33px;
                                  width: 33px;
                                }
                              }
                            }
                          }
                        }
                      }
                    } 
                    
                  }
                }
                .chat-actions-sendIcon {
                  margin-left: 8px;
                  margin-bottom: 3px;
                  a {
                    svg{
                      height: 45px;
                      width: 45px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .dialer-memberList-area {
        display: none;
      }
    }
  }
  .dialer-chatBox-inVideo {
    .dialer-videoCall-addMember {
      .items {
        height: 100%;
        .item {
          .info {
            img {
              height: 40px;
              width: 40px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
  &.forCentringOnlyInDesktop{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>

