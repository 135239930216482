<template>
  <div>
    <slot name="top" :api="{ create_addressbook: { send: api.create_addressbook.send }, update_addressbook: { send: api.update_addressbook.send } }"/>
    <b-form v-if="creating" @submit.prevent="createAddressbook()">
      <b-form-group class="whiteBGinputWithGreyRoundedBorder dropdownVersion mb-0 mt-0">
        <vb-select 
          v-model="forms.create_addressbook.type"
          @change="
            forms.create_addressbook.firstname='';
            forms.create_addressbook.lastname='';
            forms.create_addressbook.company='';
            forms.create_addressbook.jobtitle='';
            forms.create_addressbook.nickname='nickname';
            forms.create_addressbook.department='';
            forms.create_addressbook.suffix='';
            forms.create_addressbook.note='';
            forms.create_addressbook.emails=formAllowFields.emails.required ? [ { emails: '' } ] : [];
            forms.create_addressbook.phones=[
              {
                country: 'GB',
                phone: 'Main',
                number: '',
                shortcode: '',
              },
            ];
          " 
          :disabled="api.create_addressbook.send || api.fetch_integrated.send"
          :defaultSelectedText="'Select Type'"
          class="right-0 selectVoice-dropdown" 
          :options="typeOptions"
        />
        <p 
          v-if="forms.create_addressbook.submitted && $v.forms.create_addressbook.type.$invalid" 
          class="text text-danger animated bounceIntop mb-0"
        >
          <span v-if="!$v.forms.create_addressbook.type.required">* Type is required</span>
        </p>
      </b-form-group>
      <b-form-group v-if="formAllowFields.firstname" label="First name *" class="whiteBGinputWithGreyRoundedBorder mt-20px mb-0">
        <b-form-input 
          type="text" 
          oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]*$/gi, '')"
          v-model="forms.create_addressbook.firstname" 
          :disabled="api.create_addressbook.send"
          :maxlength="$v.forms.create_addressbook.firstname.$params.maxLength.max" 
          placeholder="First name" 
        />
        <p 
          v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.firstname.$invalid) || api.create_addressbook.validation_errors.firstname" 
          class="text text-danger animated bounceIntop mb-0"
        >
          <span v-if="!$v.forms.create_addressbook.firstname.required">*First name is required</span>
          <span v-if="!$v.forms.create_addressbook.firstname.valid">*First name is invalid</span>
          <span v-if="!$v.forms.create_addressbook.firstname.maxLength">
          *First name should be more than {{$v.forms.create_addressbook.firstname.$params.maxLength.max}} characters
        </span>
          <span v-for="(em,i) in api.create_addressbook.validation_errors.firstname" :key="i">{{ em }}</span>
        </p>
      </b-form-group>
      <b-form-group v-if="formAllowFields.lastname" label="Last name" class="whiteBGinputWithGreyRoundedBorder mb-0 mt-20px">
        <b-form-input 
          type="text" 
          oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]*$/gi, '')"
          v-model="forms.create_addressbook.lastname" 
          :disabled="api.create_addressbook.send"
          placeholder="Last name" 
          :maxlength="$v.forms.create_addressbook.lastname.$params.maxLength.max" 
        />
        <p v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.lastname.$invalid) || api.create_addressbook.validation_errors.lastname" class="text text-danger animated bounceIntop mb-0">
          <span v-if="!$v.forms.create_addressbook.lastname.maxLength">*Last name should be more than {{$v.forms.create_addressbook.lastname.$params.maxLength.max}} characters</span>
          <span v-if="!$v.forms.create_addressbook.lastname.valid">*Last name is invalid</span>
          <span v-for="(em,i) in api.create_addressbook.validation_errors.lastname" :key="i">{{ em }}</span>
        </p>
      </b-form-group>
      <b-form-group  v-if="formAllowFields.company" label="Company" class="whiteBGinputWithGreyRoundedBorder mb-0 mt-20px">
        <b-form-input 
          type="text" 
          oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]*$/gi, '')"
          v-model="forms.create_addressbook.company" 
          placeholder="Company" 
          :disabled="api.create_addressbook.send"
        />
        <p 
          v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.company.$invalid) || api.create_addressbook.validation_errors.company" 
          class="text text-danger animated bounceIntop mb-0"
        >
          <span v-if="!$v.forms.create_addressbook.company.minLength">
            *Company should be more than {{$v.forms.create_addressbook.company.$params.minLength.min}} characters
          </span>
          <span v-if="!$v.forms.create_addressbook.company.valid">*Company is invalid</span>
          <span v-for="(em,i) in api.create_addressbook.validation_errors.company" :key="i">{{ em }}</span>
        </p>
      </b-form-group>
      <div v-if="formAllowFields.phones.allow" class="add_fields add_phone_box mt-20px">
        <div class="fullWidthLessHeightGreyButton pl-3 pr-2 py-2 mt-2 flex-column align-items-start w-100 h-auto" v-for="(data, index) in forms.create_addressbook.phones" :key="index">
          <div class="d-flex justify-content-between align-items-center w-100">
            <p class="mb-0 d-flex align-items-center">
              <span class="mr-1 d-flex flex-column align-items-start justify-content-start">
                <span class="font-12">Type</span>
                <span>{{ data.phone }}</span>
              </span> 
              <span class="mx-1 d-flex flex-column align-items-start justify-content-start" style="width:138px;">
                <span class="font-12">Number</span>
                <span>{{ data.number | number_formater(data.country) }}</span>
                <span v-if="!data.number"><br></span>
              </span> 
              <span v-if="data.shortcode" class="mx-1 font-14 d-flex flex-column align-items-start justify-content-start">
                <span class="font-12">Shortcode</span>
                <span>{{ data.shortcode }}</span>
                <span v-if="!data.shortcode"><br></span>
              </span>
            </p>
            <div class="d-flex align-items-center">
              <b-button class="p-0 border-0 d-flex align-items-center"
                variant="link" 
                :disabled="api.create_addressbook.send"
                @click="$modal.show(`${_uid}-UpdatePhoneNumber`,{ 
                  phone: data.phone,
                  shortcode: data.shortcode,
                  country: data.country,
                  number: data.number,
                  fields: {
                    type: formAllowFields.phones.type,
                    short_code: formAllowFields.phones.short_code,
                  },
                  index: index, 
                  create: true, 
                })"
              >
                <b-icon icon="pencil-fill" variant="black" font-scale="0.99" />
              </b-button>
              <b-button class="p-0 ml-2 border-0 d-flex align-items-center"
                v-if="formAllowFields.phones.required ? forms.create_addressbook.phones.length > 1 : true" 
                variant="link"
                :disabled="api.create_addressbook.send"
                @click="forms.create_addressbook.phones.splice(index, 1)"
              >
                <b-icon variant="danger" font-scale="0.99" icon="trash-fill" />
              </b-button>
            </div>
          </div>
          <p 
            v-if="forms.create_addressbook.submitted && $v.forms.create_addressbook.phones.$each[index].phone.$invalid" 
            class="text text-danger animated bounceIntop font-14 mt-1"
          >
            <span v-if="!$v.forms.create_addressbook.phones.$each[index].phone.required">* Type is required</span>
          </p>
          <p 
            v-if="forms.create_addressbook.submitted && $v.forms.create_addressbook.phones.$each[index].number.$invalid" 
            class="text text-danger animated bounceIntop mb-0 font-14 mt-1"
          >
            <span v-if="!$v.forms.create_addressbook.phones.$each[index].number.required">* Number is required</span>
            <span v-else-if="!$v.forms.create_addressbook.phones.$each[index].number.validNumber">* Number is invalid</span>
          </p>
        </div>
        <div v-if="forms.create_addressbook.phones.length<formAllowFields.phones.length" class="d-flex flex-column">
          <b-form-group class="mb-0">
            <div class="d-flex justify-content-end w-100 mt-2">
              <a 
                class="fullWidthLessHeightGreyButton" 
                @click="api.create_addressbook.send ? '' : forms.create_addressbook.phones.push({ 
                  country: 'GB', 
                  number: '', 
                  phone: formAllowFields.phones.type?'Main':'', 
                  shortcode: formAllowFields.phones.short_code?filterShortCodes[0]:'', 
                })"
              >
                Add phone
              </a>
            </div>
          </b-form-group>
        </div>
        <p 
          v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.phones.$invalid) || api.create_addressbook.validation_errors.phones" 
          class="text text-danger animated bounceIntop"
        >
          <span v-for="(em,i) in api.create_addressbook.validation_errors.phones" :key="i">{{ em }}</span>
        </p>
      </div>
      <div v-if="formAllowFields.emails.allow" class="add_fields add_email_box mt-20px">
        <div class="fullWidthLessHeightGreyButton mt-2 pl-3 pr-2 py-2 mt-2 flex-column align-items-start w-100 h-auto" v-for="(data, index) in forms.create_addressbook.emails" :key="index">
          <div class="d-flex justify-content-between align-items-center w-100">
            <p class="mb-0 d-flex align-items-center">
              <span class="mr-1">{{ data.emails }}</span>
            </p>
            <div class="d-flex">
              <b-button  class="p-0 border-0 d-flex align-items-center"
                variant="link" 
                :disabled="api.create_addressbook.send"
                @click="$modal.show(`${_uid}-UpdateEmail`,{ 
                  email: data.emails,
                  index: index, 
                  create: true, 
                })"
              >
                <b-icon icon="pencil-fill" variant="black" font-scale="0.99" />
              </b-button>
              <b-button class="p-0 border-0 d-flex align-items-center ml-2"
                v-if="formAllowFields.emails.required ? forms.create_addressbook.emails.length > 1 : true"
                variant="link"
                :disabled="api.create_addressbook.send"
                @click="api.create_addressbook.send ? '' : forms.create_addressbook.emails.splice(index, 1)"
              >
                <b-icon variant="danger" font-scale="0.99" icon="trash-fill" />
              </b-button>
            </div>
          </div>
          <p 
            v-if="forms.create_addressbook.submitted && $v.forms.create_addressbook.emails.$each[index].emails.$invalid" 
            class="text text-danger animated bounceIntop font-14 mt-1"
          >
            <span v-if="!$v.forms.create_addressbook.emails.$each[index].emails.required">* email is required</span>
            <span v-if="!$v.forms.create_addressbook.emails.$each[index].emails.email">* email is not valid</span>
          </p>
        </div>
        <div class="d-flex flex-column">
          <b-form-group class="mb-0">
            <div class="d-flex justify-content-end w-100 mt-2 mb-20px">
              <a 
                v-if="forms.create_addressbook.emails.length<formAllowFields.emails.length"
                class="fullWidthLessHeightGreyButton"
                @click="api.create_addressbook.send ? '' : forms.create_addressbook.emails.unshift({ emails: '', })"
              >
                Add email
              </a>
            </div>
          </b-form-group>
          <p 
            v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.emails.$invalid) || api.create_addressbook.validation_errors.emails" 
            class="text text-danger animated bounceIntop"
          >
            <span v-for="(em,i) in api.create_addressbook.validation_errors.emails" :key="i">{{ em }}</span>
          </p>
        </div>
      </div>
      <b-form-group v-if="formAllowFields.jobtitle" label="job title" class="whiteBGinputWithGreyRoundedBorder mb-0">
        <b-form-input 
          type="text" 
          oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]*$/gi, '')"
          :disabled="api.create_addressbook.send"
          v-model="forms.create_addressbook.jobtitle" 
          placeholder="Job title" 
        />
        <p 
          v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.jobtitle.$invalid) || api.create_addressbook.validation_errors.jobtitle" 
          class="text text-danger animated bounceIntop mb-0"
        >
          <span v-if="!$v.forms.create_addressbook.jobtitle.minLength" >
            Job title should be more than {{$v.forms.create_addressbook.jobtitle.$params.minLength.min}} characters
          </span>
          <span v-if="!$v.forms.create_addressbook.jobtitle.valid">Job title is invalid</span>
          <span v-for="(em,i) in api.create_addressbook.validation_errors.jobtitle" :key="i">{{ em }}</span>
        </p>
      </b-form-group>
      <b-form-group v-if="formAllowFields.department" label="Department" class="whiteBGinputWithGreyRoundedBorder mb-0 mt-20px">
        <b-form-input 
          type="text" 
          oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]*$/gi, '')"
          :disabled="api.create_addressbook.send"
          v-model="forms.create_addressbook.department" 
          placeholder="Department" 
        />
        <p 
          v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.department.$invalid) || api.create_addressbook.validation_errors.department" 
          class="text text-danger animated bounceIntop mb-0"
        >
          <span v-if="!$v.forms.create_addressbook.department.minLength">
            *Department should be more than {{$v.forms.create_addressbook.department.$params.minLength.min}} characters
          </span>
          <span v-if="!$v.forms.create_addressbook.department.invalid">*Department is invalid</span>
          <span v-for="(em,i) in api.create_addressbook.validation_errors.department" :key="i">{{ em }}</span>
        </p>
      </b-form-group>
      <b-form-group v-if="formAllowFields.suffix" class="whiteBGinputWithGreyRoundedBorder dropdownVersion mb-0 mt-12px">
        <vb-select 
          v-model="forms.create_addressbook.suffix" 
          :disabled="api.create_addressbook.send"
          class="right-0 selectVoice-dropdown" 
          :defaultSelectedText="'Select suffix'" 
          :options="suffixs" 
        />
        <p 
          v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.suffix.$invalid) || api.create_addressbook.validation_errors.suffix" 
          class="text text-danger animated bounceIntop mb-0"
        >
          <span v-for="(em,i) in api.create_addressbook.validation_errors.suffix" :key="i">{{ em }}</span>
        </p>
      </b-form-group>
      <b-form-group v-if="formAllowFields.note"  class="whiteBGinputWithGreyRoundedBorder mb-0 mt-20px">
        <b-form-input 
          type="text" 
          oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]*$/gi, '')"
          v-model="forms.create_addressbook.note" 
          :disabled="api.create_addressbook.send" 
          placeholder="Note" 
        />
        <p 
          v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.note.$invalid) || api.create_addressbook.validation_errors.note" 
          class="text text-danger animated bounceIntop mb-0"
        >
          <span v-if="!$v.forms.create_addressbook.note.invalid">*Note is invalid</span>
          <span v-for="(em,i) in api.create_addressbook.validation_errors.note" :key="i">{{ em }}</span>
        </p>
      </b-form-group>
      <div class="d-flex justify-content-end mt-20px">
        <button class="fullWidthDoneButton" :disabled="api.create_addressbook.send" type="submit">
          <vb-spinner v-if="api.create_addressbook.send" />
          <template v-else>Save</template>
        </button>
      </div>
    </b-form>
    <b-form v-else @submit.prevent="updateAddressbook()" class="dialer_form">
      <div 
        class="profile-image-section-container" 
        @click="$modal.show(`${_uid}-AddressBookProfileImageUploader`,{ 
          type: 'ADDRESS_BOOK', 
          accountcode: forms.update_addressbook.id, 
          current_image: getProperty(editAddressBook,'profile_image') 
        })"
      > 
        <div class="profile-image-section">
          <div class="profile-img">
            <img style="height: 100%; width: 100%" :src="editAddressBook | get_property('profile_image')" @error="$event.target.src=local_filess.images.user_male"  />
          </div>
        </div>
      </div>
      <b-form-group v-if="formAllowFields.firstname" label="First name" class="mb-0 mt-20px whiteBGinputWithGreyRoundedBorder">
        <b-form-input 
          type="text" 
          oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]*$/gi, '')"
          v-model="forms.update_addressbook.firstname" 
          :disabled="api.update_addressbook.send"
          :maxlength="$v.forms.update_addressbook.firstname.$params.maxLength.max" 
          placeholder="First name" 
        />
        <p 
          v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.firstname.$invalid) || api.update_addressbook.validation_errors.firstname" 
          class="text text-danger animated bounceIntop"
        >
          <span v-if="!$v.forms.update_addressbook.firstname.required">*First name is required</span>
          <span v-if="!$v.forms.update_addressbook.firstname.valid">*First name is invalid</span>
          <span v-if="!$v.forms.update_addressbook.firstname.maxLength">
            First name should be more than {{$v.forms.update_addressbook.firstname.$params.maxLength.max}} characters
          </span>
          <span v-for="(em,i) in api.update_addressbook.validation_errors.firstname" :key="i">{{ em }}</span>
        </p>
      </b-form-group>
      <b-form-group v-if="formAllowFields.lastname" label="Last name" class="mb-0 mt-20px whiteBGinputWithGreyRoundedBorder">
        <b-form-input 
          type="text" 
          oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]*$/gi, '')"
          v-model="forms.update_addressbook.lastname" 
          :disabled="api.update_addressbook.send"
          :maxlength="$v.forms.update_addressbook.lastname.$params.maxLength.max" 
          placeholder="Last name" 
        />
        <p 
          v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.lastname.$invalid) || api.update_addressbook.validation_errors.lastname" 
          class="text text-danger animated bounceIntop"
        >
          <span v-if="!$v.forms.update_addressbook.lastname.valid">Last name is invalid</span>
          <span v-if="!$v.forms.update_addressbook.lastname.maxLength">
            Last name should be more than {{$v.forms.update_addressbook.lastname.$params.maxLength.max}} characters
          </span>
          <span v-for="(em,i) in api.update_addressbook.validation_errors.lastname" :key="i">{{ em }}</span>
        </p>
      </b-form-group>
      <b-form-group v-if="formAllowFields.company" label=" Company" class="mb-0 mt-20px whiteBGinputWithGreyRoundedBorder">
        <b-form-input 
          type="text" 
          oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]*$/gi, '')"
          v-model="forms.update_addressbook.company" 
          :disabled="api.update_addressbook.send"
          placeholder="Company" 
        />
        <p 
          v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.company.$invalid) || api.update_addressbook.validation_errors.company" 
          class="text text-danger animated bounceIntop"
        >
          <span v-if="!$v.forms.update_addressbook.company.valid">*Company is invalid</span>
          <span v-if="!$v.forms.update_addressbook.company.minLength">
            *Company should be more than {{$v.forms.update_addressbook.company.$params.minLength.min}} characters
          </span>
          <span v-for="(em,i) in api.update_addressbook.validation_errors.company" :key="i">{{ em }}</span>
        </p>
      </b-form-group>
      <div v-if="formAllowFields.phones.allow" class="add_fields add_phone_box">
        <div class="fullWidthLessHeightGreyButton mt-2 justify-content-between" v-for="(data, index) in forms.update_addressbook.phones" :key="index">
          <p>{{data.phone}} {{ data.number | number_formater(data.country) }} {{ data.shortcode }}</p>
          <div class="d-flex">
            <b-button 
              variant="link" 
              :disabled="api.update_addressbook.send"
              @click="$modal.show(`${_uid}-UpdatePhoneNumber`,{ 
                phone: data.phone,
                shortcode: data.shortcode,
                country: data.country,
                number: data.number,
                fields: {
                  type: formAllowFields.phones.type,
                  short_code: formAllowFields.phones.short_code,
                },
                index: index, 
                create: false, 
              })"
            >
              <b-icon icon="pencil" />
            </b-button>
            <b-button 
              v-if="formAllowFields.phones.required ? forms.update_addressbook.phones.length > 1 : true" 
              variant="link"
              :disabled="api.update_addressbook.send"
              @click="forms.update_addressbook.phones.splice(index, 1)"
            >
              <b-icon variant="danger" icon="trash" />
            </b-button>
          </div>
          <p 
            v-if="forms.update_addressbook.submitted && $v.forms.update_addressbook.phones.$each[index].phone.$invalid" 
            class="text text-danger animated bounceIntop"
          >
            <span v-if="!$v.forms.update_addressbook.phones.$each[index].phone.required">* Type is required</span>
          </p>
          <p 
            v-if="forms.update_addressbook.submitted && $v.forms.update_addressbook.phones.$each[index].number.$invalid" 
            class="text text-danger animated bounceIntop mb-0"
          >
            <span v-if="!$v.forms.update_addressbook.phones.$each[index].number.required">* Number is required</span>
            <span v-else-if="!$v.forms.update_addressbook.phones.$each[index].number.validNumber">* Number is invalid</span>
          </p>
        </div>
        <div v-if="forms.update_addressbook.phones.length<formAllowFields.phones.length" class="d-flex flex-column">
          <b-form-group class="mb-0">
            <div class="d-flex justify-content-end w-100 mt-20px">
              <a 
                class="fullWidthLessHeightGreyButton" 
                @click="api.update_addressbook.send ? '' : forms.update_addressbook.phones.push({ 
                  country: 'GB', 
                  number: '', 
                  phone: formAllowFields.phones.type?'Main':'', 
                  shortcode: formAllowFields.phones.short_code?filterShortCodes[0]:'', 
                })"
              >
                Add phone
              </a>
            </div>
          </b-form-group>
        </div>
        <p 
          v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.phones.$invalid) || api.update_addressbook.validation_errors.phones" 
          class="text text-danger animated bounceIntop"
        >
          <span v-for="(em,i) in api.update_addressbook.validation_errors.phones" :key="i">{{ em }}</span>
        </p>
      </div>
      <div v-if="formAllowFields.emails.allow" class="add_fields add_email_box">
        <div class="fullWidthLessHeightGreyButton mt-2 justify-content-between" v-for="(data, index) in forms.update_addressbook.emails" :key="index">
          <p>{{ data.emails }}</p>
          <div class="d-flex">
            <b-button 
              variant="link" 
              :disabled="api.update_addressbook.send"
              @click="$modal.show(`${_uid}-UpdateEmail`,{ 
                email: data.emails,
                index: index, 
                create: false, 
              })"
            >
              <b-icon icon="pencil" />
            </b-button>
            <b-button 
              v-if="formAllowFields.emails.required ? forms.update_addressbook.emails.length > 1 : true"
              variant="link"
              :disabled="api.update_addressbook.send"
              @click="api.update_addressbook.send ? '' : forms.update_addressbook.emails.splice(index, 1)"
            >
              <b-icon variant="danger" icon="trash" />
            </b-button>
          </div>
          <p 
            v-if="forms.update_addressbook.submitted && $v.forms.update_addressbook.emails.$each[index].emails.$invalid" 
            class="text text-danger animated bounceIntop"
          >
            <span v-if="!$v.forms.update_addressbook.emails.$each[index].emails.required">* email is required</span>
            <span v-if="!$v.forms.update_addressbook.emails.$each[index].emails.email">* email is not valid</span>
          </p>
        </div>
        <div class="d-flex flex-column">
          <b-form-group class="mb-0">
            <div class="d-flex justify-content-end w-100 mt-20px mb-20px">
              <a 
                v-if="forms.update_addressbook.emails.length<formAllowFields.emails.length"
                class="fullWidthLessHeightGreyButton"
                @click="api.update_addressbook.send ? '' : forms.update_addressbook.emails.unshift({ emails: '', })"
              >
                Add email
              </a>
            </div>
          </b-form-group>
          <p 
            v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.emails.$invalid) || api.update_addressbook.validation_errors.emails" 
            class="text text-danger animated bounceIntop"
          >
            <span v-for="(em,i) in api.update_addressbook.validation_errors.emails" :key="i">{{ em }}</span>
          </p>
        </div>
      </div>
      <b-form-group v-if="formAllowFields.jobtitle" label="job title" class="mb-0 mt-20px whiteBGinputWithGreyRoundedBorder">
        <b-form-input 
          type="text" 
          oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]*$/gi, '')"
          v-model="forms.update_addressbook.jobtitle" 
          :disabled="api.update_addressbook.send" 
          placeholder="Job Title" 
        />
        <p 
          v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.jobtitle.$invalid) || api.update_addressbook.validation_errors.jobtitle" 
          class="text text-danger animated bounceIntop"
        >
          <span v-if="!$v.forms.update_addressbook.jobtitle.valid">Job title is invalid</span>
          <span v-if="!$v.forms.update_addressbook.jobtitle.minLength">
            Job title should be more than {{$v.forms.update_addressbook.jobtitle.$params.minLength.min}} characters
          </span>
        <span v-for="(em,i) in api.update_addressbook.validation_errors.jobtitle" :key="i">{{ em }}</span>
        </p>
      </b-form-group>
      <b-form-group v-if="formAllowFields.department" label=" Department" class="mb-0 mt-20px whiteBGinputWithGreyRoundedBorder">
        <b-form-input 
          type="text" 
          oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]*$/gi, '')"
          v-model="forms.update_addressbook.department" 
          :disabled="api.update_addressbook.send" 
          placeholder="Department" 
        />
        <p 
          v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.department.$invalid) || api.update_addressbook.validation_errors.department" 
          class="text text-danger animated bounceIntop"
        >
          <span v-if="!$v.forms.update_addressbook.department.valid">*Department is invalid</span>
          <span v-if="!$v.forms.update_addressbook.department.minLength">
            *Department should be more than {{$v.forms.update_addressbook.department.$params.minLength.min}} characters
          </span>
          <span v-for="(em,i) in api.update_addressbook.validation_errors.department" :key="i">{{ em }}</span>
        </p>
      </b-form-group>
      <b-form-group v-if="formAllowFields.suffix" class="mb-0 mt-20px whiteBGinputWithGreyRoundedBorder dropdownVersion">
        <vb-select 
          v-model="forms.update_addressbook.suffix" 
          :disabled="api.update_addressbook.send" 
          class="right-0 selectVoice-dropdown" 
          :defaultSelectedText="'Select suffix'" 
          :options="suffixs" 
        />
        <p 
          v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.suffix.$invalid) || api.update_addressbook.validation_errors.suffix" 
          class="text text-danger animated bounceIntop"
        >
          <span v-if="!$v.forms.update_addressbook.suffix.minLength">
            *suffix should be more than {{$v.forms.update_addressbook.suffix.$params.minLength.min}} characters
          </span>
          <span v-for="(em,i) in api.update_addressbook.validation_errors.suffix" :key="i">{{ em }}</span>
        </p>
      </b-form-group>
      <b-form-group v-if="formAllowFields.note" class="mb-0 mt-20px whiteBGinputWithGreyRoundedBorder">
        <b-form-input 
          type="text" 
          oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]*$/gi, '')"
          v-model="forms.update_addressbook.note" 
          :disabled="api.update_addressbook.send" 
          placeholder="Note" 
        />
        <p 
          v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.note.$invalid) || api.update_addressbook.validation_errors.note" 
          class="text text-danger animated bounceIntop"
        >
          <span v-if="!$v.forms.update_addressbook.note.valid">*Note is invalid</span>
          <span v-for="(em,i) in api.update_addressbook.validation_errors.note" :key="i">{{ em }}</span>
        </p>
      </b-form-group>
      <div class="d-flex justify-content-end mt-20px">
        <button class="fullWidthDoneButton" :disabled="api.update_addressbook.send" type="submit">
          <vb-spinner v-if="api.update_addressbook.send" />
          <template v-else>Update</template>
        </button>
      </div>
    </b-form>
    <ProfileImageUploader :modalName="`${_uid}-AddressBookProfileImageUploader`" @image-uploaded="onImageUploaded($event);" />
    <modal 
      :name="`${_uid}-UpdatePhoneNumber`" 
      class="center-zoomIn-transition v2 m-w-500 AddTeamModal AddPhoneNumberFromContactsModal" 
      width="500px" 
      height="auto" 
      :scrollable="true" 
      @before-open="
        forms.update_phone_input.phone=$event.params.phone;
        forms.update_phone_input.number=$event.params.number;
        forms.update_phone_input.country=$event.params.country;
        forms.update_phone_input.shortcode=$event.params.shortcode;
        forms.update_phone_input.fields.type=$event.params.fields.type;
        forms.update_phone_input.fields.short_code=$event.params.fields.short_code;
        forms.update_phone_input.index=$event.params.index;
        forms.update_phone_input.create=$event.params.create;
      "
      @before-close="
        $v.forms.update_phone_input.$reset();
        conditions.collapse=false;
      "
    >
      <section class="ct-user-new dialer-edit s1">
        <div class="dialer-edit-header d-block pb-0">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <h2 class="dialer-settings-title newer mb-0">Add phone</h2>
            </div>
            <div class="dialer-edit-actions">
              <a class="newCloseButton" @click="$modal.hide(`${_uid}-UpdatePhoneNumber`)">
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span>
              </a>
            </div>
          </div>
          <div class="vm--modal-text">Enter the phone details below.</div>
        </div>
        <form @submit.prevent="onSubmitPhoneInput()" class="latestGreyBox-9-9-2023 mt-20px">
          <b-form-group class="mb-0">
            <div>
              <div class="d-flex w-100">
                <div class="whiteBGinputWithGreyRoundedBorder wd-60 pr-2">
                  <legend class="form-control-label small">Number</legend>
                  <div class="whiteBGinputWithGreyRoundedBorder joinWithInput">
                    <b-dropdown>
                      <template #button-content>{{ forms.update_phone_input.country }}</template>
                      <b-dropdown-item 
                        v-for="country in countries" 
                        :key="country.value" 
                        @click="forms.update_phone_input.country=country.value"
                      >
                        {{country.value}} {{ country.text }}
                      </b-dropdown-item>
                    </b-dropdown>
                    <b-form-input
                      type="text"
                      v-vb-input-number-formate="forms.update_phone_input.country" 
                      v-model="forms.update_phone_input.number"
                      placeholder="Number"
                    />
                  </div>
                </div>
                <div v-if="forms.update_phone_input.fields.type" class="whiteBGinputWithGreyRoundedBorder dropdownVersion wd-40 pl-2 mb-0">
                  <legend class="form-control-label small">Type</legend>
                  <select 
                    v-model="forms.update_phone_input.phone" 
                    class="form-control custom-select" 
                    placeholder="Phone"
                  >
                    <option value="" disabled>Select</option>
                    <option value="Home">Home</option>
                    <option value="Office">Office</option>
                    <option value="Cell">Cell</option>
                    <option value="Work">Work</option>
                    <option value="Main">Main</option>
                    <option value="HOME FAX">Home fax</option>
                    <option value="Office FAX">Office fax</option>
                    <option value="Others">Others</option>
                  </select>
                  <p 
                    v-if="$v.forms.update_phone_input.phone.$dirty && $v.forms.update_phone_input.phone.$invalid" 
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.update_phone_input.phone.required">* Type is required</span>
                  </p>
                </div>
              </div>
              <p 
                v-if="$v.forms.update_phone_input.number.$dirty && $v.forms.update_phone_input.number.$invalid" 
                class="text text-danger animated bounceIntop mb-0"
              >
                <span v-if="!$v.forms.update_phone_input.number.required">* Number is required</span>
                <span v-else-if="!$v.forms.update_phone_input.number.validNumber">* Number is invalid</span>
              </p>
              <div v-if="forms.update_phone_input.fields.short_code">
                <div class="d-flex justify-content-end">
                  <button 
                    type="button"
                    class="addShortCodeCollaseableButton" 
                    v-b-toggle.my-collapse 
                    @click="conditions.collapse = !conditions.collapse"
                    :class="conditions.collapse ? null : 'collapsed'"
                    :aria-expanded="conditions.collapse ? 'true' : 'false'"
                    aria-controls="collapse-4"
                  >
                    <b-icon :icon="`${conditions.collapse ? 'dash-circle-fill' : 'plus-circle-fill'}`" />
                    Add shortcode
                  </button>
                </div>
                <b-collapse id="collapse-4" v-model="conditions.collapse">
                  <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion wd-60 pr-2 mb-0">
                    <legend class="form-control-label small">Shortcode</legend>
                    <select v-model="forms.update_phone_input.shortcode" class="form-control custom-select">
                      <option disabled value="">Select</option>
                      <option value="">not select</option>
                      <option 
                        v-for="shortcode in (forms.update_phone_input.shortcode ? [forms.update_phone_input.shortcode,...filterShortCodes] : filterShortCodes)" 
                        :value="shortcode" 
                        :key="shortcode"
                      >
                        {{ shortcode }}
                      </option>
                    </select>
                    <p 
                      v-if="$v.forms.update_phone_input.shortcode.$dirty && $v.forms.update_phone_input.shortcode.$invalid" 
                      class="text text-danger animated bounceIntop"
                    >

                    </p>
                  </div>
                </b-collapse>
              </div>
            </div>
          </b-form-group>
          <b-button class="fullWidthDoneButton mt-20px" type="submit">Submit</b-button>
        </form>
      </section>
    </modal>
    <modal 
      :name="`${_uid}-UpdateEmail`" 
      class="center-zoomIn-transition v2 m-w-500 AddTeamModal" 
      width="500px" 
      height="auto" 
      :scrollable="true"
      @before-open="
        forms.update_email_input.email=$event.params.email;
        forms.update_email_input.index=$event.params.index;
        forms.update_email_input.create=$event.params.create;
      "
    >
      <section class="ct-user-new dialer-edit s1">
        <div class="dialer-edit-header d-block pb-0">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <h2 class="dialer-settings-title newer mb-0">Add email</h2>
            </div>
            <div class="dialer-edit-actions">
              <a class="newCloseButton" @click="$modal.hide(`${_uid}-UpdateEmail`)">
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span>
              </a>
            </div>
          </div>
          <div class="vm--modal-text">Enter the email below.</div>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <form @submit.prevent="onSubmitEmailInput()" >
            <b-form-group :class="`mb-0`">
              <div class="d-flex align-items-center">
                <div class="whiteBGinputWithGreyRoundedBorder flex-fill">
                  <input 
                    type="email" 
                    v-model="forms.update_email_input.email" 
                    class="d-inline-flex flex-grow-1 adjust-width add_new_email_row form-control" 
                    placeholder="Email" 
                  />
                  <p 
                    v-if="$v.forms.update_email_input.email.$dirty && $v.forms.update_email_input.email.$invalid" 
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.update_email_input.email.required">* email is required</span>
                    <span v-if="!$v.forms.update_email_input.email.email">* email is not valid</span>
                  </p>
                </div>
              </div>
            </b-form-group>
            <b-button class="fullWidthDoneButton mt-20px" type="submit">Submit</b-button>
          </form>
        </div>
      </section>
    </modal>
  </div>
</template>

<script>
import { $fn, events, VOIP_API } from '@/utils';
import { CountryName2, flagsCountryCode } from '@/utils/flags';
import moment from 'moment-timezone';
import _ from 'lodash';
import { GET_LOCAL_SETTING_IS_DARK_MODE } from '@/store/helper/getters';
import { mapGetters } from 'vuex';
import required from 'vuelidate/lib/validators/required';
import requiredIf from 'vuelidate/lib/validators/requiredIf';
import maxLength from 'vuelidate/lib/validators/maxLength';
import { helpers } from 'vuelidate/lib/validators';
import minLength from 'vuelidate/lib/validators/minLength';
import email from 'vuelidate/lib/validators/email';
import { number_formater } from '@/filter';
import ProfileImageUploader from "@/components/Modals/ProfileImageUploader.vue";

const suffixs = [
  'Mr', 
  'Miss', 
  'Mrs', 
  'Other'
]
const integrations = {
  hubspot: {
    name: 'HubSpot',
    icon: 'hubSpot-icon',
    desc: `V2 - HubSpot is an inbound marketing and sales platform that helps companies attract visitors, convert leads, and close customers.`,
    type: 'CRM',
    value: 'hubspot',
  },
  zoho: {
    name: 'ZOHO',
    icon: 'zoho-icon',
    desc: `End-to-end, fully customizable CRM solution for growing businesses and enterprises.`,
    type: 'CRM',
    value: 'zoho',
  },
  salesforce: {
    name: 'Sales Force',
    icon: 'salesForce-icon',
    desc: `Integrate all your phone calls & phone numbers into salesforce cloud. Get an integrated dialer & have your phone calls/text messages logged at the right place.`,
    type: 'CRM',
    value: 'salesforce',
  },
  copper: {
    name: 'Copper',
    icon: 'copper-icon',
    desc: `Copper organizes all your emails, calls, files, and notes for every contact—so you have a full view of that relationship history.`,
    type: 'CRM',
    value: 'copper',
  },
  pipedrive: {
    name: 'Pipedrive',
    icon: 'pipedrive-icon',
    desc: `Sync your pipedrive contacts. Create activities for your calls and text messages against your contacts and associated deals. Get click to call & text icons.`,
    type: 'CRM',
    value: 'pipedrive',
  },
  freshdesk: {
    name: 'Freshdesk',
    icon: 'freahdesk-icon',
    desc: `Sync your freshdesk contacts. Create activities for your calls and text messages against your contacts and associated deals. Get click to call & text icons.`,
    type: 'CRM',
    value: 'freshdesk',
  },
  intercom: {
    name: 'Intercom',
    icon: 'intercom-icon',
    desc: `Sync your intercom contacts. Create activities for your calls and text messages against your contacts and associated deals. Get click to call & text icons.`,
    type: 'CRM',
    value: 'intercom',
  },
  monday: {
    name: 'Monday',
    icon: 'intercom-icon',
    desc: `Sync your monday contacts. Create activities for your calls and text messages against your contacts and associated deals. Get click to call & text icons.`,
    type: 'CRM',
    value: 'monday',
  },
}
export default {
  name: 'CreateOrUpdateAddressbook',
  components: {
    ProfileImageUploader,
  },
  inject: [
    'local_filess', 
    'isEmpty', 
    'getProperty',
    'getAddressBooks',
    'appNotify'
  ],
  props: {
    addressbookID: {
      type: [Number,String],
      default: ''
    },
    creating: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      forms: {
        create_addressbook: this.$helperFunction.formInstance({
          data: {
            type: 'local',
            firstname: '',
            lastname: '',
            company: '',
            jobtitle: '',
            nickname: "nickname",
            department: '',
            suffix: '',
            note: '',
            emails: [],
            phones: [
              {
                country: 'GB',
                phone: "Main",
                number: "",
                shortcode: "",
              },
            ],
          }
        }),
        update_addressbook: this.$helperFunction.formInstance({
          data: {
            id: '',
            type: '',
            firstname: '',
            lastname: '',
            company: '',
            jobtitle: '',
            nickname: "nickname",
            department: '',
            suffix: '',
            note: '',
            emails: [],
            phones: [
              {
                country: 'GB',
                phone: "Main",
                number: "",
                shortcode: "",
              },
            ],
          }
        }),
        update_phone_input: this.$helperFunction.formInstance({
          data: {
            country: 'GB',
            phone: 'Main',
            number: '',
            shortcode: '',
            fields: {
              type: true,
              short_code: true,
            },
            index: null,
            create: true,
          },
        }),
        update_email_input: this.$helperFunction.formInstance({
          data: {
            email: '',
            index: null,
            create: true,
          },
        }),
      },
      api: {
        fetch_shortcodes: this.$helperFunction.apiInstance(),
        create_addressbook: this.$helperFunction.apiInstance({
          validation_errors: true,
        }),
        update_addressbook: this.$helperFunction.apiInstance({
          validation_errors: true,
        }),
        fetch_integrated: this.$helperFunction.apiInstance(),
      },
      response: {
        integrateds: [],
        shortcodes: [],
      },
      conditions: {
        collapse: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      GET_LOCAL_SETTING_IS_DARK_MODE,
      'getCurrentUser',
      'getIsMobile'
    ]),
    countries(){ 
      return _.orderBy(moment.tz.countries().map((i)=>({
        value: i,
        text: CountryName2[i],
        flag: flagsCountryCode[i],
      })) , ['text'], ['asc']);
    },
    suffixs(){ return suffixs },
    integrateds(){ return this.response.integrateds?.map?.(i=>i.platform) ?? [] },
    formAllowFields(){
      const type = !this.creating ? this.forms.update_addressbook.type : this.forms.create_addressbook.type
      return {
        firstname: true,
        lastname: true,
        company: ['local','org'].includes(type),
        jobtitle: ['local','org'].includes(type),
        nickname: ['local','org'].includes(type),
        department: ['local','org'].includes(type),
        suffix: ['local','org'].includes(type),
        note: ['local','org'].includes(type),
        phones: {
          allow: true,
          required: true,
          short_code: ['local','org'].includes(type),
          type: ['local','org'].includes(type),
          length: !['local','org'].includes(type) ? 1 : 10,
        },
        emails: {
          allow: true,
          required: !['local','org'].includes(type),
          length: !['local','org'].includes(type) ? 1 : 10,
        },
      }
    },
    typeOptions(){ return [...[{ text: 'Local', value: 'local' },{ text: 'Organizaton', value: 'org' }],...this.response.integrateds].map(i=>({ text: integrations[i.platform]?.name || i.text, value: i.platform || i.value, })) },
    editAddressBook(){ return this.$store.state.common.address_books.data.find((addressbook) => addressbook.real_id == this.addressbookID); },
    filterShortCodes(){ 
      const phones = !this.creating?this.forms.update_addressbook.phones:this.forms.create_addressbook.phones
      const used_short_codes = phones?.map(i=>i.shortcode).filter(i=>!!i)
      return this.response.shortcodes.filter(i=>!used_short_codes.includes(i)) 
    },
  },
  validations: {
    forms: {
      create_addressbook: {
        type: {
          required: required,
        },
        firstname: {
          required: requiredIf(function(){
            return this.formAllowFields.firstname
          }),
          maxLength: maxLength(16),
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        suffix: {
          
        },
        lastname: {
          maxLength: maxLength(16),
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        company: {
          minLength: minLength(3),
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        jobtitle: {
          minLength: minLength(3),
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        department: {
          minLength: minLength(3),
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        emails: {
          $each: {
            emails: {
              required: requiredIf(function(){
                return this.formAllowFields.emails.required
              }),
              email: email,
            },
          }
        },
        phones: {
          minLength: minLength(1),
          $each: {
            phone: {
              required: requiredIf(function(){
                return this.formAllowFields.phones.type
              }),
            },
            number: {
              required: requiredIf(function(){
                return this.formAllowFields.phones.required
              }),
              validNumber(value,obj){ return $fn.validPhoneNumber(value,obj.country) },
            },
            country: {

            },
            shortcode: {

            },
          }
        },
        nickname: {
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        note: {
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
      },
      update_addressbook: {
        id: {
          required: required,
        },
        type: {
          required: required,
        },
        firstname: {
          required: requiredIf(function(){
            return this.formAllowFields.firstname
          }),
          maxLength: maxLength(16),
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        suffix: {
          
        },
        lastname: {
          maxLength: maxLength(16),
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        company: {
          minLength: minLength(3),
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        jobtitle: {
          minLength: minLength(3),
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        department: {
          minLength: minLength(3),
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        emails: {
          $each: {
            emails: {
              required: requiredIf(function(){
                return this.formAllowFields.emails.required
              }),
              email: email,
            },
          }
        },
        phones: {
          minLength: minLength(1),
          $each: {
            phone: {
              required: requiredIf(function(){
                return this.formAllowFields.phones.type
              }),
            },
            number: {
              required: requiredIf(function(){
                return this.formAllowFields.phones.required
              }),
              validNumber(value,obj){ return $fn.validPhoneNumber(value,obj.country) },
            },
            country: {
              // required: required,
            },
            shortcode: {
              // required: required,
            },
          }
        },
        nickname: {
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        note: {
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
      },
      update_phone_input: {
        phone: {
          required: requiredIf(function(){
            return this.forms.update_phone_input.fields.type
          }),
        },
        number: {
          required,
          validNumber(value,obj){ return $fn.validPhoneNumber(value,obj.country) },
        },
        country: {

        },
        shortcode: {
          
        },
      },
      update_email_input: {
        email: {
          required: required,
          email: email,
        },
      },
    },
  },
  methods: {
    setUpdate(){
      const addressbook = this.editAddressBook
      if(!addressbook) return;
      const emails = addressbook.emails ? addressbook.emails?.split?.(";")?.map?.((email) => {
        return {
          emails: email,
        };
      }) ?? [] : [];
      const phones = addressbook.phones.map((phone) => {
        let number = ''
        let country = ''
        const international_number = number_formater(phone.number,'',true)
        const phoneNumber = $fn.getParsePhoneNumber('')(international_number)
        if(phoneNumber && phoneNumber.isValid()){
          number=phoneNumber.formatNational()
          country=phoneNumber.country
        }
        return {
          phone: phone.type,
          country: country,
          number: number,
          shortcode: phone.shortCode,
        };
      });
      const shortCodes = addressbook.phones.map((phone)=>phone.shortCode).filter(shortCode=>!!shortCode);
      this.forms.update_addressbook.id=addressbook.real_id ?? ''
      this.forms.update_addressbook.firstname=addressbook.firstName ?? ''
      this.forms.update_addressbook.lastname=addressbook.lastName ?? ''
      this.forms.update_addressbook.company=addressbook.company ?? ''
      this.forms.update_addressbook.jobtitle=addressbook.jobtitle ?? ''
      this.forms.update_addressbook.nickname=addressbook.nickname ?? ''
      this.forms.update_addressbook.department=addressbook.department ?? ''
      this.forms.update_addressbook.suffix=addressbook.suffix ?? ''
      this.forms.update_addressbook.note=addressbook.note ?? ''
      this.forms.update_addressbook.emails=emails ?? []
      this.forms.update_addressbook.phones=phones ?? []
      this.forms.update_addressbook.type=addressbook.global==1?'org':!addressbook.tags?'local':addressbook.tags
      this.fetchAvailableShortCodes(shortCodes);
    },
    onImageUploaded(image){
      const updated_id = this.forms.update_addressbook.id
      const index = this.$store.state.common.address_books.data.findIndex(addressbook=>addressbook.real_id==updated_id)
      if(index>-1){
        this.$set(this.$store.state.common.address_books.data[index],'profile_image',image)
      }
    },
    onSubmitEmailInput(){
      this.$v.forms.update_email_input.$touch()
      if(this.$v.forms.update_email_input.$invalid) return;
      if(this.forms.update_email_input.create) {
        if(!this.forms.create_addressbook.emails[this.forms.update_email_input.index]) return;
        this.forms.create_addressbook.emails[this.forms.update_email_input.index].emails=this.forms.update_email_input.email
      } else {
        if(!this.forms.update_addressbook.emails[this.forms.update_email_input.index]) return;
        this.forms.update_addressbook.emails[this.forms.update_email_input.index].emails=this.forms.update_email_input.email
      }
      this.$modal.hide(`${this._uid}-UpdateEmail`)
    },
    onSubmitPhoneInput(){
      this.$v.forms.update_phone_input.$touch()
      if(this.$v.forms.update_phone_input.$invalid) return;
      if(this.forms.update_phone_input.create) {
        if(!this.forms.create_addressbook.phones[this.forms.update_phone_input.index]) return;
        this.forms.create_addressbook.phones[this.forms.update_phone_input.index].phone=this.forms.update_phone_input.phone
        this.forms.create_addressbook.phones[this.forms.update_phone_input.index].shortcode=this.forms.update_phone_input.shortcode
        this.forms.create_addressbook.phones[this.forms.update_phone_input.index].number=this.forms.update_phone_input.number
        this.forms.create_addressbook.phones[this.forms.update_phone_input.index].country=this.forms.update_phone_input.country
      } else {
        if(!this.forms.update_addressbook.phones[this.forms.update_phone_input.index]) return;
        this.forms.update_addressbook.phones[this.forms.update_phone_input.index].phone=this.forms.update_phone_input.phone
        this.forms.update_addressbook.phones[this.forms.update_phone_input.index].shortcode=this.forms.update_phone_input.shortcode
        this.forms.update_addressbook.phones[this.forms.update_phone_input.index].number=this.forms.update_phone_input.number
        this.forms.update_addressbook.phones[this.forms.update_phone_input.index].country=this.forms.update_phone_input.country
      }
      this.$modal.hide(`${this._uid}-UpdatePhoneNumber`)
    },
    onInputNumberField(index){
      let obj
      if(this.creating) obj = this.forms.create_addressbook.phones[index]
      else obj = this.forms.update_addressbook.phones[index]
      const phoneNumber = $fn.getParsePhoneNumber('')(obj.number,obj.country)
      if(phoneNumber){
        if(obj.country!=phoneNumber.country) {
          if(this.creating) this.forms.create_addressbook.phones[index].country=phoneNumber.country
          else this.forms.update_addressbook.phones[index].country=phoneNumber.country
        }
      }
    },
    async createAddressbook() {
      this.forms.create_addressbook.submitted = true;
      this.$v.forms.create_addressbook.$touch();
      if (this.api.create_addressbook.send || this.$v.forms.create_addressbook.$invalid) return;
      try {
        this.api.create_addressbook.send = true;
        this.api.create_addressbook.validation_errors = {};
        const phones = await this.forms.create_addressbook.phones.map(phone=>({
          ...phone,
          number: $fn.getNumberFormated(phone.number,phone.country)
        }))
        const { data: { data: address_book } } = await VOIP_API.endpoints.address_books.create({
          firstname: this.forms.create_addressbook.firstname,
          lastname: this.forms.create_addressbook.lastname,
          company: this.forms.create_addressbook.company,
          jobtitle: this.forms.create_addressbook.jobtitle,
          nickname: this.forms.create_addressbook.nickname,
          department: this.forms.create_addressbook.department,
          suffix: this.forms.create_addressbook.suffix,
          note: this.forms.create_addressbook.note,
          emails: this.forms.create_addressbook.emails,
          phones: phones,
          is_global: this.forms.create_addressbook.type=='org',
          flag: this.forms.create_addressbook.type=='org' ? 'global' : 'local',
          integrations: !['org','local'].includes(this.forms.create_addressbook.type) ? this.forms.create_addressbook.type : ''
        })
        const _address_book = $fn.manipulateAddressBook(address_book)
        this.$store.state.common.address_books.total = this.$store.state.common.address_books.total + 1
        this.$store.state.common.address_books.data.unshift(_address_book)
        this.$store.state.global_conditions.add_addressbooks = false;
        this.appNotify({
          message: "Successfully Added!",
          type: "success",
        })
        this.$emit('created',{
          addressbook: _address_book
        })
        this.$root.$emit(events.fetch_call_activity)
      } catch (ex) {
        // console.log(ex)
        this.api.create_addressbook.validation_errors = ex.own_errors || {};
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.forms.create_addressbook.submitted = false;
        this.api.create_addressbook.send = false;
      }
    },
    async updateAddressbook() {
      this.forms.update_addressbook.submitted = true;
      this.$v.forms.update_addressbook.$touch();
      if (this.api.update_addressbook.send || this.$v.forms.update_addressbook.$invalid) return;
      try {
        this.api.update_addressbook.send = true;
        this.api.update_addressbook.validation_errors = {};
        const phones = await this.forms.update_addressbook.phones.map(phone=>({
          ...phone,
          number: $fn.getNumberFormated(phone.number,phone.country)
        }))
        const { data: { data: address_book } } = await VOIP_API.endpoints.address_books.update({
          id: this.forms.update_addressbook.id,
          firstname: this.forms.update_addressbook.firstname,
          lastname: this.forms.update_addressbook.lastname,
          company: this.forms.update_addressbook.company,
          jobtitle: this.forms.update_addressbook.jobtitle,
          nickname: this.forms.update_addressbook.nickname,
          department: this.forms.update_addressbook.department,
          suffix: this.forms.update_addressbook.suffix,
          note: this.forms.update_addressbook.note,
          emails: this.forms.update_addressbook.emails,
          phones: phones,
          is_global: this.forms.update_addressbook.type=='org',
          flag: this.forms.update_addressbook.type=='org' ? 'global' : 'local',
          integrations: !['org','local'].includes(this.forms.update_addressbook.type) ? this.forms.update_addressbook.type : ''
        })
        const _address_book = $fn.manipulateAddressBook(address_book)
        const addressbooks = await this.$store.state.common.address_books.data.map((item)=>item.real_id==this.forms.update_addressbook.id?_address_book:item)
        this.$store.state.common.address_books.data = addressbooks
        this.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
        this.$emit('updated',{
          addressbook: _address_book
        })
        this.$root.$emit(events.fetch_call_activity)
      } catch (ex) {
        this.api.update_addressbook.validation_errors = ex.own_errors;
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.forms.update_addressbook.submitted = false;
        this.api.update_addressbook.send = false;
      }
    },
    async fetchAvailableShortCodes(shortCodes=[]) {
      try {
        this.api.fetch_shortcodes.send = true;
        const { data } = await VOIP_API.endpoints.address_books.shortcodes({
          type: !this.creating ? this.forms.update_addressbook.type : this.forms.create_addressbook.type,
          accountcode: this.getCurrentUser?.account,
          uid: this.getCurrentUser?.uid,
        })
        this.response.shortcodes = [...data,...shortCodes];
      } finally {
        this.api.fetch_shortcodes.send = false;
      }
    },
    async fetchIntegrated(){
      if(this.api.fetch_integrated.send) return;
      try {
        this.api.fetch_integrated.send=true
        const { data } = await VOIP_API.endpoints.integrations.integratedlist({
          accountcode: this.getCurrentUser.account
        })
        this.response.integrateds=data
      } finally {
        this.api.fetch_integrated.send=false
      }
    },
  },
  mounted(){
    this.fetchIntegrated()
    this.fetchAvailableShortCodes()
  },
}
</script>

<style>

</style>