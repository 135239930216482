var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "callActivity-Item-v2-container",
    class: [_vm.call.status == 'Dialed' ? 'Right' : 'Left', {
      'missedCall': _vm.call.status == 'Missed',
      'recievedCall': _vm.call.status == 'Received',
      'dialledCall': _vm.call.status == 'Dialed'
    }]
  }, [_c('div', {
    staticClass: "callActivity-Item-v2-inner-top"
  }, [_c('div', {
    staticClass: "icon-container"
  }, [_vm.call.status == 'Missed' ? _c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: 'Missed Video Call',
      expression: "'Missed Video Call'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    attrs: {
      "icon": "CallActivityV2-missedVideoCall-icon",
      "height": "55.922",
      "width": "55.922"
    }
  }) : _vm.call.status == 'Received' ? _c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: 'Received Video Call',
      expression: "'Received Video Call'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    attrs: {
      "icon": "CallActivityV2-receivedVideoCall-icon",
      "height": "55.922",
      "width": "55.922"
    }
  }) : _c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: 'Dialed Video Call',
      expression: "'Dialed Video Call'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    attrs: {
      "icon": "CallActivityV2-dialledVideoCall-icon",
      "height": "55.922",
      "width": "55.922"
    }
  })], 1), _c('div', {
    staticClass: "user-info"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "user-name",
    attrs: {
      "title": _vm.call.destinationExt
    }
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.destinationName)))])]), _vm._m(0), _c('div', {
    staticClass: "callLogIcons-container"
  }, [_vm.conditions.action.enable ? [_vm.conditions.action.call ? _c('span', {
    staticClass: "mr-8px"
  }, [_c('vb-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": "Audio call",
      "icon": "call-callLog-icon",
      "width": "35px",
      "height": "35px"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('call', {
          type: 'jitsi',
          call_type: 'audio',
          dialable: _vm.call.dialable
        });
      }
    }
  })], 1) : _vm._e(), _vm.conditions.action.call ? _c('span', {
    staticClass: "mr-8px"
  }, [_c('vb-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": "Video call",
      "icon": "videoCall-callLog-icon",
      "width": "35px",
      "height": "35px"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('call', {
          type: 'jitsi',
          call_type: 'video',
          dialable: _vm.call.dialable
        });
      }
    }
  })], 1) : _vm._e(), _vm.conditions.action.info ? _c('span', {
    staticClass: "mr-8px"
  }, [_c('vb-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "icon": "callInfo-callLog-icon",
      "width": "35px",
      "height": "35px",
      "title": `Call info`
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('info');
      }
    }
  })], 1) : _vm._e()] : _vm._e()], 2), _c('div', {
    staticClass: "last-section"
  }, [_c('div', {
    staticClass: "time"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("filter_date_current")(parseInt(_vm.call.date), null, {
    unix: true,
    formate: 'h:mm a'
  })))]), _vm.conditions.design.more ? _vm._t("more", function () {
    return [_vm.conditions.action.enable ? _c('b-dropdown', {
      staticClass: "ico-filter",
      attrs: {
        "varient": "link",
        "no-caret": "",
        "menu-class": "w-fit-content-imp"
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('b-icon', {
            attrs: {
              "icon": "three-dots",
              "variant": "#393939",
              "font-scale": "1.4"
            }
          })];
        },
        proxy: true
      }], null, false, 764454405)
    }, [_vm.conditions.action.call ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.$emit('call', {
            type: 'jitsi',
            call_type: 'audio',
            dialable: _vm.call.dialable
          });
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "telephone",
        "font-scale": "0.96"
      }
    }), _c('span', {
      staticClass: "ml-3"
    }, [_vm._v("Call")])], 1) : _vm._e(), _vm.conditions.action.call ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.$emit('call', {
            type: 'jitsi',
            call_type: 'video',
            dialable: _vm.call.dialable
          });
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "telephone",
        "font-scale": "0.96"
      }
    }), _c('span', {
      staticClass: "ml-3"
    }, [_vm._v("Video call")])], 1) : _vm._e(), _vm.conditions.action.info ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.$emit('info');
        }
      }
    }, [_c('vb-icon', {
      staticClass: "callInfoIcon",
      attrs: {
        "icon": "dialer-callinfo-icon",
        "width": "15.7",
        "height": "15.701"
      }
    }), _c('span', {
      staticClass: "ml-3"
    }, [_vm._v("Call info")])], 1) : _vm._e()], 1) : _vm._e()];
  }) : _vm._e()], 2)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "middle-section"
  }, [_c('span', {
    staticClass: "middle-section-text"
  })]);

}]

export { render, staticRenderFns }