var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_c('section', {
    staticClass: "dialer-settings-section GlobalSettings v2"
  }, [_c('div', {
    staticClass: "settingsFirstSection"
  }, [_vm.getIsMobile ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-width-button w-100"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title w-100 mb-0 text-center"
  }, [_vm._v("Global settings")])], 1)] : [_c('h2', {
    staticClass: "dialer-settings-title mb-0 settingsFirstSection-left newer"
  }, [_vm._v(" Global settings "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon md-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('KbInfoModal', {
          kb: 'globalSettings'
        });
      }
    }
  })], 1)]], 2), _vm._m(0), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox GlobalSetingsSection-1"
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-24px mb-32px"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100 GlobalSetingsSection-1-1"
  }, [_c('div', {
    staticClass: "verySmallBlackBorderedWhiteBox"
  }, [_c('span', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "sm-mar-right",
    attrs: {
      "icon": "latest-clockinsideGlobalSettings-icon",
      "width": "19.292px",
      "height": "19.292px"
    }
  }), _vm._v(" " + _vm._s(_vm.current_date.time) + " ")], 1), _c('span', {
    staticClass: "d-flex align-items-center ml-60px"
  }, [_c('vb-icon', {
    staticClass: "sm-mar-right",
    attrs: {
      "icon": "latest-calenderInsideGlobalSettings-icon",
      "width": "18.006px",
      "height": "17.617px"
    }
  }), _vm._v(" " + _vm._s(_vm.current_date.date) + " ")], 1)])]), _c('div', {
    staticClass: "d-flex align-items-center juystify-content-between GlobalSetingsSection-1-2"
  }, [_c('div', {
    staticClass: "d-flex align-items-center flex-wrap w-100 mb-60px GlobalSetingsSection-1-2-1"
  }, [_c('form', {
    staticClass: "dialer-form mr-32px",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.update.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "profileImg-section-latest-container"
  }, [_c('div', {
    staticClass: "profileImg-section-latest",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('GlobalSettingProfileImageUploader', {
          type: 'COMPANY',
          accountcode: _vm.getCurrentUser.default_accountcode,
          current_image: _vm.getCurrentUser.companyLogo
        });
      }
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.getCurrentUser.companyLogo,
      "alt": 'Company Image'
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.local_filess.images.user_male;
      }
    }
  })])])]), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', [_c('h1', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(`${_vm.getCurrentUser.company}`))]), _c('h4', [_vm._v(_vm._s(` (${_vm.getCurrentUser.uid})`))])])])]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 mt-20px GlobalSetingsSection-1-2-2"
  }, [_c('b-form-select', {
    attrs: {
      "disabled": _vm.api.fetch_account_detail.send || _vm.api.update_time_zone.send,
      "options": _vm.countries
    },
    on: {
      "change": function ($event) {
        _vm.forms.update_time_zone.time_zone = '';
      }
    },
    model: {
      value: _vm.forms.update_time_zone.country,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_time_zone, "country", $$v);
      },
      expression: "forms.update_time_zone.country"
    }
  }), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_time_zone.time_zone,
      expression: "forms.update_time_zone.time_zone"
    }],
    staticClass: "form-control custom-select mt-20px",
    attrs: {
      "disabled": _vm.api.fetch_account_detail.send || _vm.api.update_time_zone.send
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.forms.update_time_zone, "time_zone", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.updateTimeZone();
      }]
    }
  }, _vm._l(_vm.timezones, function (timeZone) {
    return _c('option', {
      key: timeZone
    }, [_vm._v(_vm._s(timeZone))]);
  }), 0)], 1)])])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(1), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "w-fit-content",
    class: {
      'for-disable': !_vm.getUserPermissions.blf
    },
    attrs: {
      "title": _vm.getUserPermissions.blf ? '' : 'Your package does not support this feature'
    }
  }, [_c('b-form-checkbox', {
    ref: "blf_switch",
    staticClass: "newerSwitch ml-4",
    attrs: {
      "disabled": _vm.api.fetch_account_detail.send || _vm.api.update_blf.send || !_vm.getUserPermissions.blf,
      "name": "check-button",
      "switch": "",
      "checked": _vm.forms.update_blf.blf
    },
    on: {
      "change": function ($event) {
        return _vm.updateBLF();
      }
    }
  })], 1)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(2), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "w-fit-content",
    class: {
      'for-disable': !_vm.getUserPermissions.status
    },
    attrs: {
      "title": _vm.getUserPermissions.status ? '' : 'Your package does not support this feature'
    }
  }, [_c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.status ? '' : _vm.$modal.show('StatusesModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-status-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Status list")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(3), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "w-fit-content",
    class: {
      'for-disable': !_vm.getUserPermissions.call_tagging_and_notes
    },
    attrs: {
      "title": _vm.getUserPermissions.call_tagging_and_notes ? '' : 'Your package does not support this feature'
    }
  }, [_c('a', {
    staticClass: "newButton sm-mar-left",
    attrs: {
      "aria-disabled": !_vm.getUserPermissions.call_tagging_and_notes
    },
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.call_tagging_and_notes ? '' : _vm.$modal.show('TagsModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-tags-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Set call tags")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(4), _c('div', {
    staticClass: "w-fit-content"
  }, [_c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-PowerDialerTags`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-tags-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Set power dialer tags")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(5), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "w-fit-content",
    class: {
      'for-disable': !_vm.getUserPermissions.call_pickup
    },
    attrs: {
      "title": _vm.getUserPermissions.call_pickup ? '' : 'Your package does not support this feature'
    }
  }, [_c('a', {
    staticClass: "newButton sm-mar-left",
    attrs: {
      "aria-disabled": !_vm.getUserPermissions.call_pickup
    },
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.call_pickup ? '' : _vm.$modal.show('PickupGroupsModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-show-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("View")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(6), _c('div', [_c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('NumberBlocking');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-show-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("View")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(7), _c('div', [_c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('SoundLibrary');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-show-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("View")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(8), _c('div', [_c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallRecording');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-show-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("View")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(9), _c('div', [_c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('SomethingVoicemailModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-show-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("View")])], 1)])])])]), _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-800 EditUserModal bigSquareCheckbox vm--container scrollable",
    attrs: {
      "name": "SomethingVoicemailModal"
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Voicemail greeting settings")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('SomethingVoicemailModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('UserRecordedVoicemail', {
    staticClass: "s2-mobile",
    attrs: {
      "accountcode": _vm.getCurrentUser.default_accountcode
    }
  })], 1)])]), _c('StatusesModal'), _c('TagsModal'), _c('PowerDialerTagsModal', {
    attrs: {
      "modalName": `${_vm._uid}-PowerDialerTags`
    }
  })], 1), _c('ProfileImageUploader', {
    attrs: {
      "modalName": `GlobalSettingProfileImageUploader`
    },
    on: {
      "image-uploaded": function ($event) {
        '';
      }
    }
  }), _c('PickupGroupsModal'), _c('NumberBlockingModal'), _c('CallRecordingModal'), _c('SoundLibraryModal'), _c('AllExtensionsModal')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settingsSecondSection"
  }, [_c('span', {
    staticClass: "dialer-settings-intro newerTextVersion mt-0 mb-0"
  }, [_vm._v("Global configurations affecting users and devices across the organization")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Busy lamp field")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Use the BLF button to effectively track a user's live status, allowing you to instantly determine their availability or current call status.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Status")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Indicate your availability or current status, such as available, away, or busy.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Call tags")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Call tagging helps organise and analyse call data, enabling easy reporting and customer identification.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Power Dialer tags")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Call tagging helps organise and analyse call data, enabling easy reporting and customer identification.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Pickup groups")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Users within a group can answer calls for each other, ensuring efficient call handling.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Number blocking")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Block unwanted telephone numbers by adding them to a block list, preventing calls to or from those numbers.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Sound library")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("A library of all available sound files for announcements and music on hold, including both our collection and customer uploads.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Call recording")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Record and save audio from phone conversations.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Voicemail ")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Set a voicemail message to be used for all users who haven't personalised their own.")])]);

}]

export { render, staticRenderFns }