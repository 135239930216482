<template>
  <div>
    <div v-if="openedFromModal" class="dialer-edit-header w-100 d-block">
      <div class="d-flex justify-content-between align-items-center">
        <h2 class="dialer-settings-title newer mb-0 d-flex align-items-center">
          <button class="newBackButton mr-16px" @click="$emit('closeModal')">
            <b-icon icon="arrow-left-circle" />
            <span class="newBackButton-textpart">Back</span>
          </button>
          Number blocking
        </h2>
        <div class="dialer-edit-actions">
          <div 
            id="NumberBlockButton" 
            v-b-tooltip :title="getUserPermissions.block_and_privacy?'':'Your package does not support this feature'"
            :class="{
              'for-disable': !getUserPermissions.block_and_privacy
            }"
          >
            <button :disabled="!getUserPermissions.block_and_privacy" class="newButton mwd-200px" @click="$modal.show('AddBlockNumbersModal')">
              <vb-icon icon="squared-add-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Add blocked numbers</span>
            </button>
          </div>
        </div>
      </div>
      <div class="vm--modal-text">
        Block unwanted or spam incoming calls by adding the phone number below.
      </div>
      <div class="whiteBGinputWithGreyRoundedBorder seachBox mt-20px d-flex justify-content-end w-100">
        <b-input placeholder="Search numbers" class="" type="text" v-model.lazy="filter.rules.search" />
      </div>
    </div>
    <div class="dialer-settings-main b-0">
      <section class="dialer-settings-section PrivacySettings p-0">
        <div v-if="!openedFromModal" class="d-flex align-items-start justify-content-between md-mar-top md-mar-bottom">
          <span>Block unwanted or spam incoming calls by adding the phone number below.</span>
          <div class="d-flex flex-column justify-content-end align-items-end w-50">
            <div class="d-flex">
              <div 
                id="NumberBlockButton" 
                v-b-tooltip :title="getUserPermissions.block_and_privacy?'':'Your package does not support this feature'"
                :class="{
                  'for-disable': !getUserPermissions.block_and_privacy
                }"
              >
                <button :disabled="!getUserPermissions.block_and_privacy" class="newButton mwd-200px" @click="$modal.show('AddBlockNumbersModal')">
                  <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                  <span class="newButton-textPart">Add blocked numbers</span>
                </button>
              </div>
            </div>
            <div class="whiteBGinputWithGreyRoundedBorder seachBox mt-20px d-flex justify-content-end w-100">
              <b-input placeholder="Search numbers" class="" type="text" v-model.lazy="filter.rules.search" />
            </div>
          </div>
        </div>
        <vb-table
          :isListEmpty="isEmpty(filterRules)" 
          :listLength="pagination.rules.total" 
          :perPage="pagination.rules.per_page" 
          :loading="api.rules.send" 
          @page-changed="pagination.rules.page=$event;fetchRules();"
          :conditions="{ pagination:{ per_page: false } }"
          :page="pagination.rules.page"
          class="latestTableDesign-withBlackBorders-again mb-0 mt-2"
          :noRecordWholePage="true"
        >
          <template slot="header" v-if="!$store.getters.getIsMobile">
            <tr>
              <th class="dialer-has-sort one">
                <span>Number</span>
              </th>
              <th class="dialer-has-sort three">Label</th> 
              <th class="dialer-has-sort dialer-col-right" style="max-width: 115px;width: 115px;"></th>
            </tr>
          </template>
          <template #body>
            <template v-if="!$store.getters.getIsMobile">
              <tr 
                class="dialer-row-select" 
                v-for="rule in filterRules" 
                :key="rule.id" 
                @click="!getUserPermissions.block_and_privacy ? appNotify({
                  message:'Your package does not support this feature',
                  type:'danger'
                }) : api.delete.send==rule.id ? '' : $modal.show('EditNumberBlockModal', { 
                  number: rule 
                })"
              >
                <td class="dialer-row-title one w-75">
                  <div class="d-flex align-items-center">
                    <div class=" bg-transparent">
                      <vb-icon v-if="rule.type == 'both'" icon="NumberBlocking-inOut-icon" class="redC" height="13.922" width="13.922"/>
                      <vb-icon v-if="rule.type == 'in'" icon="analyticsReporting-receiveCall-icon" height="13.922" width="13.922"/>
                      <vb-icon v-if="rule.type == 'out'" icon="analyticsReporting-dialledCall-icon" height="13.922" width="13.922"/>
                    </div>
                    <div v-b-popover.hover.top="rule.callerid" class="ml-2">{{ (rule.callerid_format || rule.callerid) | number_formater }}</div>
                  </div>
                </td>
                <td class="dialer-col-right three w-25">
                  <div class="d-flex align-items-center">
                    <div>{{ rule.displayname }}</div>
                  </div>
                </td>
                <td class="dialer-row-title dialer-col-right" style="max-width: 115px;width: 115px;">
                  <span class="newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content w-fit-content ml-0 mr-0 showOnly-OnHover-insideTable" > 
                    Configure
                  </span>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr 
                class="dialer-row-select" 
                v-for="rule in filterRules" 
                :key="rule.id" 
                @click="!getUserPermissions.block_and_privacy ? appNotify({
                  message:'Your package does not support this feature',
                  type:'danger'
                }) : api.delete.send==rule.id ? '' : $modal.show('EditNumberBlockModal', { 
                  number: rule 
                })"
              >
                <td class="left">
                  <div class="d-flex align-items-center">
                    <div class=" bg-transparent">
                      <vb-icon v-if="rule.type == 'both'" icon="NumberBlocking-inOut-icon" class="redC" height="25px" width="25px"/>
                      <vb-icon v-if="rule.type == 'in'" icon="analyticsReporting-receiveCall-icon" height="25px" width="25px"/>
                      <vb-icon v-if="rule.type == 'out'" icon="analyticsReporting-dialledCall-icon" height="25px" width="25px"/>
                    </div>
                    <div v-b-popover.hover.top="rule.callerid" class="headingInsideTable ml-2">{{ (rule.callerid_format || rule.callerid) | number_formater }}</div>
                  </div>
                </td>
                <td class="dialer-col-right right">
                  <div class="textInsideCard">
                    <div>{{ rule.displayname }}</div>
                  </div>
                </td>
              </tr>
            </template>
          </template>
          <template #loading>
            <tr v-for="n in 5" :key="n">
              <td>
                <div class="d-flex align-items-center w-fit-content">
                  <div class="latestShimmerDesign mr-2" style="height:21px;width:21px;"></div>
                  <div class="latestShimmerDesign flex-1" style="height:21px;width:154px"></div>
                </div>
              </td>
              <td class="dialer-col-right">
                <div class="latestShimmerDesign " style="height:21px;width:150px;"></div>
              </td>
            </tr>
          </template>
          <template #no-record>
            <div class="latestGreyBox-9-9-2023 mb-32px">
              <div class="emptyScreenContainer">
                <img class="mt-12px wd-30 h-auto" v-if="GET_LOCAL_SETTING_IS_DARK_MODE" :src="require('@/assets/images/emptyScreenImages/darkMode/numberBlocking.png')"/>
                <img class="mt-12px wd-30 h-auto" v-else :src="require('@/assets/images/emptyScreenImages/numberBlocking.png')"/>
                <div class="emptyScreenContainer-heading mt-32px">
                  No blocked numbers yet
                </div>
                <div class="emptyScreenContainer-text w-75">
                  Start managing your blocked numbers to control your call experience
                </div>
                <div 
                  v-b-tooltip :title="getUserPermissions.block_and_privacy?'':'Your package does not support this feature'"
                  :class="{
                    'for-disable': !getUserPermissions.block_and_privacy
                  }"
                >
                  <button :disabled="!getUserPermissions.block_and_privacy" class="newButton mwd-200px mt-20px" @click="$modal.show('AddBlockNumbersModal')">
                    <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                    <span class="newButton-textPart">Block a number</span>
                  </button>
                </div>
              </div>
            </div>
          </template>
        </vb-table>
        <AddBlockNumbersModal @interface="afterAdding" />
        <EditBlockNumberModal @interface="afterAdding" />
        <UsersCallRecordingModal />
      </section>
    </div>
  </div>
</template>

<script>
import AddBlockNumbersModal from "../Modals/AddBlockNumbersModal.vue";
import EditBlockNumberModal from "../Modals/EditBlockNumberModal.vue";
import UsersCallRecordingModal from "../Modals/UsersCallRecordingModal";
import { VOIP_API } from "../../utils";
import { mapGetters } from 'vuex';
import { GET_LOCAL_SETTING_IS_DARK_MODE } from '@/store/helper/getters';
export default {
  name: "PrivacySettings",
  props: {
    openedFromModal: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AddBlockNumbersModal,
    EditBlockNumberModal,
    UsersCallRecordingModal,
  },
  data() {
    return {
      response: {
        rules: [],
      },
      pagination: {
        rules: {
          per_page: 10,
          page: 1,
          total: 0,
        },
      },
      filter: {
        rules: {
          search: '',
          direction: '',
        }
      },
      api: {
        rules: this.$helperFunction.apiInstance(),
        delete: this.$helperFunction.apiInstance({ send: '' }),
      },
    };
  },
  inject:['isEmpty','appNotify'],
  computed: {
    ...mapGetters({
      getUserPermissions: 'getUserPermissions',
      GET_LOCAL_SETTING_IS_DARK_MODE: GET_LOCAL_SETTING_IS_DARK_MODE,
    }),
    filterRules(){
      const search = this.filter.rules.search.trim().toLowerCase()
      const direction = this.filter.rules.direction
      const rules = this.response.rules
      const filter_rules = rules.filter(item=>
        `${(item.callerid || '').replace(/-/g,'')} ${item.displayname}`.trim().toLowerCase().includes(search) &&
        (!direction || item.type == direction)
      )
      return filter_rules
    },
    directionOptions(){
      return [
        {
          text: 'All',
          value: '',
        },
        {
          text: 'Inbound',
          value: 'in',
        },
        {
          text: 'Outbound',
          value: 'out',
        },
        {
          text: 'in and out bound',
          value: 'both',
        },
      ]
    },
  },
  filters: {
    direction(value) {
      if (value === "in") return "Inbound";
      else if (value === "out") return "Outbound";
      else if (value === "both") return "in and out bound";
      return "";
    },
  },
  methods: {
    fetchRules() {
      let vm = this
      if(vm.api.rules.send) return;
      vm.api.rules.send=true
      VOIP_API.endpoints.inbound_rule.list({
        fid: 12,
        page: vm.pagination.rules.page,
      }).then(({ data, no_record }) => {
        if(no_record){
          vm.response.rules = [];
          if(vm.pagination.rules.page>1){
            vm.pagination.rules.total=0
          } else {
            vm.pagination.rules.page=vm.pagination.rules.page-1
            vm.fetchRules()
          }
        } else {
          vm.response.rules = data?.data?.list ?? [];
          vm.pagination.rules.total=data?.total ?? 0
        }
      }).catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(() => {
        vm.api.rules.send=false
      });
    },
    afterAdding() {
      let vm = this
      setTimeout(() => {
        vm.pagination.rules.page=1
        vm.fetchRules();
      }, 2 * 1000);
    },
    deleteInbound(rule){
      let vm = this
      if(vm.api.delete.send) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this ${rule.displayname} number`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.delete.send=rule.id
            VOIP_API.endpoints.inbound_rule.delete(rule.id)
            .then(()=>{
              vm.fetchRules()
              vm.appNotify({
                message: 'Successfully Deleted!',
                type: 'success',
              })
            })
            .catch((ex)=>{
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            })
            .finally(()=>{
              vm.api.delete.send=''
            })
          }
        },
      });
    },
  },
  mounted() {
    this.pagination.rules.page=1
    this.fetchRules();
  },
};
</script>