var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_c('section', {
    staticClass: "dialer-settings-section dialer-account-setting"
  }, [_c('div', {
    staticClass: "dialer-settings-top"
  }, [_vm.getIsMobile ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-width"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title w-100 mb-0 text-center"
  }, [_vm._v("Billing")])], 1)] : _c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_c('span', [_vm._v("Billing")]), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon md-mar-left",
    attrs: {
      "id": "ivr-info",
      "icon": "info-circle-fill"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('KbInfoModal', {
          kb: 'billing'
        });
      }
    }
  })], 1)], 2), _c('div', {
    staticClass: "dialer-settings-section_inner p-0 no-padding"
  }, [_vm.isAnyUnPaidInvoice ? _c('div', {
    staticClass: "innerRedBoxwithRoundedBorder mt-20px"
  }, [_c('vb-icon', {
    staticClass: "AccountBalance-newBilling-icon",
    attrs: {
      "icon": "AccountBalance-newBilling-icon",
      "height": "25px",
      "width": "25px"
    }
  }), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between flex-fill"
  }, [_c('p', {
    staticClass: "innerRedBoxwithRoundedBorder-text mb-0"
  }, [_vm._v(" Unpaid invoices, please pay now so that there is no disruption of services. ")]), _c('span', {
    staticClass: "OwnertaggedVersion mb-0 showOnly-OnHover-insideTable cursor_pointer_no_hover",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-unpaidInvoices`);
      }
    }
  }, [_vm._v("Pay now")])])], 1) : _vm._e(), _c('div', {
    staticClass: "latestBilling-section-1"
  }, [_c('div', {
    staticClass: "latestBilling-section-1-card firstCard"
  }, [_vm.api.subscription_detail.send ? [_c('vb-icon', {
    staticClass: "latestBilling-section-1-card-icon",
    attrs: {
      "icon": "PlanIcon-newBilling-icon"
    }
  }), _vm._m(0)] : [_c('vb-icon', {
    staticClass: "latestBilling-section-1-card-icon",
    attrs: {
      "icon": "PlanIcon-newBilling-icon"
    }
  }), _c('div', {
    staticClass: "latestBilling-section-1-textSection"
  }, [_c('div', {
    staticClass: "latestBilling-section-1-cardType"
  }, [_vm._v(" Current Plan "), _vm.isOnTrail ? _c('span', {
    staticClass: "OwnertaggedVersion mb-0 mt-0 mr-16px"
  }, [_vm._v(_vm._s('Trial'))]) : _vm._e(), _vm.currentPlanSuspended ? _c('span', {
    staticClass: "suspendedTag"
  }, [_vm._v(_vm._s(_vm.currentPlanSuspended ? 'Suspended' : ''))]) : _vm._e()]), _c('div', {
    staticClass: "latestBilling-section-1-PackageLabel"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.subscription_detail, 'userCurrentSubscription.plan_detail.plan_label')))]), _c('div', {
    staticClass: "latestBilling-section-1-PackageText"
  }, [_vm._v(" Your current plan is " + _vm._s(_vm._f("get_property")(_vm.response.subscription_detail, 'userCurrentSubscription.plan_detail.plan_label')) + ". "), !_vm.isOnFreePlan ? [_vm.response.subscription_detail && _vm.response.subscription_detail.next_billing_date && !_vm.isOnFreePlan ? _c('span', [_vm._v(" The " + _vm._s(_vm.isOnTrail ? 'trial expiration date' : 'next billing date') + " is "), _c('span', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: `Subscription date: ${_vm.response.subscription_detail.current_date}`,
      expression: "`Subscription date: ${response.subscription_detail.current_date}`",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm._f("showTime")(_vm._f("get_property")(_vm.response.subscription_detail, 'next_billing_date'))) + ".")])]) : _vm._e()] : _vm._e()], 2), _c('div', {
    staticClass: "latestBilling-section-1-lastRow mt-20px"
  }, [!_vm.currentPlanSuspended && _vm.isOnTrail ? _c('button', {
    staticClass: "dialer-button dialer-button-primary",
    on: {
      "click": function ($event) {
        return _vm.cancelTrail();
      }
    }
  }, [_vm._v("Cancel Trial")]) : _vm._e(), _vm.currentPlanSuspended && _vm.isOnTrail ? _c('button', {
    staticClass: "dialer-button dialer-button-primary",
    on: {
      "click": function ($event) {
        return _vm.reactivateServices();
      }
    }
  }, [_vm._v("Reactivate Services")]) : _vm._e(), _c('button', {
    staticClass: "latestBilling-section-1-button ml-auto",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('SubscriptionPlanDetailModal');
      }
    }
  }, [_vm._v(" Change plan ")])])])]], 2), _c('div', {
    staticClass: "latestBilling-section-1-card secondCard"
  }, [_vm.api.cards.send && !_vm.primaryCard ? [_c('vb-icon', {
    staticClass: "latestBilling-section-1-card-icon",
    attrs: {
      "icon": "masterCard-newBilling-icon2"
    }
  }), _vm._m(1)] : [_vm.primaryCard ? [_c('vb-icon', {
    staticClass: "latestBilling-section-1-card-icon",
    attrs: {
      "icon": "masterCard-newBilling-icon2"
    }
  }), _c('div', {
    staticClass: "latestBilling-section-1-textSection"
  }, [_c('div', {
    staticClass: "latestBilling-section-1-cardType"
  }, [_vm._v("Payment card")]), _c('div', {
    staticClass: "latestBilling-section-1-creditCardNumberPart"
  }, [_c('div', {
    staticClass: "latestBilling-section-1-creditCardNumber"
  }, [_vm._m(2), _vm._m(3), _vm._m(4), _c('div', {
    staticClass: "latestBilling-section-1-creditCardNumber-text"
  }, [_vm._v(" " + _vm._s(_vm._f("get_property")(_vm.primaryCard, 'digits', '0000')) + " ")])]), _c('div', {
    staticClass: "latestBilling-section-1-creditCardNumberPart-cardHolder"
  }, [_vm._v(_vm._s(_vm.primaryCard.label))]), _c('div', {
    staticClass: "latestBilling-section-1-creditCardNumberPart-expiryDate"
  }, [_vm._v(" " + _vm._s(_vm._f("get_property")(_vm.primaryCard, 'expiry_month', '00')) + "/" + _vm._s(_vm._f("get_property")(_vm.primaryCard, 'expiry_year', '00')) + " ")]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100 mt-8px"
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-secondary color-primary font-weight-700 small px-4 border-0",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-OtherCreditCard`);
      }
    }
  }, [_vm._v(" Manage card ")])])])])] : [_c('vb-icon', {
    staticClass: "latestBilling-section-1-card-icon",
    attrs: {
      "icon": "masterCard-newBilling-icon2"
    }
  }), _c('div', {
    staticClass: "latestBilling-section-1-textSection"
  }, [_c('div', {
    staticClass: "latestBilling-section-1-cardType"
  }, [_vm._v("Payment card")]), _c('div', {
    staticClass: "latestBilling-section-1-creditCardNumberPart"
  }, [_vm._m(5), _c('p', {
    staticClass: "font-14"
  }, [_vm._v(" Add your payment method now and elevate your experience with VoIP Business! ")]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-wnd w-100 mt-8px"
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddCardStripe');
      }
    }
  }, [_vm._v("Add card")])])])])]]], 2)]), _c('div', {
    staticClass: "latestBilling-tabsSection"
  }, [_c('div', {
    staticClass: "TabsDesignWithIcon-container"
  }, _vm._l(_vm.tabs, function (allTab) {
    return _c('div', {
      key: allTab.id,
      staticClass: "TabsDesignWithIcon-item",
      class: {
        'active': _vm.selected.tab == allTab.value,
        'mwd-168px': allTab.value == 'balance'
      },
      attrs: {
        "id": `popover-1-${allTab.value}`
      },
      on: {
        "click": function ($event) {
          _vm.selected.tab = allTab.value;
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": allTab.icon,
        "height": "38px",
        "width": "38px"
      }
    }), _c('span', {
      staticClass: "TabsDesignWithIcon-item-textPart"
    }, [_vm._v(_vm._s(allTab.text))]), _c('b-popover', {
      attrs: {
        "custom-class": "latestTooltipVersion",
        "target": `popover-1-${allTab.value}`,
        "placement": "bottom",
        "triggers": "hover",
        "content": `${allTab.tooltipText}`
      }
    })], 1);
  }), 0), _c('div', {
    staticClass: "latestBilling-balancesSection"
  }, [_c('div', {
    staticClass: "latestBilling-balancesSection-leftSide"
  }, [_c('div', {
    staticClass: "latestBilling-balancesSection-balance"
  }, [_vm._v("£" + _vm._s(_vm._f("to_fixed")(_vm._f("get_property")(_vm.response.invoice_detail, 'user_balance', '0.00'))))]), _c('div', {
    staticClass: "latestBilling-balancesSection-balanceTitle"
  }, [_vm._v("Call credit")])]), _c('div', {
    staticClass: "latestBilling-balancesSection-rightSide"
  }, [_c('div', {
    staticClass: "latestBilling-balancesSection-balance"
  }, [_vm._v("£" + _vm._s(_vm._f("to_fixed")(_vm._f("get_property")(_vm.response.sms_balance, 'sms_balance', '0.00'))))]), _c('div', {
    staticClass: "latestBilling-balancesSection-balanceTitle"
  }, [_vm._v("SMS credit")])])])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 pt-3 mt-30px"
  }, [_vm.selected.tab == 'invoices' ? [_c('vb-table', {
    staticClass: "trustedDevicesTable-updated latestTableDesign-withBlackBorders-again mt-0",
    attrs: {
      "noRecordWholePage": true,
      "isListEmpty": _vm.response.invoices.length == 0,
      "loading": _vm.api.invoices.send,
      "listLength": _vm.pagination.invoices.total,
      "perPage": 10,
      "conditions": {
        pagination: {
          per_page: false
        }
      },
      "page": _vm.pagination.invoices.page
    },
    on: {
      "page-changed": function ($event) {
        _vm.pagination.invoices.page = $event;
        _vm.fetchInvoices(false);
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [!_vm.getIsMobile ? _vm._l(_vm.response.invoices, function (invoice) {
          return _c('tr', {
            key: invoice.id
          }, [_c('td', {
            staticClass: "border-0"
          }, [_vm._v(_vm._s(_vm._f("filterInvoiceType")(invoice)))]), _c('td', {
            staticClass: "border-0"
          }, [_vm._v(_vm._s(_vm._f("to_fixed")(invoice.invoice_total_amount)))]), _c('td', {
            staticClass: "border-0"
          }, [_vm._v(_vm._s(_vm._f("filter_date_current")(invoice.invoice_paid_at, false, {
            formate: 'DD MMM YYYY'
          })))]), _c('td', {
            staticClass: "border-0"
          }, [_c('span', {
            class: `OwnertaggedVersion ${invoice.invoice_status != 'PAID' ? 'red' : 'green'}`
          }, [_vm._v(_vm._s(invoice.invoice_status))])]), _c('td', {
            staticClass: "border-0"
          }, [_c('div', {
            staticClass: "d-flex justify-content-end w-100"
          }, [invoice.invoice_status != 'PAID' ? _c('span', {
            staticClass: "OwnertaggedVersion mb-0 mr-16px showOnly-OnHover-insideTable",
            on: {
              "click": function ($event) {
                return _vm.payOutstandingAmount(invoice);
              }
            }
          }, [_vm._v(" Pay now ")]) : _vm._e(), _c('span', {
            staticClass: "text-underline cursor_pointer_no_hover",
            on: {
              "click": function ($event) {
                return _vm.$modal.show('InvoicePDFDownloadAccountSetting', {
                  id: invoice.invoice_id
                });
              }
            }
          }, [_vm._v("View invoice")])])])]);
        }) : _vm._l(_vm.invoices, function (invoice, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= _vm.start && index <= _vm.end,
              expression: "index >= start && index <= end"
            }],
            key: invoice.id
          }, [_c('td', {
            staticClass: "left"
          }, [_c('div', {
            staticClass: "headingInsideTable"
          }, [_vm._v(_vm._s(_vm._f("filterInvoiceType")(invoice)))]), _c('div', {
            staticClass: "textInsideCard"
          }, [_vm._v("Amount: " + _vm._s(_vm._f("to_fixed")(invoice.invoice_total_amount)))]), _c('div', {
            staticClass: "textInsideCard"
          }, [_vm._v("Paid at: " + _vm._s(_vm._f("filter_date_current")(invoice.invoice_paid_at, false, {
            formate: 'DD MMM YYYY'
          })))])]), _c('td', {
            staticClass: "dialer-col-right right"
          }, [_c('span', {
            class: `OwnertaggedVersion ${invoice.invoice_status != 'PAID' ? 'red' : 'green'}`
          }, [_vm._v(_vm._s(invoice.invoice_status))]), _c('br'), invoice.invoice_status != 'PAID' ? _c('span', {
            staticClass: "OwnertaggedVersion mb-0 mr-16px showOnly-OnHover-insideTable",
            on: {
              "click": function ($event) {
                return _vm.payOutstandingAmount(invoice);
              }
            }
          }, [_vm._v(" Pay now ")]) : _vm._e(), _c('span', {
            staticClass: "text-underline cursor_pointer_no_hover",
            on: {
              "click": function ($event) {
                return _vm.$modal.show('InvoicePDFDownloadAccountSetting', {
                  id: invoice.invoice_id
                });
              }
            }
          }, [_vm._v("View invoice")])])]);
        })];
      },
      proxy: true
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "19px",
              "width": "135px"
            }
          })])]);
        });
      },
      proxy: true
    }, {
      key: "no-record",
      fn: function () {
        return [_c('div', {
          staticClass: "latestGreyBox-9-9-2023 mt-32px p-0"
        }, [_c('div', {
          staticClass: "emptyScreenContainer"
        }, [_c('img', {
          staticClass: "mt-12px wd-30 h-auto",
          attrs: {
            "src": require('@/assets/images/emptyScreenImages/invoices.png')
          }
        }), _c('div', {
          staticClass: "emptyScreenContainer-heading"
        }, [_vm._v(" Manage invoices and credit notes ")]), _c('div', {
          staticClass: "emptyScreenContainer-text w-75"
        }, [_vm._v(" View and manage your invoices and credit notes here. Start by creating a new invoice or credit note. ")])])])];
      },
      proxy: true
    }], null, false, 3695684721)
  }, [!_vm.getIsMobile ? _c('tr', {
    staticClass: "w-100",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("Invoice")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("Amount")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("Date")])]), _c('th', {
    staticClass: "border-0"
  }), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_c('br')])])]) : _vm._e()]), _c('div', {
    staticClass: "latestGreyBox-heading-main-18px w-fit-content text-underline cursor_pointer_no_hover mt-20px",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('InvoiceDetailModal', {
          detail: _vm.response.account_detail.user_detail
        });
      }
    }
  }, [_vm._v(" My invoice details ")])] : _vm.selected.tab == 'users' ? [_c('vb-table', {
    staticClass: "trustedDevicesTable-updated latestTableDesign-withBlackBorders-again mt-0",
    attrs: {
      "isListEmpty": _vm.users.length == 0,
      "listLength": _vm.users.length,
      "perPage": 5,
      "loading": _vm.api.account_detail.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.users, function (account, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: account,
            staticClass: "w-100"
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('Info', {
            attrs: {
              "id": account,
              "is_blf": false
            }
          })], 1), _c('td', {
            staticClass: "dialer-has-sort dialer-col-right"
          }, [_c('div', {
            staticClass: "d-flex justify-content-end"
          }, [_vm.response.account_detail.users[account].cancel_request > 0 ? _c('p', [_vm._v("Cancel at " + _vm._s(_vm._f("get_property")(_vm.response.subscription_detail, 'userCurrentSubscription.subscription_next_billing_date')))]) : _vm._e(), _vm.api.change_prefixes.send == account ? _c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "34px",
              "width": "60px",
              "border-radius": "60px"
            }
          }) : _c('b-form-checkbox', {
            ref: `${account}-toggle`,
            refInFor: true,
            staticClass: "newerSwitch ml-auto",
            attrs: {
              "switch": "",
              "disabled": !!_vm.api.change_prefixes.send || _vm.api.account_detail.send,
              "checked": _vm.response.account_detail.users[account].plan_prefix_type == 'unlimited'
            },
            on: {
              "change": function ($event) {
                return _vm.changePrefix(account);
              }
            }
          })], 1)])]);
        });
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "35px",
              "width": "35px"
            }
          }), _c('div', {
            staticClass: "d-flex flex-column justify-content-between"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "16px",
              "width": "60px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign mt-1",
            staticStyle: {
              "height": "16px",
              "width": "30px"
            }
          })])])]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "32px",
              "width": "60px"
            }
          })])]);
        });
      },
      proxy: true
    }])
  }, [_c('tr', {
    staticClass: "w-100",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', [_c('span', [_vm._v("Name")])]), _c('th', {
    staticClass: "text-right"
  }, [_c('span', [_vm._v("Unlimited call plan")])])])])] : _vm.selected.tab == 'whmcs' ? [_c('ul', {
    staticClass: "list-unstyled mb-0"
  }, [_c('li', [_c('vb-table', {
    staticClass: "trustedDevicesTable-updated latestTableDesign-withBlackBorders-again mt-0 paddingZeroInMobile",
    attrs: {
      "isListEmpty": Object.keys(_vm.subscriptionProductsGroupBy).length == 0,
      "listLength": Object.keys(_vm.subscriptionProductsGroupBy).length,
      "perPage": 5,
      "loading": _vm.api.fetch_subscriptions.send
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [!_vm.getIsMobile ? _c('tr', {
          staticClass: "w-100"
        }, [_c('th', {
          staticClass: "dialer-has-sort"
        }, [_c('span', [_vm._v("name")])]), _c('th', {
          staticClass: "dialer-has-sort"
        }, [_c('span', [_vm._v("type")])]), _c('th', {
          staticClass: "dialer-has-sort"
        }, [_c('span', [_vm._v("next billing date")])]), _c('th', {
          staticClass: "dialer-has-sort"
        }, [_c('span', [_vm._v("amount")])]), _c('th', {
          staticClass: "dialer-has-sort"
        }, [_c('span', [_vm._v("status")])]), _c('th', {
          staticClass: "dialer-has-sort text-right"
        }, [_c('span', [_vm._v("billing frequency")])])]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "body",
      fn: function (_ref2) {
        var start = _ref2.start,
          end = _ref2.end;
        return [!_vm.getIsMobile ? [_vm._l(_vm.subscriptionProductsGroupBy, function (data, key, index) {
          return [_c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: key
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(_vm._f("get_property")(data, 'length')) + "x")]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(key))]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', [_vm._v(_vm._s(_vm._f("get_property")(data, '[0].nextBillingDate')))])]), _c('td', {
            staticClass: "dialer-col-left"
          }, [_vm._v(_vm._s(_vm._f("to_fixed")(_vm._f("subscriptionGroupTotalPrice")(data))))]), _c('td', {
            staticClass: "dialer-col-left"
          }, [_c('span', {
            staticClass: "OwnertaggedVersion",
            class: {
              'green': data[0] && data[0].status == 'ACTIVE'
            }
          }, [_vm._v(" " + _vm._s(_vm._f("get_property")(data, '[0].status')) + " ")])]), _c('td', {
            staticClass: "dialer-row-title dialer-col-right"
          }, [_vm._v(" " + _vm._s(_vm._f("get_property")(data, '[0].billingFrequency')) + " "), _c('b-button', {
            attrs: {
              "variant": "link"
            },
            on: {
              "click": function ($event) {
                _vm.conditions.collapse = _vm.conditions.collapse == key ? '' : key;
              }
            }
          }, [_c('b-icon', {
            attrs: {
              "icon": _vm.conditions.collapse == key ? 'chevron-up' : 'chevron-down'
            }
          })], 1)], 1)]), _vm.conditions.collapse == key ? [_c('tr', {
            key: `collapse-${key}`,
            staticClass: "w-100"
          }, [_c('td', {
            staticClass: "w-100 tableInsideTable-contaienr",
            attrs: {
              "colspan": "6"
            }
          }, [_c('table', {
            staticClass: "w-100 tableInsideTable mt-0"
          }, [_c('thead', [_c('tr', {
            staticClass: "w-100"
          }, [_c('th', {
            staticClass: "dialer-has-sort"
          }, [_c('span', [_vm._v("name")])]), _c('th', {
            staticClass: "dialer-has-sort"
          }, [_c('span', [_vm._v("type")])]), _c('th', {
            staticClass: "dialer-has-sort"
          }, [_c('span', [_vm._v("next billing date")])]), _c('th', {
            staticClass: "dialer-has-sort"
          }, [_c('span', [_vm._v("amount")])]), _c('th', {
            staticClass: "dialer-has-sort"
          }, [_c('span', [_vm._v("status")])]), _c('th', {
            staticClass: "dialer-has-sort text-right"
          }, [_c('span', [_vm._v("billing frequency")])])])]), _c('tbody', {
            staticClass: "w-100"
          }, _vm._l(data, function (subs, subs_index) {
            return _c('tr', {
              key: subs_index,
              staticClass: "w-100"
            }, [_c('td', {
              staticClass: "dialer-row-title"
            }, [_vm._v(" " + _vm._s(_vm._f("get_property")(subs, 'subscriptionName')) + " ")]), _c('td', {
              staticClass: "dialer-row-title"
            }, [_vm._v(_vm._s(_vm._f("get_property")(subs, 'type')))]), _c('td', {
              staticClass: "dialer-row-title"
            }, [_c('span', [_vm._v(_vm._s(_vm._f("get_property")(subs, 'nextBillingDate')))])]), _c('td', {
              staticClass: "dialer-col-left"
            }, [_vm._v(_vm._s(_vm._f("to_fixed")(subs.amountDue)))]), _c('td', {
              staticClass: "dialer-col-left"
            }, [_c('span', {
              staticClass: "OwnertaggedVersion",
              class: {
                'green': subs.status == 'ACTIVE'
              }
            }, [_vm._v(" " + _vm._s(_vm._f("get_property")(subs, 'status')) + " ")])]), _c('td', {
              staticClass: "dialer-row-title dialer-col-right"
            }, [_vm._v(_vm._s(_vm._f("get_property")(subs, 'billingFrequency')))])]);
          }), 0)])])])] : _vm._e()];
        })] : [_vm._l(_vm.subscriptionProductsGroupBy, function (data, key, index) {
          return [_c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: key
          }, [_c('td', {
            staticClass: "left"
          }, [_c('div', {
            staticClass: "headingInsideTable"
          }, [_vm._v(_vm._s(_vm._f("get_property")(data, 'length')) + "x " + _vm._s(key))]), _c('div', {
            staticClass: "textInsideCard"
          }, [_vm._v(_vm._s(_vm._f("to_fixed")(_vm._f("subscriptionGroupTotalPrice")(data))))]), _c('div', {
            staticClass: "textInsideCard"
          }, [_vm._v(_vm._s(_vm._f("get_property")(data, '[0].billingFrequency')))])]), _c('td', {
            staticClass: "dialer-row-title right pr-1"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('span', {
            staticClass: "OwnertaggedVersion",
            class: {
              'green': data[0] && data[0].status == 'ACTIVE'
            }
          }, [_vm._v(" " + _vm._s(_vm._f("get_property")(data, '[0].status')) + " ")]), _c('b-button', {
            staticClass: "p-0",
            attrs: {
              "variant": "link",
              "font-scale": "0.8"
            },
            on: {
              "click": function ($event) {
                _vm.conditions.collapse = _vm.conditions.collapse == key ? '' : key;
              }
            }
          }, [_c('b-icon', {
            attrs: {
              "icon": _vm.conditions.collapse == key ? 'chevron-down' : 'chevron-right'
            }
          })], 1)], 1)])]), _vm.conditions.collapse == key ? [_c('tr', {
            key: `collapse-${key}`,
            staticClass: "w-100"
          }, [_c('td', {
            staticClass: "w-100 tableInsideTable-contaienr",
            attrs: {
              "colspan": "6"
            }
          }, [_c('table', {
            staticClass: "w-100 tableInsideTable mt-0"
          }, [_c('tbody', {
            staticClass: "w-100"
          }, _vm._l(data, function (subs, subs_index) {
            return _c('tr', {
              key: subs_index,
              staticClass: "w-100"
            }, [_c('td', {
              staticClass: "left"
            }, [_c('div', {
              staticClass: "headingInsideTable"
            }, [_vm._v(_vm._s(_vm._f("get_property")(subs, 'subscriptionName')))]), _c('div', {
              staticClass: "textInsideCard"
            }, [_vm._v(_vm._s(_vm._f("get_property")(subs, 'type')))]), _c('div', {
              staticClass: "textInsideCard"
            }, [_vm._v(_vm._s(_vm._f("to_fixed")(subs.amountDue)) + " / " + _vm._s(_vm._f("get_property")(subs, 'billingFrequency')))]), _c('div', {
              staticClass: "textInsideCard"
            }, [_vm._v(_vm._s(_vm._f("get_property")(subs, 'nextBillingDate')))])]), _c('td', {
              staticClass: "right dialer-col-right"
            }, [_c('div', {
              staticClass: "d-flex align-items-center justify-content-end"
            }, [_c('span', {
              staticClass: "OwnertaggedVersion",
              class: {
                'green': subs.status == 'ACTIVE'
              }
            }, [_vm._v(" " + _vm._s(_vm._f("get_property")(subs, 'status')) + " ")])])])]);
          }), 0)])])])] : _vm._e()];
        })]];
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]);
        });
      },
      proxy: true
    }])
  })], 1), _c('li', [_c('button', {
    staticClass: "newBlueButton mt-50px",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('viewPlanLicensesModal');
      }
    }
  }, [_vm._v("View plan license")])])])] : _vm.selected.tab == 'balance' ? [_c('ul', {
    staticClass: "latestBilling-balanceSections list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "latestBilling-balanceSection first"
  }, [_c('vb-icon', {
    staticClass: "latestBilling-balanceSection-icon",
    attrs: {
      "icon": "callCredit-newBilling-icon2"
    }
  }), _c('div', {
    staticClass: "latestBilling-balanceSection-textSection"
  }, [_c('div', {
    staticClass: "latestBilling-balanceSection-heading"
  }, [_c('span', [_vm._v("Out of plan call credit")]), _c('b', {
    attrs: {
      "id": `popover-creditAllowed`
    }
  }, [_vm._v("£" + _vm._s(_vm._f("to_fixed")(_vm._f("get_property")(_vm.response.invoice_detail, 'user_balance', '0.00'))))]), _vm.isPostPaidEnabled ? _c('b-popover', {
    attrs: {
      "custom-class": "latestTooltipVersion",
      "target": `popover-creditAllowed`,
      "placement": "top",
      "triggers": "hover",
      "content": `Credit allowed: ${_vm.getProperty(_vm.response.invoice_detail, 'voip_balance.creditAllowed', 0)}`
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "latestBilling-balanceSection-text"
  }, [_vm._v(" This credit is used for calls made to destinations not included in your call plan. ")]), _c('div', {
    staticClass: "latestBilling-balanceSection-lastRow"
  }, [!_vm.isPostPaidEnabled ? _c('div', {
    staticClass: "latestGreyBox-heading-main-16px cursor_pointer_no_hover text-underline",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('TopupSettingModal');
      }
    }
  }, [_vm._v(" Top up settings ")]) : _vm._e(), _vm.isPostPaidEnabled ? _c('span', {
    staticClass: "OwnertaggedVersion mb-0"
  }, [_vm._v("£" + _vm._s(_vm._f("get_property")(_vm.response.invoice_detail, 'voip_balance.creditAllowed', 0)))]) : _vm._e(), _c('button', {
    staticClass: "latestBilling-balanceSection-newBlueButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddCreditModal');
      }
    }
  }, [_vm._v(" Add credit ")])])])], 1), _c('li', {
    staticClass: "latestBilling-balanceSection second"
  }, [_c('vb-icon', {
    staticClass: "latestBilling-balanceSection-icon",
    attrs: {
      "icon": "SMSCredit-newBilling-icon2"
    }
  }), _c('div', {
    staticClass: "latestBilling-balanceSection-textSection"
  }, [_c('div', {
    staticClass: "latestBilling-balanceSection-heading"
  }, [_c('span', [_vm._v("SMS Credit")]), _c('b', [_vm._v("£" + _vm._s(_vm._f("to_fixed")(_vm._f("get_property")(_vm.response.sms_balance, 'sms_balance', '0.00'))))])]), _c('div', {
    staticClass: "latestBilling-balanceSection-text"
  }, [_vm._v(" This credit is used for sending SMS messages through your VoIPbusiness application. ")]), _c('div', {
    staticClass: "latestBilling-balanceSection-lastRow justify-content-end"
  }, [_c('button', {
    staticClass: "latestBilling-balanceSection-newBlueButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddCreditModal', {
          sms_balance_products: true
        });
      }
    }
  }, [_vm._v(" Add credit ")])])])], 1), _c('li', {
    staticClass: "latestBilling-balanceSection third"
  }, [_c('vb-icon', {
    staticClass: "latestBilling-balanceSection-icon",
    attrs: {
      "icon": "account-Balance-newBilling-icon2"
    }
  }), _c('div', {
    staticClass: "latestBilling-balanceSection-textSection"
  }, [_c('div', {
    staticClass: "latestBilling-balanceSection-heading"
  }, [_c('span', [_vm._v("Account Balance")]), _c('b', [_vm._v("£" + _vm._s(_vm._f("to_fixed")(_vm._f("get_property")(_vm.response.balance, 'balance', '0'))))])]), _c('div', {
    staticClass: "latestBilling-balanceSection-text"
  }, [_vm._v(" This balance is used to purchase additional services such as user subscriptions, phone numbers, and more within the VoIPbusiness application. ")]), _c('div', {
    staticClass: "latestBilling-balanceSection-lastRow"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main-16px cursor_pointer_no_hover text-underline",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-TransactionLogsModal`);
      }
    }
  }, [_vm._v(" Transaction logs ")]), _c('button', {
    staticClass: "latestBilling-balanceSection-newBlueButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddCreditModal', {
          top_up_balance_products: true
        });
      }
    }
  }, [_vm._v(" Add credit ")])])])], 1)])] : _vm._e()], 2)]), _c('modal', {
    staticClass: "dialer_animation right_side_popup",
    attrs: {
      "name": "viewPlanLicensesModal",
      "width": "80%",
      "height": "auto",
      "scrollable": true
    }
  }, [_vm.getIsMobile ? _c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer mb-0"
  }, [_vm._v("Plan lincense")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('viewPlanLicensesModal');
      }
    }
  })], 1)]) : _c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer mb-0"
  }, [_vm._v("Plan lincense")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('a', {
    staticClass: "newCloseButton mr-3",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('viewPlanLicensesModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again pt-3 mt-0",
    attrs: {
      "isListEmpty": Object.keys(_vm.freeProducts).length == 0,
      "listLength": Object.keys(_vm.freeProducts).length,
      "perPage": 5,
      "loading": _vm.api.subscription_detail.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref3) {
        var start = _ref3.start,
          end = _ref3.end;
        return [_vm._l(_vm.freeProducts, function (data, key, index) {
          return [!_vm.getIsMobile ? _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.product_id
          }, [_c('td', {
            staticClass: "dialer-row-title dialer-col-fixed-width"
          }, [_vm._v(_vm._s(_vm._f("get_property")(data, "product_label")))]), _c('td', {
            staticClass: "dialer-row-title dialer-col-fixed-width"
          }, [_vm._v(_vm._s(_vm._f("get_property")(data, "product_total_free_allowed", 0)))]), _c('td', {
            staticClass: "dialer-row-title dialer-col-fixed-width"
          }, [_vm._v(_vm._s(_vm._f("get_property")(data, "product_already_used", 0)))]), _c('td', {
            staticClass: "dialer-row-title dialer-col-fixed-width"
          }, [_vm._v(_vm._s(_vm._f("get_property")(data, "product_free_remaining", 0)))]), _c('td', {
            staticClass: "dialer-row-title dialer-col-right"
          }, [_vm.getProperty(data, 'product_already_used', 0) > 0 ? _c('button', {
            staticClass: "dialer-button dialer-button-transparent showDetailButton color-575757",
            staticStyle: {
              "width": "max-content"
            },
            on: {
              "click": function ($event) {
                return _vm.$modal.show('ShowDetailsBillingModal', {
                  id: data.product_id
                });
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": "show-sub-table-icon",
              "height": "16px",
              "width": "16px",
              "color": "primary"
            }
          }), _vm._v(" View details ")], 1) : _vm._e()])]) : _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.product_id
          }, [_c('td', {
            staticClass: "left"
          }, [_c('div', {
            staticClass: "headingInsideTable"
          }, [_vm._v(_vm._s(_vm._f("get_property")(data, "product_label")))]), _c('div', {
            staticClass: "textInsideCard"
          }, [_vm._v("Limit: " + _vm._s(_vm._f("get_property")(data, "product_total_free_allowed", 0)))]), _c('div', {
            staticClass: "textInsideCard"
          }, [_vm._v("Used: " + _vm._s(_vm._f("get_property")(data, "product_already_used", 0)))]), _c('div', {
            staticClass: "textInsideCard"
          }, [_vm._v("Remaining: " + _vm._s(_vm._f("get_property")(data, "product_free_remaining", 0)))])]), _c('td', {
            staticClass: "dialer-col-right right"
          }, [_vm.getProperty(data, 'product_already_used', 0) > 0 ? _c('button', {
            staticClass: "p-0 b-0",
            attrs: {
              "variant": "link"
            },
            on: {
              "click": function ($event) {
                return _vm.$modal.show('ShowDetailsBillingModal', {
                  id: data.product_id
                });
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": "show-sub-table-icon",
              "height": "16px",
              "width": "16px",
              "color": "primary"
            }
          })], 1) : _vm._e()])])];
        })];
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]);
        });
      },
      proxy: true
    }])
  }, [!_vm.getIsMobile ? _c('tr', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Label")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: ""
  }, [_vm._v("Limit")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: ""
  }, [_vm._v("Used")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: ""
  }, [_vm._v("Remaining")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-right"
  })]) : _vm._e()]), !_vm.isOnFreePlan ? _c('div', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Available license")]) : _vm._e(), !_vm.isOnFreePlan ? _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again pt-3",
    attrs: {
      "isListEmpty": _vm.serviceAddons.length == 0,
      "listLength": _vm.serviceAddons.length,
      "perPage": 5,
      "loading": _vm.api.addons.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref4) {
        var start = _ref4.start,
          end = _ref4.end;
        return [!_vm.getIsMobile ? _vm._l(_vm.serviceAddons, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.product_id,
            staticClass: "w-100"
          }, [_c('td', {
            staticClass: "dialer-row-title dialer-col-fixed-width"
          }, [_vm._v(_vm._s(_vm._f("get_property")(data, `label`)))]), _c('td', {
            staticClass: "dialer-row-title dialer-col-fixed-width"
          }, [_vm._v(_vm._s(_vm._f("get_property")(data, "allowed", 0)))]), _c('td', {
            staticClass: "dialer-row-title dialer-col-fixed-width"
          }, [_vm._v(_vm._s(_vm._f("get_property")(data, "used", 0)))]), _c('td', {
            staticClass: "dialer-row-title dialer-col-fixed-width"
          }, [_vm._v(_vm._s(_vm._f("get_property")(data, "remaining", 0)))]), _c('td', {
            staticClass: "dialer-row-title dialer-col-right"
          }, [_vm.getProperty(data, 'used', 0) > 0 ? _c('button', {
            staticClass: "dialer-button dialer-button-transparent showDetailButton color-575757",
            staticStyle: {
              "width": "max-content"
            },
            on: {
              "click": function ($event) {
                return _vm.$modal.show('ShowDetailsBillingModal', {
                  id: data.product_id,
                  is_addon: true
                });
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": "show-sub-table-icon",
              "height": "16px",
              "width": "16px",
              "color": "primary"
            }
          }), _vm._v(" View details ")], 1) : _vm._e()])]);
        }) : _vm._l(_vm.serviceAddons, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.product_id,
            staticClass: "w-100"
          }, [_c('td', {
            staticClass: "left"
          }, [_c('div', {
            staticClass: "headingInsideTable"
          }, [_vm._v(_vm._s(_vm._f("get_property")(data, "label")))]), _c('div', {
            staticClass: "textInsideCard"
          }, [_vm._v("Limit: " + _vm._s(_vm._f("get_property")(data, "allowed", 0)))]), _c('div', {
            staticClass: "textInsideCard"
          }, [_vm._v("Used: " + _vm._s(_vm._f("get_property")(data, "used", 0)))]), _c('div', {
            staticClass: "textInsideCard"
          }, [_vm._v("Remaining: " + _vm._s(_vm._f("get_property")(data, "remaining", 0)))])]), _c('td', {
            staticClass: "dialer-col-right right"
          }, [_vm.getProperty(data, 'used', 0) > 0 ? _c('button', {
            staticClass: "p-0 b-0",
            attrs: {
              "variant": "link"
            },
            on: {
              "click": function ($event) {
                return _vm.$modal.show('ShowDetailsBillingModal', {
                  id: data.product_id,
                  is_addon: true
                });
              }
            }
          }, [_c('vb-icon', {
            attrs: {
              "icon": "show-sub-table-icon",
              "height": "16px",
              "width": "16px",
              "color": "primary"
            }
          })], 1) : _vm._e()])]);
        })];
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]);
        });
      },
      proxy: true
    }], null, false, 3089888154)
  }, [!_vm.getIsMobile ? _c('tr', {
    staticClass: "w-100",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Label")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Limit")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Used")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Remaining")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-right"
  })]) : _vm._e()]) : _vm._e(), _c('div', [!_vm.isOnFreePlan ? [_c('div', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Included Telephone Number")]), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again pt-3",
    attrs: {
      "isListEmpty": !_vm.freeNumber,
      "listLength": _vm.freeNumber ? 1 : 0,
      "perPage": 5,
      "loading": _vm.api.addons.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [!_vm.getIsMobile ? [_vm.freeNumber ? _c('tr', {
          staticClass: "w-100"
        }, [_c('td', {
          staticClass: "dialer-row-title dialer-col-fixed-width"
        }, [_vm._v("Included Telephone Number")]), _c('td', {
          staticClass: "dialer-row-title dialer-col-fixed-width"
        }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.freeNumber, "remaining", 0)))]), _c('td', {
          staticClass: "dialer-row-title dialer-col-right"
        }, [_vm.getProperty(_vm.freeNumber, 'used', 0) > 0 ? _c('button', {
          staticClass: "dialer-button dialer-button-transparent showDetailButton color-575757",
          staticStyle: {
            "width": "max-content"
          },
          on: {
            "click": function ($event) {
              return _vm.$modal.show('ShowDetailsBillingModal', {
                id: _vm.freeNumber.product_id,
                is_addon: true
              });
            }
          }
        }, [_c('vb-icon', {
          attrs: {
            "icon": "show-sub-table-icon",
            "height": "16px",
            "width": "16px",
            "color": "primary"
          }
        }), _vm._v(" View details ")], 1) : _vm._e()])]) : _vm._e()] : [_vm.freeNumber ? _c('tr', {
          staticClass: "w-100"
        }, [_c('td', {
          staticClass: "left"
        }, [_c('div', {
          staticClass: "headingInsideTable"
        }, [_vm._v("Included Telephone Number")]), _c('div', {
          staticClass: "textInsideCard"
        }, [_vm._v("Remaining: " + _vm._s(_vm._f("get_property")(_vm.freeNumber, "remaining", 0)))])]), _c('td', {
          staticClass: "right dialer-col-right"
        }, [_vm.getProperty(_vm.freeNumber, 'used', 0) > 0 ? _c('button', {
          staticClass: "p-0 b-0",
          attrs: {
            "variant": "link"
          },
          on: {
            "click": function ($event) {
              return _vm.$modal.show('ShowDetailsBillingModal', {
                id: _vm.freeNumber.product_id,
                is_addon: true
              });
            }
          }
        }, [_c('vb-icon', {
          attrs: {
            "icon": "show-sub-table-icon",
            "height": "16px",
            "width": "16px",
            "color": "primary"
          }
        })], 1) : _vm._e()])]) : _vm._e()]];
      },
      proxy: true
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(1, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]);
        });
      },
      proxy: true
    }], null, false, 4089082501)
  }, [!_vm.getIsMobile ? _c('tr', {
    staticClass: "w-100",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Label")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Remaining")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-right"
  })]) : _vm._e()])] : _vm._e()], 2), _c('div', {
    staticClass: "dialer-settings-title newer mt-20px mb-0"
  }, [_vm._v("Call bundles")]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again mt-0 pt-3",
    attrs: {
      "isListEmpty": _vm.plans.length == 0,
      "listLength": _vm.plans.length,
      "perPage": 5,
      "loading": _vm.api.whmcs_detail.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref5) {
        var start = _ref5.start,
          end = _ref5.end;
        return [!_vm.getIsMobile ? _vm._l(_vm.plans, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: index,
            class: `w-100`
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(_vm._f("get_property")(data, 'name')))]), _c('td', [_vm._v(_vm._s(data.amount > 0 ? 'active' : 'inactive'))]), _c('td', {
            staticClass: "dialer-row-title dialer-col-right"
          }, [_vm._v(_vm._s(_vm._f("get_property")(data, 'amount')))])]);
        }) : _vm._l(_vm.plans, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: index,
            class: `w-100`
          }, [_c('td', {
            staticClass: "left"
          }, [_c('div', {
            staticClass: "headingInsideTable"
          }, [_vm._v(_vm._s(_vm._f("get_property")(data, 'name')))]), _c('div', {
            staticClass: "textInsideCard"
          }, [_vm._v(_vm._s(data.amount > 0 ? 'active' : 'inactive'))])]), _c('td', {
            staticClass: "right dialer-col-right"
          }, [_vm._v(_vm._s(_vm._f("get_property")(data, 'amount')))])]);
        })];
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]);
        });
      },
      proxy: true
    }])
  }, [!_vm.getIsMobile ? _c('template', {
    slot: "header"
  }, [_c('tr', {
    staticClass: "w-100"
  }, [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Call plan name")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Status")])]), _c('th', {
    staticClass: "dialer-has-sort text-right"
  }, [_c('span', [_vm._v("Remaining minutes")])])])]) : _vm._e()], 2)], 1), _c('div', {
    staticClass: "dialer-settings-title newer mt-30px"
  }, [_vm._v("Reserve balance")]), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again pt-3",
    attrs: {
      "isListEmpty": _vm.reserveBalances.length == 0,
      "listLength": _vm.reserveBalances.length,
      "loading": _vm.api.fetch_reserve_balance.send,
      "noRecordWholePage": true
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref6) {
        var start = _ref6.start,
          end = _ref6.end;
        return [!_vm.getIsMobile ? _vm._l(_vm.reserveBalances, function (reserveBalance, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: reserveBalance.id
          }, [_c('td', {
            staticClass: "border-0"
          }, [_c('Info', {
            attrs: {
              "is_blf": false,
              "id": reserveBalance.service_account,
              "type": reserveBalance.service_type == 'Number' ? 'number' : reserveBalance.service_type == 'CALL_PLAN_PREFIX' ? 'USER' : reserveBalance.service_type
            }
          })], 1), _c('td', {
            staticClass: "border-0"
          }, [_vm._v(_vm._s(reserveBalance.service_type == 'CALL_PLAN_PREFIX' ? 'Unlimited Call Plan' : reserveBalance.service_type))]), _c('td', {
            staticClass: "border-0"
          }, [_vm._v(_vm._s(reserveBalance.description))]), _c('td', {
            staticClass: "border-0"
          }, [_vm._v(_vm._s(_vm._f("to_fixed")(reserveBalance.amount)))]), _c('td', {
            staticClass: "border-0"
          }, [_vm._v(_vm._s(reserveBalance.status))]), _c('td', {
            staticClass: "border-0"
          }, [_vm._v(_vm._s(reserveBalance.expire_at))]), _c('td', {
            staticClass: "border-0"
          }, [_vm._v(_vm._s(reserveBalance.created_at))])]);
        }) : _vm._l(_vm.reserveBalances, function (reserveBalance, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: reserveBalance.id
          }, [_c('td', {
            staticClass: "left"
          }, [_c('Info', {
            attrs: {
              "is_blf": false,
              "id": reserveBalance.service_account,
              "type": reserveBalance.service_type == 'Number' ? 'number' : reserveBalance.service_type == 'CALL_PLAN_PREFIX' ? 'USER' : reserveBalance.service_type
            }
          }), _c('div', {
            staticClass: "textInsideCard"
          }, [_vm._v("Amount: " + _vm._s(_vm._f("to_fixed")(reserveBalance.amount)))]), _c('div', {
            staticClass: "textInsideCard"
          }, [_vm._v("Expires: " + _vm._s(reserveBalance.expire_at))]), _c('div', {
            staticClass: "textInsideCard"
          }, [_vm._v(_vm._s(reserveBalance.service_type == 'CALL_PLAN_PREFIX' ? 'Unlimited Call Plan' : reserveBalance.service_type))]), _c('div', {
            staticClass: "textInsideCard"
          }, [_vm._v(_vm._s(reserveBalance.description))])], 1), _c('td', {
            staticClass: "dialer-col-right right"
          }, [_c('div', {
            staticClass: "textInsideCard"
          }, [_vm._v(_vm._s(reserveBalance.status))])])]);
        })];
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "50px",
              "width": "135px"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "50px",
              "width": "135px"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "50px",
              "width": "135px"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "50px",
              "width": "135px"
            }
          })])]);
        });
      },
      proxy: true
    }, {
      key: "no-record",
      fn: function () {
        return [_c('div', {
          staticClass: "latestGreyBox-9-9-2023 mt-32px p-0"
        }, [_c('div', {
          staticClass: "emptyScreenContainer"
        }, [_c('img', {
          staticClass: "mt-12px wd-30 h-auto",
          attrs: {
            "src": require('@/assets/images/emptyScreenImages/invoices.png')
          }
        }), _c('div', {
          staticClass: "emptyScreenContainer-heading"
        }, [_vm._v(" Manage invoices and credit notes ")]), _c('div', {
          staticClass: "emptyScreenContainer-text w-75"
        }, [_vm._v(" View and manage your invoices and credit notes here. Start by creating a new invoice or credit note. ")])])])];
      },
      proxy: true
    }])
  }, [!_vm.getIsMobile ? _c('tr', {
    staticClass: "w-100",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', [_c('span', [_vm._v("Name")])]), _c('th', [_c('span', [_vm._v("Type")])]), _c('th', [_c('span', [_vm._v("Description")])]), _c('th', [_c('span', [_vm._v("Amount")])]), _c('th', [_c('span', [_vm._v("Status")])]), _c('th', [_c('span', [_vm._v("Expire at")])]), _c('th', [_c('span', [_vm._v("Created at")])])]) : _vm._e()])], 1), _c('modal', {
    staticClass: "dialer_animation right_side_popup viewPDFmodal full-width-big-modal",
    attrs: {
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-unpaidInvoices`
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header d-flex align-items-start justify-content-between px-0 pt-3"
  }, [_c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "cursor_pointer ml-2",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-unpaidInvoices`);
      }
    }
  }, [_c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])])]), [_c('vb-table', {
    staticClass: "trustedDevicesTable-updated latestTableDesign-withBlackBorders-again mt-0",
    attrs: {
      "isListEmpty": _vm.unpaidInvoices.length == 0,
      "listLength": _vm.unpaidInvoices.length,
      "perPage": 5,
      "loading": _vm.api.invoice_detail.send,
      "noRecordWholePage": true
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref7) {
        var start = _ref7.start,
          end = _ref7.end;
        return _vm._l(_vm.unpaidInvoices, function (invoice, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: invoice.id
          }, [_c('td', {
            staticClass: "border-0"
          }, [_vm._v(_vm._s(_vm._f("filterInvoiceType")(invoice)))]), _c('td', {
            staticClass: "border-0"
          }, [_c('span', {
            staticClass: "OwnertaggedVersion green"
          }, [_vm._v(" " + _vm._s(invoice.invoice_status) + " ")])]), _c('td', {
            staticClass: "border-0"
          }, [_c('div', {
            staticClass: "d-flex justify-content-end w-100"
          }, [invoice.invoice_status != 'PAID' ? _c('span', {
            staticClass: "OwnertaggedVersion mb-0 mr-16px showOnly-OnHover-insideTable",
            on: {
              "click": function ($event) {
                return _vm.payOutstandingAmount(invoice);
              }
            }
          }, [_vm._v(" Pay now ")]) : _vm._e(), _c('span', {
            staticClass: "text-underline cursor_pointer_no_hover",
            on: {
              "click": function ($event) {
                return _vm.$modal.show('InvoicePDFDownloadAccountSetting', {
                  id: invoice.invoice_id
                });
              }
            }
          }, [_vm._v("View invoice")])])])]);
        });
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "50px",
              "width": "135px"
            }
          })])]);
        });
      },
      proxy: true
    }, {
      key: "no-record",
      fn: function () {
        return [_c('div', {
          staticClass: "latestGreyBox-9-9-2023 mt-32px p-0"
        }, [_c('div', {
          staticClass: "emptyScreenContainer"
        }, [_c('img', {
          staticClass: "mt-12px wd-30 h-auto",
          attrs: {
            "src": require('@/assets/images/emptyScreenImages/invoices.png')
          }
        }), _c('div', {
          staticClass: "emptyScreenContainer-heading"
        }, [_vm._v(" Manage invoices and credit notes ")]), _c('div', {
          staticClass: "emptyScreenContainer-text w-75"
        }, [_vm._v(" View and manage your invoices and credit notes here. Start by creating a new invoice or credit note. ")])])])];
      },
      proxy: true
    }])
  }, [_c('tr', {
    staticClass: "w-100",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("Invoice")])]), _c('th', {
    staticClass: "border-0"
  }), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_c('br')])])])])]], 2), _c('modal', {
    staticClass: "dialer_animation right_side_popup viewPDFmodal full-width-big-modal",
    attrs: {
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-DetailReserveBalance`
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header d-flex align-items-center justify-content-between px-0 pt-3"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer mb-0"
  }, [_vm._v("Reserve balance details")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "cursor_pointer ml-2 newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-DetailReserveBalance`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), [_c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again mt-0",
    attrs: {
      "isListEmpty": _vm.reserveBalances.length == 0,
      "listLength": _vm.reserveBalances.length,
      "loading": _vm.api.fetch_reserve_balance.send,
      "noRecordWholePage": true
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref8) {
        var start = _ref8.start,
          end = _ref8.end;
        return _vm._l(_vm.reserveBalances, function (reserveBalance, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: reserveBalance.id
          }, [_c('td', {
            staticClass: "border-0"
          }, [_vm._v(_vm._s(reserveBalance.service_account))]), _c('td', {
            staticClass: "border-0"
          }, [_vm._v(_vm._s(reserveBalance.service_type))]), _c('td', {
            staticClass: "border-0"
          }, [_vm._v(_vm._s(reserveBalance.description))]), _c('td', {
            staticClass: "border-0"
          }, [_vm._v(_vm._s(_vm._f("to_fixed")(reserveBalance.amount)))]), _c('td', {
            staticClass: "border-0"
          }, [_vm._v(_vm._s(reserveBalance.status))]), _c('td', {
            staticClass: "border-0"
          }, [_vm._v(_vm._s(reserveBalance.expire_at))]), _c('td', {
            staticClass: "border-0"
          }, [_vm._v(_vm._s(reserveBalance.created_at))])]);
        });
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "50px",
              "width": "135px"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "50px",
              "width": "135px"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "50px",
              "width": "135px"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "50px",
              "width": "135px"
            }
          })])]);
        });
      },
      proxy: true
    }, {
      key: "no-record",
      fn: function () {
        return [_c('div', {
          staticClass: "latestGreyBox-9-9-2023 mt-32px p-0"
        }, [_c('div', {
          staticClass: "emptyScreenContainer"
        }, [_c('img', {
          staticClass: "mt-12px wd-30 h-auto",
          attrs: {
            "src": require('@/assets/images/emptyScreenImages/invoices.png')
          }
        }), _c('div', {
          staticClass: "emptyScreenContainer-heading"
        }, [_vm._v(" Manage invoices and credit notes ")]), _c('div', {
          staticClass: "emptyScreenContainer-text w-75"
        }, [_vm._v(" View and manage your invoices and credit notes here. Start by creating a new invoice or credit note. ")])])])];
      },
      proxy: true
    }])
  }, [_c('tr', {
    staticClass: "w-100",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("Name")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("Type")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("Description")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("Amount")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("Status")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("Expire at")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("Created at")])])])])]], 2), _c('modal', {
    staticClass: "center-zoomIn-transition v2 TopUpProductsModal",
    attrs: {
      "width": "500px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-TransactionLogsModal`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-form lg-mar-bottom"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-flex justify-content-between align-items-center"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Transaction logs")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-TransactionLogsModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "md-mar-vertical"
  }, [_c('vb-table', {
    staticClass: "TopUpProductsBox latestTableDesign-withBlackBorders-again",
    attrs: {
      "noRecordWholePage": true,
      "isListEmpty": _vm.response.credit_logs.length == 0,
      "loading": _vm.api.credit_logs.send,
      "listLength": _vm.pagination.credit_logs.total,
      "perPage": 10,
      "conditions": {
        pagination: {
          per_page: false
        }
      },
      "page": _vm.pagination.credit_logs.page
    },
    on: {
      "page-changed": function ($event) {
        _vm.pagination.credit_logs.page = $event;
        _vm.fetchCreditLogs(false);
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return _vm._l(_vm.response.credit_logs, function (creditLog) {
          return _c('tr', {
            key: creditLog.id
          }, [_c('td', {
            staticClass: "border-0"
          }, [_vm._v(_vm._s(_vm._f("to_fixed")(creditLog.amount)))]), _c('td', {
            staticClass: "border-0"
          }, [_vm._v(_vm._s(creditLog.type))]), _c('td', {
            staticClass: "border-0"
          }, [_vm._v(_vm._s(creditLog.description))])]);
        });
      },
      proxy: true
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content w-100"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "100%"
            }
          })])])]);
        });
      },
      proxy: true
    }, {
      key: "no-record",
      fn: function () {
        return [_c('div', {
          staticClass: "latestGreyBox-9-9-2023 mt-32px p-0"
        }, [_c('div', {
          staticClass: "emptyScreenContainer"
        }, [_c('img', {
          staticClass: "mt-12px wd-30 h-auto",
          attrs: {
            "src": require('@/assets/images/emptyScreenImages/invoices.png')
          }
        }), _c('div', {
          staticClass: "emptyScreenContainer-heading"
        }, [_vm._v(" Manage invoices and credit notes ")]), _c('div', {
          staticClass: "emptyScreenContainer-text w-75"
        }, [_vm._v(" View and manage your invoices and credit notes here. Start by creating a new invoice or credit note. ")])])])];
      },
      proxy: true
    }])
  }, [_c('tr', {
    staticClass: "w-100",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("Amount")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("Type")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', [_vm._v("Description")])])])])], 1)])])]), _c('InvoiceDetailModal', {
    on: {
      "updated": function ($event) {
        return _vm.fetchAccountDetail();
      }
    }
  }), _c('UserDetailModal'), _c('ViewAllInvoicesModal'), _c('TopupSettingModal'), _c('OtherCardListModal', {
    ref: "other_cards",
    attrs: {
      "modalName": `${_vm._uid}-OtherCreditCard`,
      "isOnFreePlan": _vm.isOnFreePlan,
      "anyUnlimitedPlan": _vm.anyUnlimitedPlan
    },
    on: {
      "fetch-cards": function ($event) {
        return _vm.fetchCards();
      }
    }
  }), _c('PaymentDetailModal'), _c('SetCardPriorityModal', {
    on: {
      "priority-set": function ($event) {
        return _vm.fetchCards();
      },
      "cards-updated": function ($event) {
        return _vm.fetchCards();
      }
    }
  }), _c('SubscriptionPlanDetailModal', {
    attrs: {
      "allAddons": _vm.allAddons
    },
    on: {
      "update-plan": function ($event) {
        _vm.fetchAccountDetail();
        _vm.fetchSubscriptionDetail();
        _vm.fetchSubscriptions();
      },
      "delete-service": function ($event) {
        _vm.fetchSubscriptionDetail();
        _vm.fetchSubscriptions();
        _vm.fetchAddons();
      }
    }
  }), _c('NumbersListModal'), _c('InvoicePDFDownload', {
    attrs: {
      "modalName": `InvoicePDFDownloadAccountSetting`
    }
  }), _c('AddCreditModal'), _c('VsPackagesModal'), _c('ShowDetailsBilling', {
    attrs: {
      "subscription_detail": _vm.response.subscription_detail,
      "allAddons": _vm.allAddons
    },
    on: {
      "delete-service": function ($event) {
        _vm.fetchSubscriptionDetail();
        _vm.fetchSubscriptions();
        _vm.fetchAddons();
      }
    }
  }), _c('AddCardStripeModal')], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "latestBilling-section-1-textSection"
  }, [_c('div', {
    staticClass: "latestBilling-section-1-cardType"
  }, [_c('div', {
    staticClass: "latestShimmerDesign someTransparent",
    staticStyle: {
      "height": "18px",
      "width": "50%"
    }
  })]), _c('div', {
    staticClass: "latestShimmerDesign someTransparent",
    staticStyle: {
      "height": "24px",
      "width": "50%"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign someTransparent mt-16px",
    staticStyle: {
      "height": "38px",
      "width": "100%"
    }
  }), _c('div', {
    staticClass: "latestBilling-section-1-lastRow mt-8px"
  }, [_c('div', {
    staticClass: "latestShimmerDesign someTransparent",
    staticStyle: {
      "height": "18px",
      "width": "50%"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign someTransparent ml-auto",
    staticStyle: {
      "height": "50px",
      "width": "150px"
    }
  })])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "latestBilling-section-1-textSection"
  }, [_c('div', {
    staticClass: "latestBilling-section-1-cardType"
  }, [_c('div', {
    staticClass: "latestShimmerDesign someTransparent",
    staticStyle: {
      "height": "18px",
      "width": "50%"
    }
  })]), _c('div', {
    staticClass: "latestShimmerDesign someTransparent",
    staticStyle: {
      "height": "24px",
      "width": "50%"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign someTransparent mt-16px",
    staticStyle: {
      "height": "38px",
      "width": "100%"
    }
  }), _c('div', {
    staticClass: "latestBilling-section-1-lastRow mt-8px"
  }, [_c('div', {
    staticClass: "latestShimmerDesign someTransparent",
    staticStyle: {
      "height": "18px",
      "width": "50%"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign someTransparent ml-auto",
    staticStyle: {
      "height": "50px",
      "width": "150px"
    }
  })])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "d-flex align-items-center mr-20px"
  }, [_c('span', {
    staticClass: "smallCircle"
  }), _c('span', {
    staticClass: "smallCircle"
  }), _c('span', {
    staticClass: "smallCircle"
  }), _c('span', {
    staticClass: "smallCircle"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "d-flex align-items-center mr-20px"
  }, [_c('span', {
    staticClass: "smallCircle"
  }), _c('span', {
    staticClass: "smallCircle"
  }), _c('span', {
    staticClass: "smallCircle"
  }), _c('span', {
    staticClass: "smallCircle"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "d-flex align-items-center mr-20px"
  }, [_c('span', {
    staticClass: "smallCircle"
  }), _c('span', {
    staticClass: "smallCircle"
  }), _c('span', {
    staticClass: "smallCircle"
  }), _c('span', {
    staticClass: "smallCircle"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "latestBilling-section-1-creditCardNumber"
  }, [_c('div', {
    staticClass: "latestBilling-section-1-cardType"
  }, [_vm._v(" Add your payment method ")])]);

}]

export { render, staticRenderFns }