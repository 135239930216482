var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "addressBookContainer"
  }, [_vm.conditions.screen == _vm.screens.add ? _c('div', {
    staticClass: "dialer_contact_list allow_scroll add"
  }, [_c('CreateOrUpdateAddressBook', {
    ref: "create-addressbook",
    attrs: {
      "creating": true
    },
    on: {
      "created": function ($event) {
        _vm.conditions.screen = _vm.screens.list;
        _vm.fetchAddressBooks();
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function (slotProp) {
        return [_c('div', {
          staticClass: "dialer_activity_box_header"
        }, [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('a', {
          staticClass: "goToPreviousScreen",
          on: {
            "click": function ($event) {
              slotProp.api.create_addressbook.send ? '' : _vm.conditions.screen = _vm.screens.list;
            }
          }
        }, [_c('vb-svg', {
          attrs: {
            "name": "leftLongArrow-icon",
            "width": "20",
            "height": "12",
            "viewBox": "0 0 30 16",
            "stroke-width": "1",
            "stroke": "",
            "fill": "none",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          }
        })], 1), _c('h4', {
          staticClass: "w-100 text-center"
        }, [_vm._v("Add contact")])])])];
      }
    }], null, false, 1957337214)
  })], 1) : _vm.conditions.screen == _vm.screens.list ? _c('div', [_c('div', {
    staticClass: "tab-pane active"
  }, [_c('div', {
    staticClass: "dialer-tabs"
  }, [_c('div', {
    staticClass: "dialer-tab-content"
  }, [_vm.getIsMobile ? _c('div', [_c('div', {
    staticClass: "inContacts-contacts-searchBar-container"
  }, [_c('b-form', {
    staticClass: "updatingContactsAgain-contacts-searchBar-container",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "updatingContactsAgain-contacts-searchBar"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "mobile-searchBar-icon-likeAirCall-icon",
      "width": "12.68px",
      "height": "12.67px"
    }
  }), _c('b-input', {
    attrs: {
      "placeholder": "Search"
    },
    model: {
      value: _vm.filter.addressbooks.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.addressbooks, "search", $$v);
      },
      expression: "filter.addressbooks.search"
    }
  })], 1), _c('b-dropdown', {
    staticClass: "filterDD noPaddingInButton",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-icon', {
          attrs: {
            "icon": "mobile-funnel-icon-likeAirCall-icon",
            "width": "18.61px",
            "height": "18.61px"
          }
        })];
      },
      proxy: true
    }], null, false, 1712499967)
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.filter.addressbooks.type = 'all';
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.filter.addressbooks.type == 'all' ? _c('b-icon', {
    staticClass: "mr-2",
    style: `opacity:1;`,
    attrs: {
      "icon": "check"
    }
  }) : _vm._e(), _c('div', [_vm._v("All")])], 1)]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.filter.addressbooks.type = 'local';
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.filter.addressbooks.type == 'local' ? _c('b-icon', {
    staticClass: "mr-2",
    style: `opacity:1;`,
    attrs: {
      "icon": "check"
    }
  }) : _vm._e(), _c('div', [_vm._v("Local")])], 1)]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.filter.addressbooks.type = 'org';
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.filter.addressbooks.type == 'org' ? _c('b-icon', {
    staticClass: "mr-2",
    style: `opacity:1;`,
    attrs: {
      "icon": "check"
    }
  }) : _vm._e(), _c('div', [_vm._v("Organization")])], 1)]), _vm._l(_vm.response.integrateds, function (platform) {
    return _c('b-dropdown-item', {
      key: platform.platform,
      on: {
        "click": function ($event) {
          _vm.filter.addressbooks.type = platform.platform;
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_vm.filter.addressbooks.type == platform.platform ? _c('b-icon', {
      staticClass: "mr-2",
      style: `opacity:1;`,
      attrs: {
        "icon": "check"
      }
    }) : _vm._e(), _c('div', [_vm._v(_vm._s(platform.platform))])], 1)]);
  })], 2), _c('vb-icon', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "icon": "mobile-plus-icon-likeAirCall-icon",
      "width": "20.38px",
      "height": "20.38px"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.global_conditions.add_addressbooks = true;
      }
    }
  })], 1)], 1), _c('div', {
    staticClass: "dialer_contact_list addressbook_list allow_scroll"
  }, [_vm._l(_vm.filterAddressBooks, function (addressbook) {
    return _c('div', {
      key: addressbook.real_id,
      staticClass: "dialer_contact_row"
    }, [_c('div', {
      staticClass: "updatingContactsAgain-contact-item"
    }, [_c('div', {
      staticClass: "d-flex"
    }, [_c('vb-avatar', {
      staticClass: "sm-mar-right",
      attrs: {
        "image": _vm._f("get_property")(addressbook, 'profile_image')
      }
    }), _c('div', {
      staticClass: "user-info"
    }, [_c('div', {
      staticClass: "user-name-box"
    }, [_c('span', {
      staticClass: "user-name"
    }, [_c('span', [_vm._v(_vm._s(`${addressbook.firstName} ${addressbook.lastName || ''}`))])])])])], 1), _vm.api.delete_addressbook.send == addressbook.real_id ? _c('vb-spinner', {
      attrs: {
        "variant": "primary"
      }
    }) : _c('b-dropdown', {
      staticClass: "ico-filter",
      attrs: {
        "varient": "link",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('vb-icon', {
            staticClass: "mobile-topBar-threeDots-likeAirCall-icon",
            attrs: {
              "icon": "mobile-topBar-threeDots-likeAirCall-icon",
              "height": "27.06px",
              "width": "6px"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.audioCall(addressbook);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "telephone-fill"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Call")])], 1), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.setUpdate(addressbook);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "pencil-square"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Edit")])], 1), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.deleteAddressBook(addressbook);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "trash-fill"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Delete")])], 1), _c('b-dropdown-item', {
      attrs: {
        "disabled": _vm.api.toggle_favourite.send.includes(addressbook.real_id)
      },
      on: {
        "click": function ($event) {
          return _vm.toggleFavourite(addressbook);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "star-fill"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v(_vm._s(addressbook.is_favourite ? 'Remove from favourites' : 'Add to favourites'))])], 1)], 1)], 1)]);
  }), _vm.api.fetch_addressbooks.send ? _c('vb-loading') : _vm.isEmpty(_vm.filterAddressBooks) ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.fetch_addressbooks.send ? '' : 'There is no addressbook',
      "design": 3
    }
  }) : _vm._e()], 2)]) : _c('div', [_c('div', {
    staticClass: "integrationFilterWithSearchBar-container mb-3"
  }, [_c('b-form', {
    staticClass: "whiteBGinputWithGreyRoundedBorder flex-fill",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-input', {
    attrs: {
      "placeholder": "Search here"
    },
    model: {
      value: _vm.filter.addressbooks.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.addressbooks, "search", $$v);
      },
      expression: "filter.addressbooks.search"
    }
  })], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD withCustomSvgCheck"
  }, [_c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical",
            "font-scale": "1.7"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.filter.addressbooks.type = 'all';
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.filter.addressbooks.type == 'all' ? _c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }) : _vm._e(), _c('div', [_vm._v("All")])], 1)]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.filter.addressbooks.type = 'local';
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.filter.addressbooks.type == 'local' ? _c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }) : _vm._e(), _c('div', [_vm._v("Local")])], 1)]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.filter.addressbooks.type = 'org';
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.filter.addressbooks.type == 'org' ? _c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }) : _vm._e(), _c('div', [_vm._v("Organization")])], 1)]), _vm._l(_vm.response.integrateds, function (platform) {
    return _c('b-dropdown-item', {
      key: platform.platform,
      on: {
        "click": function ($event) {
          _vm.filter.addressbooks.type = platform.platform;
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_vm.filter.addressbooks.type == platform.platform ? _c('vb-svg', {
      attrs: {
        "name": "dialer-tick",
        "width": "10.5",
        "height": "7.584",
        "viewBox": "0 0 10.5 7.584",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }) : _vm._e(), _c('div', [_vm._v(_vm._s(platform.platform))])], 1)]);
  })], 2)], 1)], 1), _c('div', {
    staticClass: "dialer-addContact cursor_pointer",
    on: {
      "click": function ($event) {
        _vm.$store.state.global_conditions.add_addressbooks = true;
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "name": "dialer-addUser-icon",
      "width": "23.401",
      "height": "18.295",
      "viewBox": "0 0 23.401 18.295",
      "stroke-width": "0",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v("New contact")])], 1), _c('div', {
    staticClass: "dialer_contact_list addressbook_list allow_scroll"
  }, [_vm._l(_vm.filterAddressBooks, function (addressbook) {
    return _c('div', {
      key: addressbook.real_id,
      staticClass: "dialer_contact_row"
    }, [_c('div', {
      staticClass: "dialer_contact"
    }, [_c('div', {
      staticClass: "dialer_contact-imageContainer"
    }, [_c('vb-avatar', {
      attrs: {
        "image": _vm._f("get_property")(addressbook, 'profile_image')
      }
    }), addressbook.tags && addressbook.integration_ref_id ? _c('vb-icon', {
      staticClass: "integrationType-icon",
      attrs: {
        "icon": _vm._f("integrationIcons")(addressbook.tags)
      },
      on: {
        "click": function ($event) {
          return _vm.openIntegrationContactDetail(addressbook);
        }
      }
    }) : _vm._e()], 1), _c('div', {
      staticClass: "user-info"
    }, [_c('div', {
      staticClass: "user-name-box"
    }, [_c('span', {
      staticClass: "user-name"
    }, [_c('span', [_vm._v(_vm._s(`${addressbook.firstName} ${addressbook.lastName || ''}`))]), addressbook.is_favourite == 1 ? _c('span', [_c('b-icon', {
      attrs: {
        "icon": "star-fill",
        "variant": "primary"
      }
    })], 1) : _vm._e()]), _c('div', {
      staticClass: "dialer_contact_hidden_area"
    }, [_c('div', {
      staticClass: "dialer_contact_connection_box"
    }, [_c('span', {
      staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute thereIsAnotherDropdownInside"
    }, [_vm.api.delete_addressbook.send == addressbook.real_id ? _c('vb-spinner', {
      attrs: {
        "variant": "primary"
      }
    }) : _c('b-dropdown', {
      staticClass: "ico-filter",
      attrs: {
        "varient": "link",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('b-icon', {
            attrs: {
              "icon": "three-dots",
              "scale": "1.5"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.audioCall(addressbook);
        }
      }
    }, [_c('vb-svg', {
      staticClass: "mr-2",
      attrs: {
        "name": "dialer-outlineCall-icon2",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', [_vm._v("Call")])], 1), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.setUpdate(addressbook);
        }
      }
    }, [_c('vb-svg', {
      staticClass: "mr-2",
      attrs: {
        "name": "dialer-edit-icon",
        "width": "16",
        "height": "16",
        "viewBox": "0 0 24 24",
        "stroke-width": "1.2",
        "stroke": "currentColor",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', [_vm._v("Edit")])], 1), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.deleteAddressBook(addressbook);
        }
      }
    }, [_c('vb-svg', {
      staticClass: "dialer-icon dialer-delete-icon mr-2",
      attrs: {
        "name": "dialer-delete-icon",
        "width": "16",
        "height": "16",
        "viewBox": "0 0 24 24",
        "stroke-width": "1.2",
        "stroke": "currentColor",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', [_vm._v("Delete")])], 1), addressbook.global == 0 && !addressbook.tags ? _c('b-dropdown-form', {
      on: {
        "submit": function ($event) {
          $event.stopPropagation();
          $event.preventDefault();
          '';
        }
      }
    }, [_c('b-form-group', {
      attrs: {
        "label-for": "dropdown-form-email"
      }
    }, [_c('b-form-select', {
      attrs: {
        "disabled": _vm.api.sync_contact.send,
        "size": "sm"
      },
      on: {
        "change": function ($event) {
          return _vm.syncContact(addressbook, $event);
        }
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function () {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null,
              "disabled": ""
            }
          }, [_vm._v("Sync integration")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: addressbook.tags,
        callback: function ($$v) {
          _vm.$set(addressbook, "tags", $$v);
        },
        expression: "addressbook.tags"
      }
    }, _vm._l(_vm.response.integrateds, function (data) {
      return _c('b-form-select-option', {
        key: data.platform,
        attrs: {
          "value": data.platform
        }
      }, [_c('span', {
        staticStyle: {
          "width": "16px",
          "display": "inline-block"
        }
      }), _vm._v(_vm._s(data.platform) + " ")]);
    }), 1)], 1)], 1) : _vm._e(), _c('b-dropdown-item', {
      attrs: {
        "disabled": _vm.api.toggle_favourite.send.includes(addressbook.real_id)
      },
      on: {
        "click": function ($event) {
          return _vm.toggleFavourite(addressbook);
        }
      }
    }, [_c('b-icon', {
      staticClass: "mr-2",
      attrs: {
        "icon": addressbook.is_favourite ? 'star-fill' : 'star'
      }
    }), _c('span', [_vm._v(_vm._s(addressbook.is_favourite ? 'Remove from favourites' : 'Add to favourites'))])], 1)], 1)], 1)])])]), _c('div', {
      staticClass: "user-number"
    }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm._f("get_property")(addressbook, 'main_phone.number'))))])])])]);
  }), _vm.api.fetch_addressbooks.send ? _vm._l(3, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "d-flex mt-20px align-items-center w-100"
    }, [_c('div', {
      staticClass: "latestShimmerDesign mr-3",
      staticStyle: {
        "height": "40px",
        "width": "40px"
      }
    }), _vm._m(0, true)]);
  }) : _vm.isEmpty(_vm.filterAddressBooks) ? _c('div', {
    staticClass: "emptyScreenContainer mt-32px b-0 p-0"
  }, [_vm.GET_LOCAL_SETTING_IS_DARK_MODE ? _c('img', {
    attrs: {
      "width": "50%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/darkMode/addressBook.png')
    }
  }) : _c('img', {
    attrs: {
      "width": "50%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/addressBook.png')
    }
  }), _c('div', {
    staticClass: "emptyScreenContainer-heading mt-32px"
  }, [_vm._v("Explore address book")])]) : _vm._e()], 2)])])])])]) : _vm.conditions.screen == _vm.screens.update ? _c('div', {
    staticClass: "dialer_contact_list allow_scroll add"
  }, [_c('CreateOrUpdateAddressBook', {
    ref: "update-addressbook",
    attrs: {
      "creating": false,
      "addressbookID": _vm.selected.updated_id
    },
    on: {
      "updated": function ($event) {
        _vm.conditions.screen = _vm.screens.list;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function (slotProps) {
        return [_c('div', {
          staticClass: "dialer_activity_box_header"
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('a', {
          staticClass: "goToPreviousScreen",
          on: {
            "click": function ($event) {
              slotProps.api.update_addressbook.send ? '' : _vm.conditions.screen = _vm.screens.list;
            }
          }
        }, [_c('vb-svg', {
          attrs: {
            "name": "leftLongArrow-icon",
            "width": "20",
            "height": "12",
            "viewBox": "0 0 30 16",
            "stroke-width": "1",
            "stroke": "",
            "fill": "none",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          }
        })], 1), _c('h4', {
          staticClass: "w-100 text-center"
        }, [_vm._v("Edit contact")])])])];
      }
    }])
  })], 1) : _vm._e(), _c('SelectAddressBookNumberModal')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column flex-fill"
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "18px",
      "width": "50%"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign mt-1",
    staticStyle: {
      "height": "18px",
      "width": "80%"
    }
  })]);

}]

export { render, staticRenderFns }