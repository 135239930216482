var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "ViewContactInfoModal schedulesModal",
    attrs: {
      "name": _vm.modalName
    },
    on: {
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      },
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      }
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to md-pad-bottom dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-flex w-100 justify-content-between align-items-center"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Update schedule")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_vm.api.get_info.send ? [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(10, function (n) {
    return _c('div', {
      key: n,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none py-3"
    }, [_c('div', {
      staticClass: "latestShimmerDesign w-100",
      staticStyle: {
        "height": "25px"
      }
    })]);
  }), 0)] : _c('div', [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.updateSchedule();
      }
    }
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionLeftAligned"
  }, [_c('label', [_vm._v("Select date and time:")]), _c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "date-time-input",
    attrs: {
      "format": "MM/DD/YYYY hh:mm a"
    },
    model: {
      value: _vm.forms.update_schedule.schedule,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_schedule, "schedule", $$v);
      },
      expression: "forms.update_schedule.schedule"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1), _c('b-button', {
    staticClass: "mt-20px fullWidthDoneButton",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v("Submit")])], 1)], 1)], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }