<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable100VHcustom bigSquareCheckbox min-width-50percent-modal" 
      width="50%" 
      :scrollable="true" 
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="dialer-edit-blocked">
        <div class="dialer-edit">
          <form class="dialer-form" novalidate @submit.prevent="editnumber()">
            <div v-if="getIsMobile" class="dialer-edit-header flex-column">
              <div class="w-100 d-flex justify-content-between align-items-center">
                <h2 class="dialer-edit-title">Edit blocked number</h2>
                <button class="newDoneButton" :disabled="api.edit_number.send || api.delete_number.send">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                    <vb-spinner v-if="api.edit_number.send" />
                    <template v-else>Done</template>
                  </span>
                </button>
              </div>
            </div>
            <div v-else class="dialer-edit-header position-relative">
              <h2 class="dialer-settings-title newer mb-0 d-flex align-items-center">
                <a type="button" class="newBackButton mr-16px" @click="$modal.hide(modalName)" :disabled="api.edit_number.send || api.delete_number.send" >
                  <b-icon icon="arrow-left-circle" />
                  <span class="newBackButton-textpart">Back</span>
                </a>
                Edit blocked number
              </h2>
              <div class="dialer-edit-actions">
                <button class="newDoneButton" :disabled="api.edit_number.send || api.delete_number.send">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                    <vb-spinner v-if="api.edit_number.send" />
                    <template v-else>Done</template>
                  </span>
                </button>
              </div>
            </div>
            <b-alert :show="!!api.edit_number.error_message" variant="danger">{{ api.edit_number.error_message }}</b-alert>
            <div class="latestGreyBox-9-9-2023 mt-20px">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row">
                  <div class="whiteBGinputWithGreyRoundedBorder w-100">
                    <label class="dialer-input-label">Number</label>
                    <input type="text" class="w-100" v-vb-input-number-formate :disabled="api.edit_number.send || api.delete_number.send" v-model="forms.edit_number.callerid" />
                    <p v-if="$v.forms.edit_number.callerid.$error" class="text text-danger animated bounceIntop">
                      <span v-if="!$v.forms.edit_number.callerid.required">* Number is required</span>
                      <span v-else-if="!$v.forms.edit_number.callerid.validNumber">* Number is not valid</span>
                    </p>
                    <p v-if="api.edit_number.validation_errors.callerid" class="text text-danger animated bounceIntop">
                      <span v-for="(em,i) in api.edit_number.validation_errors.callerid" :key="i">* {{ em }}</span>
                    </p>
                  </div>
                </div>
                <div class="whiteBoxWithBorderInsideGreyBox-row">
                  <div class="whiteBGinputWithGreyRoundedBorder w-100">
                    <label class="dialer-input-label">Reason for blocking</label>
                    <input 
                      class="w-100" 
                      type="text" 
                      :maxlength="$v.forms.edit_number.displayname.$params.maxLength.max" 
                      :disabled="api.edit_number.send || api.delete_number.send" 
                      v-model="forms.edit_number.displayname"  
                    />
                    <p v-if="$v.forms.edit_number.displayname.$error" class="text text-danger animated bounceIntop">
                      <span v-if="!$v.forms.edit_number.displayname.required">* Reason is required</span>
                      <span v-if="!$v.forms.edit_number.displayname.maxLength">* Reason can be maximum {{$v.forms.edit_number.displayname.$params.maxLength.max}} characters</span>
                    </p>
                    <p v-if="api.edit_number.validation_errors.displayname" class="text text-danger animated bounceIntop">
                      <span v-for="(em,i) in api.edit_number.validation_errors.displayname" :key="i">* {{ em }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="latestGreyBox-heading-main w-100 mt-20px">Direction of number block</div>
              <div class="latestGreyBox-descriptionText w-100">
                You can choose which direction you'd like to block this number.
                Inbound will prevent calls coming in whereas, Outbound will prevent
                calls being made to that number. Selecting both In and
                Outbound will prevent calls to or from this number.
              </div>
              <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
                <div class="whiteBoxWithBorderInsideGreyBox-row">
                  <div class="latestGreyBox-heading-main w-100">Block inbound calls</div>
                  <b-form-checkbox 
                    switch 
                    :disabled="api.edit_number.send || api.delete_number.send" 
                    :checked="['both','in'].includes(forms.edit_number.direction)"
                    @change="forms.edit_number.direction=$event?forms.edit_number.direction=='out'?'both':'in':forms.edit_number.direction=='both'?'out':''" 
                    class="newerSwitch" 
                  />
                </div>
                <div class="whiteBoxWithBorderInsideGreyBox-row">
                  <div class="latestGreyBox-heading-main w-100">Block outbound calls</div>
                  <b-form-checkbox 
                    switch 
                    :disabled="api.edit_number.send || api.delete_number.send" 
                    :checked="['both','out'].includes(forms.edit_number.direction)"
                    @change="forms.edit_number.direction=$event?forms.edit_number.direction=='in'?'both':'out':forms.edit_number.direction=='both'?'in':''" 
                    class="newerSwitch"
                  />
                </div>
                <p v-if="$v.forms.edit_number.direction.$error" class="text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.edit_number.direction.required">* Direction is required</span>
                </p>
              </div>
              <div class="mt-20px">
                <div class="whiteBoxWithBorderInsideGreyBox">
                  <div class="whiteBoxWithBorderInsideGreyBox-row">
                    <div class="latestGreyBox-heading-main">Remove from block list</div>
                    <button class="newDeleteButton" :disabled="api.edit_number.send || api.delete_number.send" type="button" @click="deleteInbound()">
                      <vb-icon icon="squared-delete-icon" height="38px" width="38px" />
                      <span class="newDeleteButton-textPart">Remove</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import required from "vuelidate/lib/validators/required";
import maxLength from 'vuelidate/lib/validators/maxLength';
import { $fn, VOIP_API } from "../../utils";
import { mapGetters } from 'vuex';
import { validNumberChecker } from '@/filter';
export default {
  name: "EditNumberBlockModal",
  props: {
    modalName: {
      type: String,
      default: 'EditNumberBlockModal',
    },
  },
  data() {
    return {
      forms: {
        edit_number: this.$helperFunction.formInstance({
          data: {
            callerid: '',
            displayname: '',
            direction: 'both',
          }
        }),
      },
      api: {
        edit_number: this.$helperFunction.apiInstance({
          validation_errors: true,
          error_message: true,
        }),
        delete_number: this.$helperFunction.apiInstance(),
      },
      data: {
        id: '',
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getIsMobile',
    ])
  },
  validations: {
    forms: {
      edit_number: {
        callerid: { 
          required: required,
          validNumber(value){ return $fn.validPhoneNumber(value) },
        },
        displayname: {
          required: required,
          maxLength: maxLength(255)
        },
        direction: {
          required: required,
        },
      },
    },
  },
  methods: {
    onBeforeOpen(event){
      const params = event.params || {}
      this.data.id = params.number.id;
      this.forms.edit_number.callerid = validNumberChecker(params.number.callerid,'',false,false)?.format?.('INTERNATIONAL') ?? params.number.callerid;
      this.forms.edit_number.displayname = params.number.displayname;
      this.forms.edit_number.direction = params.number.type;
    },
    onBeforeClose(){
      this.forms.edit_number.reset()
      this.api.edit_number.reset()
      this.$v.forms.edit_number.$reset()
      this.data.id=''
    },
    async editnumber(){
      this.$v.forms.edit_number.$touch();
      if(this.api.edit_number.send) return;
      if(this.$v.forms.edit_number.$invalid) return;
      try {
        this.api.edit_number.send=true
        this.api.edit_number.validation_errors={}
        this.api.edit_number.error_message=''
        await VOIP_API.endpoints.inbound_rule.create({
          callerid: $fn.getNumberFormated(this.forms.edit_number.callerid),
          displayname: this.forms.edit_number.displayname,
          type: this.forms.edit_number.direction,
          id: this.data.id,
          action: "update",
          enable: "Y",
          command: "busy",
          fid: 12,
          args: "fsdfsd",
          source: "app",
        })
        this.appNotify({
          message: "Successfully Updated",
          type: "success",
        })
        this.$modal.hide(this.modalName);
        this.$emit("interface");
      } catch (ex) {
        this.api.edit_number.validation_errors=ex.own_errors
        this.api.edit_number.error_message=ex.own_message
      } finally {
        this.api.edit_number.send=false
      }
    },
    async deleteInbound(){
      if(this.api.delete_number.send) return;
      try {
        const { confirm } = await this.$appConfirmation({
          title: "warning",
          message: `Are you sure you want to delete this number`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if (!confirm) return;
        this.api.delete_number.send=true
        await VOIP_API.endpoints.inbound_rule.delete(this.data.id)
        this.appNotify({
          message: "Successfully Deleted!",
          type: "success",
        })
        this.$modal.hide(this.modalName);
        this.$emit("interface");
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.delete_number.send=false
      }
    },
  },
};
</script>
