
import { VOIP_API } from "../index"

export const ELECTRON_API_ENDPOINTS = {
  checkAvailable(platform, version, params = {}) {
    return VOIP_API.axios.voip_centeral_point.v2.request({
      method: "GET",
      url: `desktop-versions/${platform}/${version}/check-available`,
      params,
    })
  },
  allowVersions(data = {}) {
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data: {
        ...data,
        type: 'relay',
        action: 'get-platform-version',
      },
    })
  },
  latestVersion(data = {}) {
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data: {
        ...data,
        type: 'relay',
        action: 'get-account-version',
      },
    })
  },
  appVersion(data = {}) {
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data: {
        ...data,
        type: 'relay',
        action: 'app_version',
      },
    })
  },
  checkMaintenance(params = {}) {
    return VOIP_API.axios.voip_centeral_point.v2.request({
      url: `desktop-versions/check-maintenance`,
      method: "GET",
      params,
    })
  },
}