var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500 EditNumbersModal min-width-50percent-modal linkCallModal bigSquareCheckbox",
    attrs: {
      "name": _vm.modalName,
      "width": "50%",
      "height": "auto",
      "scrollable": true
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', [_c('div', {
    staticClass: "dialer-edit-header flex-column align-items-start"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer d-flex align-items-center"
  }, [_c('a', {
    staticClass: "newBackButton mr-16px",
    attrs: {
      "type": "button",
      "disabled": _vm.api.link_call.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "arrow-left-circle"
    }
  }), _c('span', {
    staticClass: "newBackButton-textpart"
  }, [_vm._v("Back")])], 1), _vm._v(" Link call ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('b-button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.link_call.send
    },
    on: {
      "click": function ($event) {
        return _vm.linkCall();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm._v("Done")])], 1)], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px hasScrollBarInsideIt",
    staticStyle: {
      "max-height": "calc(100vh - 150px)",
      "overflow-y": "auto"
    },
    on: {
      "scroll": function ($event) {
        return _vm.onScroll($event);
      }
    }
  }, [_vm._l(_vm.response.call_logs, function (call) {
    return _c('b-form-checkbox', {
      key: call.id,
      attrs: {
        "disabled": _vm.api.link_call.send,
        "checked": _vm.selected.cdr_ids.includes(call.call_id)
      },
      on: {
        "change": function ($event) {
          _vm.selected.cdr_ids.includes(call.call_id) ? _vm.selected.cdr_ids.splice(_vm.selected.cdr_ids.indexOf(call.call_id), 1) : _vm.selected.cdr_ids.push(call.call_id);
        }
      }
    }, [_c('CallActivityTestItem', {
      attrs: {
        "call": call,
        "conditions": {
          action: {
            enable: _vm.fasle
          },
          design: {
            more: false
          }
        }
      }
    })], 1);
  }), _vm.response.call_logs.length == 0 ? _c('vb-no-record', {
    attrs: {
      "design": 3,
      "text": _vm.api.get_call_logs.send ? '' : 'No Calls'
    }
  }, [_vm.api.get_call_logs.send ? _c('div', {
    staticClass: "w-100",
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }, _vm._l(6, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "w-100"
    }, [_c('div', {
      class: `latestShimmerDesign w-75 mt-20px ${n % 2 == 1 ? 'ml-auto' : 'mr-auto'}`,
      staticStyle: {
        "height": "75px"
      }
    })]);
  }), 0) : _vm._e()]) : _vm._e()], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }