var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "center-zoomIn-transition sipActiveCall-Modal TagsModal",
    attrs: {
      "transition": !_vm.getIsMobile ? 'center-zoomIn-transition' : '',
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-call-notification-tray d-block"
  }, [_c('div', {
    staticClass: "dialer-call-notification"
  }, [_c('div', {
    staticClass: "dialer-call-notification-inner justify-content-between w-100"
  }, [_c('div', {
    staticClass: "dialer-call-notification-text"
  }, [_c('div', {
    staticClass: "dialer-call-notification-main-text"
  }, [_vm._v("Power dialer tag")]), _c('div', {
    staticClass: "dialer-call-notification-sub-text"
  }, [_vm._v("Here you can select the tags for this call")])]), _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('div', {
    staticClass: "dialer-call-notification-actions"
  }, [_c('div', {
    staticClass: "dialer-call-notification-actions-inner"
  }, [_c('b-form', {
    staticClass: "dialer-searchBox",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search here"
    },
    model: {
      value: _vm.filter.tag.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.tag, "search", $$v);
      },
      expression: "filter.tag.search"
    }
  })], 1)]), _c('ul', {
    staticClass: "dialer-tagList mt-20px"
  }, _vm._l(_vm.tags, function (tag) {
    return _c('li', {
      directives: [{
        name: "vb-tag-color",
        rawName: "v-vb-tag-color.bg.text.border",
        value: _vm.forms.update_tags.tags.includes(tag.real_id) && {
          color: tag.colour,
          text_color: tag.text_color
        },
        expression: "forms.update_tags.tags.includes(tag.real_id) && {color:tag.colour,text_color:tag.text_color}",
        modifiers: {
          "bg": true,
          "text": true,
          "border": true
        }
      }],
      key: tag.real_id,
      class: {
        selected: _vm.forms.update_tags.tags.includes(tag.real_id)
      },
      on: {
        "click": function ($event) {
          _vm.api.update_tags.send ? '' : (_vm.forms.update_tags.tags.includes(tag.real_id) ? _vm.forms.update_tags.tags.splice(_vm.forms.update_tags.tags.indexOf(tag.real_id), 1) : _vm.forms.update_tags.tags.push(tag.real_id), _vm.updateTagsDebounce());
        }
      }
    }, [_vm._v(" " + _vm._s(tag.tag) + " ")]);
  }), 0)], 1)])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }