var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup tagStatus-popup dialer-setting-call-tag",
    attrs: {
      "width": "50%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to md-pad-bottom dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title d-flex align-items-center"
  }, [_c('button', {
    staticClass: "newBackButton mr-16px",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "arrow-left-circle"
    }
  }), _c('span', {
    staticClass: "newBackButton-textpart"
  }, [_vm._v("Back")])], 1), _vm._v(" Power Dialer Tags ")]), _c('div', [_c('button', {
    staticClass: "newButton",
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-AddPowerDialerTag`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add tag")])], 1)])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("Call tagging helps organise and analyse call data, enabling easy reporting and customer identification.")])]), _c('div', {
    staticClass: "dialer-tabs"
  }, [_c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again",
    attrs: {
      "isListEmpty": _vm.response.tags.length == 0,
      "listLength": _vm.response.tags.length,
      "perPage": 5,
      "loading": _vm.api.fetch_tags.send,
      "conditions": {
        pagination: {
          last: true,
          first: true,
          show: true
        },
        is_no_record: {
          show: true
        },
        loading: {
          show: true
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return [_vm.getIsMobile ? _vm._l(_vm.response.tags, function (tag, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: tag.id
          }, [_c('td', {
            staticClass: "left"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('div', {
            directives: [{
              name: "vb-tag-color",
              rawName: "v-vb-tag-color",
              value: tag.colour,
              expression: "tag.colour"
            }],
            staticClass: "mr-8px",
            style: `border-radius:50%;height:25px;width:25px;min-height:25px;min-width:25px;`
          }), _c('div', [_c('div', {
            staticClass: "headingInsideTable"
          }, [_vm._v(_vm._s(tag.tag))]), tag.accountcode != '0' ? _c('span', {
            staticClass: "OwnertaggedVersion mb-0"
          }, [_vm._v("Custom")]) : _vm._e(), _c('div', {
            directives: [{
              name: "vb-tag-color",
              rawName: "v-vb-tag-color.text.bg",
              value: {
                color: tag.colour,
                text_color: tag.text_color
              },
              expression: "{\n                          color: tag.colour,\n                          text_color: tag.text_color\n                        }",
              modifiers: {
                "text": true,
                "bg": true
              }
            }],
            style: `border-radius:3px;height:auto;width:fit-content;padding:2px 6px;font-size: 8px;font-weight: 600;line-height: 1.2;text-align: center;vertical-align: initial;white-space: nowrap;`
          }, [_vm._v(" " + _vm._s(tag.tag) + " ")])])])]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "d-flex justify-content-end align-items-center"
          }, [_c('div', {
            staticClass: "switchWidth"
          }, [_vm.api.toggle_hide_tag.send == tag.id ? _c('div', {
            staticClass: "d-flex align-items-center w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "21px",
              "width": "50px"
            }
          })]) : _c('b-checkbox', {
            staticClass: "newerSwitch",
            attrs: {
              "disabled": !!_vm.api.toggle_hide_tag.send,
              "checked": tag.deleted_tags == 1 ? true : false,
              "switch": ""
            },
            on: {
              "change": function ($event) {
                return _vm.toggleHideTag(tag);
              }
            }
          })], 1), _c('div', {
            staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD"
          }, [tag.accountcode != '0' ? _c('div', [_c('b-dropdown', {
            attrs: {
              "right": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('b-icon', {
                  attrs: {
                    "icon": "three-dots-vertical",
                    "scale": "1.5"
                  }
                })];
              },
              proxy: true
            }], null, true)
          }, [_vm.api.remove_tag.send == tag.id ? _c('b-dropdown-item') : _c('b-dropdown-item', {
            attrs: {
              "disabled": !!_vm.api.remove_tag.send
            },
            on: {
              "click": function ($event) {
                return _vm.removeTag(tag);
              }
            }
          }, [_vm._v("Delete")])], 1)], 1) : _vm._e()])])])]);
        }) : _vm._l(_vm.response.tags, function (tag, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: tag.id
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(" " + _vm._s(tag.tag) + " "), tag.accountcode != '0' ? _c('span', {
            staticClass: "OwnertaggedVersion mb-0 ml-16px"
          }, [_vm._v("Custom")]) : _vm._e()]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_c('div', {
            directives: [{
              name: "vb-tag-color",
              rawName: "v-vb-tag-color",
              value: tag.colour,
              expression: "tag.colour"
            }],
            staticClass: "md-mar-left",
            style: `border-radius:50%;height:16px;width:16px;`
          })]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_c('div', {
            directives: [{
              name: "vb-tag-color",
              rawName: "v-vb-tag-color.text.bg",
              value: {
                color: tag.colour,
                text_color: tag.text_color
              },
              expression: "{\n                      color: tag.colour,\n                      text_color: tag.text_color\n                    }",
              modifiers: {
                "text": true,
                "bg": true
              }
            }],
            staticClass: "md-mar-left",
            style: `border-radius:4px;height:auto;width:fit-content;padding:0.2rem 0.4rem;font-size: 10.5px;font-weight: 500;line-height: 1.3;text-align: center;vertical-align: initial;white-space: nowrap;`
          }, [_vm._v(" " + _vm._s(tag.tag) + " ")])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_c('div', {
            staticClass: "switchWidth"
          }, [_vm.api.toggle_hide_tag.send == tag.id ? _c('div', {
            staticClass: "d-flex align-items-center w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "21px",
              "width": "50px"
            }
          })]) : _c('b-checkbox', {
            staticClass: "newerSwitch",
            attrs: {
              "disabled": !!_vm.api.toggle_hide_tag.send,
              "checked": tag.deleted_tags == 1 ? true : false,
              "switch": ""
            },
            on: {
              "change": function ($event) {
                return _vm.toggleHideTag(tag);
              }
            }
          })], 1)]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "d-flex justify-content-end"
          }, [_c('div', {
            staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD"
          }, [tag.accountcode != '0' ? _c('div', [_c('b-dropdown', {
            attrs: {
              "right": "",
              "disabled": _vm.api.remove_tag.send == tag.id
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('b-icon', {
                  attrs: {
                    "icon": "three-dots",
                    "scale": "1.5"
                  }
                })];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', {
            attrs: {
              "disabled": !!_vm.api.remove_tag.send
            },
            on: {
              "click": function ($event) {
                return _vm.removeTag(tag);
              }
            }
          }, [_vm._v("Delete")])], 1)], 1) : _vm._e()])])])]);
        })];
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "21px",
              "width": "80px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content"
          }, [_c('div', {
            staticClass: "md-mar-left latestShimmerDesign",
            staticStyle: {
              "height": "21px",
              "width": "21px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign md-mar-left",
            staticStyle: {
              "height": "21px",
              "width": "21px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign md-mar-left",
            staticStyle: {
              "height": "21px",
              "width": "50px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center justify-sontent-end w-fit-content ml-auto"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "16px",
              "width": "35px"
            }
          })])])]);
        });
      },
      proxy: true
    }])
  }, [!_vm.getIsMobile ? _c('tr', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Name")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Tag color")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Sample tag")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Hide")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-center"
  }, [_c('span')])]) : _vm._e()])], 1)])]), _c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500 addTagsModal-centerVersion onlyTagsModal scrollable",
    attrs: {
      "name": `${_vm._uid}-AddPowerDialerTag`
    }
  }, [_c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "dialer-edit-header position-relative d-block pb-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Add tag")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-AddPowerDialerTag`);
      }
    }
  }) : _c('a', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-AddPowerDialerTag`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)]), _c('div', {
    staticClass: "vm--modal-text mb-0"
  }, [_vm._v("Add tags name and details.")])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 w-100"
  }, [_c('b-form', {
    staticClass: "dialer-form w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.createTag();
      }
    }
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100"
  }, [_c('label', [_vm._v("Tag name")]), _c('b-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api.create_tag.send,
      "placeholder": "Enter name"
    },
    model: {
      value: _vm.forms.create_tag.tag,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_tag, "tag", $$v);
      },
      expression: "forms.create_tag.tag"
    }
  }), _vm.$v.forms.create_tag.$dirty && _vm.$v.forms.create.tag.$invalid ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.create_tag.tag.required ? _c('span', [_vm._v("* Name is required")]) : _vm._e()]) : _vm._e()], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mt-20px"
  }, [_c('label', [_vm._v("Tag color")]), _c('div', {
    staticClass: "createSpace-selectColor-container"
  }, [_vm.api.fetch_color_combination.send ? _vm._l(30, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "createSpace-selectColor-item-container"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "30px",
        "width": "30px"
      }
    })]);
  }) : _vm._l(_vm.response.color_combinations, function (color) {
    return _c('div', {
      key: color.id,
      staticClass: "createSpace-selectColor-item-container"
    }, [_c('div', {
      staticClass: "createSpace-selectColor-item",
      class: {
        'selected': _vm.forms.create_tag.color_combo.id == color.id,
        'TransparentSelected': color.color == 'rgba(0,0,0,0)'
      },
      style: `background-color:${color.color};`,
      on: {
        "click": function ($event) {
          _vm.api.create_tag.send ? '' : _vm.forms.create_tag.color_combo.id = color.id;
          _vm.forms.create_tag.color_combo.color = color.color;
          _vm.forms.create_tag.color_combo.text_color = color.text_color;
        }
      }
    }, [color.color == 'rgba(0,0,0,0)' ? _c('vb-icon', {
      attrs: {
        "icon": "todo-transparentColored-icon",
        "height": "28px",
        "width": "30px"
      }
    }) : _vm._e()], 1)]);
  })], 2), _vm.$v.forms.create_tag.$dirty && _vm.$v.forms.create_tag.color_combo.id.$invalid ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.create_tag.color_combo.id.required ? _c('span', [_vm._v("* Tag color is required")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "mt-4"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "variant": "success",
      "disabled": _vm.api.create_tag.send
    }
  }, [_vm.api.create_tag.send ? _c('vb-spinner') : [_vm._v("Add")]], 2)])])], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }