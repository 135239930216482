<template>
  <div>
    <div class="flow__graph designed designedAgain responsiveGraph scaleDown-0-6" ref="app_designed" id="app_designed">
      <div v-if="ivr" class="w-100 d-flex justify-content-center align-items-center flex-column">
        <div class="w-auto d-flex justify-content-center align-items-start flex-column" >
          <NumbersNode :dids="ivr.userDid" />
          <InfoNode :voipaccount="ivr.voipaccount" @edit="$modal.show(`${_uid}-EditIVRGraphModal`,{ accountcode: ivr.voipaccount })" />
          <OutOfHoursNode ref="ofh" :voipaccount="ivr.voipaccount" />
          <!-- welcome greeting -->
          <div class="nodeBasic-new" >
            <div class="nodeBasic-heading">Welcome Greeting</div>
            <div class="d-flex justify-content-between align-items-center w-100 mt-3">
              <vb-audio-player :src="ivr | welcomgreetingsrc" :design="'minimum'" class="withWhiteBg" />
              <b-icon 
                style="position: relative;" 
                @click="$modal.show('IVRWelcomeGreetingmodal',{ 
                  accountcode: ivr.voipaccount, 
                })" 
                icon="pencil-fill" 
                variant="primary" 
                class="cursor_pointer ml-auto" 
              />
            </div>
          </div>
          <div class="d-flex justify-content-center" style="width: 200px;">
            <div class="lineWithArrow">
              <div class="lineWithArrow-line"></div>
            </div>
          </div>
          <!-- options -->
          <div class="d-flex">
            <div>
              <DTMFNode 
                v-for="option in options" 
                :key="option.id"
                :option="option" 
                @updateDTMF="$modal.show(`${_uid}-MenuOptionExtensionAssignModal`, { 
                  account: option.ivr_cmd_args,
                  type: 'setting',
                  dtmf: option.user_dtmf,
                  schedule_id: schedule.id,
                  accountcode: ivr.voipaccount,
                  ringGroupMembers: option.ringGroupMembers || [],
                  ringgroup_id: option.ringgroup_id || '',
                  user_mode: option.user_mode || 0,
                })" 
                @configDTMF="$modal.show(`${_uid}-IVRMenuRingGroupSettingModal`,{
                  account: option.ivr_cmd_args,
                  type: 'setting',
                  dtmf: option.user_dtmf,
                  schedule_id: schedule.id,
                  accountcode: ivr.voipaccount,
                  ringGroupMembers: option.ringGroupMembers || [],
                  ringgroup_id: option.ringgroup_id || '',
                  user_mode: option.user_mode || 0,
                })"
                @deleteDTMF="deleteDTMF({
                  dtmf: option.user_dtmf,
                })" 
                @showGraph="$emit('change-graph',option.ivr_cmd_args)"
              />
              <div :class="`mb-3 d-flex align-items-center`">
                <div :class="`lineOnlyForIVR last`" style="align-self: stretch;width: 200px">
                  <div class="lineOnlyForIVR-y"></div>
                  <div class="lineOnlyForIVR-x"></div>
                </div>
                <div class="lineWithArrow right longLine-150px pt-0 d-flex align-items-center">
                  <div class="lineWithArrow-line "></div>
                </div>
                <button class="newDoneButton" @click="$modal.show('IvrGraphMenuOptionsModal', { accountcode: ivr.voipaccount })">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart d-flex justify-content-center position-relative">Add Menu Options</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MenuOptionExtensionAssignModal :modalName="`${_uid}-MenuOptionExtensionAssignModal`" @updated="fetchIvrsDebounce()" />
    <IVRMenuRingGroupSettingModal :modalName="`${_uid}-IVRMenuRingGroupSettingModal`" @updated="fetchIvrsDebounce()" />
    <IvrGraphMenuOptionsModal @options-updated="fetchIvrsDebounce()" />
    <IVRWelcomeGreetingmodal @file-change="fetchIvrsDebounce()" />
    <EditMenuModal 
      :modalName="`${_uid}-EditIVRGraphModal`" 
      @ofh-days-updated="$refs.ofh.fetchSchedule();"
      @ofh-toggle-updated="$refs.ofh.fetchcallRouteSetting();"
      @ofh-default-updated="$refs.ofh.fetchDefaultSetting();"
      @delete-ivr="$emit('delete-ivr')"
    />
  </div>
</template>

<script>
import NumbersNode from '../common/custom/NumbersNode.vue'
import InfoNode from '../common/custom/InfoNode.vue'
import OutOfHoursNode from '../common/custom/OutOfHoursNode.vue'
import DTMFNode from './DTMFNode.vue'
import IvrGraphMenuOptionsModal from '../../Modals/IvrGraphMenuOptions.vue'
import IVRWelcomeGreetingmodal from '../../Modals/IVRWelcomeGreetingmodal.vue'
import MenuOptionExtensionAssignModal from "../../Modals/MenuOptionExtensionAssignModal.vue";
import IVRMenuRingGroupSettingModal from "../../Modals/IVRMenuRingGroupSetting.vue";
import EditMenuModal from "../../Modals/EditMenuModal.vue";
import { mapGetters } from 'vuex'
import { VOIP_API } from '../../../utils';
import { welcomgreetingsrc } from '@/filter'
import _ from 'lodash'
export default {
  name: 'IVRGraph',
  components: {
    NumbersNode,
    InfoNode,
    OutOfHoursNode,
    DTMFNode,
    IvrGraphMenuOptionsModal,
    MenuOptionExtensionAssignModal,
    IVRWelcomeGreetingmodal,
    EditMenuModal,
    IVRMenuRingGroupSettingModal,
  },
  inject: [
    'getIvrs'
  ],
  props:{
    accountcode:{
      type: String
    }
  },
  data(){
    return{
      api: {
        delete_dtmf: this.$helperFunction.apiInstance(),
        extension_update: this.$helperFunction.apiInstance(),
      },
    }
  },
  computed:{
    ...mapGetters({
      getCurrentUser: 'getCurrentUser',
      getVoipIVRSAlises: 'getVoipIVRSAlises'
    }),
    ivr(){ return this.getVoipIVRSAlises[this.accountcode] },
    schedule(){ return this.ivr?.schedules?.[0] ?? null },
    options(){ return this.ivr?.schedules?.[0]?.options ?? [] },
    voipaccount(){ return this.ivr.voipaccount },
  },
  filters: {
    welcomgreetingsrc: welcomgreetingsrc,
  },
  methods: {
    fetchIvrsDebounce: _.debounce(function(){
      this.getIvrs()
    }, 2*1000),
    deleteDTMF(playload){
      var vm = this;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.delete_dtmf.send=true
            VOIP_API.endpoints.menu.resetoption(this.ivr.voipaccount, {
              option: playload.dtmf == 10 ? "*" : playload.dtmf,
              schedule: this.ivr.schedules[0].id,
            })
            .then(() => {
              vm.$emit('interface');
            })
            .finally(()=>{
              vm.api.delete_dtmf.send=false
            });
          }
        },
      });
    },
    extensionUpdated(message){
      let vm = this
      vm.api.extension_update.send=true
      VOIP_API.endpoints.menu.addoption({
        account: message?.account,
        id: this.ivr?.voipaccount,
        option: message?.dtmf,
        schedule_id: this.ivr.schedules[0].id,
        schedule_no: this.ivr.schedules[0].options.findIndex((i)=>i.user_dtmf==message?.dtmf)+1,
        type: "extension",
        command: "callfwd",
        args: message?.account,
      })
      .then(() => {
        vm.$emit('interface');
      })
      .finally(()=>{
        vm.api.extension_update.send=false
      });
    },
  },
}
</script>

<style>

</style>