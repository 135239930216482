module.exports.electron_events = {
  SHOW_NOTIFICATION: 'show-notification',
  INCOMING_CALL: 'incoming-call-modal',
  MINIZME_WINDOW: 'minimize-window',
  MAXIMIZE_WINDOW: 'maximize-window',
  UNMAXIMIZE_WINDOW: 'unmaximize-window',
  CLOSE_WINDOW: 'close-window',
  LOGIN: 'user-loggedin',
  SET_STATUSES: 'statuses',
  USER_UPDATEED: 'user-updated',
  LOGOUT: 'user-loggedout',
  TURN_OFF_SPLASH: 'turn-off-splash',
  SET_BADGES: 'set-badges',
  BEFORE_UNLOAD: 'before-unload',
  RELOAD: 'reload',
  CANCEL_DIAL_CALL: 'cancel-dial-call',
  DIAL_CALL: 'dial-call',
  ACCEPT_CALL: 'accept-call',
  REJECT_CALL: 'reject-call',
  INCOMING_CALL_HIDE: 'incoming-call-window-hide',
  UPDATE_APP: 'update-app',
  UPDATE_LATER: 'update-later',
  ABOUT_WINDOW_CLOSED: 'about-window-closed',
  GET_ELECTRON_UPDATE_INFO: 'GET_ELECTRON_UPDATE_INFO',
  CHECK_UPDATE: 'CHECK_UPDATE',
  TOGGLE_AUTO_UPDATE: 'TOGGLE_AUTO_UPDATE',
  INSTALL_UPDATE: 'INSTALL_UPDATE',
  SWITCH_VERSION: 'SWITCH_VERSION',
  CHANGE_THEME: 'CHANGE_THEME',
  CHANGE_UPDATE_DISABLED: 'CHANGE_UPDATE_DISABLED',
  CHANGE_APP_VIEW: 'CHANGE_APP_VIEW',
  CHANGE_CENTERAL_POINT: 'CHANGE_CENTERAL_POINT',
  CHANGE_ACCOUNTCODE: 'CHANGE_ACCOUNTCODE',
  CHANE_DEV_MODE: 'CHANE_DEV_MODE',
  recieve: {
    NOTIFICATION_CLICKED: 'notification-clicked',
    CALL_ACCEPTED: 'call-accepted',
    CALL_REJECTED: 'call-rejected',
    INCOMING_CALL_DATA: 'incoming-call-modal-data',
    DIAL_CALL: 'dial-call-main',
    LOGOUT: 'logout',
    SET_STATUSES: 'setStatus',
    IS_MAXIMIZED: 'window-maximize',
    IS_FOCUSED: 'IS_FOCUSED',
    ON_ABOUT_LOADED: 'ON_ABOUT_LOADED',
    
    IDLE_STATE: 'IDLE_STATE',
    AUTO_UPDATE: 'AUTO_UPDATE',
    UPDATE_DISABLED: 'UPDATE_DISABLED',
    APP_VIEW: 'APP_VIEW',
    UPDATE_AVAILABLE: 'UPDATE_AVAILABLE',
    UPDATE_NOT_AVAILABLE: 'UPDATE_NOT_AVAILABLE',
    CHECK_FOR_UPDATE: 'CHECK_FOR_UPDATE',
    UPDATE_DOWNLOADED: 'UPDATE_DOWNLOADED',
    UPDATE_DOWNLOAD_PROGRESS: 'UPDATE_DOWNLOAD_PROGRESS',
    UPDATE_ERROR: 'UPDATE_ERROR',
    APP_VERSION: 'APP_VERSION',
    DEVICE_NAME: 'DEVICE_NAME',
  },
}