var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.component, {
    directives: [{
      name: "observe-visibility",
      rawName: "v-observe-visibility",
      value: {
        callback: _vm.visibilityChanged,
        intersection: {
          threshold: 1
        }
      },
      expression: "{\n    callback: visibilityChanged,\n    intersection: {\n      threshold: 1,\n    },\n  }"
    }],
    tag: "component",
    class: {
      'visibleTransition': _vm.isVisible
    },
    attrs: {
      "call": _vm.call,
      "conditions": _vm.condition
    },
    on: {
      "call": function ($event) {
        return _vm.dial(_vm.call, $event);
      },
      "sms": function ($event) {
        return _vm.sms(_vm.call, $event);
      },
      "info": function ($event) {
        return _vm.$emit('info', $event);
      },
      "assign": function ($event) {
        return _vm.$emit('assign');
      },
      "transcription": function ($event) {
        return _vm.$emit('transcription');
      },
      "play-audio": function ($event) {
        return _vm.$emit('play-audio', $event);
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (slot, key) {
      return {
        key: key,
        fn: function () {
          return [_vm._t(key)];
        },
        proxy: true
      };
    })], null, true)
  });

}
var staticRenderFns = []

export { render, staticRenderFns }