var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "latestDuringCallDesign-parentContainer"
  }, [!_vm.getIsMobile ? [_vm.callObjInfo.is_transfer ? _c('div', {
    staticClass: "transferCallBar-container"
  }, [_c('div', {
    staticClass: "transferCallBar-heading"
  }, [_vm._v(" Transfer ")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn afterTransfer-Grid",
    class: {
      'disabled': !_vm.callObjInfo.incall
    },
    on: {
      "click": function ($event) {
        !_vm.callObjInfo.incall ? '' : _vm.phones.customAfterTransfer();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "makePathBlack",
    attrs: {
      "icon": "duringCall-afterTransfer-icon",
      "width": "63",
      "height": "63"
    }
  }), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn-text"
  }, [_vm._v("After Transfer")])], 1), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn cancelTransfer-Grid",
    class: {
      'disabled': !_vm.callObjInfo.incall
    },
    on: {
      "click": function ($event) {
        !_vm.callObjInfo.incall ? '' : _vm.phones.cutomCancelTransfer();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "makePathBlack",
    attrs: {
      "icon": "duringCall-cancelTransfer-icon",
      "width": "63",
      "height": "63"
    }
  }), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn-text"
  }, [_vm._v("Cancel Transfer")])], 1)])]) : _c('div', {
    staticClass: "recordingDIV-duringCall-container topRightBTN",
    on: {
      "click": function ($event) {
        _vm.$store.state.global_conditions.tab = 'homeCopy';
      }
    }
  }, [_c('div', {
    staticClass: "recordingDIV-duringCall-text"
  }, [_vm._v("New Call")])]), _vm._l(_vm.session.speech_to_text.streams, function (data) {
    return _c('div', {
      key: data.stream_id
    }, [_c('p', [_vm._v(_vm._s(data.text))])]);
  })] : _vm._e(), _c('div', {
    staticClass: "latestDuringCallDesign"
  }, [!_vm.getIsMobile ? _c('div', {
    staticClass: "latestDuringCallDesign-inner d-flex flex-column align-items-center",
    class: !_vm.callObjInfo.incall ? 'mobile-incomingCall-container' : 'mobile-duringCall-container'
  }, [_vm.session.feedback_screen_active ? _c('div') : _c('div', {
    staticClass: "d-flex flex-column align-items-center justify-content-between h-100 w-100"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center w-100"
  }, [!_vm.callObjInfo.incall ? _c('h6', {
    staticClass: "color-white"
  }, [_vm._v(_vm._s(_vm.callObjInfo.incoming ? 'Incoming call' : 'Outgoing call'))]) : _vm._e(), !_vm.callObjInfo.incall ? _c('p', {
    staticClass: "color-white"
  }, [_vm._v(_vm._s(_vm.session.callStatus))]) : _vm._e(), _c('div', {
    staticClass: "call-user-info-container d-flex flex-column align-items-center"
  }, [_vm.callObjInfo.test_call ? _c('div', {
    staticClass: "color-white latestDuringCallDesign-text"
  }, [_vm._v("Test Call")]) : [_vm.session.extra_data.power_dialer_contact ? _c('div', {
    staticClass: "color-white latestDuringCallDesign-text"
  }, [_vm._v("Power dialer")]) : _vm._e(), _c('div', {
    staticClass: "color-white latestDuringCallDesign-text"
  }, [_vm._v("From: " + _vm._s(_vm.callObjInfo.from_name) + " " + _vm._s(_vm._f("number_formater")(_vm.callObjInfo.from_number)))]), _c('div', {
    staticClass: "color-white latestDuringCallDesign-text"
  }, [_vm._v("To: " + _vm._s(_vm.callObjInfo.to_name) + " " + _vm._s(_vm._f("number_formater")(_vm.callObjInfo.to_number)))]), _vm.callObjInfo.call_type ? _c('div', {
    staticClass: "color-white latestDuringCallDesign-text"
  }, [_vm._v(_vm._s(_vm.callObjInfo.call_type))]) : _vm._e()]], 2), _vm.callObjInfo.incall ? _c('div', {
    staticClass: "call-time-container d-flex align-items-center"
  }, [_c('div', {
    staticClass: "color-white latestDuringCallDesign-text"
  }, [_vm._v(_vm._s(_vm._f("incrementFilter")(_vm.session.time)))])]) : _vm._e()]), _c('div', {
    staticClass: "w-100",
    class: {
      'mobile-incomingCall-actions': !_vm.callObjInfo.incall,
      'mobile-duringCall-actions': _vm.callObjInfo.incall,
      'mobile-transfer-actions': _vm.callObjInfo.is_transfer
    }
  }, [_c('div', {
    staticClass: "latestDuringCallDesign-gridLayout",
    class: {
      'incomminCallCase': !_vm.callObjInfo.incall && _vm.callObjInfo.incoming
    }
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "latestDuringCallDesign-iconCloumn forNotes-Grid",
    class: {
      'for-disable': !_vm.getUserPermissions.call_tagging_and_notes,
      'disabled': _vm.session.is_terminated || _vm.callObjInfo.call_type || !_vm.getUserPermissions.call_tagging_and_notes || !(_vm.callObjInfo.incall && !_vm.callObjInfo.is_transfer)
    },
    attrs: {
      "title": _vm.getUserPermissions.call_tagging_and_notes ? '' : 'Your package does not support this feature'
    },
    on: {
      "click": function ($event) {
        _vm.session.is_terminated || _vm.callObjInfo.call_type || !_vm.getUserPermissions.call_tagging_and_notes || !(_vm.callObjInfo.incall && !_vm.callObjInfo.is_transfer) ? '' : _vm.$modal.show(`${_vm.callObjInfo.uuid}NoteModal`, {
          id: _vm.session.extra_data.id,
          note: _vm.session.note
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "latestDuringCallDesign-notes-icon",
      "id": "NotesIcon",
      "width": "63",
      "height": "63"
    }
  }), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn-text"
  }, [_vm._v("Notes")])], 1), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn hold-Grid",
    class: {
      'disabled': _vm.session.is_terminated || !_vm.callObjInfo.incall
    },
    on: {
      "click": function ($event) {
        _vm.session.is_terminated || !_vm.callObjInfo.incall ? '' : _vm.session.hold();
      }
    }
  }, [_c('vb-icon', {
    class: {
      'makePathBlack': _vm.callObjInfo.hold
    },
    attrs: {
      "icon": _vm.callObjInfo.hold ? 'dialer-playInBox-icon' : 'latestDuringCallDesign-hold-icon',
      "width": "63",
      "height": "63"
    }
  }), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn-text"
  }, [_vm._v(_vm._s(_vm.session.held ? 'Resume' : 'Hold'))])], 1), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn transfer-Grid",
    class: {
      'disabled': _vm.session.is_terminated || _vm.callObjInfo.call_type || !(_vm.callObjInfo.incall && !_vm.callObjInfo.is_transfer && !_vm.callObjInfo.call_transfered)
    },
    on: {
      "click": function ($event) {
        _vm.session.is_terminated || _vm.callObjInfo.call_type || !(_vm.callObjInfo.incall && !_vm.callObjInfo.is_transfer && !_vm.callObjInfo.call_transfered) ? '' : _vm.$modal.show(`${_vm.callObjInfo.uuid}TransferCallModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "latestDuringCallDesign-transfer-icon",
      "width": "63",
      "height": "63"
    }
  }), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn-text"
  }, [_vm._v("Transfer")])], 1), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "latestDuringCallDesign-iconCloumn tags-Grid",
    class: {
      'for-disable': !_vm.getUserPermissions.call_tagging_and_notes,
      'disabled': _vm.session.is_terminated || _vm.callObjInfo.call_type || !_vm.getUserPermissions.call_tagging_and_notes || !(_vm.callObjInfo.incall && !_vm.callObjInfo.is_transfer)
    },
    attrs: {
      "title": _vm.getUserPermissions.call_tagging_and_notes ? '' : 'Your package does not support this feature'
    },
    on: {
      "click": function ($event) {
        _vm.session.is_terminated || _vm.callObjInfo.call_type || !_vm.getUserPermissions.call_tagging_and_notes || !(_vm.callObjInfo.incall && !_vm.callObjInfo.is_transfer) ? '' : _vm.$modal.show(`${_vm.callObjInfo.uuid}TagsModal`, {
          id: _vm.session.extra_data.id,
          tags: _vm.session.tags
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "latestDuringCallDesign-tags-icon",
      "id": "tagsIcon",
      "width": "63",
      "height": "63"
    }
  }), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn-text"
  }, [_vm._v("Tags")])], 1), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn mute-Grid",
    class: {
      'disabled': _vm.session.is_terminated || !(_vm.callObjInfo.incall && !_vm.callObjInfo.hold)
    },
    on: {
      "click": function ($event) {
        _vm.session.is_terminated || !(_vm.callObjInfo.incall && !_vm.callObjInfo.hold) ? '' : _vm.session.toggelMute();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": _vm.callObjInfo.is_mute ? 'latestDuringCallDesign-mute-icon' : 'latestDuringCallDesign-unMute-icon',
      "width": "63",
      "height": "63"
    }
  }), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn-text"
  }, [_vm._v(_vm._s(_vm.callObjInfo.is_mute ? 'Mute' : 'Unmute'))])], 1), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn keypad-Grid",
    class: {
      'disabled': _vm.session.is_terminated || _vm.callObjInfo.call_type || !(_vm.callObjInfo.incall && !_vm.callObjInfo.is_transfer)
    },
    on: {
      "click": function ($event) {
        _vm.session.is_terminated || _vm.callObjInfo.call_type || !(_vm.callObjInfo.incall && !_vm.callObjInfo.is_transfer) ? '' : _vm.$modal.show(`${_vm.callObjInfo.uuid}SipCallKeypadModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "latestDuringCallDesign-keypad-icon",
      "width": "63",
      "height": "63"
    }
  }), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn-text"
  }, [_vm._v("Keypad")])], 1), !_vm.callObjInfo.incall && _vm.callObjInfo.incoming ? _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn accept-Grid",
    on: {
      "click": function ($event) {
        return _vm.session.accepts();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "mobile-pickup-icon",
      "width": "63",
      "height": "63"
    }
  }), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn-text"
  }, [_vm._v("Accept")])], 1) : _vm._e(), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn hangup-icon hangup-Grid",
    on: {
      "click": function ($event) {
        return _vm.session.hangup();
      }
    }
  }, [_c('span', {
    class: `circularanimationContainer ${_vm.callObjInfo.call_type || !(_vm.callObjInfo.incall && !_vm.callObjInfo.is_transfer) ? 'notInCall' : ''}`
  }, [_c('vb-icon', {
    attrs: {
      "icon": "latestDuringCallDesign-hangUp-icon",
      "width": "63",
      "height": "63"
    }
  })], 1), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn-text"
  }, [_vm._v("Hangup")])])])])])]) : _vm._e(), _vm.getIsMobile ? _c('div', {
    class: !_vm.callObjInfo.incall ? 'mobile-incomingCall-container' : 'mobile-duringCall-container'
  }, [!_vm.callObjInfo.incall ? _c('h6', {
    staticClass: "mobile-incomingCall-heading"
  }, [_vm._v(_vm._s(_vm.callObjInfo.incoming ? 'Incoming call' : 'Outgoing call'))]) : _vm._e(), !_vm.callObjInfo.incall ? _c('p', {
    staticClass: "mobile-incomingCall-heading"
  }, [_vm._v(_vm._s(_vm.session.callStatus))]) : _vm._e(), _c('div', {
    staticClass: "mobile-duringCall-top"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.3"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('to-dashboard');
      }
    }
  }), _vm.callObjInfo.incall ? _c('div', {
    staticClass: "call-time-container"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "mobile-duringCall-phone-icon",
      "width": "14.784",
      "height": "14.784"
    }
  }), _c('div', {
    staticClass: "call-time"
  }, [_vm._v(_vm._s(_vm._f("incrementFilter")(_vm.session.time)))])], 1) : _vm._e(), _c('vb-icon', {
    attrs: {
      "icon": "mobile-duringCall-switchCall-icon",
      "width": "19.461",
      "height": "19.492"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('calls-list');
      }
    }
  })], 1), _c('div', {
    staticClass: "call-user-info-container"
  }, [_c('div', {
    staticClass: "user-image"
  }, [_c('img', {
    attrs: {
      "src": _vm.callObjInfo.image
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.userDp;
      }
    }
  })]), _c('div', {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm.callObjInfo.incoming ? `${_vm.callObjInfo.from_name}` : `${_vm.callObjInfo.to_name}`))]), _c('div', {
    staticClass: "user-number"
  }, [_vm.callObjInfo.incoming ? [_vm._v(_vm._s(_vm._f("number_formater")(_vm.callObjInfo.from_number)))] : [_vm._v(_vm._s(_vm._f("number_formater")(_vm.callObjInfo.to_number)))]], 2)]), _c('div', {
    staticClass: "fixed-bottom",
    class: {
      'mobile-incomingCall-actions': !_vm.callObjInfo.incall,
      'mobile-duringCall-actions': _vm.callObjInfo.incall,
      'mobile-transfer-actions': _vm.callObjInfo.is_transfer,
      'mobile-outGoingCall-actions': !_vm.callObjInfo.incoming
    }
  }, [_vm.callObjInfo.is_transfer ? _c('div', {
    staticClass: "transferCallCas-container"
  }, [_c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn afterTransfer-Grid",
    class: {
      'disabled': !_vm.callObjInfo.incall
    },
    on: {
      "click": function ($event) {
        !_vm.callObjInfo.incall ? '' : _vm.phones.customAfterTransfer();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "makePathBlack",
    attrs: {
      "icon": "duringCall-afterTransfer-icon",
      "width": "63",
      "height": "63"
    }
  }), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn-text"
  }, [_vm._v("After Transfer")])], 1), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn cancelTransfer-Grid",
    class: {
      'disabled': !_vm.callObjInfo.incall
    },
    on: {
      "click": function ($event) {
        !_vm.callObjInfo.incall ? '' : _vm.phones.cutomCancelTransfer();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "makePathBlack",
    attrs: {
      "icon": "duringCall-cancelTransfer-icon",
      "width": "63",
      "height": "63"
    }
  }), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn-text"
  }, [_vm._v("Cancel Transfer")])], 1)]) : _vm._e(), _c('div', {
    staticClass: "latestDuringCallDesign-gridLayout",
    class: {
      'inTransferCase': _vm.callObjInfo.is_transfer && _vm.callObjInfo.incall
    }
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "latestDuringCallDesign-iconCloumn forNotes-Grid",
    class: {
      'for-disable': !_vm.getUserPermissions.call_tagging_and_notes,
      'disabled': !_vm.getUserPermissions.call_tagging_and_notes || !(_vm.callObjInfo.incall && !_vm.callObjInfo.is_transfer)
    },
    attrs: {
      "title": _vm.getUserPermissions.call_tagging_and_notes ? '' : 'Your package does not support this feature'
    },
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.call_tagging_and_notes || !(_vm.callObjInfo.incall && !_vm.callObjInfo.is_transfer) ? '' : _vm.$modal.show(`${_vm.callObjInfo.uuid}NoteModal`, {
          id: _vm.session.extra_data.id,
          note: _vm.session.note
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "latestDuringCallDesign-notes-icon",
      "id": "NotesIcon",
      "width": "63",
      "height": "63"
    }
  }), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn-text"
  }, [_vm._v("Notes")])], 1), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn hold-Grid",
    class: {
      'disabled': !_vm.callObjInfo.incall
    },
    on: {
      "click": function ($event) {
        !_vm.callObjInfo.incall ? '' : _vm.session.hold();
      }
    }
  }, [_c('vb-icon', {
    class: {
      'makePathBlack': _vm.callObjInfo.hold
    },
    attrs: {
      "icon": _vm.callObjInfo.hold ? 'dialer-playInBox-icon' : 'latestDuringCallDesign-hold-icon',
      "width": "63",
      "height": "63"
    }
  }), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn-text"
  }, [_vm._v(_vm._s(_vm.session.held ? 'Resume' : 'Hold'))])], 1), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn transfer-Grid",
    class: {
      'disabled': !(_vm.callObjInfo.incall && !_vm.callObjInfo.is_transfer && !_vm.callObjInfo.call_transfered)
    },
    on: {
      "click": function ($event) {
        !(_vm.callObjInfo.incall && !_vm.callObjInfo.is_transfer && !_vm.callObjInfo.call_transfered) ? '' : _vm.$modal.show(`${_vm.callObjInfo.uuid}TransferCallModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "latestDuringCallDesign-transfer-icon",
      "width": "63",
      "height": "63"
    }
  }), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn-text"
  }, [_vm._v("Transfer")])], 1), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn tags-Grid",
    class: {
      'for-disable': !_vm.getUserPermissions.call_tagging_and_notes,
      'disabled': !_vm.getUserPermissions.call_tagging_and_notes || !(_vm.callObjInfo.incall && !_vm.callObjInfo.is_transfer)
    },
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.call_tagging_and_notes || !(_vm.callObjInfo.incall && !_vm.callObjInfo.is_transfer) ? '' : _vm.$modal.show(`${_vm.callObjInfo.uuid}TagsModal`, {
          id: _vm.session.extra_data.id,
          tags: _vm.session.tags
        });
      }
    }
  }, [_c('vb-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    attrs: {
      "id": "tagsIcon",
      "title": _vm.getUserPermissions.call_tagging_and_notes ? '' : 'Your package does not support this feature',
      "icon": "latestDuringCallDesign-tags-icon",
      "width": "63",
      "height": "63"
    }
  }), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn-text"
  }, [_vm._v("Tags")])], 1), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn mute-Grid",
    class: {
      'disabled': !(_vm.callObjInfo.incall && !_vm.callObjInfo.hold)
    },
    on: {
      "click": function ($event) {
        !(_vm.callObjInfo.incall && !_vm.callObjInfo.hold) ? '' : _vm.session.toggelMute();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": _vm.callObjInfo.is_mute ? 'latestDuringCallDesign-mute-icon' : 'latestDuringCallDesign-unMute-icon',
      "width": "63",
      "height": "63"
    }
  }), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn-text"
  }, [_vm._v(_vm._s(_vm.callObjInfo.is_mute ? 'Mute' : 'Unmute'))])], 1), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn keypad-Grid",
    class: {
      'disabled': !(_vm.callObjInfo.incall && !_vm.callObjInfo.is_transfer)
    },
    on: {
      "click": function ($event) {
        !(_vm.callObjInfo.incall && !_vm.callObjInfo.is_transfer) ? '' : _vm.$modal.show(`${_vm.callObjInfo.uuid}SipCallKeypadModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "latestDuringCallDesign-keypad-icon",
      "width": "63",
      "height": "63"
    }
  }), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn-text"
  }, [_vm._v("Keypad")])], 1), !_vm.callObjInfo.incall && _vm.callObjInfo.incoming ? _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn accept-Grid",
    on: {
      "click": function ($event) {
        return _vm.onAccept();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "mobile-pickup-icon",
      "width": "63",
      "height": "63"
    }
  }), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn-text"
  }, [_vm._v("Accept")])], 1) : _vm._e(), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn hangup-icon hangup-Grid",
    on: {
      "click": function ($event) {
        return _vm.session.hangup();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "latestDuringCallDesign-hangUp-icon",
      "width": "63",
      "height": "63"
    }
  }), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn-text"
  }, [_vm._v("Hangup")])], 1), _vm.callObjInfo.incall && !_vm.callObjInfo.is_transfer && _vm.isOnDemandCallRecording ? _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn d-flex forRecording recording-Grid",
    on: {
      "click": function ($event) {
        return _vm.session.record();
      }
    }
  }, [_c('div', {
    staticClass: "forRecording-iconContainer"
  }, [_c('vb-icon', {
    staticClass: "makePathBlack",
    attrs: {
      "icon": _vm.session.recording ? 'callRecording-duringCall-icon' : 'callRecording-duringCall-icon',
      "width": "63",
      "height": "63"
    }
  })], 1), _c('div', {
    staticClass: "latestDuringCallDesign-iconCloumn-text"
  }, [_vm._v(_vm._s(_vm.session.recording ? 'Stop' : 'Record'))])]) : _vm._e()])])]) : _vm._e(), _c('SipCallKeypadModal', {
    attrs: {
      "modalName": `${_vm.callObjInfo.uuid}SipCallKeypadModal`,
      "phones": _vm.phones
    }
  }), _c('CallTransferModal', {
    attrs: {
      "modalName": `${_vm.callObjInfo.uuid}TransferCallModal`,
      "phones": _vm.phones,
      "session": _vm.session
    }
  }), _c('NoteModal', {
    attrs: {
      "modalName": `${_vm.callObjInfo.uuid}NoteModal`
    },
    on: {
      "updated-note": function ($event) {
        _vm.session.note = $event;
      }
    }
  }), _c('TagsModal', {
    attrs: {
      "modalName": `${_vm.callObjInfo.uuid}TagsModal`
    },
    on: {
      "updated-tags": function ($event) {
        _vm.session.tags = $event;
      }
    }
  })], 1), !_vm.getIsMobile ? [!_vm.session.feedback_screen_active && _vm.callObjInfo.incall && !_vm.callObjInfo.is_transfer && _vm.isOnDemandCallRecording ? _c('div', {
    staticClass: "recordingDIV-duringCall-container",
    on: {
      "click": function ($event) {
        return _vm.session.record();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "makePathBlack",
    attrs: {
      "icon": _vm.session.recording ? 'callRecording-duringCall-icon' : 'callRecording-duringCall-icon',
      "width": "63",
      "height": "63"
    }
  }), _c('div', {
    staticClass: "recordingDIV-duringCall-text"
  }, [_vm._v(_vm._s(_vm.session.recording ? 'Stop Recording' : 'Record Call'))])], 1) : _vm._e(), _vm.callObjInfo.incall && _vm.callObjInfo.is_internal_call ? _c('div', {
    staticClass: "recordingDIV-duringCall-container rightSide",
    on: {
      "click": function ($event) {
        return _vm.switchCall();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "makePathBlack",
    attrs: {
      "icon": "switchToVideoCall-icon",
      "width": "63",
      "height": "63"
    }
  }), _c('div', {
    staticClass: "recordingDIV-duringCall-text"
  }, [_vm._v("Switch Video Call")])], 1) : _vm._e()] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }