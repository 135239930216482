<template>
  <div :class="`mb-3 d-flex align-items-center position-relative`">
    <div :class="`lineOnlyForIVR`" style="align-self: stretch;width: 200px">
      <div class="lineOnlyForIVR-y"></div>
      <div class="lineOnlyForIVR-x"></div>
    </div>
    <div class="lineWithArrow right longLine-150px pt-0 d-flex align-items-center">
      <div class="lineWithArrow-line "></div>
    </div>
    <div class="textAboveHorizontalLine">Press Option {{option.user_dtmf}}</div>
    <div class="nodeBasic-new" >
      <span v-if="option.ivr_cmd_args==option.ringgroup_id">{{option.ringGroupMembers.length}} users</span>
      <Info v-else :id="option.ivr_cmd_args" :is_blf="false"  />
      <div :class="`d-flex ${forwardInfo && forwardInfo.type=='ivr' ? 'justify-content-between' : 'justify-content-end'} align-items-center w-100 mt-3 position-relative`">
        <vb-audio-player v-if="forwardInfo && forwardInfo.type=='ivr'" :src="forwardInfo.data | welcomgreetingsrc" :design="'minimum'" class="audioForMobile"/>
        <b-icon 
          style="position: relative;" 
          @click="$emit('updateDTMF')" 
          icon="pencil-fill" 
          variant="primary" 
          class="cursor_pointer ml-3" 
        />
        <b-icon 
          v-if="option.ringgroup_id"
          style="position: relative;" 
          @click="$emit('configDTMF')" 
          icon="info-circle-fill" 
          variant="primary" 
          class="cursor_pointer ml-3" 
        />
        <b-icon 
          style="position: relative;" 
          @click="$emit('deleteDTMF')" 
          icon="trash-fill" 
          variant="primary" 
          class="cursor_pointer ml-3" 
        />
      </div>
    </div>
    <template v-if="members.length>0">
      <div class="lineWithArrow right longLine-150px pt-0 d-flex align-items-center">
        <div class="lineWithArrow-line "></div>
      </div>
      <div class="nodeBasic-new">
        <div v-for="member in firstFourMembers" :key="member.key"  class="mb-2">
          <Info :id="member.key" :type="'USER'" :is_blf="false" />
        </div>
        <div v-if="members.length>4" class="d-flex justify-content-end w-100 mt-4">
          <div style="color: rgb(85, 118, 209); font-weight: 600; font-size: 16px;"> {{members.length - 4}} more members </div>
        </div>
        <div class="d-flex justify-content-end mt-8px">
          <vb-icon icon="graphIconInsideGraph-icon" height="32px" width="32px" class="cursor_pointer graphIconInsideGraph-icon" @click="$emit('showGraph')"/>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Info from '../../Lists/Info.vue';
export default {
  name: 'DTMFNode',
  components: {
    Info,
  },
  props: {
    option: {
      type: Object,
      default: ()=>({})
    },
  },
  computed: {
    ...mapGetters([
      'getVoipUsersAlises',
      'getVoipUserAccountcode',
      'getVoipTeamsAlises',
      'getVoipCallQueuesAlises',
      'getVoipIVRSAlises',
    ]),
    forwardInfo(){
      const key = this.option?.ivr_cmd_args ?? ''
      const user = this.getVoipUsersAlises[key];
      const team = this.getVoipTeamsAlises[key]
      const call_queue = this.getVoipCallQueuesAlises[key]
      const ivr = this.getVoipIVRSAlises[key]
      if(team) return { data: team, type: 'team' }
      else if(call_queue) return { data: call_queue, type: 'call_queue' }
      else if(ivr) return { data: ivr, type: 'ivr' }
      else if(user) return { data: user, type: 'user' }
      else return null;
    },
    members(){
      if(this.forwardInfo?.type=='team') return this.forwardInfo.data?.members?.map?.(i=>{return {key:i.accountcode}}) ?? []
      else if(this.forwardInfo?.type=='call_queue') return this.forwardInfo.data?.members?.map?.(i=>{return {key:i.membername}}) ?? []
      else if(this.forwardInfo?.type=='ivr') return this.forwardInfo.data?.schedules?.[0]?.options?.map?.(i=>{return {key:i.ivr_cmd_args,name:i.accountname}}) ?? []
      else if(this.forwardInfo?.type=='number') return this.forwardInfo.data?.extensions ?? []
      else return []
    },
    firstFourMembers(){
      return _.take(this.members,4)
    }
  },
}
</script>

<style>

</style>