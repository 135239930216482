<template>
  <div class="dialer-settings-main">
    <section class="dialer-settings-section dialer-account-setting">
      <div class="dialer-settings-top">
        <template v-if="getIsMobile">
          <div class="d-flex align-items-center mb-3 calc-title-width">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" @click="$emit('back')" />
            <h2 class="dialer-settings-title w-100 mb-0 text-center">Billing</h2>
          </div>
        </template>
        <h2 v-else class="dialer-settings-title newer">
          <span>Billing</span>
          <b-icon id="ivr-info" icon="info-circle-fill" @click="$modal.show('KbInfoModal',{ kb: 'billing' })" class="cursor_pointer infoKbIcon md-mar-left" />
        </h2>
      </div>
      <div class="dialer-settings-section_inner p-0 no-padding">
        <div v-if="isAnyUnPaidInvoice" class="innerRedBoxwithRoundedBorder mt-20px">
          <vb-icon icon="AccountBalance-newBilling-icon" height="25px" width="25px" class="AccountBalance-newBilling-icon"/>
          <div class="d-flex align-items-center justify-content-between flex-fill">
            <p class="innerRedBoxwithRoundedBorder-text mb-0">
              Unpaid invoices, please pay now so that there is no disruption of services.
            </p>
            <span @click="$modal.show(`${_uid}-unpaidInvoices`)" class="OwnertaggedVersion mb-0 showOnly-OnHover-insideTable cursor_pointer_no_hover">Pay now</span>
          </div>
        </div>
        <div class="latestBilling-section-1">
          <!-- plan -->
          <div class="latestBilling-section-1-card firstCard">
            <template v-if="api.subscription_detail.send">
              <vb-icon icon="PlanIcon-newBilling-icon" class="latestBilling-section-1-card-icon" />
              <div class="latestBilling-section-1-textSection">
                <div class="latestBilling-section-1-cardType">
                  <div class="latestShimmerDesign someTransparent" style="height:18px;width:50%"></div>
                </div>
                <div class="latestShimmerDesign someTransparent" style="height:24px;width:50%"></div>
                <div class="latestShimmerDesign someTransparent mt-16px" style="height:38px;width:100%"></div>
                <div class="latestBilling-section-1-lastRow mt-8px">
                  <div class="latestShimmerDesign someTransparent" style="height:18px;width:50%"></div>
                  <div class="latestShimmerDesign someTransparent ml-auto" style="height:50px;width:150px"></div>
                </div>
              </div>
            </template>
            <template v-else>
              <vb-icon icon="PlanIcon-newBilling-icon" class="latestBilling-section-1-card-icon" />
              <div class="latestBilling-section-1-textSection">
                <div class="latestBilling-section-1-cardType">
                  Current Plan
                  <span v-if="isOnTrail" class="OwnertaggedVersion mb-0 mt-0 mr-16px">{{ 'Trial' }}</span>
                  <span v-if="currentPlanSuspended" class="suspendedTag">{{ currentPlanSuspended ? 'Suspended' : '' }}</span>
                </div>
                <div class="latestBilling-section-1-PackageLabel">{{ response.subscription_detail | get_property('userCurrentSubscription.plan_detail.plan_label') }}</div>
                <div class="latestBilling-section-1-PackageText">
                  Your current plan is {{ response.subscription_detail | get_property('userCurrentSubscription.plan_detail.plan_label') }}. 
                  <template v-if="!isOnFreePlan">
                    <span v-if="response.subscription_detail && response.subscription_detail.next_billing_date && !isOnFreePlan">
                      The {{isOnTrail ? 'trial expiration date' : 'next billing date'}} is <span class="mb-0" v-b-popover.hover.top="`Subscription date: ${response.subscription_detail.current_date}`">{{ response.subscription_detail | get_property('next_billing_date') | showTime }}.</span>
                    </span>
                  </template>
                </div>
                <div class="latestBilling-section-1-lastRow mt-20px">
                  <button v-if="!currentPlanSuspended && isOnTrail" class="dialer-button dialer-button-primary" @click="cancelTrail()">Cancel Trial</button>
                  <button v-if="currentPlanSuspended && isOnTrail" class="dialer-button dialer-button-primary" @click="reactivateServices()">Reactivate Services</button>
                  <button class="latestBilling-section-1-button ml-auto" @click="$modal.show('SubscriptionPlanDetailModal')">
                    Change plan
                  </button>
                </div>
              </div>
            </template>
          </div>
          <!-- card -->
          <div class="latestBilling-section-1-card secondCard">
            <template v-if="api.cards.send && !primaryCard">
              <vb-icon icon="masterCard-newBilling-icon2" class="latestBilling-section-1-card-icon" />
              <div class="latestBilling-section-1-textSection">
                <div class="latestBilling-section-1-cardType">
                  <div class="latestShimmerDesign someTransparent" style="height:18px;width:50%"></div>
                </div>
                <div class="latestShimmerDesign someTransparent" style="height:24px;width:50%"></div>
                <div class="latestShimmerDesign someTransparent mt-16px" style="height:38px;width:100%"></div>
                <div class="latestBilling-section-1-lastRow mt-8px">
                  <div class="latestShimmerDesign someTransparent" style="height:18px;width:50%"></div>
                  <div class="latestShimmerDesign someTransparent ml-auto" style="height:50px;width:150px"></div>
                </div>
              </div>
            </template>
            <template v-else>
              <template v-if="primaryCard">
                <vb-icon icon="masterCard-newBilling-icon2" class="latestBilling-section-1-card-icon" />
                <div class="latestBilling-section-1-textSection">
                  <div class="latestBilling-section-1-cardType">Payment card</div>
                  <div class="latestBilling-section-1-creditCardNumberPart">
                    <div class="latestBilling-section-1-creditCardNumber">
                      <span class="d-flex align-items-center mr-20px">
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                      </span>
                      <span class="d-flex align-items-center mr-20px">
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                      </span>
                      <span class="d-flex align-items-center mr-20px">
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                        <span class="smallCircle"></span>
                      </span>
                      <div class="latestBilling-section-1-creditCardNumber-text">
                        {{ primaryCard | get_property('digits','0000') }}
                      </div>
                    </div>
                    <div class="latestBilling-section-1-creditCardNumberPart-cardHolder">{{primaryCard.label}}</div>
                    <div class="latestBilling-section-1-creditCardNumberPart-expiryDate">
                      {{ primaryCard | get_property('expiry_month','00') }}/{{ primaryCard | get_property('expiry_year','00') }}
                    </div>
                    <div class="d-flex align-items-center justify-content-end w-100 mt-8px">
                      <button class="dialer-button dialer-button-secondary color-primary font-weight-700 small px-4 border-0" @click="$modal.show(`${_uid}-OtherCreditCard`)">
                        Manage card
                      </button>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <vb-icon icon="masterCard-newBilling-icon2" class="latestBilling-section-1-card-icon" />
                <div class="latestBilling-section-1-textSection">
                  <div class="latestBilling-section-1-cardType">Payment card</div>
                  <div class="latestBilling-section-1-creditCardNumberPart">
                    <div class="latestBilling-section-1-creditCardNumber">
                      <div class="latestBilling-section-1-cardType">
                        Add your payment method
                      </div>
                    </div>
                    <p class="font-14">
                      Add your payment method now and elevate your experience with VoIP Business!
                    </p>
                    <div class="d-flex align-items-center justify-content-wnd w-100 mt-8px">
                      <button class="dialer-button dialer-button-primary" @click="$modal.show('AddCardStripe')">Add card</button>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
        <div class="latestBilling-tabsSection">
          <div class="TabsDesignWithIcon-container">
            <!-- tabs -->
            <div class="TabsDesignWithIcon-item" :id="`popover-1-${allTab.value}`" v-for="allTab in tabs" :key="allTab.id" @click="selected.tab = allTab.value" :class="{'active': selected.tab == allTab.value,'mwd-168px' : allTab.value == 'balance'}">
              <vb-icon :icon="allTab.icon" height="38px" width="38px" />
              <span class="TabsDesignWithIcon-item-textPart">{{allTab.text}}</span>
              <b-popover custom-class="latestTooltipVersion"
                :target="`popover-1-${allTab.value}`"
                placement="bottom"
                triggers="hover"
                :content="`${allTab.tooltipText}`"
              ></b-popover>
            </div>
          </div>
          <div class="latestBilling-balancesSection">
            <div class="latestBilling-balancesSection-leftSide">
              <div class="latestBilling-balancesSection-balance">£{{ response.invoice_detail | get_property('user_balance','0.00') | to_fixed }}</div>
              <div class="latestBilling-balancesSection-balanceTitle">Call credit</div>
            </div>
            <div class="latestBilling-balancesSection-rightSide">
              <div class="latestBilling-balancesSection-balance">£{{ response.sms_balance | get_property('sms_balance','0.00') | to_fixed }}</div>
              <div class="latestBilling-balancesSection-balanceTitle">SMS credit</div>
            </div>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 pt-3 mt-30px">
          <template v-if="selected.tab == 'invoices'">
            <vb-table
              class="trustedDevicesTable-updated latestTableDesign-withBlackBorders-again mt-0"
              :noRecordWholePage="true"
              :isListEmpty="response.invoices.length==0"
              :loading="api.invoices.send" 
              :listLength="pagination.invoices.total" 
              :perPage="10" 
              @page-changed="pagination.invoices.page=$event;fetchInvoices(false);"
              :conditions="{ 
                pagination: { 
                  per_page: false,
                } 
              }"
              :page="pagination.invoices.page"
            >
              <tr slot="header" class="w-100" v-if="!getIsMobile">
                <th class="border-0"> 
                  <span>Invoice</span>
                </th> 
                <th class=" border-0 ">
                  <span>Amount</span>
                </th>
                <th class=" border-0 ">
                  <span>Date</span>
                </th>
                <th class=" border-0 ">
                </th>
                <th class=" border-0 ">
                  <span><br/> </span>
                </th>
              </tr>
              <template #body>
                <template v-if="!getIsMobile">
                  <tr v-for="invoice in response.invoices" :key="invoice.id">
                    <td class="border-0">{{ invoice | filterInvoiceType }}</td>
                    <td class="border-0">{{ invoice.invoice_total_amount | to_fixed }}</td>
                    <td class="border-0">{{ invoice.invoice_paid_at | filter_date_current(false,{ formate: 'DD MMM YYYY' })  }}</td>
                    <td class="border-0">
                      <span :class="`OwnertaggedVersion ${invoice.invoice_status != 'PAID' ? 'red' : 'green'}`">{{ invoice.invoice_status }}</span>
                    </td>
                    <td class="border-0" >
                      <div class="d-flex justify-content-end w-100">
                        <span v-if="invoice.invoice_status != 'PAID'" @click="payOutstandingAmount(invoice)" class="OwnertaggedVersion mb-0 mr-16px showOnly-OnHover-insideTable">
                          Pay now
                        </span>
                        <span class="text-underline cursor_pointer_no_hover" @click="$modal.show('InvoicePDFDownloadAccountSetting',{id:invoice.invoice_id})">View invoice</span>
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(invoice, index) in invoices" :key="invoice.id" v-show="index >= start && index <= end">
                    <td class="left">
                      <div class="headingInsideTable">{{ invoice | filterInvoiceType }}</div>
                      <div class="textInsideCard">Amount: {{ invoice.invoice_total_amount | to_fixed }}</div>
                      <div class="textInsideCard">Paid at: {{ invoice.invoice_paid_at | filter_date_current(false,{ formate: 'DD MMM YYYY' }) }}</div>
                    </td>
                    <td class="dialer-col-right right">
                      <span :class="`OwnertaggedVersion ${invoice.invoice_status != 'PAID' ? 'red' : 'green'}`">{{ invoice.invoice_status }}</span>
                      <br/>
                      <span v-if="invoice.invoice_status != 'PAID'" @click="payOutstandingAmount(invoice)" class="OwnertaggedVersion mb-0 mr-16px showOnly-OnHover-insideTable">
                        Pay now
                      </span>
                      <span class="text-underline cursor_pointer_no_hover" @click="$modal.show('InvoicePDFDownloadAccountSetting',{id:invoice.invoice_id})">View invoice</span>
                    </td>
                  </tr>
                </template>
              </template>
              <template #loading>
                <tr v-for="n in 5" :key="n">
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                    </div>
                  </td>
                  <td class="dialer-col-right">
                    <div class="latestShimmerDesign ml-auto" style="height:19px;width:135px;"></div>
                  </td>
                </tr>
              </template>
              <template #no-record>
                <div class="latestGreyBox-9-9-2023 mt-32px p-0">
                  <div class="emptyScreenContainer">
                    <img class="mt-12px wd-30 h-auto" :src="require('@/assets/images/emptyScreenImages/invoices.png')"/>
                    <div class="emptyScreenContainer-heading">
                      Manage invoices and credit notes
                    </div>
                    <div class="emptyScreenContainer-text w-75">
                      View and manage your invoices and credit notes here. Start 
                      by creating a new invoice or credit note.
                    </div>
                  </div>
                </div>
              </template>
            </vb-table>
            <div @click="$modal.show('InvoiceDetailModal', {detail: response.account_detail.user_detail,})" class="latestGreyBox-heading-main-18px w-fit-content text-underline cursor_pointer_no_hover mt-20px">
              My invoice details
            </div>
          </template>
          <template v-else-if="selected.tab == 'users'">
            <vb-table
              class="trustedDevicesTable-updated latestTableDesign-withBlackBorders-again mt-0"
              :isListEmpty="users.length==0"
              :listLength="users.length"
              :perPage="5"
              :loading="api.account_detail.send"
            >
              <tr slot="header" class="w-100">
                <th>
                  <span>Name</span>
                </th>
                <th class="text-right">
                  <span>Unlimited call plan</span>
                </th>
              </tr>
              <template #body="{ start, end }">
                <tr class="w-100" v-for="(account, index) in users" :key="account" v-show="index >= start && index <= end">
                  <td class="dialer-row-title">
                    <Info :id="account" :is_blf="false" />
                  </td>
                  <td class="dialer-has-sort dialer-col-right">
                    <div class="d-flex justify-content-end">
                      <p v-if="response.account_detail.users[account].cancel_request>0">Cancel at {{ response.subscription_detail | get_property('userCurrentSubscription.subscription_next_billing_date') }}</p>
                      <div v-if="api.change_prefixes.send==account" class="latestShimmerDesign ml-auto" style="height:34px;width: 60px;border-radius: 60px;" />
                      <b-form-checkbox 
                        v-else
                        class="newerSwitch ml-auto" 
                        switch 
                        :disabled="!!api.change_prefixes.send || api.account_detail.send"
                        :checked="response.account_detail.users[account].plan_prefix_type=='unlimited'" 
                        :ref="`${account}-toggle`" 
                        @change="changePrefix(account)" 
                      />
                    </div>
                  </td>
                </tr>
              </template>
              <template #loading>
                <tr v-for="n in 5" :key="n">
                  <td>
                    <div class="d-flex">
                      <div class="latestShimmerDesign mr-2" style="height:35px;width:35px;"></div>
                      <div class="d-flex flex-column justify-content-between">
                        <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
                        <div class="latestShimmerDesign mt-1" style="height:16px;width:30px"></div>
                      </div>
                    </div>
                  </td>
                  <td class="dialer-col-right">
                    <div class="latestShimmerDesign ml-auto" style="height:32px;width:60px;"></div>
                  </td>
                </tr>
              </template>
            </vb-table>
          </template>
          <template v-else-if="selected.tab == 'whmcs'">
            <ul class="list-unstyled mb-0">
              <li>
                <vb-table 
                  class="trustedDevicesTable-updated latestTableDesign-withBlackBorders-again mt-0 paddingZeroInMobile"
                  :isListEmpty="Object.keys(subscriptionProductsGroupBy).length==0" 
                  :listLength="Object.keys(subscriptionProductsGroupBy).length" 
                  :perPage="5" 
                  :loading="api.fetch_subscriptions.send"
                >
                  <template #header>
                    <tr class="w-100" v-if="!getIsMobile">
                      <th class="dialer-has-sort ">
                        <span>name</span>
                      </th>
                      <th class="dialer-has-sort">
                        <span>type</span>
                      </th>
                      <th class="dialer-has-sort">
                        <span>next billing date</span>
                      </th>
                      <th class="dialer-has-sort">
                        <span>amount</span>
                      </th>
                      <th class="dialer-has-sort">
                        <span>status</span>
                      </th>
                      <th class="dialer-has-sort text-right">
                        <span>billing frequency</span>
                      </th>
                    </tr>
                  </template>
                  <template #body="{ start, end }">
                    <template v-if="!getIsMobile">
                      <template v-for="(data, key, index) in subscriptionProductsGroupBy">
                        <tr :key="key" v-show="index >= start && index <= end">
                          <td class="dialer-row-title ">{{ data | get_property('length') }}x</td>
                          <td class="dialer-row-title">{{ key }}</td>
                          <td class="dialer-row-title">
                            <span >{{ data | get_property('[0].nextBillingDate') }}</span>
                          </td>
                          <td class="dialer-col-left">{{ data | subscriptionGroupTotalPrice | to_fixed }}</td>
                          <td class="dialer-col-left">
                            <span class="OwnertaggedVersion" :class="{ 'green': data[0] && data[0].status=='ACTIVE' }">
                              {{ data | get_property('[0].status') }}
                            </span>
                          </td>
                          <td class="dialer-row-title dialer-col-right ">
                            {{ data | get_property('[0].billingFrequency') }}
                            <b-button @click="conditions.collapse=conditions.collapse==key?'':key" variant="link">
                              <b-icon :icon="conditions.collapse==key?'chevron-up':'chevron-down'" />
                            </b-button>
                          </td>
                        </tr>
                        <template v-if="conditions.collapse==key">
                          <tr :key="`collapse-${key}`" class="w-100" >
                            <td colspan="6" class="w-100 tableInsideTable-contaienr">
                              <table class="w-100 tableInsideTable mt-0">
                                <thead>
                                  <tr class="w-100">
                                    <th class="dialer-has-sort">
                                      <span>name</span>
                                    </th>
                                    <th class="dialer-has-sort">
                                      <span>type</span>
                                    </th>
                                    <th class="dialer-has-sort">
                                      <span>next billing date</span>
                                    </th>
                                    <th class="dialer-has-sort">
                                      <span>amount</span>
                                    </th>
                                    <th class="dialer-has-sort">
                                      <span>status</span>
                                    </th>
                                    <th class="dialer-has-sort text-right">
                                      <span>billing frequency</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody class="w-100">
                                  <tr class="w-100"
                                    v-for="(subs, subs_index) in data" 
                                    :key="subs_index" 
                                  >
                                    <td class="dialer-row-title">
                                      {{ subs | get_property('subscriptionName') }}
                                    </td>
                                    <td class="dialer-row-title">{{ subs | get_property('type') }}</td>
                                    <td class="dialer-row-title">
                                      <span >{{ subs | get_property('nextBillingDate') }}</span>
                                    </td>
                                    <td class="dialer-col-left">{{ subs.amountDue | to_fixed }}</td>
                                    <td class="dialer-col-left">
                                      <span class="OwnertaggedVersion" :class="{ 'green': subs.status=='ACTIVE' }">
                                        {{ subs | get_property('status') }}
                                      </span>
                                    </td>
                                    <td class="dialer-row-title dialer-col-right">{{ subs | get_property('billingFrequency') }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </template>
                      </template>
                    </template>
                    <template v-else>
                      <template v-for="(data, key, index) in subscriptionProductsGroupBy">
                        <tr :key="key" v-show="index >= start && index <= end">
                          <td class="left">
                            <div class="headingInsideTable">{{ data | get_property('length') }}x {{ key }}</div>
                            <div class="textInsideCard">{{ data | subscriptionGroupTotalPrice | to_fixed }}</div>
                            <div class="textInsideCard">{{ data | get_property('[0].billingFrequency') }}</div>
                          </td>
                          <td class="dialer-row-title right pr-1">
                            <div class="d-flex align-items-center">
                              <span class="OwnertaggedVersion" :class="{ 'green': data[0] && data[0].status=='ACTIVE' }">
                                {{ data | get_property('[0].status') }}
                              </span>
                              <b-button @click="conditions.collapse=conditions.collapse==key?'':key" variant="link" class="p-0" font-scale="0.8">
                                <b-icon :icon="conditions.collapse==key?'chevron-down':'chevron-right'" />
                              </b-button>
                            </div>
                          </td>
                        </tr>
                        <template v-if="conditions.collapse==key">
                          <tr :key="`collapse-${key}`" class="w-100" >
                            <td colspan="6" class="w-100 tableInsideTable-contaienr">
                              <table class="w-100 tableInsideTable mt-0">
                                <tbody class="w-100">
                                  <tr class="w-100"
                                    v-for="(subs, subs_index) in data" 
                                    :key="subs_index" 
                                  >
                                    <td class="left">
                                      <div class="headingInsideTable">{{ subs | get_property('subscriptionName') }}</div>
                                      <div class="textInsideCard">{{ subs | get_property('type') }}</div>
                                      <div class="textInsideCard">{{ subs.amountDue | to_fixed }} / {{ subs | get_property('billingFrequency') }}</div>
                                      <div class="textInsideCard">{{ subs | get_property('nextBillingDate') }}</div>
                                    </td>
                                    <td class="right dialer-col-right">
                                      <div class="d-flex align-items-center justify-content-end">
                                        <span class="OwnertaggedVersion" :class="{ 'green': subs.status=='ACTIVE' }">
                                          {{ subs | get_property('status') }}
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </template>
                      </template>
                    </template>
                  </template>
                  <template #loading>
                    <tr v-for="n in 5" :key="n">
                      <td>
                        <div class="d-flex w-fit-content">
                          <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex w-fit-content">
                          <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex w-fit-content">
                          <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex w-fit-content">
                          <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex w-fit-content">
                          <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                        </div>
                      </td>
                      <td class="dialer-col-right">
                        <div class="latestShimmerDesign ml-auto" style="height:19px;width:60px;"></div>
                      </td>
                    </tr>
                  </template>
                </vb-table>
              </li>
              <li>
                <button class="newBlueButton mt-50px" @click="$modal.show('viewPlanLicensesModal')">View plan license</button>
              </li>
            </ul>
          </template>
          <template v-else-if="selected.tab == 'balance'">
            <ul class="latestBilling-balanceSections list-unstyled mb-0">
              <li class="latestBilling-balanceSection first">
                <vb-icon icon="callCredit-newBilling-icon2" class="latestBilling-balanceSection-icon" />
                <div class="latestBilling-balanceSection-textSection">
                  <div class="latestBilling-balanceSection-heading">
                    <span>Out of plan call credit</span>
                    <b :id="`popover-creditAllowed`">£{{ response.invoice_detail | get_property('user_balance','0.00') | to_fixed }}</b>
                    <b-popover v-if="isPostPaidEnabled" custom-class="latestTooltipVersion"
                      :target="`popover-creditAllowed`"
                      placement="top"
                      triggers="hover"
                      :content="`Credit allowed: ${getProperty(response.invoice_detail,'voip_balance.creditAllowed',0)}`"
                    ></b-popover>
                  </div>
                  <div class="latestBilling-balanceSection-text">
                    This credit is used for calls made to destinations not included in your call plan.
                  </div>
                  <div class="latestBilling-balanceSection-lastRow">
                    <div v-if="!isPostPaidEnabled" class="latestGreyBox-heading-main-16px cursor_pointer_no_hover text-underline" @click="$modal.show('TopupSettingModal')">
                      Top up settings
                    </div>
                    <span class="OwnertaggedVersion mb-0" v-if="isPostPaidEnabled">£{{ response.invoice_detail | get_property('voip_balance.creditAllowed',0) }}</span>
                    <button class="latestBilling-balanceSection-newBlueButton" @click="$modal.show('AddCreditModal')">
                      Add credit
                    </button>
                  </div>
                </div>
              </li>
              <li class="latestBilling-balanceSection second">
                <vb-icon icon="SMSCredit-newBilling-icon2" class="latestBilling-balanceSection-icon" />
                <div class="latestBilling-balanceSection-textSection">
                  <div class="latestBilling-balanceSection-heading">
                    <span>SMS Credit</span>
                    <b>£{{ response.sms_balance | get_property('sms_balance','0.00') | to_fixed }}</b>
                  </div>
                  <div class="latestBilling-balanceSection-text">
                    This credit is used for sending SMS messages through your VoIPbusiness application.
                  </div>
                  <div class="latestBilling-balanceSection-lastRow justify-content-end">
                    <button class="latestBilling-balanceSection-newBlueButton" @click="$modal.show('AddCreditModal',{ sms_balance_products: true, })">
                      Add credit
                    </button>
                  </div>
                </div>
              </li>
              <li class="latestBilling-balanceSection third">
                <vb-icon icon="account-Balance-newBilling-icon2" class="latestBilling-balanceSection-icon" />
                <div class="latestBilling-balanceSection-textSection">
                  <div class="latestBilling-balanceSection-heading">
                    <span>Account Balance</span>
                    <b>£{{ response.balance | get_property('balance','0') | to_fixed }}</b>
                  </div>
                  <div class="latestBilling-balanceSection-text">
                    This balance is used to purchase additional services such as user subscriptions, phone numbers, and more within the VoIPbusiness application.
                  </div>
                  <div class="latestBilling-balanceSection-lastRow">
                    <div class="latestGreyBox-heading-main-16px cursor_pointer_no_hover text-underline" @click="$modal.show(`${_uid}-TransactionLogsModal`)">
                      Transaction logs
                    </div>
                    <button class="latestBilling-balanceSection-newBlueButton" 
                      @click="$modal.show('AddCreditModal',{
                        top_up_balance_products: true,
                      })"
                    >
                      Add credit
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </template>
        </div>
      </div>
      <modal name="viewPlanLicensesModal" class="dialer_animation right_side_popup" width="80%" height="auto" :scrollable="true">
        <div class="dialer-edit-header" v-if="getIsMobile">
          <h2 class="dialer-edit-title newer mb-0">Plan lincense</h2>
          <div class="d-flex align-items-center">
            <b-icon class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide('viewPlanLicensesModal')" />
          </div>
        </div>
        <div class="dialer-edit-header" v-else>
          <h2 class="dialer-edit-title newer mb-0">Plan lincense</h2>
          <div class="d-flex align-items-center">
            <a class="newCloseButton mr-3" @click="$modal.hide('viewPlanLicensesModal')">
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </a>
          </div>
        </div>
        <vb-table 
          class="latestTableDesign-withBlackBorders-again pt-3 mt-0"
          :isListEmpty="Object.keys(freeProducts).length==0" 
          :listLength="Object.keys(freeProducts).length" 
          :perPage="5" 
          :loading="api.subscription_detail.send"
        >
          <tr slot="header" v-if="!getIsMobile">
            <th class="dialer-has-sort">
              <span>Label</span>
            </th>
            <th class="dialer-has-sort">
              <span class=" ">Limit</span>
            </th>
            <th class="dialer-has-sort">
              <span class=" ">Used</span>
            </th>
            <th class="dialer-has-sort">
              <span class=" ">Remaining</span>
            </th>
            <th class="dialer-has-sort dialer-col-right">
            </th>
          </tr>
          <template #body="{ start, end }">
            <template v-for="(data, key, index) in freeProducts">
              <tr class :key="data.product_id"  v-show="index >= start && index <= end" v-if="!getIsMobile">
                <td class="dialer-row-title dialer-col-fixed-width">{{ data | get_property("product_label") }}</td>
                <td class="dialer-row-title dialer-col-fixed-width">{{ data | get_property("product_total_free_allowed", 0) }}</td>
                <td class="dialer-row-title dialer-col-fixed-width">{{ data | get_property("product_already_used", 0) }}</td>
                <td class="dialer-row-title dialer-col-fixed-width">{{ data | get_property("product_free_remaining", 0) }}</td>
                <td class="dialer-row-title dialer-col-right">
                  <button style="width: max-content"
                    v-if="getProperty(data, 'product_already_used', 0) > 0" 
                    class="dialer-button dialer-button-transparent showDetailButton color-575757" 
                    @click="$modal.show('ShowDetailsBillingModal',{ id: data.product_id })"
                  >
                    <vb-icon icon="show-sub-table-icon" height="16px" width="16px" color="primary"/>
                    View details
                  </button>
                </td>
              </tr>
              <tr class :key="data.product_id"  v-show="index >= start && index <= end" v-else>
                <td class="left">
                  <div class="headingInsideTable">{{ data | get_property("product_label") }}</div>
                  <div class="textInsideCard">Limit: {{ data | get_property("product_total_free_allowed", 0) }}</div>
                  <div class="textInsideCard">Used: {{ data | get_property("product_already_used", 0) }}</div>
                  <div class="textInsideCard">Remaining: {{ data | get_property("product_free_remaining", 0) }}</div>
                </td>
                <td class="dialer-col-right right">
                  <button variant="link"
                    v-if="getProperty(data, 'product_already_used', 0) > 0" 
                    class="p-0 b-0" 
                    @click="$modal.show('ShowDetailsBillingModal',{ id: data.product_id })"
                  >
                    <vb-icon icon="show-sub-table-icon" height="16px" width="16px" color="primary"/>
                  </button>
                </td>
              </tr>
            </template>
          </template>
          <template #loading>
            <tr v-for="n in 5" :key="n">
              <td>
                <div class="d-flex w-fit-content">
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </div>
              </td>
              <td>
                <div class="d-flex w-fit-content">
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </div>
              </td>
              <td>
                <div class="d-flex w-fit-content">
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </div>
              </td>
              <td>
                <div class="d-flex w-fit-content">
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </div>
              </td>
              <td class="dialer-col-right">
                <div class="latestShimmerDesign ml-auto" style="height:19px;width:60px;"></div>
              </td>
            </tr>
          </template>
        </vb-table>

        <div v-if="!isOnFreePlan" class="dialer-settings-title newer mb-0">Available license</div>
        <vb-table
          v-if="!isOnFreePlan"
          class="latestTableDesign-withBlackBorders-again pt-3"
          :isListEmpty="serviceAddons.length==0" 
          :listLength="serviceAddons.length" 
          :perPage="5" 
          :loading="api.addons.send"
        >
          <tr slot="header" class="w-100" v-if="!getIsMobile">
            <th class="dialer-has-sort ">
              <span>Label</span>
            </th>
            <th class="dialer-has-sort ">
              <span>Limit</span>
            </th>
            <th class="dialer-has-sort">
              <span>Used</span>
            </th>
            <th class="dialer-has-sort">
              <span>Remaining</span>
            </th>
            <th class="dialer-has-sort dialer-col-right ">
            </th>
          </tr>
          <template #body="{ start, end }">
            <template v-if="!getIsMobile">
              <tr v-for="(data, index) in serviceAddons" :key="data.product_id" class="w-100" v-show="index >= start && index <= end" >
                <td class="dialer-row-title dialer-col-fixed-width">{{ data | get_property(`label`) }}</td>
                <td class="dialer-row-title dialer-col-fixed-width">{{ data | get_property("allowed", 0) }}</td>
                <td class="dialer-row-title dialer-col-fixed-width">{{ data | get_property("used", 0) }}</td>
                <td class="dialer-row-title dialer-col-fixed-width">{{ data | get_property("remaining", 0) }}</td>
                <td class="dialer-row-title dialer-col-right">
                  <button style="width: max-content"
                    v-if="getProperty(data, 'used', 0) > 0" 
                    class="dialer-button dialer-button-transparent showDetailButton color-575757" 
                    @click="$modal.show('ShowDetailsBillingModal',{ id: data.product_id, is_addon: true })"
                  >
                    <vb-icon icon="show-sub-table-icon" height="16px" width="16px" color="primary"/>
                    View details
                  </button>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr v-for="(data, index) in serviceAddons" :key="data.product_id" class="w-100" v-show="index >= start && index <= end" >
                <td class="left">
                  <div class="headingInsideTable">{{ data | get_property("label") }}</div>
                  <div class="textInsideCard">Limit: {{ data | get_property("allowed", 0) }}</div>
                  <div class="textInsideCard">Used: {{ data | get_property("used", 0) }}</div>
                  <div class="textInsideCard">Remaining: {{ data | get_property("remaining", 0) }}</div>
                </td>
                <td class="dialer-col-right right">
                  <button variant="link"
                    v-if="getProperty(data, 'used', 0) > 0" 
                    class="p-0 b-0" 
                    @click="$modal.show('ShowDetailsBillingModal',{ id: data.product_id, is_addon: true })"
                  >
                    <vb-icon icon="show-sub-table-icon" height="16px" width="16px" color="primary"/>
                  </button>
                </td>
              </tr>
            </template>
          </template>
          <template #loading>
            <tr v-for="n in 5" :key="n">
              <td>
                <div class="d-flex w-fit-content">
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </div>
              </td>
              <td>
                <div class="d-flex w-fit-content">
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </div>
              </td>
              <td>
                <div class="d-flex w-fit-content">
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </div>
              </td>
              <td>
                <div class="d-flex w-fit-content">
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </div>
              </td>
              <td class="dialer-col-right">
                <div class="latestShimmerDesign ml-auto" style="height:19px;width:60px;"></div>
              </td>
            </tr>
          </template>
        </vb-table>
        <div>
          <template v-if="!isOnFreePlan">
            <div class="dialer-settings-title newer mb-0">Included Telephone Number</div>
            <vb-table
              class="latestTableDesign-withBlackBorders-again pt-3"
              :isListEmpty="!freeNumber" 
              :listLength="freeNumber?1:0" 
              :perPage="5" 
              :loading="api.addons.send"
            >
              <tr slot="header" class="w-100" v-if="!getIsMobile">
                <th class="dialer-has-sort ">
                  <span>Label</span>
                </th>
                <th class="dialer-has-sort">
                  <span>Remaining</span>
                </th>
                <th class="dialer-has-sort dialer-col-right ">
                </th>
              </tr>
              <template #body>
                <template v-if="!getIsMobile">
                  <tr v-if="freeNumber" class="w-100">
                    <td class="dialer-row-title dialer-col-fixed-width">Included Telephone Number</td>
                    <td class="dialer-row-title dialer-col-fixed-width">{{ freeNumber | get_property("remaining", 0) }}</td>
                    <td class="dialer-row-title dialer-col-right">
                      <button style="width: max-content"
                        v-if="getProperty(freeNumber, 'used', 0) > 0" 
                        class="dialer-button dialer-button-transparent showDetailButton color-575757" 
                        @click="$modal.show('ShowDetailsBillingModal',{ id: freeNumber.product_id, is_addon: true })"
                      >
                        <vb-icon icon="show-sub-table-icon" height="16px" width="16px" color="primary"/>
                        View details
                      </button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-if="freeNumber" class="w-100">
                    <td class="left">
                      <div class="headingInsideTable">Included Telephone Number</div>
                      <div class="textInsideCard">Remaining: {{ freeNumber | get_property("remaining", 0) }}</div>
                    </td>
                    <td class="right dialer-col-right">
                      <button variant="link"
                        v-if="getProperty(freeNumber, 'used', 0) > 0" 
                        class="p-0 b-0" 
                        @click="$modal.show('ShowDetailsBillingModal',{ id: freeNumber.product_id, is_addon: true })"
                      >
                        <vb-icon icon="show-sub-table-icon" height="16px" width="16px" color="primary"/>
                      </button>
                    </td>
                  </tr>
                </template>
              </template>
              <template #loading>
                <tr v-for="n in 1" :key="n">
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                    </div>
                  </td>
                  <td class="dialer-col-right">
                    <div class="latestShimmerDesign ml-auto" style="height:19px;width:60px;"></div>
                  </td>
                </tr>
              </template>
            </vb-table>
          </template>
        </div>

        <div class="dialer-settings-title newer mt-20px mb-0">Call bundles</div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <vb-table 
            class="latestTableDesign-withBlackBorders-again mt-0 pt-3"
            :isListEmpty="plans.length==0" 
            :listLength="plans.length" 
            :perPage="5" 
            :loading="api.whmcs_detail.send"
          >
            <template slot="header" v-if="!getIsMobile">
              <tr class="w-100">
                <th class="dialer-has-sort">
                  <span>Call plan name</span>
                </th>
                <th class="dialer-has-sort">
                  <span>Status</span>
                </th>
                <th class="dialer-has-sort text-right">
                  <span>Remaining minutes</span>
                </th>
              </tr>
            </template>
            <template #body="{ start, end }">
              <template v-if="!getIsMobile">
                <tr :class="`w-100`" 
                  v-for="(data, index) in plans" 
                  :key="index" 
                  v-show="index >= start && index <= end"
                >
                  <td class="dialer-row-title">{{ data | get_property('name') }}</td>
                  <td>{{ data.amount > 0 ? 'active' : 'inactive' }}</td>
                  <td class="dialer-row-title dialer-col-right">{{ data | get_property('amount') }}</td>
                </tr>
              </template>
              <template v-else>
                <tr :class="`w-100`" v-for="(data, index) in plans" :key="index" v-show="index >= start && index <= end">
                  <td class="left">
                    <div class="headingInsideTable">{{ data | get_property('name') }}</div>
                    <div class="textInsideCard">{{ data.amount > 0 ? 'active' : 'inactive' }}</div>
                  </td>
                  <td class="right dialer-col-right">{{ data | get_property('amount') }}</td>
                </tr>
              </template>
            </template>
            <template #loading>
              <tr v-for="n in 5" :key="n">
                <td>
                  <div class="d-flex w-fit-content">
                    <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                  </div>
                </td>
                <td>
                  <div class="d-flex w-fit-content">
                    <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                  </div>
                </td>
                <td class="dialer-col-right">
                  <div class="latestShimmerDesign ml-auto" style="height:19px;width:60px;"></div>
                </td>
              </tr>
            </template>
          </vb-table>
        </div>
        <div class="dialer-settings-title newer mt-30px">Reserve balance</div>
        <vb-table
          class="latestTableDesign-withBlackBorders-again pt-3"
          :isListEmpty="reserveBalances.length==0" 
          :listLength="reserveBalances.length" 
          :loading="api.fetch_reserve_balance.send"
          :noRecordWholePage="true"
        >
          <tr slot="header" class="w-100" v-if="!getIsMobile">
            <th > 
              <span>Name</span>
            </th> 
            <th > 
              <span>Type</span>
            </th> 
            <th > 
              <span>Description</span>
            </th> 
            <th >
              <span>Amount</span>
            </th>
            <th >
              <span>Status</span>
            </th>
            <th >
              <span>Expire at</span>
            </th>
            <th >
              <span>Created at</span>
            </th>
          </tr>
          <template #body="{ start, end }">
            <template v-if="!getIsMobile">
              <tr v-for="(reserveBalance, index) in reserveBalances" :key="reserveBalance.id" v-show="index >= start && index <= end">
                <td class="border-0">
                  <Info :is_blf="false" :id="reserveBalance.service_account" :type="reserveBalance.service_type=='Number'?'number':reserveBalance.service_type=='CALL_PLAN_PREFIX'?'USER':reserveBalance.service_type" />
                </td>
                <td class="border-0">{{ reserveBalance.service_type=='CALL_PLAN_PREFIX'?'Unlimited Call Plan':reserveBalance.service_type }}</td>
                <td class="border-0">{{ reserveBalance.description }}</td>
                <td class="border-0">{{ reserveBalance.amount | to_fixed }}</td>
                <td class="border-0">{{ reserveBalance.status }}</td>
                <td class="border-0">{{ reserveBalance.expire_at }}</td>
                <td class="border-0">{{ reserveBalance.created_at }}</td>
              </tr>
            </template>
            <template v-else>
              <tr v-for="(reserveBalance, index) in reserveBalances" :key="reserveBalance.id" v-show="index >= start && index <= end">
                <td class="left">
                  <Info :is_blf="false" :id="reserveBalance.service_account" :type="reserveBalance.service_type=='Number'?'number':reserveBalance.service_type=='CALL_PLAN_PREFIX'?'USER':reserveBalance.service_type" />
                  <div class="textInsideCard">Amount: {{ reserveBalance.amount | to_fixed }}</div>
                  <div class="textInsideCard">Expires: {{ reserveBalance.expire_at }}</div>
                  <div class="textInsideCard">{{ reserveBalance.service_type=='CALL_PLAN_PREFIX'?'Unlimited Call Plan':reserveBalance.service_type }}</div>
                  <div class="textInsideCard">{{ reserveBalance.description }}</div>
                </td>
                <td class="dialer-col-right right">
                  <div class="textInsideCard">{{ reserveBalance.status }}</div>
                </td>
              </tr>
            </template>
          </template>
          <template #loading>
            <tr v-for="n in 5" :key="n">
              <td>
                <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
              </td>
              <td>
                <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
              </td>
              <td>
                <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
              </td>
              <td class="dialer-col-right">
                <div class="latestShimmerDesign ml-auto" style="height:50px;width:135px;"></div>
              </td>
              <td class="dialer-col-right">
                <div class="latestShimmerDesign ml-auto" style="height:50px;width:135px;"></div>
              </td>
              <td class="dialer-col-right">
                <div class="latestShimmerDesign ml-auto" style="height:50px;width:135px;"></div>
              </td>
              <td class="dialer-col-right">
                <div class="latestShimmerDesign ml-auto" style="height:50px;width:135px;"></div>
              </td>
            </tr>
          </template>
          <template #no-record>
            <div class="latestGreyBox-9-9-2023 mt-32px p-0">
              <div class="emptyScreenContainer">
                <img class="mt-12px wd-30 h-auto" :src="require('@/assets/images/emptyScreenImages/invoices.png')"/>
                <div class="emptyScreenContainer-heading">
                  Manage invoices and credit notes
                </div>
                <div class="emptyScreenContainer-text w-75">
                  View and manage your invoices and credit notes here. Start 
                  by creating a new invoice or credit note.
                </div>
              </div>
            </div>
          </template>
        </vb-table>

      </modal>
      <modal class="dialer_animation right_side_popup viewPDFmodal full-width-big-modal" height="auto" :scrollable="true" :name="`${_uid}-unpaidInvoices`">
        <div class="dialer-edit-header d-flex align-items-start justify-content-between px-0 pt-3">
          <div class="dialer-edit-actions">
            <a class="cursor_pointer ml-2" @click="$modal.hide(`${_uid}-unpaidInvoices`)">
              <button type="button" class="newCloseButton"> 
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span> 
              </button>
            </a>
          </div>
        </div>
        <template>
          <vb-table
            class="trustedDevicesTable-updated latestTableDesign-withBlackBorders-again mt-0"
            :isListEmpty="unpaidInvoices.length==0" 
            :listLength="unpaidInvoices.length" 
            :perPage="5" 
            :loading="api.invoice_detail.send"
            :noRecordWholePage="true"
          >
            <tr slot="header" class="w-100">
              <th class="border-0"> 
                <span>Invoice</span>
              </th> 
              <th class=" border-0 ">
              </th>
              <th class=" border-0 ">
                <span><br/> </span>
              </th>
            </tr>
            <template #body="{ start, end }">
              <tr v-for="(invoice, index) in unpaidInvoices" :key="invoice.id" v-show="index >= start && index <= end">
                <td class="border-0">{{ invoice | filterInvoiceType }}</td>
                <td class="border-0">
                  <span class="OwnertaggedVersion green">
                    {{ invoice.invoice_status }}
                  </span>
                </td>
                <td class="border-0" >
                  <div class="d-flex justify-content-end w-100">
                    <span v-if="invoice.invoice_status!='PAID'" @click="payOutstandingAmount(invoice)" class="OwnertaggedVersion mb-0 mr-16px showOnly-OnHover-insideTable">
                      Pay now
                    </span>
                    <span class="text-underline cursor_pointer_no_hover" @click="$modal.show('InvoicePDFDownloadAccountSetting',{id:invoice.invoice_id})">View invoice</span>
                  </div>
                </td>
              </tr>
            </template>
            <template #loading>
              <tr v-for="n in 5" :key="n">
                <td>
                  <div class="d-flex w-fit-content">
                    <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                  </div>
                </td>
                <td>
                  <div class="d-flex w-fit-content">
                    <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                  </div>
                </td>
                <td>
                  <div class="d-flex w-fit-content">
                    <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                  </div>
                </td>
                <td class="dialer-col-right">
                  <div class="latestShimmerDesign ml-auto" style="height:50px;width:135px;"></div>
                </td>
              </tr>
            </template>
            <template #no-record>
              <div class="latestGreyBox-9-9-2023 mt-32px p-0">
                <div class="emptyScreenContainer">
                  <img class="mt-12px wd-30 h-auto" :src="require('@/assets/images/emptyScreenImages/invoices.png')"/>
                  <div class="emptyScreenContainer-heading">
                    Manage invoices and credit notes
                  </div>
                  <div class="emptyScreenContainer-text w-75">
                    View and manage your invoices and credit notes here. Start 
                    by creating a new invoice or credit note.
                  </div>
                </div>
              </div>
            </template>
          </vb-table>
        </template>
      </modal>
      <modal class="dialer_animation right_side_popup viewPDFmodal full-width-big-modal" height="auto" :scrollable="true" :name="`${_uid}-DetailReserveBalance`">
        <div class="dialer-edit-header d-flex align-items-center justify-content-between px-0 pt-3">
          <h2 class="dialer-edit-title newer mb-0">Reserve balance details</h2>
          <div class="dialer-edit-actions">
            <a class="cursor_pointer ml-2 newCloseButton" @click="$modal.hide(`${_uid}-DetailReserveBalance`)">
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span> 
            </a>
          </div>
        </div>
        <template>
          <vb-table
            class="latestTableDesign-withBlackBorders-again mt-0"
            :isListEmpty="reserveBalances.length==0" 
            :listLength="reserveBalances.length" 
            :loading="api.fetch_reserve_balance.send"
            :noRecordWholePage="true"
          >
            <tr slot="header" class="w-100">
              <th class="border-0"> 
                <span>Name</span>
              </th> 
              <th class="border-0"> 
                <span>Type</span>
              </th> 
              <th class="border-0"> 
                <span>Description</span>
              </th> 
              <th class="border-0">
                <span>Amount</span>
              </th>
              <th class="border-0">
                <span>Status</span>
              </th>
              <th class="border-0">
                <span>Expire at</span>
              </th>
              <th class="border-0">
                <span>Created at</span>
              </th>
            </tr>
            <template #body="{ start, end }">
              <tr v-for="(reserveBalance, index) in reserveBalances" :key="reserveBalance.id" v-show="index >= start && index <= end">
                <td class="border-0">{{ reserveBalance.service_account }}</td>
                <td class="border-0">{{ reserveBalance.service_type }}</td>
                <td class="border-0">{{ reserveBalance.description }}</td>
                <td class="border-0">{{ reserveBalance.amount | to_fixed }}</td>
                <td class="border-0">{{ reserveBalance.status }}</td>
                <td class="border-0">{{ reserveBalance.expire_at }}</td>
                <td class="border-0">{{ reserveBalance.created_at }}</td>
              </tr>
            </template>
            <template #loading>
              <tr v-for="n in 5" :key="n">
                <td>
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </td>
                <td>
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </td>
                <td>
                  <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                </td>
                <td class="dialer-col-right">
                  <div class="latestShimmerDesign ml-auto" style="height:50px;width:135px;"></div>
                </td>
                <td class="dialer-col-right">
                  <div class="latestShimmerDesign ml-auto" style="height:50px;width:135px;"></div>
                </td>
                <td class="dialer-col-right">
                  <div class="latestShimmerDesign ml-auto" style="height:50px;width:135px;"></div>
                </td>
                <td class="dialer-col-right">
                  <div class="latestShimmerDesign ml-auto" style="height:50px;width:135px;"></div>
                </td>
              </tr>
            </template>
            <template #no-record>
              <div class="latestGreyBox-9-9-2023 mt-32px p-0">
                <div class="emptyScreenContainer">
                  <img class="mt-12px wd-30 h-auto" :src="require('@/assets/images/emptyScreenImages/invoices.png')"/>
                  <div class="emptyScreenContainer-heading">
                    Manage invoices and credit notes
                  </div>
                  <div class="emptyScreenContainer-text w-75">
                    View and manage your invoices and credit notes here. Start 
                    by creating a new invoice or credit note.
                  </div>
                </div>
              </div>
            </template>
          </vb-table>
        </template>
      </modal>
      <modal 
        class="center-zoomIn-transition v2 TopUpProductsModal" 
        width="500px" 
        height="auto" 
        :scrollable="true" 
        :name="`${_uid}-TransactionLogsModal`" 
      >
        <section class="ct-user-new dialer-edit">
          <div class="dialer-form lg-mar-bottom">
            <div class="dialer-edit-header d-flex justify-content-between align-items-center">
              <div>
                <h2 class="dialer-settings-title newer mb-0">Transaction logs</h2>
              </div>
              <div class="dialer-edit-actions">
                <a class="newCloseButton" @click="$modal.hide(`${_uid}-TransactionLogsModal`)">
                  <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                  <span class="newCloseButton-textPart">Close</span>
                </a>
              </div>
            </div>
            <div class="md-mar-vertical">
              <vb-table 
                :noRecordWholePage="true"
                class="TopUpProductsBox latestTableDesign-withBlackBorders-again"
                :isListEmpty="response.credit_logs.length==0"
                :loading="api.credit_logs.send" 
                :listLength="pagination.credit_logs.total" 
                :perPage="10" 
                @page-changed="pagination.credit_logs.page=$event;fetchCreditLogs(false);"
                :conditions="{ 
                  pagination: { 
                    per_page: false,
                  } 
                }"
                :page="pagination.credit_logs.page"
              >
                <tr slot="header" class="w-100">
                  <th class="border-0"> 
                    <span>Amount</span>
                  </th> 
                  <th class="border-0"> 
                    <span>Type</span>
                  </th> 
                  <th class="border-0"> 
                    <span>Description</span>
                  </th> 
                </tr>
                <template #body>
                  <tr v-for="creditLog in response.credit_logs" :key="creditLog.id">
                    <td class="border-0">{{ creditLog.amount | to_fixed }}</td>
                    <td class="border-0">{{ creditLog.type }}</td>
                    <td class="border-0">{{ creditLog.description }}</td>
                  </tr>
                </template>
                <template #loading>
                  <tr v-for="n in 5" :key="n">
                    <td>
                      <div class="d-flex w-fit-content">
                        <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex w-fit-content">
                        <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex w-fit-content w-100">
                        <div class="latestShimmerDesign flex-1" style="height:19px;width:100%"></div>
                      </div>
                    </td>
                  </tr>
                </template>
                <template #no-record>
                  <div class="latestGreyBox-9-9-2023 mt-32px p-0">
                    <div class="emptyScreenContainer">
                      <img class="mt-12px wd-30 h-auto" :src="require('@/assets/images/emptyScreenImages/invoices.png')"/>
                      <div class="emptyScreenContainer-heading">
                        Manage invoices and credit notes
                      </div>
                      <div class="emptyScreenContainer-text w-75">
                        View and manage your invoices and credit notes here. Start 
                        by creating a new invoice or credit note.
                      </div>
                    </div>
                  </div>
                </template>
              </vb-table>
            </div>
          </div>
        </section>
      </modal>
      <InvoiceDetailModal @updated="fetchAccountDetail()" />
      <UserDetailModal />
      <ViewAllInvoicesModal />
      <TopupSettingModal />
      <OtherCardListModal 
        :modalName="`${_uid}-OtherCreditCard`" 
        ref="other_cards" 
        @fetch-cards="fetchCards()" 
        :isOnFreePlan="isOnFreePlan"
        :anyUnlimitedPlan="anyUnlimitedPlan" 
      />
      <PaymentDetailModal />
      <SetCardPriorityModal @priority-set="fetchCards()" @cards-updated="fetchCards();" />
      <SubscriptionPlanDetailModal 
        :allAddons="allAddons" 
        @update-plan="fetchAccountDetail();fetchSubscriptionDetail();fetchSubscriptions()" 
        @delete-service="fetchSubscriptionDetail();fetchSubscriptions();fetchAddons();" 
      />
      <NumbersListModal />
      <InvoicePDFDownload :modalName="`InvoicePDFDownloadAccountSetting`" />
      <AddCreditModal />
      <VsPackagesModal />
      <ShowDetailsBilling 
        :subscription_detail="response.subscription_detail" 
        :allAddons="allAddons" 
        @delete-service="fetchSubscriptionDetail();fetchSubscriptions();fetchAddons();" 
      />
      <AddCardStripeModal />
    </section>
  </div>
</template>

<script>
import InvoiceDetailModal from "../Modals/InvoiceDetailModal.vue";
import SubscriptionPlanDetailModal from "../Modals/settings/SubscriptionPlanDetailModal.vue";
import OtherCardListModal from "../Modals/settings/OtherCardList.vue";
import UserDetailModal from "../Modals/UserDetailModal.vue";
import InvoicePDFDownload from '../Modals/InvoicePDFDownload.vue'
import TopupSettingModal from "../Modals/TopupSettingModal.vue";
import SetCardPriorityModal from "../Modals/SetCardPriorityModal.vue";
import ShowDetailsBilling from "../Modals/settings/ShowDetailsBilling.vue";

import PaymentDetailModal from '../Modals/PaymentDetailModal.vue'
import NumbersListModal from '../Modals/NumbersListModal.vue';
import ViewAllInvoicesModal from '../Modals/ViewAllInvoicesModal.vue';
import AddCreditModal from '../Modals/AddCreitModal.vue';
import VsPackagesModal from "../Modals/VsPackagesModal.vue";
import { $fn, VOIP_API } from "../../utils";
import { mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment-timezone';



import Info from '../Lists/Info.vue';
import { check_service } from '../../mixin';
import AddCardStripeModal from '../Modals/AddCardStripeModal.vue';
import { AMI_EVENTS, AMI_SOCKET } from '@/ami';

let tabs = [
  {text:'Call plans',value:'users',tooltipText:'View all available user accounts linked to your account',icon:'newbilling-tab-callplans-icon'},
  {text:'Subscriptions',value:'whmcs',tooltipText:'Access information about your subscription details and preferences',icon:'newbilling-tab-Subscriptions-icon'},
  {text:'Invoices',value:'invoices',tooltipText:'Effortlessly manage invoices and credit notes in one place',icon:'newbilling-tab-invoices-icon'},
  {text:'Account balance',value:'balance',tooltipText:'Effortlessly manage invoices and credit notes in one place',icon:'newbilling-tab-accountBalance-icon'},
]

export default {
  name: "AccountSettings",
  mixins: [
    check_service
  ],
  inject:[
    'isEmpty',
    'getProperty',
    'appNotify',
    'getVoipUsers',
    'getVoipTeams',
    'getCallQueue',
    'getIvrs',
    'getChangePlanLog',
    'getFeatures',
    'appFetchSubscriptionDetail',
  ],
  components: {
    OtherCardListModal,
    InvoiceDetailModal,
    SubscriptionPlanDetailModal,
    UserDetailModal,
    AddCreditModal,
    SetCardPriorityModal,
    PaymentDetailModal,
    TopupSettingModal,
    NumbersListModal,
    ViewAllInvoicesModal,
    InvoicePDFDownload,
    VsPackagesModal,
    Info,
    ShowDetailsBilling,
    AddCardStripeModal
  },
  data() {
    return {
      api: {
        // cards
        cards: this.$helperFunction.apiInstance({  }),
        // account
        account_detail: this.$helperFunction.apiInstance({  }),
        // invoices
        invoice_detail: this.$helperFunction.apiInstance({  }),
        invoices: this.$helperFunction.apiInstance({  }),
        // credit logs
        credit_logs: this.$helperFunction.apiInstance({  }),
        // whmcs
        whmcs_detail: this.$helperFunction.apiInstance({  }),
        // prefixes
        prefixes: this.$helperFunction.apiInstance({  }),
        change_prefixes: this.$helperFunction.apiInstance({ send: '' }),
        update_addon_prefixes: this.$helperFunction.apiInstance({ send: '', }),
        // subscription
        subscription_detail: this.$helperFunction.apiInstance({  }),
        cancel_trail: this.$helperFunction.apiInstance({  }),
        reactivate_services: this.$helperFunction.apiInstance({  }),
        fetch_subscriptions: this.$helperFunction.apiInstance({  }),
        // reserve balance
        fetch_reserve_balance: this.$helperFunction.apiInstance({  }),
        // addon
        addons: this.$helperFunction.apiInstance({  }),
        // balance
        fetch_balance: this.$helperFunction.apiInstance({  }),
        fetch_sms_balance: this.$helperFunction.apiInstance({  }),
        pay_outstanding_blance: this.$helperFunction.apiInstance({  }),
      },
      response: {
        reserve_balance: {},
        cards: [],
        account_detail: {},
        invoice_detail: {},
        invoices: [],
        credit_logs: [],
        whmcs_detail: {},
        prefixes: {},
        subscription_detail: {},
        subscriptions: {},
        addons: {},
        balance: {},
        sms_balance: {},
        unpaid_invoices: [],
      },
      selected: {
        tab: tabs[0].value,
      },
      conditions: {
        collapse: '',
      },
      pagination: {
        invoices: {
          page: 1,
          total: 0,
        },
        credit_logs: {
          page: 1,
          total: 0,
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile',
      'getVoipUsersAlises',
      'getVoipNumbers',
    ]),
    // cards
    primaryCard(){ return this.response.cards?.find?.(item=>item.primary=='yes') },
    // users
    users(){ return Object.keys(this.response.account_detail.users || {}) },
    anyUnlimitedPlan(){ return Object.values(this.response.account_detail.users || {}).some(i=>i.plan_prefix_type=='unlimited') },
    // invoices
    invoices(){ return this.response.invoices ?? [] },
    unpaidInvoices(){ return this.response.unpaid_invoices },
    isAnyUnPaidInvoice(){ return this.unpaidInvoices.length>0 },
    // whmcs
    plans(){ return this.response.whmcs_detail?.plans ?? [] },
    // subscription
    currentPlanSuspended(){ return this.response.subscription_detail?.userCurrentSubscription?.subscription_status=="INACTIVE" },
    isOnTrail(){ return this.response.subscription_detail?.usercheckTrial?.trial?.trial_plan_status=='active' },
    isOnFreePlan(){ return this.response.subscription_detail?.userCurrentSubscription?.plan_detail?.plan_free==1 },
    freeProducts(){ return this.response.subscription_detail?.freeProductBreakDown ?? {} },
    subscriptionProductsGroupBy(){ return _.groupBy(this.response.subscriptions?.data ?? [], 'type'); },
    // addons
    allAddons(){ return Object.values(this.response.addons ?? {}) ?? [] },
    serviceAddons(){ return this.allAddons.filter(i=>i.type=='service' && !`${i.product_id}`.startsWith('free_number_')) },
    freeNumber(){ return this.allAddons.find(i=>i.type=='service' && `${i.product_id}`.startsWith('free_number_')) },
    isPostPaidEnabled(){ return this.response.invoice_detail?.voip_balance?.is_post_paid_enable=='yes' },
    // others
    tabs(){ return tabs },
    reserveBalances(){ return this.response.reserve_balance?.data ?? [] },
  },
  filters: {
    subscriptionGroupTotalPrice(data){ return data?.reduce?.((c,v)=>parseFloat(v.amountDue)+parseFloat(c),0) ?? 0 },
    filterInvoiceType(invoice){
      const { invoice_type, invoice_id } = invoice
      let type = invoice_type || ''
      if(type=='upgrade') type = 'Plan Change'
      else if(type=='RECURRING') type = 'Periodic'
      if(type) type += '-'
      return `${type}${invoice_id || ''}`
    },
    showTime(value,dv='-'){
      if (!value) return dv;
      return moment(value).format("MMM Do");
    },
  },
  methods: {
    // cards
    async fetchCards(){
      try {
        this.api.cards.send = true;
        const { data: { data: cards } } = await VOIP_API.endpoints.billing_api.getcreditcards({ 
          uid: this.getCurrentUser?.uid 
        })
        this.response.cards = cards;
      } finally {
        this.api.cards.send = false;
      }
    },
    // account detail
    async fetchAccountDetail(){
      try {
        this.api.account_detail.send = true;
        const { data } = await VOIP_API.endpoints.users.accountdetail()
        this.response.account_detail = data
      } finally {
        this.api.account_detail.send = false
      }
    },
    // invoice detail
    async fetchInvoiceDetail(){
      try {
        this.api.invoice_detail.send = true;
        const { data } = await VOIP_API.endpoints.users.accountinvoicedetail({
          uid: this.getCurrentUser?.uid,
        })
        this.response.invoice_detail = data
      } finally {
        this.api.invoice_detail.send = false
      }
    },
    async fetchInvoices(start=true){
      if(this.api.invoices.send) return;
      try {
        if(start) {
          this.pagination.invoices.page=1
          this.pagination.invoices.total=0
        }
        this.api.invoices.send = true;
        const { data } = await VOIP_API.endpoints.users.userInvoices({
          uid: this.getCurrentUser?.uid,
          api_type: 'invoice',
          page: this.pagination.invoices.page
        })
        if(this.pagination.invoices.page==1) {
          this.response.unpaid_invoices=data?.data?.filter?.(item=>item.invoice_status!='PAID') ?? []
        }
        this.pagination.invoices.total=data?.total ?? 0
        this.response.invoices = data?.data ?? []
      } finally {
        this.api.invoices.send = false
      }
    },
    // credit logs
    async fetchCreditLogs(start=true){
      if(this.api.credit_logs.send) return;
      try {
        if(start) {
          this.pagination.credit_logs.page=1
          this.pagination.credit_logs.total=0
        }
        this.api.credit_logs.send = true;
        const { data } = await VOIP_API.endpoints.users.userInvoices({
          uid: this.getCurrentUser?.uid,
          api_type: 'creditLogs',
          page: this.pagination.credit_logs.page
        })
        this.pagination.credit_logs.total=data?.creditLogCount ?? 0
        this.response.credit_logs = data?.creditLogs ?? []
      } finally {
        this.api.credit_logs.send = false
      }
    },
    // whmcs
    async fetchWHMCS(){
      if(this.api.whmcs_detail.send) return;
      try {
        this.api.whmcs_detail.send=true
        const { data: detail } = await VOIP_API.endpoints.billing.whmcsDetail({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
        })
        this.response.whmcs_detail= detail
      } finally {
        this.api.whmcs_detail.send=false
      }
    },
    // prefixes
    async changePrefix(account){
      if(this.api.change_prefixes.send) return;
      let value = this.response.account_detail.users[account].plan_prefix_type=='unlimited'
      try {
        this.api.change_prefixes.send=account
        if(!value) {
          const payload = await this.checkServicePromise({
            service: 'unlimited_call_plan',
            account: account,
          })
          if(payload?.finally) return;
          await VOIP_API.endpoints.techprifex.update(account,{
            account,
            accountcode: account,
          })
          value=!value
        } else {
          const { confirm } = await this.$appConfirmation({
            title: 'Warning',
            message: `With this action you are going to downgrade your package.`,
            button: {
              yes: 'Now',
              no: 'Till Next Billing Date',
            },
          })
          if(confirm==null) return;
          await VOIP_API.endpoints.techprifex.update(account,{
            account,
            accountcode: account,
            type: 'downgrade_call_plan_prefix',
            billing: confirm?0:1,
          })
        }
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.change_prefixes.send=''
        await this.$nextTick();
        const toggle_ref = this.$refs?.[`${account}-toggle`]?.[0]
        if(toggle_ref) toggle_ref.localChecked = value;
        this.response.account_detail.users[account].plan_prefix_type=value?'unlimited':'limited'
      }
    },
    // subscription
    async fetchSubscriptionDetail(){
      if(this.api.subscription_detail.send) return;
      try {
        this.api.subscription_detail.send = true;
        const { data } = await VOIP_API.endpoints.billing.getsubscriptiondetail({ 
          uid: this.getCurrentUser?.uid,
        })
        this.response.subscription_detail = data;
      } finally {
        this.api.subscription_detail.send = false;
      }
    },
    async fetchSubscriptions(){
      if(this.api.fetch_subscriptions.send) return;
      try {
        this.api.fetch_subscriptions.send = true;
        const { data } = await VOIP_API.endpoints.billing.getsubscriptions({ 
          uid: this.getCurrentUser?.uid,
        })
        this.response.subscriptions = data;
      } finally {
        this.api.fetch_subscriptions.send = false;
      }
    },
    async cancelTrail(){
      if(this.api.cancel_trail.send) return;
      try {
        const { confirm } = await this.$appConfirmation({
          title: "Warning",
          message: `Are you sure you want to end the trial`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        this.api.cancel_trail.send=true
        await VOIP_API.endpoints.billing.cancelTrail({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
        })
        this.fetchSubscriptionDetail()
        this.fetchSubscriptions()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.cancel_trail.send=false
      }
    },
    async reactivateServices(){
      if(this.api.reactivate_services.send) return;
      try {
        const { confirm } = await this.$appConfirmation({
          title: "Warning",
          message: `Are you sure you want to reactivate the services`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
          alert: {
            variant: 'info',
            title: 'Attention',
            message: `Make sure you sufficient balance to reactive services`,
          },
        })
        if(!confirm) return;
        this.api.reactivate_services.send=true
        await VOIP_API.endpoints.billing.reactivateServices({
          uid: this.getCurrentUser.uid,
        })
        this.fetchAccountDetail();
        this.fetchInvoiceDetail();
        this.fetchInvoices();
        this.fetchCreditLogs();
        this.fetchWHMCS();
        this.fetchPrefixes();
        this.fetchSubscriptionDetail();
        this.fetchSubscriptions();
        this.fetchAddons();
        
        this.getVoipUsers();
        this.getVoipTeams();
        this.getCallQueue();
        this.getIvrs();
        this.getChangePlanLog();
        this.getFeatures();
        this.appFetchSubscriptionDetail();
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.cancel_trail.send=false
      }
    },
    // addon
    async fetchAddons(){
      if(this.api.addons.send) return;
      try {
        this.api.addons.send = true;
        const { data } = await VOIP_API.endpoints.billing.getPlanAddonDetail({ 
          uid: this.$store.getters.getCurrentUser?.uid,
        })
        this.response.addons = data;
      } finally {
        this.api.addons.send = false;
      }
    },
    // balance
    async fetchBalance(){
      if(this.api.fetch_balance.send) return;
      try {
        this.api.fetch_balance.send=true
        const { data } = await VOIP_API.endpoints.billing.getbalance({
          uid: this.getCurrentUser.uid,
        })
        this.response.balance=data??{}
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_balance.send=false
      }
    },
    async fetchSMSBalance(){
      if(this.api.fetch_sms_balance.send) return;
      try {
        this.api.fetch_sms_balance.send=true
        const { data } = await VOIP_API.endpoints.billing.getsmsbalance({
          uid: this.getCurrentUser.uid,
        })
        this.response.sms_balance=data??{}
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_sms_balance.send=false
      }
    },
    async fetchReserveBalance(){
      try {
        this.api.fetch_reserve_balance.send = true;
        const { data } = await VOIP_API.endpoints.billing_api.getReserveBalance({ 
          uid: this.getCurrentUser?.uid 
        })
        this.response.reserve_balance=data ?? {}
      } finally {
        this.api.fetch_reserve_balance.send = false;
      }
    },
    // pay outstanding amount
    async payOutstandingAmount(invoice){
      if(this.api.pay_outstanding_blance.send) return;
      try {
        const { confirm } = await this.$appConfirmation({
          title: 'Warning',
          message: `Are you sure you want to manually pay this invoice`,
          button: {
            yes: 'Yes',
            no: 'No',
          },
        })
        if(!confirm) return;
        const { data } = await VOIP_API.endpoints.billing_api.payOutstandingAmount({
          accountcode: this.getCurrentUser?.account,
          uid: this.getCurrentUser?.uid ,
          invoice_id: invoice.invoice_id,
          sub_id: invoice.sub_id,
          invoice_status: invoice.invoice_status,
          payment_gateway: "stripe",
          invoice_total_amount: invoice.invoice_total_amount,
          invoice_description: invoice.invoice_description,
        })
        if(data.invoice_status == 'PAID'){
          this.fetchInvoices()
          this.fetchCreditLogs()
          this.appNotify({
            message: 'Successfully Payed!',
            type: 'success',
          })
        }
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.pay_outstanding_blance.send=''
      }
    },
    // event
    onNotification(message) {
      let vm = this
      if (message.data.action == "add" && message.data.section == "plan") {
        this.fetchCards();
        this.$refs?.other_cards?.fetchCards?.()
        this.fetchAccountDetail();
        this.fetchInvoiceDetail();
        this.fetchInvoices();
        this.fetchCreditLogs()
        $fn.sleep(3 * 1000)
        .then(()=>{
          vm.fetchSubscriptionDetail()
          vm.fetchSubscriptions()
        })
      }
      if (message.data.flag == "card_added") {
        this.fetchCards();
        this.$refs?.other_cards?.fetchCards?.()
        this.fetchAccountDetail();
        this.fetchInvoiceDetail();
        this.fetchInvoices();
        this.fetchCreditLogs()
        $fn.sleep(3 * 1000)
        .then(()=>{
          vm.fetchSubscriptionDetail()
          vm.fetchSubscriptions()
        })
      }
      if(message.data.flag=='PLAN_SUSPENDED' || message.data.flag=='PLAN_TERMINATE'){
        $fn.sleep(3 * 1000)
        .then(()=>{
          vm.fetchSubscriptionDetail()
          vm.fetchSubscriptions()
          vm.fetchWHMCS()
        })
      }
      if(message.data.flag=='TRIAL_ENDED'){
        $fn.sleep(3 * 1000)
        .then(()=>{
          vm.fetchSubscriptionDetail()
          vm.fetchSubscriptions()
          vm.fetchWHMCS()
        })
      }
      if(message.data.flag=='PLAN_CHANGED'){
        $fn.sleep(3 * 1000)
        .then(()=>{
          vm.fetchSubscriptionDetail()
          vm.fetchSubscriptions()
          vm.fetchWHMCS()
        })
      }
      if(message.data.action == "new_card_add" && message.data.section == "card" && message.data.flag == "new_card"){
        this.fetchCards();
        this.$refs?.other_cards?.fetchCards?.()
        $fn.sleep(3 * 1000)
        .then(()=>{
          vm.fetchSubscriptionDetail()
          vm.fetchSubscriptions()
        })
      }
      if(message.data.flag == "CLOSE_PAYMENT_MODAL") {
        this.fetchCards();
        this.$refs?.other_cards?.fetchCards?.()
        $fn.sleep(3 * 1000)
        .then(()=>{
          vm.fetchSubscriptionDetail()
          vm.fetchSubscriptions()
        })
      }
      if(message.data.flag == "topup_added") {
        this.fetchBalance()
        this.fetchInvoices()
        this.fetchCreditLogs()
        this.fetchInvoiceDetail()
        this.fetchSMSBalance()
      }
      if(message.data.flag == "UPDATE_CALL_PLAN") {
        this.fetchAccountDetail();
        this.fetchInvoices();
        this.fetchCreditLogs()
        this.fetchInvoiceDetail();
      }
    },
  },
  activated() {
    this.fetchBalance();
    this.fetchSMSBalance();
    this.fetchCards();
    this.fetchAccountDetail();
    this.fetchInvoiceDetail();
    this.fetchInvoices();
    this.fetchCreditLogs();
    this.fetchWHMCS();
    this.fetchSubscriptionDetail();
    this.fetchSubscriptions()
    this.fetchAddons();
    this.fetchReserveBalance();
  },
  created() {
    AMI_SOCKET.on(AMI_EVENTS.broadcast,this.onNotification)
  },
  beforeDestroy() {
    AMI_SOCKET.off(AMI_EVENTS.broadcast,this.onNotification)
  },
};
</script>

<style lang="scss">
#credit-slip {
  width: 100%;
  height: 100%;
  background-color: #eee;
}
.add-topUpBtn {
  position: absolute;
  top: -80px;
  right: 0px;
}
</style>