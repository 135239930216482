<template>
  <div>
    <modal 
      class="AfterCallFeedbackModal" 
      width="50%" 
      :scrollable="true" 
      height="auto" 
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="AfterCallFeedbackModal-heading">CALL ENDED</div>
        <button class="newDoneButton" @click="$modal.hide(modalName)">
          <vb-icon icon="squared-done-icon" height="38px" width="38px" />
          <span class="newDoneButton-textPart">Done</span>
        </button>
      </div>
      <div v-if="data.call_info" class="latestGreyBox-9-9-2023 mt-32px">
        <div class="whiteBoxWithBorderInsideGreyBox">
          <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-center justify-content-center">
            <template v-if="api.fetch_cdr.send">
              <div class="latestShimmerDesign" style="height: 89px;width: 100%;border-radius: 14px;margin-bottom: 16px;"></div>
            </template>
            <template v-else>
              <CallActivityItem1 v-if="response.cdr" class="w-100 mx-0" :call="response.cdr" :conditions="{actions: false}" />
              <CallActivityItem1 v-else-if="callLog" class="w-100 mx-0" :call="callLog" :conditions="{actions: false}" />
            </template>
            <div class="d-flex flex-column align-items-center justify-content-center pb-5 mt-3">
              <div class="latestGreyBox-heading-main">How was the quality of your call?</div>
              <b-form-rating id="rating-lg-no-border" class="latestCallFeedback" no-border size="lg" v-model="forms.feedback.rating" @change="updateFeedback()" />
            </div>
            <div class="d-flex align-items-center justify-content-around w-100">
              <vb-icon 
                @click="$modal.show('CallFeedbackNoteModal',{ 
                  id: data.call_info.call_id,
                  note: data.call_info.note 
                })" 
                icon="callFeedbackModal-Notes-icon" 
                height="100px" 
                width="100px" 
              />
              <vb-icon 
                @click="$modal.show('CallFeedbackTagsModal',{ 
                  id: data.call_info.call_id,
                  tags: data.call_info.tags
                })" 
                icon="callFeedbackModal-Tags-icon" 
                height="100px" 
                width="100px" 
              />
              <vb-icon icon="callFeedbackModal-assign-icon" @click="addTodoTask()" height="100px" width="100px" />
            </div>
          </div>
        </div>
      </div>
    </modal>
    <NoteModal :modalName="`CallFeedbackNoteModal`" @updated="fetchCDR()" @updated-note="data.call_info.note = $event" />
    <TagsModal :modalName="`CallFeedbackTagsModal`" @updated="fetchCDR()" @updated-tags="data.call_info.tags = $event" />
  </div>
</template>

<script>             
import CallActivityItem1 from "@/components/Home/CallActivity/Item1.vue";
import NoteModal from "@/components/Modals/NoteModal.vue";
import TagsModal from "@/components/Modals/TagsModal.vue";
import { events, VOIP_API } from '@/utils';
import { number_formater } from '@/filter'
import { mapGetters } from 'vuex';
import required from 'vuelidate/lib/validators/required';
export default {
  name: 'CallFeedbackModal',
  components: {
    CallActivityItem1,
    NoteModal,
    TagsModal,
  },
  inject: [
    'isEmpty','appNotify'
  ],
  props: {
    modalName: {
      type: String,
      default: 'CallFeedback',
    },
  },
  data() {
    return {
      api: {
        fetch_cdr: this.$helperFunction.apiInstance({}),
        update_feedback: this.$helperFunction.apiInstance({}),
        add_todo: this.$helperFunction.apiInstance(),
      },
      forms: {
        feedback: this.$helperFunction.formInstance({
          data: {
            rating: '0.0',
          }
        }),
      },
      response: {
        cdr: {},
      },
      data: {
        call_info: {
          active:true,
          call_transfered:false,
          call_type:"",
          caller_id:"",
          cids:{
            from: {
              caller_id:"",
              label:"",
            },
            to: {
              caller_id:"",
              label:"",
            },
          },
          from_name:"bill morgan",
          from_number:"07397909344",
          from_other:"",
          from_team:"",
          hold:false,
          image:"https://api.k2.duplex.network/storage/defaults/logos/xrg6DM.png",
          incall:true,
          incoming:true,
          is_current_call:false,
          is_mute:true,
          is_transfer:false,
          name:"bill morgan",
          outgoing:false,
          sub_info:"07397909344",
          test_call:false,
          to_name:"Freddy Hajat",
          to_number:"8001",
          to_other:"",
          uuid:"zrp5u1zzy",
          call_id:"549cd45545b446bb6b64aea1607bea51@sip1.k2.duplex.network"
        },
      },
      userDp: require("@/assets/images/user-male.png"),
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
    callInfo(){
      const { 
        call_type, 
        status, 
        caller, 
        caller_name,
        callee,
        user, 
        sourceName,
        sourceExt,
        destinationName, 
        destinationExt,
        caller_extension_detail, 
        caller_extension_type,
        callee_extension_detail,
        callee_extension_type,
        remote_caller_id,
        dialable,
        contact_name,
        is_transfered,
        transfer_from,
        transfer_to,
        is_forward,
        forward_from,
        forward_to,
        receiver,
        sms_type,
      } = this.response.cdr ?? {}
      let icon = ''
      let text = ''
      let user_name = ''
      let user_extn = ''
      let dial = ''
      let via = ''
      let is_external = false
      let transfer = {
        enabled: false,
        to: {
          number: '',
          accountcode: '',
        },
        from: {
          number: '',
          accountcode: '',
        },
      }
      let forward = {
        enabled: false,
        to: {
          number: '',
          accountcode: '',
        },
        from: {
          number: '',
          accountcode: '',
        },
      }
      if(call_type=='audio'){
        if(status=='Missed') {
          icon = 'CallActivityV2-missedCall-icon'
          text = 'Missed Audio Call'
          user_name = sourceName || ''
          user_extn = sourceExt || ''
        } else if(status=='Received') {
          icon = 'CallActivityV2-receivedCall-icon'
          text = 'Received Audio Call'
          user_name = sourceName || ''
          user_extn = sourceExt || ''
        } else {
          icon = 'CallActivityV2-dialledCall-icon'
          text = 'Dialed Audio Call'
          user_name = destinationName || ''
          user_extn = destinationExt || ''
        }
        if(!caller_extension_type || !callee_extension_type) {
          is_external = true
        }
        if(remote_caller_id){
          via = remote_caller_id
        } else if(!caller_extension_type && typeof callee_extension_detail == 'string') {
          via = callee_extension_detail
        } else if(!callee_extension_type && typeof caller_extension_detail == 'string') {
          via = caller_extension_detail
        }
      } else if(call_type=='video'){
        user_name = destinationName || ''
        user_extn = destinationExt || ''
        dial = dialable
        if(status=='Missed') {
          icon = 'CallActivityV2-missedVideoCall-icon'
          text = 'Missed Video Call'
        } else if(status=='Received') {
          icon = 'CallActivityV2-receivedVideoCall-icon'
          text = 'Received Video Call'
        } else {
          icon = 'CallActivityV2-dialledVideoCall-icon'
          text = 'Dialed Video Call'
        }
      } else if(call_type=='voicemail'){
        if(caller_name=='-') {
          user_name = caller?.replace?.(/"/g, "") ?? ''
          user_extn = callee?.replace?.(/"/g, "") ?? ''
        } else {
          user_name = caller_name ?? ''
          user_extn = caller?.replace?.(/"/g, "") ?? ''
        }
        icon = 'CallActivityV2-audioMessage-icon'
        text = 'Voicemail'
      } else if(call_type=='sms'){
        icon = 'CallActivityV2-message-icon'
        text = 'SMS'
        if(sms_type=='custom') {
          user_name = user ?? ''
          // via = user ?? ''
        } else if(sms_type=='inbound') {
          user_name = receiver ?? ''
          via = user ?? ''
        } else {
          user_name = receiver ?? ''
          via = user ?? ''
        }
      }
      if(!dial) {
        dial = user_extn || user_name
      }
      if(is_transfered) {
        transfer.enabled=true
        if(typeof transfer_to == 'string') {
          transfer.to.number=transfer_to
        } else {
          transfer.to.accountcode=transfer_to?.accountcode ?? ''
        }
        if(typeof transfer_from == 'string') {
          transfer.from.number=transfer_from
        } else {
          transfer.from.accountcode=transfer_from?.accountcode ?? ''
        }
      }
      if(is_forward) {
        forward.enabled=true
        if(typeof forward_to == 'string') {
          forward.to.number=forward_to
        } else {
          forward.to.accountcode=forward_to?.accountcode ?? ''
        }
        if(typeof forward_from == 'string') {
          forward.from.number=forward_from
        } else {
          forward.from.accountcode=forward_from?.accountcode ?? ''
        }
      }
      return {
        icon,
        text,
        user_name: contact_name || user_name,
        user_extn,
        dialable: dial,
        via,
        is_external,
        transfer: transfer,
        forward: forward,
      }
    },
    callLog(){
      return this.$store.state.common.call_activity.data.find(call=>call.uniqueid==this.data.call_info?.extra_data?.uniqe_id) ?? {}
    },
  },
  validations: {
    forms: {
      feedback: {
        rating: {
          required: required,
        }
      },
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.call_info=event?.params?.info
      this.fetchCDR()
    },
    onBeforeClose() {
      this.api.fetch_cdr.reset()
      this.api.update_feedback.reset()
      this.forms.feedback.reset()
      this.response.cdr={}
      this.data.call_info={}
      this.$root.$emit(events.fetch_call_activity_interval)
    },
    async fetchCDR(){
      if(this.api.fetch_cdr.send) return;
      try {
        this.api.fetch_cdr.send=true
        const { data: { data } } = await VOIP_API.endpoints.call_activity.getCDR({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          cdr_id: this.data.call_info.call_id,
        })
        this.response.cdr=data
        this.forms.feedback.rating=parseFloat(data?.feedback ?? 0).toFixed(1)
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_cdr.send=false
      }
    },
    async updateFeedback(){
      this.forms.feedback.submitted=true
      this.$v.forms.feedback.$touch()
      if(this.api.update_feedback.send || this.$v.forms.feedback.$invalid) return;
      try {
        this.api.update_feedback.send=true
        await VOIP_API.endpoints.call_activity.callFeedback({
          accountcode: this.getCurrentUser.account,
          description: '',
          caller_id: this.data.call_info.call_id,
          stars: this.forms.feedback.rating,
        })
        this.appNotify({
          message: 'Successfully Updated',
          type: 'success',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.forms.feedback.submitted=false
        this.api.update_feedback.send=false
      }
    },
    async addTodoTask(){
      if(this.api.add_todo.send || this.isTodoAdded) return;
      try {
        this.api.add_todo.send=true
        let type = 'outgoing'
        if(this.response.cdr.status=='Missed') type='missed'
        else if(this.response.cdr.status=='Received') type='incoming'
        const { data } = await VOIP_API.endpoints.tasklist.callAssign({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          title: number_formater(this.callInfo.user_name),
          cdr_id: this.response.cdr.callerid,
          type: type
        })
        this.todo_added=true
        this.appNotify({
          message: 'Successfully Added to Tasks',
          type: 'success',
        })
        this.$modal.show('GlobalEditTask',{
          id: data.id,
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.add_todo.send=false
      }
    },
  },
}
</script>

<style>
.vm--container.opacity-1-imp{
  opacity: 1 !important;
  max-height: 100vh !important;
  transition: none !important;
  height: 100vh !important;
}
.dialer-call-notification:has(.vm--container.opacity-1-imp){
  opacity: 1 !important;
  max-height: 100vh !important;
  transition: none !important;
  height: 100vh !important;
}
/* .vm--container.opacity-1-imp *{
  opacity: 1 !important;
} */
</style>